<template>
  <div class="asset-prop">
    <p class="prop-value-designer">{{ title }}</p>
    <p :class="'prop-title'">
      {{ value }}
    </p>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "PropertyCard",
  props: ["title", "value"],
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
};
</script>
<style scoped>
.asset-prop {
  padding: 5px;
  background: rgba(217, 217, 217, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--designer-color);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}
.prop-title {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin: 0px;
}
.prop-value-designer {
  font-size: 16px;
  font-weight: 400;
  color: var(--designer-color);
  margin: 0px;
}
.prop-value-other {
  font-size: 16px;
  font-weight: 400;
  color: var(--secondary-color);
  margin: 0px;
}
</style>
