<template>
  <div class="home">
    <div class="home-cover"></div>
    <div :class="user.role === 'designer'? 'home-container-designer' :'home-container'">
      <div class="profile">
        <v-img :src="user.profile" class="img"></v-img>
      </div>
      <div class="edit-profile">
        <div class="profile-circle" @click="editProfile()">
          <v-icon :color = "user.role==='designer'?'#eb5129':'#00ffbf'" size="20">mdi-pen</v-icon>
        </div>
      </div>
      <div class="user-profile">
        <v-row>
          <v-col class="mr-10" sm="12" md="3" lg="3">
            <div :class = "user.role==='designer'?'profile-div-designer':'profile-div'">
              <v-row class="text-center">
                <v-col md="1" lg="1">
                  <v-icon :color = "user.role==='designer'?'#eb5129':'#00ffbf'" size="20">mdi-account</v-icon>
                </v-col>
                <v-col md="4" lg="6">
                  <h3>Full Name</h3>
                </v-col>
              </v-row>
              <p>{{ user.name }}</p>
            </div>
          </v-col>
          <v-col class="mr-10" sm="12" md="3" lg="3">
            <div :class = "user.role==='designer'?'profile-div-designer':'profile-div'">
              <v-row class="text-center">
                <v-col md="1" lg="1">
                  <v-icon :color = "user.role==='designer'?'#eb5129':'#00ffbf'" size="20">mdi-phone</v-icon>
                </v-col>
                <v-col md="4" lg="7">
                  <h3>Phone Number</h3>
                </v-col>
              </v-row>
              <p>{{ user.phone }}</p>
            </div>
          </v-col>
          <v-col class="mr-10" sm="12" md="3" lg="3">
            <div :class = "user.role==='designer'?'profile-div-designer':'profile-div'">
              <v-row class="text-center">
                <v-col md="1" lg="1">
                  <v-icon :color = "user.role==='designer'?'#eb5129':'#00ffbf'" size="20">mdi-email</v-icon>
                </v-col>
                <v-col md="4" lg="7">
                  <h3>Email Address</h3>
                </v-col>
              </v-row>
              <p>{{ user.email }}</p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mr-10" sm="12" md="3" lg="3">
            <div :class = "user.role==='designer'?'profile-div-designer':'profile-div'">
              <v-row class="text-center">
                <v-col md="1" lg="1">
                  <v-icon :color = "user.role==='designer'?'#eb5129':'#00ffbf'" size="20">mdi-star</v-icon>
                </v-col>
                <v-col md="4" lg="8">
                  <h3>Area of Specialization</h3>
                </v-col>
              </v-row>
              <p>{{ user.specialization }}</p>
            </div>
          </v-col>
          <v-col class="mr-10" sm="12" md="3" lg="3">
            <div :class = "user.role==='designer'?'profile-div-designer':'profile-div'">
              <v-row class="text-center">
                <v-col md="1" lg="1">
                  <v-icon :color = "user.role==='designer'?'#eb5129':'#00ffbf'" size="20">mdi-briefcase</v-icon>
                </v-col>
                <v-col md="4" lg="7">
                  <h3>ShowCase Work</h3>
                </v-col>
              </v-row>
              <p>{{ user.workUrl }}</p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mr-10" sm="12" md="6" lg="6">
            <div :class = "user.role==='designer'?'profile-div-about-designer':'profile-div-about'">
              <v-row class="text-center">
                <v-col md="1" lg="1">
                  <v-icon :color = "user.role==='designer'?'#eb5129':'#00ffbf'" size="20"
                    >mdi-card-account-details</v-icon
                  >
                </v-col>
                <v-col md="4" lg="2">
                  <h3>About You</h3>
                </v-col>
              </v-row>
              <p>{{ user.about }}</p>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <EditUserProfile @close="close" :visible="showEdit" :user="user"></EditUserProfile>
  </div>
</template>

<script>
import EditUserProfile from "../components/Modals/EditUserProfile.vue";
import { mapState } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      showEdit: false,
      userDetails: {},
    };
  },
  methods: {
    editProfile() {
      this.showEdit = true;
    },
    close() {
      this.showEdit = false;
    },
  },
  components: { EditUserProfile },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
};
</script>
<style>
.home {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.home-cover {
  width: 100%;
  height: 30%;
  background-image: url("../assets/profile/cover.svg");
  background-size: cover;
}
.home-container {
  width: 100%;
  height: 70%;
  background-image: url("../assets/profile/content.svg");
  background-size: cover;
  position: relative;
}
.home-container-designer {
  width: 100%;
  height: 70%;
  background-image: url("../assets/profile/background-designer.svg");
  background-size: cover;
  position: relative;
}
.profile {
  position: absolute;
  width: 150px;
  height: 150px;
  top: -15%;
  left: 20px;
  border-radius: 50%;
}
.img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
}
.user-profile {
  padding-top: 5%;
  padding-left: 20px;
}
.profile-div {
  width: 100%;
  height: 70px;
  background: rgba(217, 217, 217, 0.2);
  border: 1px solid #00ffbf;
  backdrop-filter: blur(4px);
  border-radius: 10px;
  padding: 10px;
}
.profile-div-designer {
  width: 100%;
  height: 70px;
  background: rgba(217, 217, 217, 0.2);
  border: 1px solid var(--designer-color);;
  backdrop-filter: blur(4px);
  border-radius: 10px;
  padding: 10px;
}
.profile-div-about {
  width: 100%;
  height: 120px;
  background: rgba(217, 217, 217, 0.2);
  border: 1px solid #00ffbf;
  backdrop-filter: blur(4px);
  border-radius: 10px;
  padding: 10px;
}
.profile-div-about-designer {
  width: 100%;
  height: 120px;
  background: rgba(217, 217, 217, 0.2);
  border: 1px solid var(--designer-color);
  backdrop-filter: blur(4px);
  border-radius: 10px;
  padding: 10px;
}
.edit-profile {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 30px;
}
.profile-circle {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(217, 217, 217, 0.52);
  cursor: pointer;
}
h3 {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  font-size: 14px;
}
p {
  color: white;
  font-weight: 500;
  margin-left: 45px;
  max-lines: 1;
  font-size: 12px;
}
</style>
