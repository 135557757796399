<template>
  <v-dialog
    transition="dialog-bottom-transition"
    persistent
    v-model="visible"
    class="dialog"
    max-width="500"
  >
    <div class="card-body dialog">
      <div class="card-header">
        <v-row>
          <v-col lg="10">
            <h3>Add Properties</h3>
          </v-col>
          <v-col lg="2" class="mt-1">
            <div class="span" @click="close()">X</div>
          </v-col>
        </v-row>
      </div>
      <div class="card-content">
        <v-row v-for="(prop, index) in property_list" :key="index">
          <v-col lg="5">
            <div class="form-group">
              <p>Type</p>
              <input v-model="prop.title" />
            </div>
          </v-col>
          <v-col lg="5">
            <div class="form-group">
              <p>Value</p>
              <input v-model="prop.value" />
            </div>
          </v-col>
          <v-col lg="2">
            <v-icon
              color="red"
              @click="removeProperties(index)"
              style="margin-top: 30px"
              >mdi-delete</v-icon
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="12">
            <div
              class="form-group"
              @click="addNewProperties()"
              style="cursor: pointer"
            >
              <p>
                <v-icon color="#ff7f3f" size="20">mdi-plus</v-icon>Add Property
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="card-footer">
        <v-btn class="btn" @click="close()">Save</v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: "AddNewProperties",
  props: ["visible", "property_list"],
  data() {
    return {
      properties: [
        {
          title: "",
          value: "",
        },
      ],
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) return this.$emit("close");
      },
    },
  },
  methods: {
    setData() {
      this.$emit("set-prop", this.properties);
    },
    addNewProperties() {
      this.property_list.push({
        title: "",
        value: "",
      });
    },
    removeProperties(index) {
      this.property_list.splice(index, 1);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.dialog {
  background: var(--empty-color);
  padding: 20px;
  border-radius: 10px;
  background-image: url("../../assets/popup.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.form-group > p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}
.form-group > input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.form-group > textarea {
  width: 100%;
  height: 100px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}
h3 {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif;
  font-size: 30px;
  font-weight: 500;
}
.card-footer {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
}
.btn {
    background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  border-radius: 10px;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  width: 30%;
  margin-top: 10px;
  color: white;
  font-size: 22px;
  letter-spacing: 0.8px ;
}
.span {
  width: 20px;
  height: 20px;
  font-size: 15px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
</style>
