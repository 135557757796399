<template>
    <div>
        <v-dialog v-model="visible" max-width="500" transition="dialog-bottom-transition">
            <v-card :color="'#15181E'" class="model-card text-center">
                <v-row justify="space-between" style="width: 100%">
                    <v-col lg="10">
                        <div :class="'general-text-title model-title'">
                            Skill Rating
                        </div>
                    </v-col>
                    <v-col lg="2" class="mt-5">
                        <v-icon color="white" @click="close()">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <div class="sub-title">
                    Rate {{ user.name }} based on their portfolio and skill set
                </div>
                <v-rating v-model="user.rating.skill" color="yellow darken-3" background-color="grey darken-1"
                    empty-icon="$ratingFull" hover large v-if="user.rating !== undefined"></v-rating>
                <v-rating v-model="rating" color="yellow darken-3" background-color="grey darken-1"
                    empty-icon="$ratingFull" hover large v-else></v-rating>
                <v-btn class="mt-5 button button-data" @click="updateRating()">Update</v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { userObj } from '../../services/user-service';

export default {
    name: "SkillRating",
    props: ['user', 'visible'],
    data() {
        return {
            rating: 0,
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },
        async updateRating() {
            try {
                if(this.user.rating === undefined){
                    this.user.rating = {
                        skill: this.rating
                    }
                }
                await userObj.updateUserData(this.user);
                this.$emit('close');
            } catch (error) {
                console.log(error);
            }
        }
    },
}
</script>
<style scoped>
.model-title {
    font-size: 40px !important;
    text-align: left;
}

.model-card {
    padding: 20px;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.sub-title {
    color: rgba(196, 196, 196, 0.5);
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}

.button-data {
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 26.2%,
            #ff3f7f 98.17%);
    font-family: "Bebas Neue", cursive;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    letter-spacing: 0.8px !important;
    color: white;
    font-size: 20px;
}
</style>