<template>
  <div class="token-container">
    <div class="token-meta">
      <div class="token-tag">
        {{
          typeof data.selectedAssetType === "object"
            ? data.selectedAssetType[0]
            : data.selectedAssetType
        }}
      </div>
      <v-row style="width:30%;justify-content: flex-end;padding-top:7px;" :class="data.image.length !== 0 ? 'mr-2' : 'mr-0'">
        <v-col lg="2" class="mr-3">
          <div class="token-fav" @click="onFavoriteClick()">
            <p>
              {{ data.favorites !== undefined ? data.favorites.length : 0 }}
            </p>
            <v-icon
              color="white"
              size="15"
              v-if="
                user !== null &&
                data.favorites !== undefined &&
                !data.favorites.includes(user.uid)
              "
              >mdi-heart-outline</v-icon
            >
            <v-icon color="white" size="15" v-else>mdi-heart</v-icon>
          </div>
        </v-col>
        <v-col lg="2" v-if="data.image.length !== 0 && user.role === 'team'">
          <div
            class="token-fav"
            @click="download(data.image)"
          >
            <v-icon color="white" size="15">mdi-download</v-icon>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="token-image" @click="onClick(data.tokenId)">
      <div class="sub-class-token">
        <img
          :src="data.placeholder"
          style="width: 100%; height: 100%; object-fit: contain"
        />
      </div>
    </div>
    <div class="token-footer">
      <v-row>
        <v-col class="col ml-1">
          <div class="token-title">{{ data.name }}</div>
        </v-col>
        <!-- <v-col lg="5">
          <div class="token-creator">
            <div class="profile-pic">
              <img :src="data.user.profile" style="width: 100%; height:100%;object-fit: cover;" />
            </div>
            <div class="address">{{data.seller}}</div>
          </div>
        </v-col> -->
      </v-row>
      <div class="token-desc">
        <div class="desc">
          {{ data.description }}
        </div>
        <div class="price">
          <v-icon color="white" size="20">mdi-currency-usd</v-icon>
          <span>{{
            data.price !== 0 && data.price.length !== 0 ? data.price : 0
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { assetObj } from "../../services/asset-service";

export default {
  name: "TokenCardNew",
  props: ["data"],
  methods: {
    onClick(tokenId) {
      const path = this.$route.path;
      if (path.includes("public")) {
        const url =
          this.data.owner === undefined
            ? `/asset/public/${tokenId}?type=db`
            : `/asset/public/${tokenId}?type=chain`;
        this.$router.push(url);
      } else {
        const url =
          this.data.owner === undefined
            ? `/asset/${tokenId}?type=db`
            : `/asset/${tokenId}?type=chain`;
        this.$router.push(url);
      }
    },
    async onFavoriteClick() {
      if (this.data.favorites.includes(this.user.uid)) {
        this.data.favorites.splice(
          this.data.favorites.indexOf(this.user.uid),
          1
        );
      } else {
        this.data.favorites.push(this.user.uid);
      }
      await assetObj.updateLazyMint(this.data.tokenId, this.data);
    },
    download(href) {
      window.open(href, "_blank");
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
};
</script>
<style scoped>
.token-container {
  /* width: 300px;*/
  height: 350px;
  border-radius: 10px;
  background: rgba(224, 224, 224, 0.14);
  backdrop-filter: blur(4.68316px);
  flex-basis: 20%;
  flex-shrink: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0px 20px 0px 0px;
  cursor: pointer;
}
.token-meta {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
}
.token-tag {
  padding: 5px;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  background: #001f5f;
  font-size: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.token-fav {
  min-width: 50px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  background: #001f5f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.token-fav > p {
  margin: 0px 5px 0px 5px;
}
.token-image {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sub-class-token {
  width: 100%;
  height: 100%;
}
.token-footer {
  width: 100%;
  height: 80px;
  border: 1px solid white;
  background: #001f5f;
  padding: 10px;
  border-radius: 10px;
}
.token-title {
  width: 100%;
  max-height: 40px;
  padding: 2px;
  font-size: 14px;
  max-lines: 2;
  color: white;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 12px;
}
.token-creator {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
}
.profile-pic {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--secondary-color);
  margin-right: 5px;
}
.address {
  margin: 0px 0px 0px 0px;
  font-size: 10px;
  color: white;
}
.token-desc {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.desc {
  width: 100%;
  font-size: 10px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: rgba(255, 255, 255, 0.76);
}
.price {
  flex: 0.2;
  font-size: 20px;
  font-weight: 600;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-content: center;
}
.col {
  padding: 5px !important;
}
</style>
