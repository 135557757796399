<template>
  <div class="user-asset-container">
    <Header
     :required="true" 
      title="Assets For Verification"
      subtitle="Exercitation cillum non laboris ut minim ut elit adipisicing deserunt ipsum est cillum."
    ></Header>
     <div class="market-body">
      <v-row>
        <v-col lg="3" v-for="(token, index) in tokens" :key="index">
          <token-card-outline :token="token"></token-card-outline>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import { assetObj } from "../services/asset-service";
import TokenCardOutline from "../components/Market/TokenCardOutline.vue";

export default {
  name: "UserAssets",
  components: {
    Header,
  },
  data() {
    return {
      tokens: [],
    };
  },
  created() {
    this.getAllTokens();
  },
  components: {
    TokenCardOutline,
    Header
  },
  methods: {
    async getAllTokens() {
      const data = await assetObj.getAllNonVerifiedAssets(false);
      this.tokens = data;
    },
  },
};
</script>
<style scoped>
.user-asset-container {
  width: 100vw;
  height: 100vh;
    background-image: url("../assets/hue.svg");
  /* background-image: url("../assets/wallet/background.svg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.market-body {
  width: 70%;
  padding-top: 70px;
  padding-left: 20px;
}
</style>
