import {
  notificationRef,
  addDoc,
  query,
  getDocs,
  getDoc,
  where,
  doc,
  setDoc,
  orderBy
} from "../helpers/firebase-handler";

class NotificationService {
  /**
   * 
   * @param {*} notificationData 
   * @param {*} fromAddress 
   * @param {*} toAddress 
   * @param {*} type 
   */
  async sendNotification(notificationData, fromAddress, toAddress, type) {
    const newJson = {
      ...notificationData,
      readStatus: false,
      from: fromAddress,
      to: toAddress,
      type: type,
      createdAt: new Date(),
    };
    await addDoc(notificationRef, newJson);
  }

  /**
   *
   * @param {*} userId
   * @returns
   */
  async getNotificationByToAddress(userId) {
    const q = query(notificationRef, where("to", "==", userId), orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    const notification = [];
    for (let index = 0; index < querySnapshot.docs.length; index++) {
      const element = querySnapshot.docs[index];
      const elementData = element.data();
      elementData.notification_id = element.id;
      notification.push(elementData);
    }
    return notification;
  }

  /**
   *
   * @param {*} notificationId
   * @returns
   */
  async getNotificationById(notificationId) {
    const q = doc(notificationRef, notificationId);
    const documentSnapshot = await getDoc(q);
    if (documentSnapshot.exists()) {
      const data = documentSnapshot.data();
      data.notification_id = documentSnapshot.id;
      return data;
    }
    return {};
  }

  /**
   *
   * @param {*} notificationId
   * @returns
   */
  async updateReadStatus(notificationId) {
    const documentSnapshot = doc(notificationRef, notificationId);
    await setDoc(documentSnapshot, { readStatus: true }, { merge: true });
  }

  /**
  *
  * @param {*} userId
  * @returns
  */
  async getUnReadNotificationByToAddress(userId) {
    const q = query(notificationRef, where("to", "==", userId), where("readStatus", "==", false), orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    const notification = [];
    for (let index = 0; index < querySnapshot.docs.length; index++) {
      const element = querySnapshot.docs[index];
      const elementData = element.data();
      elementData.notification_id = element.id;
      notification.push(elementData);
    }
    return notification;
  }
}
export const notificationObj = new NotificationService();
