import {
  doc,
  users,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
  orderBy,
  deleteDoc,
  deleteUser,
  firebaseAuth,
  websiteRef
} from "../helpers/firebase-handler";
import { assetObj } from "../services/asset-service";

class UserServices {
  async getUser(userId) {
    try {
      const querySnapshot = doc(users, userId);
      const user = await getDoc(querySnapshot);
      return user;
    } catch (error) {
      throw error;
    }
  }
  async findAll() {
    const queryDoc = query(users, orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(queryDoc);
    const allUsers = [];
    for (let index = 0; index < querySnapshot.docs.length; index++) {
      const element = querySnapshot.docs[index];
      const elementData = element.data();
      elementData.id = element.id;
      allUsers.push(elementData);
    }
    return allUsers;
  }

  async createUser(userData) {
    try {
      const querySnapshot = doc(users, userData.uid);
      const user = await getDoc(querySnapshot);
      if (user.exists()) {
        return false;
      } else {
        const query = doc(users, userData.uid);
        userData.createdAt = new Date();
        await setDoc(query, userData, { merge: true });
        return true;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateUserData(userData) {
    try {
      console.log(userData);
      const query = doc(users, userData.uid);
      await setDoc(query, userData, { merge: true });
    } catch (error) {
      throw error;
    }
  }

  async getUserByRole(role) {
    try {
      const queryRef = query(users, where("role", "==", role), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(queryRef);
      const allUsers = [];
      for (let index = 0; index < querySnapshot.docs.length; index++) {
        const element = querySnapshot.docs[index];
        const elementData = element.data();
        // const data = await assetObj.getAllLazyMintingAssetByUser(element.id);
        elementData.id = element.id;
        if (elementData.assetCreated === undefined) {
          elementData.assetCreated = 0;
        }
        // elementData.assetsCreated = 0;
        elementData.specialization = this.shuffleArray(elementData.specialization);
        // elementData.assetsCreated = data.length;
        if (elementData.followers === undefined) {
          elementData.followers = [];
        }
        allUsers.push(elementData);
      }
      return allUsers;
    } catch (error) {
      throw error;
    }
  }

  async deleteDocId(userId) {
    try {
      const docRef = doc(users, userId)
      const data = deleteDoc(docRef);
    } catch (error) {
      throw error;
    }
  }
  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      // Generate random number
      var j = Math.floor(Math.random() * (i + 1));

      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }

    return array;
  }

  async getAllVerifiedUsers(role) {
    try {
      const queryRef = query(users, where("role", "==", role), where("verified", '==', true));
      const querySnapshot = await getDocs(queryRef);
      const allUsers = [];
      for (let index = 0; index < querySnapshot.docs.length; index++) {
        const element = querySnapshot.docs[index];
        const elementData = element.data();
        elementData.id = element.id;
        if (elementData.assetCreated === undefined) {
          elementData.assetCreated = 0;
        }
        elementData.specialization = this.shuffleArray(elementData.specialization);
        if (elementData.followers === undefined) {
          elementData.followers = [];
        }
        allUsers.push(elementData);
      }
      return allUsers;
    } catch (error) {
      throw error;
    }
  }

  async getWebsiteRegistration(){
    const queryDoc = query(websiteRef, orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(queryDoc);
    const allUsers = [];
    for (let index = 0; index < querySnapshot.docs.length; index++) {
      const element = querySnapshot.docs[index];
      const elementData = element.data();
      elementData.id = element.id;
      allUsers.push(elementData);
    }
    return allUsers;
  }
}
export const userObj = new UserServices();
