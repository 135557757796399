import { render, staticRenderFns } from "./ViewMarketItemNew.vue?vue&type=template&id=38bc50bf&scoped=true&"
import script from "./ViewMarketItemNew.vue?vue&type=script&lang=js&"
export * from "./ViewMarketItemNew.vue?vue&type=script&lang=js&"
import style0 from "./ViewMarketItemNew.vue?vue&type=style&index=0&id=38bc50bf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38bc50bf",
  null
  
)

export default component.exports