<template>
  <v-snackbar color="#001f5f" :timeout="-1" :value="visible" absolute centered shaped top>
    {{ text }}
  </v-snackbar>
</template>
<script>
export default {
  name: "StatusSnackbar",
  props: ["text", "visible"],
};
</script>
