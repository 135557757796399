<template>
  <div class="summarize-container">
    <Header
      :required="true"
      :title="'Payment Gateway'"
      subtitle="Reprehenderit voluptate cillum eu minim eiusmod sint non reprehenderit quis ad."
    ></Header>
    <div class="summary-body mt-10 mb-10">
      <div class="div-header">
        <p class="title ml-10 mb-5">Uploaded Asset (s)</p>
        <p class="view ml-10 mb-5">View All</p>
      </div>
      <v-row class="ml-5 mt-0 mb-0">
        <v-col
          lg="2"
          v-for="(model, index) in submittedModel.modelFile"
          :key="index"
        >
          <div class="model-card">
            <model-viewer
              :src="model"
              ar-modes="webxr scene-viewer quick-look"
              seamless-poster
              shadow-intensity="1"
              camera-controls
              ar
              auto-rotate
              loading="eager"
              style="height: 100%; width: 100%"
            ></model-viewer>
          </div>
        </v-col>
      </v-row>
      <p class="title mt-10 ml-10 mb-0">Description</p>
      <p class="description ml-10 mt-2">{{ submittedModel.message }}</p>
      <p class="title mt-10 ml-10 mb-0">Payment Details</p>
      <v-row class="ml-7">
        <v-col md="3" lg="1">
          <p class="sub-title mt-0 mb-0">Total</p>
          <div class="asset-nature">
            <v-icon color="#00FFBF" size="20">mdi-currency-usd</v-icon>
            <p>{{ request.assetBudget }}</p>
          </div>
        </v-col>
        <v-col md="3" lg="1">
          <p class="sub-title mt-0 mb-0">Advance</p>
          <div class="asset-nature">
            <v-icon color="#00FFBF" size="20">mdi-currency-usd</v-icon>
            <p>{{ request.assetBudget }}</p>
          </div>
        </v-col>
        <v-col md="3" lg="1">
          <p class="sub-title mt-0 mb-0">Pending</p>
          <div class="asset-nature">
            <v-icon color="#00FFBF" size="20">mdi-currency-usd</v-icon>
            <p>{{ request.assetBudget }}</p>
          </div>
        </v-col>
      </v-row>
      <v-row class="ml-5">
        <v-col lg="2">
          <v-btn class="btn" @click="openPaymentModal()">Pay Now</v-btn>
        </v-col>
      </v-row>
    </div>
    <PaymentModal
      @close="closePaymentModal"
      :visible="paymentModal"
      :request="request"
    ></PaymentModal>
  </div>
</template>
<script>
import { requestObj } from "../../services/request-service";
import Header from "../Header.vue";
import PaymentModal from "../Modals/PaymentModal.vue";
export default {
  name: "SummarizeAssetRequest",
  components: { Header, PaymentModal },
  data() {
    return {
      paymentModal: false,
      request: {},
      submittedModel: {},
    };
  },
  methods: {
    async getData() {
      const idea_id = this.$route.params.ideaId;
      const userId = this.$route.params.userId;
      this.submittedModel = await requestObj.getSubmittedIdeasById(idea_id);
      this.request = await requestObj.findById(this.submittedModel.requestId);
    },
    openPaymentModal() {
      this.paymentModal = true;
    },
    closePaymentModal() {
      this.paymentModal = false;
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style scoped>
.summarize-container {
  width: 100vw;
  height: 100vh;
  background: var(--primary-color);
  background-image: url("../../assets/comm/background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
.summary-body {
  margin-top: 10px;
}
.model-card {
  width: 100%;
  height: 200px;
  border-left: 10px solid #00bfff;
  border-radius: 10px;
  background: rgba(196, 196, 196, 0.15);
  margin-top: -15px;
}
.title {
  margin: 0px;
  color: rgba(255,255,255, 0.7);
  font-weight: 400 !important;
  font-size: 14px !important;
}
.view {
  margin: 0px;
  color: rgba(255,255,255, 0.7);
  font-weight: 400 !important;
  font-size: 14px !important;
  cursor: pointer;
}

.sub-title {
  margin: 0px;
  color: #f2f2f2;
  font-weight: 400 !important;
  font-size: 14px !important;
}
.description {
  width: 50%;
  margin: 0px;
  font-size: 14px;
}
.asset-nature {
  padding: 5px;
  background: rgba(196, 196, 196, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--secondary-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}
.asset-nature > p {
  font-size: 12px;
  color: white;
  margin: 0px;
  font-weight: 400;
  margin-left: 5px;
}
.button-footer {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}
.btn {
  background: #00ffbf !important;
  border-radius: 15px;
  font-weight: 800 !important;
  text-transform: none;
  font-family: "Archivo", sans-serif !important;
  width: 100%;
  margin-top: 10px;
  font-size: 16px !important;
}
.btn-prev {
  background: transparent !important;
  border-radius: 15px;
  font-weight: 800 !important;
  text-transform: none;
  font-family: "Archivo", sans-serif !important;
  width: 20%;
  margin-top: 10px;
  border: 1px solid #00ffbf;
  color: white !important;
  font-size: 16px !important;
  margin-right: 10px;
}
.div-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}
</style>
