<template>
  <div class="preview-token">
    <Header :required="true" :title="title" subtitle="" class="mt-7"></Header>
    <div class="preview-body pt-10 mb-10">
      <v-row>
        <v-col lg="6">
          <div class="request-title">
            {{ tokenDetails.name }}
          </div>
          <div class="request-description">
            {{ tokenDetails.description }}
          </div>
          <div class="request-asset-nature">
            <p>Asset Tag</p>
            <v-row>
              <v-col lg="6">
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20">mdi-application</v-icon>
                  <p>{{ tokenDetails.selectedAssetType }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="request-asset-nature">
            <p>Asset Properties</p>
            <v-row>
              <v-col md="3" lg="4" v-for="(nature, index) in tokenDetails.attributes" :key="index">
                <property-card :title="nature.title" :value="nature.value"></property-card>
              </v-col>
            </v-row>
          </div>
          <div class="request-asset-nature">
            <p>Asset Categories</p>
            <v-row>
              <v-col lg="4" v-for="(cat, index) in tokenDetails.assetCategories" :key="index">
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20">mdi-tag</v-icon>
                  <p>{{ cat }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="request-asset-nature">
            <p>Asset File Formats</p>
            <v-row>
              <v-col lg="2" v-for="(cat, index) in tokenDetails.availableFileFormats" :key="index">
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20">mdi-file-document-outline</v-icon>
                  <p>{{ cat }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="request-asset-nature">
            <p>Asset Price</p>
            <v-row>
              <v-col md="3" lg="2">
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20">mdi-ethereum</v-icon>
                  <p>{{ tokenDetails.price }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="request-asset-nature">
            <p>Wallet Details</p>
            <v-row>
              <v-col md="4" lg="5">
                <WalletCard :title="'Wallet Address'" :value="walletAddress"></WalletCard>
              </v-col>
              <v-col md="4" lg="5">
                <WalletCard :title="'Contract Address'" :value="contractAddress"></WalletCard>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col lg="6">
          <div class="request-img">
            <model-viewer :src="tokenDetails.image" ar-modes="webxr scene-viewer quick-look" seamless-poster
              shadow-intensity="1" camera-controls ar auto-rotate loading="eager" style="height: 100%; width: 100%">
            </model-viewer>
          </div>
        </v-col>
      </v-row>
      <div class="button-footer-mult mb-10">
        <v-row>
          <v-col lg="6" v-for="(button, index) in buttons" :key="index">
            <v-btn :class="button.class" @click="button.action">{{
                button.text
            }}</v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="button-footer-mult" v-if="isLazy">
        <v-row>
          <v-col lg="6">
            <v-btn class="btn" @click="copyButtonUrl()">Copy URL</v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import PropertyCard from "../Common/PropertyCard.vue";
import { mapState } from "vuex";
import Header from "../Header.vue";
import WalletCard from "../Common/WalletCard.vue";
import { SYSTEM_CONTRACT_ADDRESS } from "../../config";
import { assetObj } from "../../services/asset-service";

export default {
  name: "TokenView",
  props: ["title", "tokenDetails", "buttons", "isLazy"],
  components: {
    PropertyCard,
    Header,
    WalletCard,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      contractAddress: "",
      walletAddress: "",
    };
  },
  created() {
    this.getWalletDetails();
  },
  methods: {
    async getWalletDetails() {
      const address = await assetObj.getWalletAddress();
      this.walletAddress = `${address.toString().substring(0, 5)}...${address
        .toString()
        .substring(address.toString().length - 5, address.toString().length)}`;
      this.contractAddress = `${SYSTEM_CONTRACT_ADDRESS.substring(
        0,
        5
      )}...${SYSTEM_CONTRACT_ADDRESS.substring(
        SYSTEM_CONTRACT_ADDRESS.length - 5,
        SYSTEM_CONTRACT_ADDRESS.length
      )}`;
    },
    async copyButtonUrl() {
      const url = `${window.location.origin}/creator/asset/${this.tokenDetails.tokenId}`;
      await navigator.clipboard.writeText(url);
    },
  },
};
</script>
<style scoped>
.preview-token {
  width: 100vw;
  min-height: 100vh;
    background-image: url("../../assets/hue.svg");
  /* background-image: url('../../assets/mint/token.svg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: scroll;
}

.create-header {
  padding: 20px;
  color: var(--white-color);
}

.preview-body {
  width: 70%;
  height: 100%;
  padding-left: 20px;
}

.create-header {
  padding-top: 2%;
  padding-left: 50px;
}

.create-header>h3 {
  background: linear-gradient(141.3deg, #00bfff 32.76%, #00ffbf 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 50px !important;
  font-weight: 600;
}

.create-header>p {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
}

.request-img {
  width: 100%;
  height: 230px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: rgba(217, 217, 217, 0.1);
  position: relative;
  border: 2px solid var(--border-color);
  margin-bottom: 10px;
}

.request_image {
  width: 70%;
  height: 100%;
  border-radius: 15px;
  object-fit: contain;
}

.request-title {
  margin: 0px;
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.request-description {
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(196, 196, 196, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.request-asset-nature {
  margin-top: 20px;
}

.request-asset-nature>p {
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  color: white;
}

.asset-nature {
  padding: 5px;

  background: rgba(217, 217, 217, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}

.asset-nature>p {
  font-size: 12px;
  color: white;
  margin: 0px;
  font-weight: 400;
  margin-left: 5px;
}

.button-footer-mult {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  width: 30%;
  margin-top: 10px;
  font-size: 22px !important;
  color: #fff !important;
}

.btn-prev {
  background: transparent !important;
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  width: 30%;
  margin-top: 10px;
  border: 1px solid #ff7f3f;
  color: white !important;
  font-size: 22px !important;
}

.btn-designer {
  background: linear-gradient(101.24deg,
      #ff7f3f 43.86%,
      #ffbf3f 86.82%) !important;
  border-radius: 15px;
  text-transform: none;
  font-weight: 400 !important;
  font-size: 22px;
  font-family: "Bebas Neue", cursive;
  letter-spacing: 0;
  width: 30%;
  margin-top: 10px;
  color: white;
}

.btn-prev-designer {
  background: transparent !important;
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  font-weight: 500 !important;
  font-size: 22px;
  font-family: "Bebas Neue", cursive;
  letter-spacing: 0;
  width: 30%;
  margin-top: 10px;
  border: 1px solid var(--border-color);
  color: white;
}
</style>
