<template>
  <div class="mint-token mb-20">
    <h1 class="general-text-title" v-if="!preview">Upload Assets</h1>
    <p v-if="!preview">Fill the form below</p>
    <v-stepper class="ml-0 mr-0 stepper mt-5" width="70%" elevation="0" alt-labels v-model="stepIndex" v-if="!preview">
      <v-stepper-header class="stepper-header">
        <v-stepper-step step="1" color="#ff7f3f" :complete="stepIndex > 1">
          <span :class="stepIndex >= 1 ? 'text-color-selected' : 'text-color'">Step 1</span>
        </v-stepper-step>
        <v-divider :class="stepIndex > 1 ? 'divider-complete' : 'divider'"></v-divider>
        <v-stepper-step step="2" color="#ff7f3f" :complete="stepIndex > 2">
          <span :class="stepIndex >= 2 ? 'text-color-selected' : 'text-color'">Step 2</span>
        </v-stepper-step>
        <v-divider :class="stepIndex > 2 ? 'divider-complete' : 'divider'"></v-divider>
        <!-- <v-stepper-step step="3" color="#FF7F3F" :complete="stepIndex > 3">
          <span :class="stepIndex >= 3 ? 'text-color-selected' : 'text-color'"
            >Step 3</span
          >
        </v-stepper-step>
        <v-divider class="divider"></v-divider> -->
        <v-stepper-step step="3" color="#ff7f3f" :complete="stepIndex > 4">
          <span :class="stepIndex >= 3 ? 'text-color-selected' : 'text-color'">Step 3</span>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <div class="form">
            <v-row>
              <v-col lg="6">
                <v-row>
                  <v-col lg="12">
                    <div :class="'form-group'">
                      <p>Asset Name * </p>
                      <input type="text" v-model="tokenDetails.name" />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="12">
                    <div :class="'form-group'">
                      <p>Asset Description * </p>
                      <textarea type="text" v-model="tokenDetails.description" maxlength="500"></textarea>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col lg="6">
                <v-row>
                  <v-col lg="12">
                    <div :class="'form-group'">
                      <p>
                        Asset Image *
                      </p>
                      <div class="file-input">
                        <v-row>
                          <v-col lg="8">
                            <div class="place-holder">
                              <v-row>
                                <v-col lg="5">
                                  <div class="place-holder-upload">
                                    <img src="../../assets/designer-file.svg" width="50" />
                                    <h3>Upload Image</h3>
                                    <p>(png, jpeg, svg)</p>
                                    <input accept="image/*" ref="placeholder" type="file" style="
                                        position: absolute;
                                        left: 0;
                                        opacity: 0;
                                        cursor: pointer !important;
                                      " @change="onPlaceHolderFileChange" />
                                  </div>
                                </v-col>
                                <v-col lg="7">
                                  <div class="choose-model" v-if="tokenDetails.placeholder === ''">
                                    <h3>Upload Placeholder</h3>
                                    <p>(png, jpeg, svg)</p>
                                  </div>
                                  <div class="choose-model" v-else>
                                    <img :src="tokenDetails.placeholder" style="
                                        width: 100%;
                                        height: 100%;
                                        object-fit: contain;
                                      " />
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                          <v-col lg="4">
                            <div class="choose-model" v-if="tokenDetails.image === ''">
                              <h3>Add 3D Sample</h3>
                              <p>(optional)</p>
                              <p>(glb, gltf)</p>
                              <v-btn class="button choose-upload" @click="onModelClick()">Upload</v-btn>
                              <input accept=".gltf, .glb" ref="model" type="file"
                                style="position: absolute; right: 0; opacity: 0" @change="onModelFileChange" />
                            </div>
                            <div class="choose-model" v-else @click="onModelClick()">
                              <model-viewer :src="tokenDetails.image" ar-modes="webxr scene-viewer quick-look"
                                seamless-poster shadow-intensity="1" camera-controls ar auto-rotate loading="eager"
                                style="height: 100%; width: 100%"></model-viewer>
                              <input accept=".gltf, .glb" ref="model" type="file"
                                style="position: absolute; right: 0; opacity: 0" @change="onModelFileChange" />
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div class="button-footer">
              <v-btn :class="'button btn'" @click="changeIndex(2)" :disabled="
                tokenDetails.placeholder.length === 0 ||
                tokenDetails.name.length === 0 || tokenDetails.description.length === 0
              ">Next</v-btn>
              <!-- <v-btn :class="'button btn'" @click="changeIndex(2)">Next</v-btn> -->
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="form">
            <v-row>
              <v-col lg="6">
                <div :class="'form-group'">
                  <p>Asset Environment * </p>
                  <v-select dark v-model="tokenDetails.selectedAssetType" :items="assetTags" :class="'select-type'"
                    color="white" background-color="transparent" multiple solo></v-select>
                </div>
              </v-col>
              <v-col lg="6">
                <div :class="'form-group'">
                  <p>
                    Asset Preview
                  </p>
                  <div class="file-input">
                    <model-viewer :src="tokenDetails.image" ar-modes="webxr scene-viewer quick-look" seamless-poster
                      shadow-intensity="1" camera-controls ar auto-rotate loading="eager"
                      style="height: 100%; width: 100%" v-if="tokenDetails.image.length !== 0"></model-viewer>
                    <img :src="tokenDetails.placeholder" style="width: 100%; height: 100%; object-fit: contain;"
                      v-else />
                  </div>
                </div>

              </v-col>
            </v-row>
            <div class="button-footer-mult">
              <v-btn :class="'button btn-prev'" @click="changeIndex(1)">Previous</v-btn>
              <v-btn :class="'button btn'" @click="changeIndex(3)"
                :disabled="tokenDetails.selectedAssetType.length === 0">Next</v-btn>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div class="form">
            <v-row>
              <v-col lg="6">
                <div :class="'form-group'">
                  <p>Asset Category * </p>
                  <v-select dark v-model="tokenDetails.assetCategories" :items="assetCategories" :class="'select-type'"
                    color="white" background-color="transparent" solo multiple>
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 1" class="chip">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ tokenDetails.assetCategories.length - 1 }}
                        others)
                      </span>
                    </template>
                  </v-select>
                </div>
                <div :class="'form-group'">
                  <p>File Formats * </p>
                  <v-select dark v-model="tokenDetails.availableFileFormats" :items="assetFileFormat"
                    :class="'select-type'" color="white" background-color="transparent" solo multiple>
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 4" class="chip">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 4" class="grey--text text-caption">
                        (+{{ tokenDetails.availableFileFormats.length - 4 }}
                        others)
                      </span>
                    </template>
                  </v-select>
                </div>
              </v-col>
              <v-col lg="6">
                <div :class="'form-group'">
                  <p>
                    Asset Preview
                  </p>
                  <div class="file-input">
                    <model-viewer :src="tokenDetails.image" ar-modes="webxr scene-viewer quick-look" seamless-poster
                      shadow-intensity="1" camera-controls ar auto-rotate loading="eager"
                      style="height: 100%; width: 100%" v-if="tokenDetails.image.length !== 0"></model-viewer>
                    <img :src="tokenDetails.placeholder" style="width: 100%; height: 100%; object-fit: contain;"
                      v-else />
                  </div>
                </div>
              </v-col>
            </v-row>
            <div class="button-footer-mult">
              <v-btn :class="'button btn-prev'" @click="changeIndex(2)">Previous</v-btn>
              <v-btn :class="'button btn'" @click="setPreview()"
                :disabled="tokenDetails.availableFileFormats.length === 0 || tokenDetails.assetCategories.length === 0">
                Preview</v-btn>
            </div>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <AddProperties :visible="propertiesDialog" @close="close" :property_list="tokenDetails.attributes"></AddProperties>
    <UploadAssetPreview title="Asset Ready to Upload " :tokenDetails="tokenDetails" :buttons="[
      {
        text: 'Previous',
        class: `${'btn-prev'}`,
        action: () => {
          changeIndex(3);
        },
      },
      {
        text: 'Upload',
        class: 'btn',
        action: () => {
          onMintingClicked();
        },
      },
    ]" v-if="preview && !isSuccess" :isLazy="false"></UploadAssetPreview>
    <Loader :text="loaderText" :visible="loading"></Loader>
    <StatusModel :status="isSuccess" :title="isSuccess ? 'Asset Upload Successful' : 'Asset Upload Failed!'"
      :message="isSuccess ? 'Your asset has been uploaded successfully. Please wait while the team will notify you shortly!' : 'Asset uploading failed. Please try again later'"
      :visible="successPopup" @close="close"></StatusModel>
  </div>
</template>
<script>
import AddProperties from "../Modals/AddProperties.vue";
import UploadAssetPreview from "./UploadAssetPreview.vue";
import Header from "../Header.vue";
import { mapState } from "vuex";
import {
  createUserAssets,
  fetchUserAssets,
} from "../../helpers/asset-contract";
import {
  uploadFileToBaseIPFS,
  uploadAssetToIPFS,
} from "../../helpers/ipfs-upload";
import Loader from "../Loader/Loader.vue";
import { walletObj } from "../../services/wallet-service";
import { assetObj } from "../../services/asset-service";
import StatusModel from "../Modals/StatusModel.vue";
import {
  CATEGORIES,
  AVAILABLE_FILE_TYPES,
  PROJECT_INTERESTED,
} from "../../helpers/constants";
import { mailServiceObj } from "../../services/mail-service";
import { userObj } from "../../services/user-service";
import {uploadFile} from "../../helpers/file-upload"

export default {
  name: "UploadAssets",
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      loading: false,
      loaderText: "Uploading Placeholder...",
      isSuccess: false,
      successPopup: false,
      preview: false,
      propertiesDialog: false,
      stepIndex: 1,
      assetTags: ["Metaverse", "Gaming", "AR/VR"],
      assetTypes: [
        "Avatar/Character",
        "Character",
        "Environment",
        "Objects",
        "GamingZone",
      ],
      assetCategories: CATEGORIES,
      assetFileFormat: [".gltf", ".glb", ".fbx", ".png", ".svg"],
      tokenDetails: {
        selectedAssetType: "",
        name: "",
        description: "",
        image: "",
        attributes: [],
        assetTime: "",
        placeholder: "",
        animation_url: "",
        external_url: "",
        background_color: "",
        model_id: +Date.now(),
        youtube_url: "",
        price: "",
        type: "",
        assetCategories: [],
        availableFileFormats: [],
      },
      fileName: "",
    };
  },
  methods: {
    async connectWallet() {
      await walletObj.metamaskConfig();
    },
    changeIndex(index) {
      this.preview = false;
      this.stepIndex = index;
    },
    onReferenceClick() {
      this.$refs.reference.click();
    },
    setPreview() {
      this.preview = true;
    },
    close() {
      this.propertiesDialog = false;
      this.successPopup = false;
    },
    onPlaceHolderClick() {
      this.$refs.placeholder.click();
    },
    onModelClick() {
      this.$refs.model.click();
    },
    async onPlaceHolderFileChange(event) {
      const file = event.target.files[0];
      this.fileName = file.name;
      this.loading = true;
      const url = await uploadFile(file,`uploads/${this.user.uid}/placeholder/`);
      this.tokenDetails.placeholder = url;
      this.loading = false;
    },
    async onModelFileChange(event) { 
      const file = event.target.files[0];
      this.loading = true;
      this.loaderText = "Uploading Model....";
      const url = await uploadFile(file,`uploads/${this.user.uid}/model/`);
      this.tokenDetails.image = url;
      this.tokenDetails.animation_url = url;
      this.fileName = file.name;
      this.tokenDetails.type = this.fileName.split(".")[1];
      this.loading = false;
    },

    async onMintingClicked() {
      this.loading = true;
      this.loaderText = "Uploading Asset....";
      if (this.user.role === "team") {
        this.tokenDetails.user = this.user;
        this.tokenDetails.userId = this.user.uid;
        this.tokenDetails.verified = false;
        const url = await uploadAssetToIPFS(this.tokenDetails);
        // await assetObj.createLazyMintAsset(this.tokenDetails);
        // const transactionData = await assetObj.getAllLazyMintingAssetByUser(
        //   this.user.uid
        // );
        const transactionData = await createUserAssets(this.tokenDetails, url);
        if (transactionData) {
          const mintedAsset = await assetObj.getUserMintedAsset(false);
          if (mintedAsset.length > 0) {
            this.tokenDetails.tokenId =
              mintedAsset[mintedAsset.length - 1].tokenId;
            this.isSuccess = true;
            this.successPopup = true;

            setTimeout(() => {
              this.successPopup = false;
              // this.$router.push("/profile");
            }, 5000);
          }
        } else {
          this.loading = false;
          this.isSuccess = false;
          this.successPopup = true;
          setTimeout(() => {
            this.successPopup = false;
          }, 2000);
        }
      } else {
        this.tokenDetails.user = this.user;
        this.tokenDetails.userId = this.user.uid;
        this.tokenDetails.verified = false;
        this.tokenDetails.favorites = [];
        const preUploaded = await assetObj.getAllLazyMintingAssetByUser(this.user.uid);
        if (preUploaded.length === 0) {
          this.user.assetCreated = 1;
        } else {
          this.user.assetCreated = preUploaded.length + 1;
        }
        await assetObj.createLazyMintAsset(this.tokenDetails);
        await userObj.updateUserData(this.user);
        const transactionData = await assetObj.getAllLazyMintingAssetByUser(
          this.user.uid
        );
        // const transactionData = await createUserAssets(this.tokenDetails, url);
        if (transactionData.length > 0) {
          // const mintedAsset = await assetObj.getUserMintedAsset(false);
          // if (mintedAsset.length > 0) {
          // this.tokenDetails.tokenId = mintedAsset[mintedAsset.length - 1].tokenId;
          this.isSuccess = true;
          this.successPopup = true;
          await mailServiceObj.assetUploadNotification(this.user.name);
          setTimeout(() => {
            this.successPopup = false;
            this.$router.push("/profile?tab=2");
          }, 5000);

          // }
        } else {
          this.loading = false;
          this.isSuccess = false;
          this.successPopup = true;
          setTimeout(() => {
            this.successPopup = false;
          }, 2000);
        }
      }
      this.loading = false;
    },
  },
  components: {
    AddProperties,
    UploadAssetPreview,
    Header,
    Loader,
    StatusModel,
  },
  created() {
    if (this.user.role === "team") {
      this.connectWallet();
    }
  },
};
</script>
<style scoped>
.mint-token {
  width: 100vw;
  min-height: 100vh;
  background-image: url("../../assets/hue.svg");
  /* background-image: url("../../assets/mint/background.svg"); */
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center; */
}

.mint-token>h1 {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px !important;
  margin: 0px 0px 0px 30px;
  width: 50%;
  padding: 20px 0px 0px 0px;
}

.mint-token>p {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px 0px 0px 30px;
  font-size: 18px;
  font-weight: 400;
}

.mint-header {
  padding-left: 20px;
  width: 100%;
  height: 100px;
  padding-top: 2%;
  text-align: left;
}

.mint-header>h1 {
  background: linear-gradient(141.3deg, #eb5129 32.76%, #ffbf3f 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 50px !important;
}

.mint-header>p {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
}

.theme--light.v-stepper {
  background: transparent !important;
}

.divider {
  border-color: grey !important;
}

.divider-complete {
  border-color: #ff7f3f !important;
}

.v-stepper__header {
  box-shadow: none !important;
}

.form-group {
  padding-left: 20px;
  margin-bottom: 20px;
}

.form-group>p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  font-weight: 400;
}

.form-group>input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.form-group>textarea {
  width: 100%;
  height: 100px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.form-group-designer {
  padding-left: 20px;
  margin-bottom: 20px;
}

.form-group-designer>p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}

.form-group-designer>input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #eb5129;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.form-group-designer>textarea {
  width: 100%;
  height: 100px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #eb5129;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.text-color {
  color: transparent !important;
}

.text-color-selected {
  color: white !important;
}

.select-type {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  outline: none;
  color: #fff !important;
  font-family: "DM Sans", sans-serif;
  padding: 5px;
  font-size: 12px !important;
}

.select-type-designer {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  outline: none;
  color: #fff !important;
  font-family: "DM Sans", sans-serif;
  padding: 5px;
  font-size: 12px !important;
}

.file-input {
  width: 100%;
  height: 200px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.place-holder {
  width: 100%;
  height: 190px;
  border-radius: 5px;
  border: 1px dashed white;
}

.choose-model {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: rgba(196, 196, 196, 0.33);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.choose-model>h3 {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
}

.choose-model>p {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
}

.choose-upload {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 56.2%,
      #ff3f7f 98.17%);
  border-radius: 15px;
  height: 30px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  margin-top: 10px !important;
  color: white !important;
  font-family: "Bebas Neue", cursive !important;
}

.place-holder-upload {
  width: 100%;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.place-holder-upload>h3 {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
}

.place-holder-upload>p {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
}

.button-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.button-footer-mult {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  margin-top: 50px;
}

.btn {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 56.2%,
      #ff3f7f 98.17%);
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  color: white !important;
  font-weight: 400 !important;
  font-size: 25px;
  width: 15%;
  font-family: "Bebas Neue", cursive !important;
}

/* .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background: grey !important;
  cursor: not-allowed;
} */

.btn-prev {
  background: transparent !important;
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  color: white;
  font-weight: 400 !important;
  font-size: 20px;
  width: 15%;
  border: 1px solid var(--border-color) !important;
  font-family: "Bebas Neue", cursive !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-designer {
  background: linear-gradient(101.24deg, #ff7f3f 43.86%, #ffbf3f 86.82%);
  border-radius: 15px;
  font-weight: 800 !important;
  text-transform: none;
  width: 10%;
  margin-top: 10px;
  color: white !important;
  font-size: 16px !important;
  font-family: "DM Sans", sans-serif;
}

.btn-prev-designer {
  background: transparent !important;
  border-radius: 15px;
  font-weight: 800 !important;
  text-transform: none;
  width: 10%;
  margin-top: 10px;
  border: 1px solid #ff7f3f;
  color: white !important;
  font-size: 16px !important;
  font-family: "DM Sans", sans-serif;
}

.text-field {
  width: 100%;
  height: 55px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid #00ffbf !important;
  outline: none;
  color: #fff !important;
  font-family: "Archivo", sans-serif;
}

.text-field-designer {
  width: 100%;
  height: 55px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid #eb5129 !important;
  outline: none;
  color: #fff !important;
  font-family: "Archivo", sans-serif;
}

.property-input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #00ffbf;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.property-input-designer {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #eb5129;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.asset-nature {
  padding: 5px;
  background: rgba(217, 217, 217, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--secondary-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.asset-nature-designer {
  padding: 5px;
  background: rgba(217, 217, 217, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--designer-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.prop-title {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin: 0px;
}

.prop-value {
  font-size: 12px;
  font-weight: 400;
  color: var(--secondary-color);
  margin: 0px;
}

.v-stepper__content {
  padding: 15px !important;
}

.stepper-header {
  padding-right: 43% !important;
  margin-left: -4% !important;
}
</style>
