<template>
  <div class="mint-token mb-20">
    <Header
     :required="true" 
      title="Mint Assets"
      subtitle="Fill in the details below"
      v-if="!preview"
    ></Header>
    <v-stepper
      class="ml-0 mr-0 stepper mt-5"
      width="70%"
      elevation="0"
      alt-labels
      v-model="stepIndex"
      v-if="!preview"
    >
      <v-stepper-header>
        <v-stepper-step step="1" color="#FF7F3F" :complete="stepIndex > 1">
          <span :class="stepIndex >= 1 ? 'text-color-selected' : 'text-color'"
            >Step 1</span
          >
        </v-stepper-step>
        <v-divider class="divider"></v-divider>
        <v-stepper-step step="2" color="#FF7F3F" :complete="stepIndex > 2">
          <span :class="stepIndex >= 2 ? 'text-color-selected' : 'text-color'"
            >Step 2</span
          >
        </v-stepper-step>
        <v-divider class="divider"></v-divider>
        <v-stepper-step step="3" color="#FF7F3F" :complete="stepIndex > 3">
          <span :class="stepIndex >= 3 ? 'text-color-selected' : 'text-color'"
            >Step 3</span
          >
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <div class="form">
            <v-row>
              <v-col lg="6">
                <v-row>
                  <v-col lg="12">
                    <div
                      :class="
                        user.role === 'designer'
                          ? 'form-group-designer'
                          : 'form-group'
                      "
                    >
                      <p>Asset Name</p>
                      <input type="text" v-model="tokenDetails.name" />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="12">
                    <div
                      :class="
                        user.role === 'designer'
                          ? 'form-group-designer'
                          : 'form-group'
                      "
                    >
                      <p>Asset Description</p>
                      <textarea
                        type="text"
                        v-model="tokenDetails.description"
                      ></textarea>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col lg="6">
                <v-row>
                  <v-col lg="12">
                    <div
                      :class="
                        user.role === 'designer'
                          ? 'form-group-designer'
                          : 'form-group'
                      "
                    >
                      <p>Asset Image</p>
                      <div class="file-input">
                        <v-row>
                          <v-col lg="8">
                            <div class="place-holder">
                              <v-row>
                                <v-col lg="5">
                                  <div
                                    class="place-holder-upload"
                                    @click="onPlaceHolderClick()"
                                  >
                                    <img
                                      src="../assets/designer-file.svg"
                                      width="50"
                                    />
                                    <h3>Upload Asset</h3>
                                    <p>(png, jpeg, gltf)</p>
                                    <input
                                      accept="image/*"
                                      ref="placeholder"
                                      type="file"
                                      style="
                                        position: absolute;
                                        right: 10%;
                                        opacity: 0;
                                      "
                                      @change="onPlaceHolderFileChange"
                                    />
                                  </div>
                                </v-col>
                                <v-col lg="7">
                                  <div
                                    class="choose-model"
                                    v-if="tokenDetails.placeholder === ''"
                                  >
                                    <h3>Upload Placeholder</h3>
                                    <p>(png, jpeg, svg)</p>
                                  </div>
                                  <div class="choose-model" v-else>
                                    <img
                                      :src="tokenDetails.placeholder"
                                      style="
                                        width: 100%;
                                        height: 100%;
                                        object-fit: contain;
                                      "
                                    />
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                          <v-col lg="4">
                            <div
                              class="choose-model"
                              v-if="tokenDetails.image === ''"
                            >
                              <h3>Add 3D Model</h3>
                              <p>(glb, gltf)</p>
                              <v-btn
                                class="choose-upload"
                                @click="onModelClick()"
                                >Upload</v-btn
                              >
                              <input
                                accept=".gltf, .glb"
                                ref="model"
                                type="file"
                                style="position: absolute; right: 0; opacity: 0"
                                @change="onModelFileChange"
                              />
                            </div>
                            <div
                              class="choose-model"
                              v-else
                              @click="onModelClick()"
                            >
                              <model-viewer
                                :src="tokenDetails.image"
                                ar-modes="webxr scene-viewer quick-look"
                                seamless-poster
                                shadow-intensity="1"
                                camera-controls
                                ar
                                auto-rotate
                                loading="eager"
                                style="height: 100%; width: 100%"
                              ></model-viewer>
                              <input
                                accept=".gltf, .glb,"
                                ref="model"
                                type="file"
                                style="position: absolute; right: 0; opacity: 0"
                                @change="onModelFileChange"
                              />
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div class="button-footer">
              <v-btn
                :class="user.role === 'designer' ? 'btn-designer' : 'btn'"
                @click="changeIndex(2)"
                >Next</v-btn
              >
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="form">
            <v-row>
              <v-col lg="6">
                <div
                  :class="
                    user.role === 'designer'
                      ? 'form-group-designer'
                      : 'form-group'
                  "
                >
                  <p>Asset Price in ETH</p>
                  <v-text-field
                    :class="
                      user.role === 'designer'
                        ? 'text-field-designer'
                        : 'text-field'
                    "
                    append-icon="mdi-ethereum"
                    solo
                    color="#00FFBF"
                    height="50"
                    background-color="transparent"
                    v-model="tokenDetails.price"
                    type="number"
                  ></v-text-field>
                </div>
                <div
                  :class="
                    user.role === 'designer'
                      ? 'form-group-designer'
                      : 'form-group'
                  "
                >
                  <p>Asset Type</p>
                  <v-select
                    dark
                    v-model="tokenDetails.selectedAssetType"
                    :items="assetTags"
                    :class="
                      user.role === 'designer'
                        ? 'select-type-designer'
                        : 'select-type'
                    "
                    color="white"
                    background-color="transparent"
                    solo
                  ></v-select>
                </div>
              </v-col>
              <v-col lg="6">
                <div class="file-input">
                  <model-viewer
                    :src="tokenDetails.image"
                    ar-modes="webxr scene-viewer quick-look"
                    seamless-poster
                    shadow-intensity="1"
                    camera-controls
                    ar
                    auto-rotate
                    loading="eager"
                    style="height: 100%; width: 100%"
                  ></model-viewer>
                </div>
              </v-col>
            </v-row>
            <div class="button-footer-mult">
              <v-btn
                :class="
                  user.role === 'designer' ? 'btn-prev-designer' : 'btn-prev'
                "
                @click="changeIndex(1)"
                >Previous</v-btn
              >
              <v-btn
                :class="user.role === 'designer' ? 'btn-designer' : 'btn'"
                @click="changeIndex(3)"
                >Next</v-btn
              >
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div class="form">
            <v-row>
              <v-col lg="6">
                <div
                  :class="
                    user.role === 'designer'
                      ? 'form-group-designer'
                      : 'form-group'
                  "
                >
                  <p>Asset Properties</p>
                  <div
                    :class="
                      user.role === 'designer'
                        ? 'property-input-designer'
                        : 'property-input'
                    "
                    @click="propertiesDialog = true"
                  >
                    <v-img
                      src="../assets/add.svg"
                      width="30"
                      style="margin-top: 3px"
                    ></v-img>
                  </div>
                </div>
                <v-row style="padding-left: 20px">
                  <v-col
                    lg="3"
                    v-for="(prop, index) in tokenDetails.attributes"
                    :key="index"
                  >
                    <div
                      :class="
                        user.role === 'designer'
                          ? 'asset-nature-designer'
                          : 'asset-nature'
                      "
                    >
                      <p class="prop-title">{{ prop.title }}</p>
                      <p class="prop-value">{{ prop.value }}</p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col lg="6">
                <div class="file-input">
                  <model-viewer
                    :src="tokenDetails.image"
                    ar-modes="webxr scene-viewer quick-look"
                    seamless-poster
                    shadow-intensity="1"
                    camera-controls
                    ar
                    auto-rotate
                    loading="eager"
                    style="height: 100%; width: 100%"
                  ></model-viewer>
                </div>
              </v-col>
            </v-row>
            <div class="button-footer-mult">
              <v-btn
                :class="
                  user.role === 'designer' ? 'btn-prev-designer' : 'btn-prev'
                "
                @click="changeIndex(2)"
                >Previous</v-btn
              >
              <v-btn
                :class="user.role === 'designer' ? 'btn-designer' : 'btn'"
                @click="setPreview()"
                >Preview</v-btn
              >
            </div>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <AddProperties
      :visible="propertiesDialog"
      @close="close"
      :property_list="tokenDetails.attributes"
    ></AddProperties>
    <TokenView
      title="Asset Minting Summary"
      :tokenDetails="tokenDetails"
      :buttons="[
        {
          text: 'Previous',
          class: `${
            user.role === 'designer' ? 'btn-prev-designer' : 'btn-prev'
          }`,
          action: () => {
            changeIndex(3);
          },
        },
        {
          text: 'Mint Asset',
          class: `${user.role === 'designer' ? 'btn-designer' : 'btn'}`,
          action: () => {
            onMintingClicked();
          },
        },
      ]"
      v-if="preview && !isSuccess"
      :isLazy="false"
    ></TokenView>
    <Loader :text="loaderText" :visible="loading"></Loader>
    <TokenView
      title="Asset Minted Successfully!"
      :tokenDetails="tokenDetails"
      :buttons="[]"
      v-if="isSuccess"
      :isLazy="true"
    ></TokenView>
    <StatusModel
      :status="isSuccess"
      :title="isSuccess ? 'Asset Minting Successful' : 'Asset Minting Failed!'"
      message="Pariatur cupidatat dolor elit ullamco."
      :visible="successPopup"
      @close="close"
    ></StatusModel>
  </div>
</template>
<script>
import AddProperties from "../components/Modals/AddProperties.vue";
import TokenView from "../components/Tokens/TokenView.vue";
import Header from "../components/Header.vue";
import { mapState } from "vuex";
import {
  createUserAssets,
  fetchUserAssets,
} from "../helpers/asset-contract";
import {
  uploadFileToBaseIPFS,
  uploadAssetToIPFS,
} from "../helpers/ipfs-upload";
import Loader from "../components/Loader/Loader.vue";
import { walletObj } from "../services/wallet-service";
import { assetObj } from "../services/asset-service";
import StatusModel from "../components/Modals/StatusModel.vue";

export default {
  name: "LazyMinting",
  computed: {
    ...mapState({
      user: (state) => state.user,
      url : (state) => state.link,
    }),
  },
  data() {
    return {
      loading: false,
      loaderText: "uploading asset",
      isSuccess: false,
      successPopup: false,
      preview: false,
      propertiesDialog: false,
      stepIndex: 1,
      assetTags: ["Metaverse", "Gaming", "Metaverse and Gaming"],
      assetTypes: [
        "Avatar/Character",
        "Character",
        "Environment",
        "Objects",
        "GamingZone",
      ],
      tokenDetails: {
        selectedAssetType: "",
        name: "",
        description: "",
        image: "",
        attributes: [],
        assetTime: "",
        placeholder: "",
        animation_url: "",
        external_url: "",
        background_color: "",
        model_id: +Date.now(),
        youtube_url: "",
        price: 0,
        type: "",
        tokenId: ""
      },
      fileName: "",
    };
  },
  methods: {
    async connectWallet() {
      this.tokenDetails.image = this.url;
    },
    changeIndex(index) {
      this.preview = false;
      this.stepIndex = index;
    },
    onReferenceClick() {
      this.$refs.reference.click();
    },
    setPreview() {
      this.preview = true;
    },
    close() {
      this.propertiesDialog = false;
      this.successPopup = false;
    },
    onPlaceHolderClick() {
      this.$refs.placeholder.click();
    },
    onModelClick() {
      this.$refs.model.click();
    },
    async onPlaceHolderFileChange(event) {
      const file = event.target.files[0];
      this.fileName = file.name;
      this.loading = true;
      const url = await uploadFileToBaseIPFS(file);
      this.tokenDetails.placeholder = url;
      this.loading = false;
    },
    async onModelFileChange(event) {
      const file = event.target.files[0];
      this.loading = true;
      this.loaderText = "Uploading Model....";
      const url = await uploadFileToBaseIPFS(file);
      this.tokenDetails.image = url;
      this.tokenDetails.animation_url = url;
      this.fileName = file.name;
      this.tokenDetails.type = this.fileName.split(".")[1];
      this.loading = false;
    },

    async onMintingClicked() {
      this.loading = true;
      this.loaderText = "Minting Asset....";
      const url = await uploadAssetToIPFS(this.tokenDetails);
      this.tokenDetails.meta = url;
      await assetObj.createLazyMintAsset(this.tokenDetails);
      const mintedAsset = await assetObj.getAllLazyMintingAssets();
      if (mintedAsset.length > 0) {
        this.tokenDetails.tokenId = mintedAsset[mintedAsset.length - 1].tokenId;
         this.isSuccess = true;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 2000);
      }
      this.loading = false;
    },
  },
  components: { AddProperties, TokenView, Header, Loader, StatusModel },
  created() {
    this.connectWallet();
  },
};
</script>
<style scoped>
.mint-token {
  width: 100vw;
  min-height: 100vh;
  background: var(--empty-color);
  background-image: url("../assets/mint/background.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.mint-header {
  padding-left: 20px;
  width: 100%;
  height: 100px;
  padding-top: 2%;
  text-align: left;
}
.mint-header > h1 {
  background: linear-gradient(141.3deg, #eb5129 32.76%, #ffbf3f 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 50px !important;
}
.mint-header > p {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
}
.theme--light.v-stepper {
  background: transparent !important;
}
.divider {
  border-color: #00bfff !important;
}
.v-stepper__header {
  box-shadow: none !important;
}
.form-group {
  padding-left: 20px;
  margin-bottom: 20px;
}
.form-group > p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}
.form-group > input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #00ffbf;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}
.form-group > textarea {
  width: 100%;
  height: 100px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #00ffbf;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.form-group-designer {
  padding-left: 20px;
  margin-bottom: 20px;
}
.form-group-designer > p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}
.form-group-designer > input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #eb5129;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}
.form-group-designer > textarea {
  width: 100%;
  height: 100px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #eb5129;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.text-color {
  color: transparent !important;
}
.text-color-selected {
  background: linear-gradient(101.24deg, #ff7f3f 43.86%, #ffbf3f 86.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.select-type {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #00ffbf;
  outline: none;
  color: #fff !important;
  font-family: "DM Sans", sans-serif;
  padding: 5px;
  font-size: 12px !important;
}
.select-type-designer {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #eb5129;
  outline: none;
  color: #fff !important;
  font-family: "DM Sans", sans-serif;
  padding: 5px;
  font-size: 12px !important;
}
.file-input {
  width: 100%;
  height: 200px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #00ffbf;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}
.place-holder {
  width: 100%;
  height: 190px;
  border-radius: 5px;
  border: 1px dashed white;
}
.choose-model {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: rgba(196, 196, 196, 0.33);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.choose-model > h3 {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
}
.choose-model > p {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
}
.choose-upload {
  background: linear-gradient(
    101.24deg,
    #ff7f3f 43.86%,
    #ffbf3f 86.82%
  ) !important;
  border-radius: 15px;
  height: 30px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin-top: 10px !important;
  color: white !important;
  letter-spacing: 1;
}
.place-holder-upload {
  width: 100%;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.place-holder-upload > h3 {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
}
.place-holder-upload > p {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
}
.button-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-top: 30px;
}
.button-footer-mult {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  margin-top: 30px;
}
.btn {
  background: #00ffbf !important;
  border-radius: 15px;
  font-weight: 600 !important;
  text-transform: none;
  font-family: "Archivo", sans-serif !important;
  width: 10%;
  margin-top: 10px;
}
.btn-prev {
  background: transparent !important;
  border-radius: 15px;
  font-weight: 600 !important;
  text-transform: none;
  font-family: "Archivo", sans-serif !important;
  width: 10%;
  margin-top: 10px;
  border: 1px solid #00ffbf;
  color: #fff;
}
.btn-designer {
  background: linear-gradient(101.24deg, #ff7f3f 43.86%, #ffbf3f 86.82%);
  border-radius: 15px;
  font-weight: 600 !important;
  text-transform: none;
  font-family: "Archivo", sans-serif !important;
  width: 10%;
  margin-top: 10px;
}
.btn-prev-designer {
  background: transparent !important;
  border-radius: 15px;
  font-weight: 600 !important;
  text-transform: none;
  font-family: "Archivo", sans-serif !important;
  width: 10%;
  margin-top: 10px;
  border: 1px solid #ff7f3f;
  color: #fff;
}

.text-field {
  width: 100%;
  height: 55px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid #00ffbf !important;
  outline: none;
  color: #fff !important;
  font-family: "Archivo", sans-serif;
}
.text-field-designer {
  width: 100%;
  height: 55px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid #eb5129 !important;
  outline: none;
  color: #fff !important;
  font-family: "Archivo", sans-serif;
}
.property-input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #00ffbf;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}
.property-input-designer {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #eb5129;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.asset-nature {
  padding: 5px;
  background: rgba(217, 217, 217, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--secondary-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.asset-nature-designer {
  padding: 5px;
  background: rgba(217, 217, 217, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--designer-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.prop-title {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin: 0px;
}
.prop-value {
  font-size: 12px;
  font-weight: 400;
  color: var(--secondary-color);
  margin: 0px;
}
</style>
