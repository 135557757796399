<template>
  <div>
    <v-dialog class="dialog" v-model="visible" persistent width="350">
      <v-card :color="'#001F5F'" dark class="p-10 text-center dialog" style="padding: 10px" >
        <v-card-text>
          <h3>{{ text }}</h3>
          <div class="loader">
            <img src="../../assets/gif/loader.gif" />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Loader",
  props: ["visible", "text"],
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) return this.$emit("close");
      },
    },
  },
};
</script>
<style scoped>
.dialog {
  border-radius: 10px;
  /* background-image: url('../../assets/popup.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
}

h3 {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  min-height: 30px;
}
</style>
