<template>
  <div class="top-asset-container">
    <div class="row-1">
      <div class="column-1">
        <div class="token-details">
          <div class="token-title">{{ data.name }}</div>
          <div class="token-creator mt-2">
            <div class="profile-pic">
              <img :src="data.user.profile" style="width: 100%; height:100%;object-fit: cover;" />
            </div>
            <div class="creator-det">
              <p>Creator</p>
              <h3>{{ data.seller }}</h3>
            </div>
          </div>
          <div class="token-env  mt-2">
            <p>Environment</p>
            <div class="env">
              {{ data.selectedAssetType }}
            </div>
          </div>
          <div class="token-env mt-2">
            <p>Price</p>
            <div class="price">
              <v-icon color="white" size="30">mdi-ethereum</v-icon>
              <p>{{ data.price }}</p>
            </div>
          </div>
          <v-btn class="button" @click="onClick()">Buy/Lease</v-btn>
        </div>
      </div>
      <div class="column-2">
        <div class="token-image">
          <img
            :src="data.placeholder"
            alt=""
            style="width: 100%; height: 100%; object-fit: contain"
          />
        </div>
      </div>
      <div class="column-3">
        <div class="token-fav">
          <p>{{ data.attributes.length }}</p>
          <v-icon color="white" size="15">mdi-heart-outline</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TopAssetCard",
  props: ["data"],
  methods: {
    onClick(){
      this.$router.push('/resource');
    }
  }
};
</script>
<style scoped>
.top-asset-container {
  width: 450px;
  height: 300px;
  border: 2px solid var(--secondary-color);
  border-radius: 10px;
  background: rgba(224, 224, 224, 0.14);
  backdrop-filter: blur(4.68316px);
  flex-basis: 20%;
  flex-shrink: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0px 20px 0px 0px;
}
.token-meta {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
}
.token-tag {
  padding: 5px;
  border-radius: 10px;
  border: 1px solid var(--secondary-color);
  background: var(--empty-color);
  font-size: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.token-details {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.row-1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.column-1 {
  flex: 0.5;
  height: 100%;
}
.column-2 {
  flex: 0.5;
  height: 100%;
}
.column-3 {
  flex: 0.1;
  height: 100%;
}
.token-fav {
  min-width: 50px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid var(--secondary-color);
  background: var(--empty-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.token-fav > p {
  margin: 0px 5px 0px 5px;
}
.token-title {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  background: linear-gradient(107.55deg, #00ffbf 4.02%, #00bfff 98.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  top: 0px;
}
.token-creator {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 0px 0px;
}
.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--empty-color);
  margin-right: 10px;
}
.creator-det {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.creator-det > p {
  margin: 0px 0px 0px 0px;
  color: rgba(255, 255, 255, 0.76);
  font-size: 10px;
  font-weight: 400;
}
.creator-det > h3 {
  margin: 0px 0px 0px 0px;
  color: white;
  font-size: 14px;
  font-weight: 600;
}
.token-env {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.token-env > p {
  margin: 0px 0px 0px 0px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.76);
}
.env {
  width: 100%;
  padding: 5px;
  border: 1px solid var(--secondary-color);
  background: transparent;
  font-size: 12px;
  font-weight: 600;
  color: white;
  border-radius: 10px;
  background: rgba(196, 196, 196, 0.07);
}
.price {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.price > p {
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  color: white;
}
.token-image {
  width: 100%;
  height: 100%;
}
.button{
    width: 70%;
    height: 30px;
    border-radius: 10px;
    background: linear-gradient(107.55deg, #00FFBF 4.02%, #00BFFF 98.87%);
    font-family: 'Archivo', sans-serif;
    font-weight: 800 !important;
    font-size: 14px;
    color: #090D2E !important;
    border: none;
    outline: none;
    text-transform: none !important;
    margin-top: 10px;
}
</style>
