import { render, staticRenderFns } from "./TokenCardUpdate.vue?vue&type=template&id=39bdcf35&scoped=true&"
import script from "./TokenCardUpdate.vue?vue&type=script&lang=js&"
export * from "./TokenCardUpdate.vue?vue&type=script&lang=js&"
import style0 from "./TokenCardUpdate.vue?vue&type=style&index=0&id=39bdcf35&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39bdcf35",
  null
  
)

export default component.exports