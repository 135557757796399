import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-container"},[(_vm.selectedIndex === 0)?_c('div',{staticClass:"initial-selector"},[_c('div',{staticClass:"initial-logo",on:{"click":function($event){return _vm.onLogoClick()}}},[_c('img',{attrs:{"src":require("../../assets/logo.svg"),"alt":"logo","width":"300"}})]),_c('div',{staticClass:"initial-choose-section"},[_vm._m(0),_c('div',{staticClass:"choose-options mt-5"},[_c('div',{staticClass:"option-container mr-5",on:{"click":function($event){_vm.onSpecialization('3D Creator (Create / Sell Assets)', 1, 0)}}},[_c('div',{staticClass:"option-card",style:(_vm.selectedSpecialization === 1
                ? { border: '2px solid var(--border-color)' }
                : { border: '2px solid #bdbdbd' })},[_c('div',{staticClass:"option-card-image"},[_c(VImg,{attrs:{"src":_vm.selectedSpecialization === 1
                    ? require('../../assets/auth/designer-selected.svg')
                    : require('../../assets/auth/designer-unselected.svg')}})],1),_vm._m(1)])]),_c('div',{staticClass:"option-container",on:{"click":function($event){_vm.onSpecialization('Metaverse Project (Request / Buy Asset)', 0, 0)}}},[_c('div',{staticClass:"option-card",style:(_vm.selectedSpecialization === 0
                ? { border: '2px solid var(--border-color)' }
                : { border: '2px solid #bdbdbd' })},[_c('div',{staticClass:"option-card-image"},[_c(VImg,{attrs:{"src":_vm.selectedSpecialization === 0
                    ? require('../../assets/auth/metaverse-selected.svg')
                    : require('../../assets/auth/metaverse-unselected.svg'),"width":"90%"}})],1),_vm._m(2)])])]),(_vm.selectedSpecialization !== -1)?_c('div',{staticClass:"initial-footer mt-2"},[_c('p',[_vm._v("Selected Role:")]),_c('h3',[_vm._v(" "+_vm._s(_vm.sectionWiseQuestions[_vm.selectedIndex].questions[0].answer)+" ")]),_c(VBtn,{staticClass:"button initial-footer-button mt-2",on:{"click":function($event){return _vm.onNextButtonClick()}}},[_vm._v("Continue")])],1):_vm._e()])]):_vm._e(),(_vm.selectedIndex != 0 && _vm.selectedIndex !== _vm.sectionWiseQuestions.length)?_c('div',{staticClass:"step-process"},[_c('div',{staticClass:"initial-logo pt-5 pl-5",on:{"click":function($event){return _vm.onLogoClick()}}},[_c('img',{attrs:{"src":require("../../assets/logo.svg"),"alt":"logo","width":"250"}})]),_c(VRow,{staticClass:"v-row",staticStyle:{"width":"100%"}},[(_vm.isDesktop)?_c(VCol,{attrs:{"lg":_vm.isDesktop ? 4 : 0}}):_vm._e(),_c(VCol,{staticClass:"form-column",attrs:{"lg":_vm.isDesktop ? 4 : 12}},[_c('div',{staticClass:"form-column-second"},[_vm._l((_vm.sectionWiseQuestions),function(sections,sectionIndex){return _c('div',{key:sectionIndex,staticClass:"form-wizard animate__animated animate__fadeInUpBig animate__fast",style:(sections.isActive ? { display: 'block' } : { display: 'none' })},[_c('p',{staticClass:"sections-title"},[_vm._v(_vm._s(sections.title))]),_c(VRow,{staticClass:"mt-0"},_vm._l((sections.questions),function(question,index){return _c(VCol,{key:index,attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":question.options.length === 0 ? (!_vm.isDesktop ? 12 : 12) : 12}},[_c('div',{staticClass:"form-div"},[_c('div',{staticClass:"form-group"},[_c('p',[_vm._v(_vm._s(question.question))]),((question.type)==='checkbox'&&(
                        question.type !== 'file' &&
                        question.options.length === 0 &&
                        question.type !== 'dropdown' &&
                        question.type !== 'singleDropDown'
                      ))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(question.answer),expression:"question.answer"}],attrs:{"autocomplete":"off","placeholder":question.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(question.answer)?_vm._i(question.answer,null)>-1:(question.answer)},on:{"change":function($event){var $$a=question.answer,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(question, "answer", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(question, "answer", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(question, "answer", $$c)}}}}):((question.type)==='radio'&&(
                        question.type !== 'file' &&
                        question.options.length === 0 &&
                        question.type !== 'dropdown' &&
                        question.type !== 'singleDropDown'
                      ))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(question.answer),expression:"question.answer"}],attrs:{"autocomplete":"off","placeholder":question.placeholder,"type":"radio"},domProps:{"checked":_vm._q(question.answer,null)},on:{"change":function($event){return _vm.$set(question, "answer", null)}}}):(
                        question.type !== 'file' &&
                        question.options.length === 0 &&
                        question.type !== 'dropdown' &&
                        question.type !== 'singleDropDown'
                      )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(question.answer),expression:"question.answer"}],attrs:{"autocomplete":"off","placeholder":question.placeholder,"type":question.type},domProps:{"value":(question.answer)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(question, "answer", $event.target.value)}}}):(
                        question.type !== 'file' &&
                        question.options.length > 1 &&
                        question.type !== 'dropdown' &&
                        question.type !== 'singleDropDown'
                      )?_c('div',{staticClass:"option-form-card"},_vm._l((question.options),function(option,optionIndex){return _c('div',{key:optionIndex,staticClass:"form-card",style:(_vm.selectedAssetNeed.includes(option)
                              ? { border: '3px solid var(--border-color)' }
                              : { border: '3px solid #F2F2F2' }),on:{"click":function($event){return _vm.onAssetNeeds(option, optionIndex, _vm.selectedIndex)}}},[_c('div',{staticClass:"form-card-image"},[(optionIndex === 0)?_c(VImg,{attrs:{"src":_vm.selectedAssetNeed.includes(option)
                                ? require('../../assets/auth/meta-selected.svg')
                                : require('../../assets/auth/meta-unselected.svg')}}):_c(VImg,{attrs:{"src":_vm.selectedAssetNeed.includes(option)
                                ? require('../../assets/auth/game-selected.svg')
                                : require('../../assets/auth/game-unselected.svg')}})],1),_c('h3',[_vm._v(_vm._s(option))]),_c('div',{staticClass:"check-dot",style:(_vm.selectedAssetNeed.includes(option)
                              ? { border: '3px solid var(--border-color)' }
                              : { border: '3px solid #F2F2F2' })},[_c(VIcon,{attrs:{"color":!_vm.selectedAssetNeed.includes(option)
                                ? '#F2F2F2'
                                : '#00ffbf',"size":"20"}},[_vm._v("mdi-check")])],1)])}),0):(question.type === 'singleDropDown')?_c('div',{staticStyle:{"width":"100%","border-style":"none"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(question.answer),expression:"question.answer"}],staticClass:"mySelect",staticStyle:{"height":"50px !important"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(question, "answer", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((question.singleDropDown),function(option,index){return _c('option',{key:index,staticStyle:{"color":"black !important"},domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),0)]):_c('div',{staticStyle:{"width":"100%","border-style":"none"}},[_c(VSelect,{staticClass:"mySelect",attrs:{"items":question.dropDown,"chips":"","multiple":"","height":"100%","color":"white","background-color":"transparent","dark":"","solo":""},on:{"change":function($event){return _vm.onChangeSelect(index, sectionIndex)}},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index < 1)?_c(VChip,[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(question.answer.length - 1)+" others) ")]):_vm._e()]}}],null,true),model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}})],1)])])])}),1)],1)}),_c('div',{staticClass:"button-footer"},[_c(VBtn,{staticClass:"button btn-prev",on:{"click":function($event){return _vm.onPrevButtonClick()}}},[_vm._v("PREVIOUS")]),_c(VBtn,{staticClass:"button btn-next",attrs:{"disabled":_vm.sectionWiseQuestions[_vm.selectedIndex].questions.filter(
                  _vm.filterQuestion
                ).length !==
                _vm.sectionWiseQuestions[_vm.selectedIndex].questions.length},on:{"click":function($event){return _vm.onNextButtonClick()}}},[_vm._v("NEXT")])],1)],2),_c('div',{staticClass:"stepper"},[_c('div',{class:_vm.selectedIndex >= 1 ? 'circle' : 'circle-not-complete'},[(_vm.selectedIndex > 1)?_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" mdi-check")]):_c('span',[_vm._v("1")])],1),_c(VDivider,{staticClass:"divider",attrs:{"dark":""}}),_c('div',{class:_vm.selectedIndex >= 2 ? 'circle' : 'circle-not-complete'},[(_vm.selectedIndex > 2)?_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" mdi-check")]):_c('span',[_vm._v("2")])],1),_c(VDivider,{staticClass:"divider",attrs:{"dark":""}}),_c('div',{class:_vm.selectedIndex >= 3 ? 'circle' : 'circle-not-complete'},[(_vm.selectedIndex > 3)?_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" mdi-check")]):_c('span',[_vm._v("3")])],1)],1)]),(_vm.isDesktop)?_c(VCol,{attrs:{"lg":_vm.isDesktop ? 4 : 0}}):_vm._e()],1)],1):_vm._e(),(_vm.selectedIndex != 0 && _vm.selectedIndex === _vm.sectionWiseQuestions.length)?_c('div',{staticClass:"summary-container"},[_c('div',{staticClass:"initial-logo",on:{"click":function($event){return _vm.onLogoClick()}}},[_c('img',{attrs:{"src":require("../../assets/logo.svg"),"alt":"logo","width":"300"}})]),_vm._m(3),_vm._l((_vm.sectionWiseQuestions),function(sections,sectionIndex){return _c(VRow,{key:sectionIndex,staticClass:"summary-content mt-10"},_vm._l((sections.questions),function(question,index){return _c(VCol,{key:index,staticClass:"summary-content-column",attrs:{"cols":"6","sx":"12","sm":"6","md":"6","lg":"4"}},[(question.type !== 'file' && question.type !== 'password')?_c('p',[_vm._v(" "+_vm._s(question.formHeader)+" ")]):_vm._e(),(
            question.type !== 'file' &&
            question.type !== 'password' &&
            typeof question.answer !== 'object'
          )?_c('h6',[_vm._v(" "+_vm._s(question.answer)+" ")]):_vm._e(),(
            question.type !== 'file' &&
            question.type !== 'password' &&
            typeof question.answer === 'object'
          )?_c('h6',[_vm._v(" "+_vm._s(question.answer.join(", "))+" ")]):_vm._e()])}),1)}),_c('div',{staticClass:"summary-button-footer"},[_c(VBtn,{staticClass:"button summary-btn-prev",on:{"click":function($event){return _vm.onPrevButtonClick()}}},[_vm._v("PREVIOUS")]),_c(VBtn,{staticClass:"button summary-btn-next",on:{"click":function($event){return _vm.onFormSubmit()}}},[_vm._v("SUBMIT")])],1)],2):_vm._e(),_c('loader',{attrs:{"visible":_vm.isLoading,"text":"Submitting Your Profile......"}}),_c('status-model',{attrs:{"visible":_vm.showSuccessPopup,"status":_vm.isSuccess,"title":_vm.popupTitle,"message":_vm.popupMessage}}),_c('terms-and-condition',{attrs:{"formData":_vm.formData,"visible":_vm.showTermsAndCondition},on:{"close":_vm.close}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"choose-headers"},[_c('h3',[_vm._v("Welcome to "),_c('span',[_vm._v("SignaX")])]),_c('p',[_vm._v("Please pick an option before we proceed:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option-card-heading"},[_c('h3',[_vm._v("3D Creator")]),_c('p',[_vm._v("(Create / Sell Assets)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option-card-heading"},[_c('h3',[_vm._v("Metaverse Project")]),_c('p',[_vm._v("(Request / Buy Asset)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"summary-header mt-10"},[_c('h2',[_vm._v("Registration Details:")]),_c('p',[_vm._v(" Here are your Registration details. Please Confirm before we proceed ")])])
}]

export { render, staticRenderFns }