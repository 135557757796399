<template>
  <div>
    <v-dialog transition="dialog-bottom-transition" persistent v-model="visible" max-width="500">
      <div class="card-body dialog">
        <div class="card-header">
          <v-row>
            <v-col lg="10">
              <h3>Buy / Lease</h3>
            </v-col>
            <v-col lg="2" class="mt-1">
              <v-icon class="span" @click="close()">mdi-close</v-icon>
            </v-col>
          </v-row>
        </div>
        <div class="form mt-10">
          <div class="form-group">
            <p>Name of the Project Owners</p>
            <input v-model="ownerName" />
          </div>
          <div class="form-group mt-5">
            <p>Activity Type</p>
            <v-radio-group v-model="activityType" row dark>
              <v-radio label="Buying" value="Buying" color="#ff7f3f"></v-radio>
              <v-radio label="Leasing" value="Leasing" color="#ff7f3f"></v-radio>
            </v-radio-group>
          </div>
          <div class="form-group" v-if="activityType === 'Leasing'">
            <p>Number of Days</p>
            <input v-model="rentDays" type="number"/>
          </div>
          <div class="form-group mt-5">
            <p>Need File Format</p>
            <v-select color="white" :items="fileFormatAvailable" v-model="fileFormat" outlined solo dark
              background-color="transparent" class="v-select-data" multiple></v-select>
          </div>
          <v-btn class="btn" @click="onButtonPressed()">Submit</v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "BuyOrLeaseModal",
  props: ["visible", "data"],
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      ownerName: '',
      activityType: "",
      fileFormat: "",
      fileFormatAvailable: [],
      rentDays: 0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.ownerName = `${this.user.name}`;
      this.fileFormatAvailable = this.data.fileFormats;
    },
    close() {
      this.$emit("close");
    },
    onButtonPressed() {
      this.data.details = {
        ownerName: this.ownerName,
        activityType: this.activityType,
        fileFormat: this.fileFormat,
        rentDays: this.rentDays,
      }
      this.$store.dispatch("setToken", this.data);
      this.$router.push(`/market/${this.data.tokenId}/payment`);
      this.close();
    }
  },
};
</script>
<style scoped>
.dialog {
  background: var(--empty-color);
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  background-image: url("../../assets/popup.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

h3 {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 30px;
  font-weight: 900;
  font-family: "Archivo", sans-serif;
}

.span {
  width: 20px;
  height: 20px;
  font-size: 15px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.form-group>p {
  font-size: 12px;
  font-weight: 400;
  color: #f2f2f2;
  margin: 0px;
}

.v-text-field--outlined>>>fieldset {
  border-color: var(--secondary-color);
  background: transparent;
  color: white !important;
}

.v-select-data {
  border-color: var(--designer-color) !important;
  color: white !important;
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff !important;
  font-family: "DM Sans", sans-serif;
  padding: 5px;
  font-size: 16px !important;
}

/* .btn {
  background: #00ffbf !important;
  border-radius: 15px;
  font-weight: 800 !important;
  text-transform: none;
  font-family: "Archivo", sans-serif !important;
  width: 20%;
  margin-top: 10px;
  font-size: 16px !important;
} */

.form-group>input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}
.btn {
    width: 40%;
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 26.2%,
            #ff3f7f 98.17%);
    border-radius: 10px;
    font-weight: 400;
    margin-top: 25px;
    margin-right: 25px;
    text-transform: none;
    font-family: "Bebas Neue", cursive !important;
    letter-spacing: 0.8px !important;
    color: white;
    font-size: 20px;
}
</style>
