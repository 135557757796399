import { addDoc, doc, getDoc, getDocs, orderBy, query, resourceRef, where } from "../helpers/firebase-handler";

class ResourceServices {

    async getAllResources() {
        try {
            const q = query(resourceRef, orderBy("order", "asc"));
            const data = await getDocs(q);
            const resources = [];
            for (let index = 0; index < data.docs.length; index++) {
                const element = data.docs[index];
                const elementData = element.data();
                elementData.resource_id = element.id;
                resources.push(elementData);
            }
            return resources;
        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    async getAllResourcesByRole(role) {
        try {
            const documentQuery = query(resourceRef, where(role, "==", role));
            const data = await getDocs(documentQuery);
            const resources = [];
            for (let index = 0; index < data.docs.length; index++) {
                const element = data.docs[index];
                const elementData = element.data();
                elementData.resource_id = element.id;
                resources.push(elementData);
            }
            return resources;
        } catch (error) {
            throw error;
        }
    }

    async createResource(resourceData) {
        try {
            await addDoc(resourceRef, resourceData);
        } catch (error) {
            throw error;
        }
    }

    async bulkCreate(resourceData) {
        try {
            for (let index = 0; index < resourceData.length; index++) {
                const element = resourceData[index];
                await addDoc(resourceRef, element);
            }
        } catch (error) {
            throw error;
        }
    }

    async getResourceById(resourceId) {
        try {
            const docData = doc(resourceRef, resourceId);
            const data = await getDoc(docData);
            if(data.exists()){
                const elementData = data.data();
                elementData.resource_id = data.id;
                return elementData;
            }
        } catch (error) {
            throw error;
        }
    }

}
export const resourceObj = new ResourceServices();