import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"request"},[(_vm.requests.length === 0 && _vm.tempArray.length === 0)?_c('div',{staticClass:"no-request pt-10"},[_c('Header',{staticStyle:{"padding-top":"1.5%"},attrs:{"required":true,"title":_vm.user.role === 'designer'
        ? 'Projects'
        : _vm.user.role === 'meta'
          ? 'My Requests'
          : 'All Projects',"subtitle":""}}),(_vm.user.role !== 'designer')?_c('img',{staticStyle:{"height":"100%","width":"100%","object-fit":"contain","margin-top":"-10%","margin-left":"2%","cursor":"pointer"},attrs:{"src":require("../assets/request/custom.svg")},on:{"click":function($event){return _vm.clickCreateNew()}}}):_c('div',{staticClass:"place-holder",staticStyle:{"margin-top":"5%"}},[_c(VImg,{staticClass:"img",attrs:{"src":require("../assets/request/empty.png")}}),_vm._m(0)],1),(_vm.user.role !== 'designer')?_c('p',{staticClass:"sub-title"},[_vm._v(" Click below and create your first project! ")]):_vm._e()],1):_c('div',{staticClass:"request-container pt-5"},[_c(VRow,{attrs:{"dense":"","justify":"space-between"}},[_c(VCol,{attrs:{"lg":"8"}},[_c('Header',{attrs:{"required":true,"title":_vm.user.role === 'designer'
            ? 'Projects'
            : _vm.user.role === 'meta'
              ? 'My Requests'
              : 'All Projects',"subtitle":"Find all the available projects including yours"}})],1)],1),(_vm.user.role !== 'designer')?_c(VRow,{staticClass:"mt-15",staticStyle:{"width":"100%","justify-content":"space-between"}},[_vm._l((_vm.tabs),function(tab,index){return _c(VCol,{key:index,attrs:{"md":"3","lg":"2"},on:{"click":function($event){return _vm.changeTab(index, tab)}}},[_c('div',{class:_vm.selectedTab == index ? 'tab-div-selected' : 'tab-div',style:(index === _vm.selectedTab
            ? { 'border-bottom': '5px solid var(--border-color)' }
            : { 'border-bottom': _vm.none })},[_vm._v(" "+_vm._s(tab)+" ")])])}),_c(VCol,{attrs:{"lg":"6"}},[(_vm.user.role !== 'designer')?_c(VBtn,{staticClass:"button",on:{"click":function($event){return _vm.clickCreateNew()}}},[_vm._v("Create Project")]):_vm._e()],1)],2):_vm._e(),(_vm.user.role === 'designer')?_c(VRow,{staticClass:"mt-15"},_vm._l((_vm.designerTabs),function(tab,index){return _c(VCol,{key:index,attrs:{"md":"3","lg":"2"},on:{"click":function($event){return _vm.changeTab(index, tab)}}},[_c('div',{class:_vm.selectedTab == index ? 'tab-div-selected' : 'tab-div',style:(index === 0 && _vm.selectedTab === 0
            ? { 'border-bottom': '5px solid var(--border-color)' }
            : index === 1 && _vm.selectedTab === 1
              ? { 'border-bottom': '5px solid white' }
              : { 'border-bottom': _vm.none })},[_vm._v(" "+_vm._s(tab)+" ")])])}),1):_vm._e(),(_vm.requests.length > 0)?_c('div',{staticClass:"request-body"},_vm._l((_vm.requests),function(request,index){return _c('div',{key:index,staticClass:"request-data"},[_c('div',{staticClass:"individual-request mt-10",style:(request.projectTakenBy.includes(_vm.user.uid)
            ? { 'border-left': '10px solid white' }
            : { 'border-left': '10px solid var(--border-color)' })},[_c(VRow,[_c(VCol,{staticClass:"pt-1 pl-5 pr-0 pb-1",attrs:{"md":"3","lg":"4"}},[_c('div',{staticClass:"request-img"},[_c('img',{staticClass:"request_image",attrs:{"src":request.uploadedImages[0]}})])]),_c(VCol,{attrs:{"md":"9","lg":"8"}},[_c('div',{staticClass:"request-title"},[_vm._v(" "+_vm._s(request.name)+" ")]),_c('div',{staticClass:"request-description"},[_vm._v(" "+_vm._s(request.description)+" ")]),_c('div',{staticClass:"request-asset-nature"},[_c('p',[_vm._v("Asset Nature:")]),_c(VRow,{staticStyle:{"width":"80%"}},_vm._l((request.selectedAssetNature),function(nature,index){return _c(VCol,{key:index,style:(index < 3 ? { 'display': 'flex' } : { 'display': 'none' }),attrs:{"md":"3","lg":"4"}},[_c('div',{staticClass:"asset-nature"},[_c(VIcon,{attrs:{"color":"#ff7f3f","size":"20"}},[_vm._v("mdi-nature")]),_c('p',[_vm._v(_vm._s(nature))])],1)])}),1)],1),_c('div',{staticClass:"misc"},[_c(VRow,[_c(VCol,{attrs:{"lg":"9"}},[_c(VRow,[_c(VCol,{attrs:{"lg":request.selectedAssetType ===
                          'Environment + Character'
                          ? 6
                          : 5}},[_c('div',{staticClass:"request-asset-nature"},[_c('p',[_vm._v("Asset Environment:")]),_c('div',{staticClass:"asset-nature"},[_c(VIcon,{attrs:{"color":"#ff7f3f","size":"20"}},[_vm._v("mdi-application")]),_c('p',[_vm._v(_vm._s(request.selectedAssetType))])],1)])])],1)],1),_c(VCol,{staticStyle:{"padding-right":"30px"},attrs:{"lg":"3"}},[(!request.projectTakenBy.includes(_vm.user.uid))?_c(VBtn,{staticClass:"view-now",on:{"click":function($event){return _vm.navigateToDetailPage(request.request_id)}}},[_vm._v("View Now")]):_vm._e(),(request.projectTakenBy.includes(_vm.user.uid))?_c(VBtn,{staticClass:"view-now",on:{"click":function($event){return _vm.navigateToDetailPage(request.request_id)}}},[_vm._v("Manage")]):_vm._e()],1)],1)],1)])],1)],1)])}),0):_c('div',{staticClass:"request-body"},[_c('div',{staticClass:"no-projects mt-10",staticStyle:{"display":"flex","justify-content":"center","align-items":"center","flex-direction":"column"}},[_c(VImg,{staticClass:"img",attrs:{"src":require("../assets/request/empty.png")}}),(_vm.user.role === 'designer')?_c('h3',{staticClass:"mt-5",staticStyle:{"text-align":"center","font-size":"20px","color":"white","width":"40%","line-height":"24px"}},[_vm._v(" No Projects Yet! Check out the "),_c('span',{staticClass:"span"},[_vm._v("All Projects")]),_vm._v(" tab and find some projects to express your interest ")]):_vm._e(),(_vm.user.role === 'designer')?_c(VBtn,{staticClass:"btn-secondary",on:{"click":function($event){return _vm.changeTab(0, 'All Projects')}}},[_vm._v("Explore Projects")]):_vm._e(),(_vm.user.role !== 'designer')?_c('h3',{staticClass:"mt-5",staticStyle:{"text-align":"center","font-size":"20px","color":"white","width":"40%","line-height":"24px"}},[_vm._v(" No Projects Yet ")]):_vm._e()],1)])],1),_c('Loader',{attrs:{"visible":_vm.isLoading,"text":'Fetching Projects....'}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" There are no available projects yet,"),_c('br'),_vm._v("you will be notified once you receive a project! ")])
}]

export { render, staticRenderFns }