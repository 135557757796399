<template>
  <div class="token-container" >
    <div class="token-meta">
      <div class="token-tag">{{ typeof data.selectedAssetType === 'object' ? data.selectedAssetType[0] : data.selectedAssetType }}</div>
      <div class="token-fav">
        <!-- <p>{{ data.favorites!== undefined ? data.favorites.length : 0 }}</p> -->
        <p>{{data.favorites === undefined ? 0 : data.favorites}}</p>
        <v-icon color="white" size="15">mdi-heart-outline</v-icon>
        <!-- <v-icon color="white" size="15" v-if="data.favorites!== undefined && !data.favorites.includes(user.uid)"
          >mdi-heart-outline</v-icon
        > -->
        <!-- <v-icon color="white" size="15" v-else>mdi-heart</v-icon> -->
      </div>
    </div>
    <div class="token-image" @click="onClick(data.tokenId)">
      <div class="sub-class-token">
        <img
          :src="data.placeholder"
          style="width: 100%; height: 100%; object-fit: contain"
        />
      </div>
    </div>
    <div class="token-footer">
      <v-row>
        <v-col lg="5">
          <div class="token-title">{{ data.name }}</div>
        </v-col>
        <v-col lg="7">
          <div class="token-creator">
            <div class="profile-pic">
              <img :src="data.user.profile" style="width: 100%; height:100%;object-fit: contain;border-radius: 50%;" />
            </div>
            <div class="address">{{data.seller}}</div>
          </div>
        </v-col>
      </v-row>
      <!-- <div class="token-desc">
        <div class="desc">
          {{ data.description }}
        </div>
        <div class="price">
          <v-icon color="white" size="20">mdi-ethereum</v-icon>
          <span>{{data.price}}</span>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {assetObj} from '../../services/asset-service';

export default {
  name: "TokenCardUpdate",
  props: ["data"],
  methods: {
    onClick(tokenId) {
      this.$store.dispatch('setSelectedMarketItem', this.data);
      const url = this.data.owner === undefined ? `/market/${tokenId}?type=db` : `/market/${tokenId}?type=chain`
      this.$router.push(url);
    },
    async onFavoriteClick() {
      if (this.data.favorites.includes(this.user.uid)) {
        this.data.favorites.splice(
          this.data.favorites.indexOf(this.user.uid),
          1
        );
      } else {
        this.data.favorites.push(this.user.uid);
      }
      await assetObj.updateLazyMint(this.data.tokenId, this.data);
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
};
</script>
<style scoped>
.token-container {
  /* width: 300px;*/
  height: 300px; 
  border-radius: 10px;
  background: rgba(224, 224, 224, 0.14);
  backdrop-filter: blur(4.68316px);
  flex-basis: 20%;
  flex-shrink: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 0px 0px;
  cursor: pointer;
}
.token-meta {
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: space-between;
}
.token-tag {
  padding: 5px;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  background: #001f5f;
  font-size: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.token-fav {
  min-width: 50px;
  height: 25px;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  background: #001f5f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.token-fav > p {
  margin: 0px 5px 0px 5px;
}
.token-image {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sub-class-token {
  width: 100%;
  height: 100%;
}
.token-footer {
  width: 100%;
  max-height: 80px;
  border: 1px solid var(--border-color);
  background: #001f5f;
  padding: 10px;
  border-radius: 10px;
}
.token-title {
  width: 100%;
  height: 40px;
  padding: 2px;
  font-size: 12px;
  max-lines: 2;
  color: white;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.token-creator {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
}
.profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}
.address {
  margin: 0px 0px 0px 0px;
  font-size: 10px;
  color: white;
}
.token-desc {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.desc {
  width: 100%;
  font-size: 10px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: rgba(255, 255, 255, 0.76);
}
.price {
  flex: 0.35;
  font-size: 16px;
  font-weight: 600;
  color: var(--secondary-color);
}
.col {
  padding: 5px !important;
}
</style>
