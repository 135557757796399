<template>
  <div class="register-container">
    <div class="initial-selector" v-if="selectedIndex === 0">
      <div class="initial-logo" @click="onLogoClick()">
        <img src="../../assets/logo.svg" alt="logo" width="300" />
      </div>
      <div class="initial-choose-section">
        <div class="choose-headers">
          <h3>Welcome to <span>SignaX</span></h3>
          <p>Please pick an option before we proceed:</p>
        </div>
        <div class="choose-options mt-5">
          <div class="option-container mr-5" @click="onSpecialization('3D Creator (Create / Sell Assets)', 1, 0)">
            <div class="option-card" :style="
              selectedSpecialization === 1
                ? { border: '2px solid var(--border-color)' }
                : { border: '2px solid #bdbdbd' }
            ">
              <div class="option-card-image">
                <v-img :src="
                  selectedSpecialization === 1
                    ? require('../../assets/auth/designer-selected.svg')
                    : require('../../assets/auth/designer-unselected.svg')
                " />
              </div>
              <div class="option-card-heading">
                <h3>3D Creator</h3>
                <p>(Create / Sell Assets)</p>
              </div>
            </div>
          </div>
          <div class="option-container" @click="
            onSpecialization('Metaverse Project (Request / Buy Asset)', 0, 0)
          ">
            <div class="option-card" :style="
              selectedSpecialization === 0
                ? { border: '2px solid var(--border-color)' }
                : { border: '2px solid #bdbdbd' }
            ">
              <div class="option-card-image">
                <v-img :src="
                  selectedSpecialization === 0
                    ? require('../../assets/auth/metaverse-selected.svg')
                    : require('../../assets/auth/metaverse-unselected.svg')
                " width="90%" />
              </div>
              <div class="option-card-heading">
                <h3>Metaverse Project</h3>
                <p>(Request / Buy Asset)</p>
              </div>
            </div>
          </div>
        </div>
        <div class="initial-footer mt-2" v-if="selectedSpecialization !== -1">
          <p>Selected Role:</p>
          <h3>
            {{ sectionWiseQuestions[selectedIndex].questions[0].answer }}
          </h3>
          <v-btn class="button initial-footer-button mt-2" @click="onNextButtonClick()">Continue</v-btn>
        </div>
      </div>
    </div>
    <div class="step-process" v-if="selectedIndex != 0 && selectedIndex !== sectionWiseQuestions.length">
      <div class="initial-logo pt-5 pl-5" @click="onLogoClick()">
        <img src="../../assets/logo.svg" alt="logo" width="250" />
      </div>
      <v-row style="width: 100%" class="v-row">
        <v-col :lg="isDesktop ? 4 : 0" v-if="isDesktop"></v-col>
        <v-col :lg="isDesktop ? 4 : 12" class="form-column">
          <div class="form-column-second">
            <div class="form-wizard animate__animated animate__fadeInUpBig animate__fast"
              v-for="(sections, sectionIndex) in sectionWiseQuestions" :key="sectionIndex" :style="
                sections.isActive ? { display: 'block' } : { display: 'none' }
              ">
              <p class="sections-title">{{ sections.title }}</p>
              <v-row class="mt-0">
                <v-col cols="12" xs="12" sm="12" md="12" :lg="
                  question.options.length === 0 ? (!isDesktop ? 12 : 12) : 12
                " v-for="(question, index) in sections.questions" :key="index">
                  <div class="form-div">
                    <div class="form-group">
                      <p>{{ question.question }}</p>
                      <input v-if="
                        question.type !== 'file' &&
                        question.options.length === 0 &&
                        question.type !== 'dropdown' &&
                        question.type !== 'singleDropDown'
                      " v-model="question.answer" autocomplete="off" :type="question.type"
                        :placeholder="question.placeholder" />
                      <div class="option-form-card" v-else-if="
                        question.type !== 'file' &&
                        question.options.length > 1 &&
                        question.type !== 'dropdown' &&
                        question.type !== 'singleDropDown'
                      ">
                        <div class="form-card" v-for="(option, optionIndex) in question.options" :key="optionIndex"
                          :style="
                            selectedAssetNeed.includes(option)
                              ? { border: '3px solid var(--border-color)' }
                              : { border: '3px solid #F2F2F2' }
                          " @click="
  onAssetNeeds(option, optionIndex, selectedIndex)
">
                          <div class="form-card-image">
                            <v-img v-if="optionIndex === 0" :src="
                              selectedAssetNeed.includes(option)
                                ? require('../../assets/auth/meta-selected.svg')
                                : require('../../assets/auth/meta-unselected.svg')
                            " />
                            <v-img v-else :src="
                              selectedAssetNeed.includes(option)
                                ? require('../../assets/auth/game-selected.svg')
                                : require('../../assets/auth/game-unselected.svg')
                            " />
                          </div>
                          <h3>{{ option }}</h3>
                          <div class="check-dot" :style="
                            selectedAssetNeed.includes(option)
                              ? { border: '3px solid var(--border-color)' }
                              : { border: '3px solid #F2F2F2' }
                          ">
                            <v-icon :color="
                              !selectedAssetNeed.includes(option)
                                ? '#F2F2F2'
                                : '#00ffbf'
                            " size="20">mdi-check</v-icon>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="question.type === 'singleDropDown'" style="width: 100%; border-style: none">
                        <!-- <v-select
                        v-model="question.answer"
                        :items="question.singleDropDown"
                        class="mySelect"
                        height="100%"
                        dark
                        ></v-select> -->
                        <select v-model="question.answer" class="mySelect" style="height: 50px !important">
                          <option v-for="(option, index) in question.singleDropDown" :key="index" :value="option"
                            style="color: black !important">
                            {{ option }}
                          </option>
                        </select>
                      </div>
                      <div v-else style="width: 100%; border-style: none">
                        <v-select v-model="question.answer" :items="question.dropDown" chips multiple class="mySelect"
                          height="100%" color="white" background-color="transparent" dark solo
                          @change="onChangeSelect(index, sectionIndex)"><template v-slot:selection="{ item, index }">
                            <v-chip v-if="index < 1">
                              <span>{{ item }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption">
                              (+{{ question.answer.length - 1 }} others)
                            </span>
                          </template></v-select>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="button-footer">
              <v-btn class="button btn-prev" @click="onPrevButtonClick()">PREVIOUS</v-btn>
              <v-btn :disabled="
                sectionWiseQuestions[selectedIndex].questions.filter(
                  filterQuestion
                ).length !==
                sectionWiseQuestions[selectedIndex].questions.length
              " class="button btn-next" @click="onNextButtonClick()">NEXT</v-btn>
            </div>
          </div>
          <div class="stepper">
            <div :class="selectedIndex >= 1 ? 'circle' : 'circle-not-complete'">
              <v-icon v-if="selectedIndex > 1" color="white"> mdi-check</v-icon>
              <span v-else>1</span>
            </div>
            <v-divider dark class="divider"></v-divider>
            <div :class="selectedIndex >= 2 ? 'circle' : 'circle-not-complete'">
              <v-icon v-if="selectedIndex > 2" color="white"> mdi-check</v-icon>
              <span v-else>2</span>
            </div>
            <v-divider dark class="divider"></v-divider>
            <div :class="selectedIndex >= 3 ? 'circle' : 'circle-not-complete'">
              <v-icon v-if="selectedIndex > 3" color="white"> mdi-check</v-icon>
              <span v-else>3</span>
            </div>
          </div>
        </v-col>
        <v-col :lg="isDesktop ? 4 : 0" v-if="isDesktop"></v-col>
      </v-row>
    </div>
    <div class="summary-container" v-if="selectedIndex != 0 && selectedIndex === sectionWiseQuestions.length">
      <div class="initial-logo" @click="onLogoClick()">
        <img src="../../assets/logo.svg" alt="logo" width="300" />
      </div>
      <div class="summary-header mt-10">
        <h2>Registration Details:</h2>
        <p>
          Here are your Registration details. Please Confirm before we proceed
        </p>
      </div>
      <v-row class="summary-content mt-10" v-for="(sections, sectionIndex) in sectionWiseQuestions" :key="sectionIndex">
        <v-col cols="6" sx="12" sm="6" md="6" lg="4" class="summary-content-column"
          v-for="(question, index) in sections.questions" :key="index">
          <p v-if="question.type !== 'file' && question.type !== 'password'">
            {{ question.formHeader }}
          </p>
          <h6 v-if="
            question.type !== 'file' &&
            question.type !== 'password' &&
            typeof question.answer !== 'object'
          ">
            {{ question.answer }}
          </h6>
          <h6 v-if="
            question.type !== 'file' &&
            question.type !== 'password' &&
            typeof question.answer === 'object'
          ">
            {{ question.answer.join(", ") }}
          </h6>
        </v-col>
      </v-row>
      <div class="summary-button-footer">
        <v-btn class="button summary-btn-prev" @click="onPrevButtonClick()">PREVIOUS</v-btn>
        <v-btn class="button summary-btn-next" @click="onFormSubmit()">SUBMIT</v-btn>
      </div>
    </div>
    <loader :visible="isLoading" text="Submitting Your Profile......"></loader>
    <status-model :visible="showSuccessPopup" :status="isSuccess" :title="popupTitle" :message="popupMessage">
    </status-model>
    <terms-and-condition @close="close" :formData="formData" :visible="showTermsAndCondition"></terms-and-condition>
  </div>
</template>
<script>
import { createUserWithEmailAndPassword } from "@firebase/auth";
import Loader from "../../components/Loader/Loader.vue";
import StatusModel from "../../components/Modals/StatusModel.vue";
import TermsAndCondition from "../../components/Modals/TermsAndCondition.vue";
import { firebaseAuth } from "../../helpers/firebase-handler";
import { authObj } from "../../services/auth_service";
import { COUNTRY } from "../../helpers/constants";

export default {
  name: "SignUpScreen",
  data() {
    return {
      isLoading: false,
      loaderText: "Submitting Details.....",
      isSuccess: false,
      showSuccessPopup: false,
      popupTitle: "",
      popupMessage: "",
      selectedIndex: 0,
      selectedSpecialization: -1,
      role: "",
      showTermsAndCondition: false,
      formData: {},
      selectedAssetNeed: [],
      questions: [
        {
          question: "You are?",
          answer: "",
          name: "role",
          type: "text",
          isActive: true,
          options: [
            "Metaverse Project (Request/Buy Assets)",
            "Game Designers (Create/Sell Assets)",
          ],
          placeholder: "You are?",
          formHeader: "You are a",
          onClick: (option, index, questionIndex) =>
            this.onSpecialization(option, index, questionIndex),
        },
      ],
      sectionWiseQuestions: [
        {
          question: "You are?",
          answer: "",
          name: "role",
          type: "text",
          isActive: true,
          options: [
            "Metaverse Project (Request/Buy Assets)",
            "Game Designers (Create/Sell Assets)",
          ],
          placeholder: "You are?",
          formHeader: "You are a",
          onClick: (option, index, questionIndex) =>
            this.onSpecialization(option, index, questionIndex),
        },
      ],
      country: COUNTRY,
    };
  },
  methods: {
    onNextButtonClick() {
      if (this.selectedIndex !== this.sectionWiseQuestions.length - 1) {
        this.sectionWiseQuestions[this.selectedIndex].isActive = false;
        this.selectedIndex++;
        this.sectionWiseQuestions[this.selectedIndex].isActive = true;
      } else {
        this.selectedIndex = this.sectionWiseQuestions.length;
      }
    },
    onPrevButtonClick() {
      if (this.selectedIndex === this.sectionWiseQuestions.length) {
        for (let index = 0; index < this.sectionWiseQuestions.length; index++) {
          const element = this.sectionWiseQuestions[index];
          if (index === this.sectionWiseQuestions.length - 1) {
            element.isActive = true;
          } else {
            element.isActive = false;
          }
        }
        this.selectedIndex--;
      } else {
        this.sectionWiseQuestions[this.selectedIndex].isActive = false;
        this.selectedIndex--;
        this.sectionWiseQuestions[this.selectedIndex].isActive = true;
      }
    },
    onSpecialization(option, index, questionIndex) {
      this.sectionWiseQuestions = [
        {
          title: "",
          isActive: true,
          questions: [
            {
              question: "You are?",
              answer: "",
              name: "role",
              type: "text",
              isActive: true,
              options: [
                "Metaverse Project (Request/Buy Assets)",
                "Game Designers (Create/Sell Assets)",
              ],
              placeholder: "You are?",
              formHeader: "You are a",
              onClick: (option, index, questionIndex) =>
                this.onSpecialization(option, index, questionIndex),
            },
          ],
        },
      ];
      this.sectionWiseQuestions[questionIndex].questions[0].answer = option;
      this.selectedSpecialization = index;
      if (index === 0) {
        this.role = "meta";
        this.sectionWiseQuestions.push({
          title: "Basic Details",
          isActive: false,
          questions: [
            {
              question: "Name",
              answer: "",
              name: "name",
              type: "text",
              isActive: false,
              options: [],
              placeholder: "name",
              formHeader: "Name",
            },
            {
              question: "Email Address",
              answer: "",
              name: "email",
              type: "email",
              isActive: false,
              options: [],
              placeholder: "email address",
              formHeader: "Email Address",
            },
            {
              question: "Phone Number",
              answer: "",
              name: "phone",
              type: "number",
              isActive: false,
              options: [],
              placeholder: "Phone Number",
              formHeader: "Phone Number",
            },
            {
              question: "Designation",
              answer: "",
              name: "designation",
              type: "text",
              isActive: false,
              options: [],
              placeholder: "designation",
              formHeader: "Designation",
            },
          ],
        });
        this.sectionWiseQuestions.push({
          title: "Organization Details",
          isActive: false,
          questions: [
            {
              question: "Organization Name",
              answer: "",
              name: "organizationName",
              type: "text",
              isActive: false,
              options: [],
              placeholder: "organization name",
              formHeader: "Organization Name",
            },
            {
              question: "Website or Social Link",
              answer: "",
              name: "websiteLink",
              type: "text",
              isActive: false,
              options: [],
              placeholder: "website or social link",
              formHeader: "Website or Social Link",
            },
            {
              question: "Organisation Type",
              answer: "",
              name: "organizationType",
              type: "singleDropDown",
              isActive: false,
              options: [],
              placeholder: "Organisation Type",
              formHeader: "Organisation Type",
              singleDropDown: [
                "Metaverse Project",
                "Gaming Guild",
                "Brand/Business",
                "Individual",
              ],
            },
          ],
        });
        this.sectionWiseQuestions.push({
          title: "Organization Details",
          isActive: false,
          questions: [
            {
              question: "Project Requirements (Check all that apply)",
              answer: "",
              name: "projectRequirements",
              type: "dropdown",
              isActive: false,
              options: [],
              placeholder: "Project Requirements",
              formHeader: "Project Requirements",
              dropDown: [
                "Hire 3D Creators",
                "Metaverse Development",
                "Game Development",
                "AR/VR (Immersive experiences)",
                "Characters (Avatars, etc)",
                "Product Mockups and Prototypes",
                "Digital Twins",
                "3D Logo NFT's",
                "Virtual Showrooms",
                "Virtual Events",
                "3D Environments",
                "In-Game Power-ups",
                "Utilities (Cars, Buildings, Game weapons etc.)",
                "All of the above",
                "Others (please specify)",
              ],
            },
            {
              question: "Create a password",
              answer: "",
              name: "password",
              type: "password",
              isActive: false,
              options: [],
              placeholder: "Enter your password",
              formHeader: "Password",
              onClick: (option, index, questionIndex) =>
                this.onSpecialization(option, index, questionIndex),
            },
          ],
        });
      } else {
        this.role = "designer";
        this.sectionWiseQuestions.push({
          title: "Basic",
          isActive: false,
          questions: [
            {
              question: "What is your Name?",
              answer: "",
              name: "name",
              type: "text",
              isActive: false,
              options: [],
              placeholder: "What is your Name?",
              formHeader: "Name",
            },
            {
              question: "Phone Number?",
              answer: "",
              name: "phone",
              type: "number",
              isActive: false,
              options: [],
              placeholder: "Phone Number?",
              formHeader: "Phone Number",
            },
            {
              question: "What's your E-mail?",
              answer: "",
              name: "email",
              type: "email",
              isActive: false,
              options: [],
              placeholder: "What's your E-mail?",
              formHeader: "Email Address",
            },
            {
              question: "Where you from ?",
              answer: "United Kingdom",
              name: "country",
              type: "singleDropDown",
              isActive: false,
              options: [],
              placeholder: "Country",
              formHeader: "Country",
              singleDropDown: COUNTRY,
            },
          ],
        });
        this.sectionWiseQuestions.push({
          title: "Skills",
          isActive: false,
          questions: [
            {
              question: "Specialisation (Check all that apply)",
              answer: "",
              name: "specialization",
              type: "dropdown",
              isActive: false,
              options: [],
              placeholder: "Specialisation (Check all that apply)",
              formHeader: "Specialisation",
              dropDown: [
                "2D Artist",
                "3D Artist",
                "2D Animation",
                "3D Animation",
                "3D Modeling",
                "3D Rendering",
                "3D Generalist (All of the above)",
                "VFX Artist",
                "Texturing",
                "Rigging",
                "Game Developer",
                "NFT's",
                "Motion Graphics",
                "Others (Please specify)",
              ],
            },
            {
              question: "Tools used (Check all that apply)",
              answer: "",
              name: "toolsUsed",
              type: "dropdown",
              isActive: false,
              options: [],
              placeholder: "Tools used (Check all that apply)",
              formHeader: "Tools Used",
              dropDown: [
                "Maya",
                "Blender",
                "ZBrush",
                "Unity",
                "3Ds Max",
                "Unreal Engine",
                "Cinema 4D",
                "Others (Please specify)",
              ],
            },
            {
              question: "Project Interests (Check all that apply)",
              answer: "",
              name: "projectInterest",
              type: "dropdown",
              isActive: false,
              options: [],
              placeholder: "Project Interests (Check all that apply)",
              formHeader: "Project Interest",
              dropDown: [
                "Metaverse",
                "Gaming",
                "AR/VR (Immersive experiences)",
                "Characters (Avatars, etc)",
                "Product Mockups and Prototypes",
                "Company Logos",
                "Virtual Showrooms",
                "Virtual Events",
                "3D Environments",
                "In-Game Power-ups",
                "Utilities (Cars, Buildings, Game weapons etc.)",
                "All of the above",
              ],
            },
          ],
        });
        this.sectionWiseQuestions.push({
          title: "About",
          isActive: false,
          questions: [
            {
              question: "No. of years of experience",
              answer: "",
              name: "yearsOfExperience",
              type: "number",
              isActive: false,
              options: [],
              placeholder: "No. of years of experience",
              formHeader: "Years of Experience",
            },
            {
              question: "Portfolio link",
              answer: "",
              name: "portfolio",
              type: "text",
              isActive: false,
              options: [],
              placeholder: "Portfolio link",
              formHeader: "Portfolio",
            },
            {
              question: "Social Links (LinkedIn or Any Other)",
              answer: "",
              name: "workUrl",
              type: "url",
              isActive: false,
              options: [],
              placeholder: "Please upload any relevant links",
              formHeader: "Work URL",
            },
            {
              question: "Create a password",
              answer: "",
              name: "password",
              type: "password",
              isActive: false,
              options: [],
              placeholder: "Enter your password",
              formHeader: "Password",
              onClick: (option, index, questionIndex) =>
                this.onSpecialization(option, index, questionIndex),
            },
          ],
        });
      }
    },
    async onFormSubmit() {
      try {
        const formInput = {};
        for (let index = 0; index < this.sectionWiseQuestions.length; index++) {
          const element = this.sectionWiseQuestions[index];
          for (let i = 0; i < element.questions.length; i++) {
            const data = element.questions[i];
            var prevData = {};
            if (i !== 0) {
              prevData = element.questions[i - 1];
            }
            if (data.name === "others") {
              prevData.answer.push(data.answer);
              const answers = prevData.answer;
              formInput[prevData.name] = answers;
            } else {
              formInput[data.name] = data.answer;
            }
          }
        }
        formInput.role = this.role;
        formInput.profile = "";
        formInput.uid = "";
        formInput.verified = false;
        // const auth = await createUserWithEmailAndPassword(
        //   firebaseAuth,
        //   formInput.email,
        //   formInput.password
        // );
        // if (auth) {
        //   const user = auth.user;
        //   formInput.uid = user.uid;
        //   formInput.profile =
        //     "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/profile.svg?alt=media&token=cfff3f59-1754-4323-8298-1d72c6a76c40";
          this.formData = formInput;
          this.$store.dispatch('setGlobalPopup', true);
          this.showTermsAndCondition = true;
          // await authObj.logout();
        // }
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          this.popupTitle = "Email Already in Use";
          this.popupMessage = "Please use a different email";
        } else if (error.code === "auth/weak-password") {
          this.popupTitle = "Weak Password";
          this.popupMessage = "Password should be at least 6 characters";
        } else if (error.code === "auth/invalid-email") {
          this.popupTitle = "Invalid Email";
          this.popupMessage = "Please enter a valid email";
        } else if (error.code === "auth/network-request-failed") {
          this.popupTitle = "Network Error";
          this.popupMessage = "Please check your internet connection";
        } else if (error.code === "auth/too-many-requests") {
          this.popupTitle = "Too Many Requests";
          this.popupMessage = "Please try again later";
        }
        this.isSuccess = false;
        this.showSuccessPopup = true;
        setTimeout(() => {
          this.showSuccessPopup = false;
          this.$store.dispatch('setGlobalPopup', false);
        }, 3000);
      }
    },
    onAssetNeeds(option, index, questionIndex) {
      if (this.selectedAssetNeed.includes(option)) {
        this.selectedAssetNeed.splice(
          this.selectedAssetNeed.indexOf(option),
          1
        );
      } else {
        this.selectedAssetNeed.push(option);
      }
      if (this.selectedAssetNeed.length > 0) {
        this.questions[questionIndex].answer =
          this.selectedAssetNeed.join(" and ");
      }
    },
    close() {
      this.$store.dispatch('setGlobalPopup', false);
      this.showTermsAndCondition = false;
    },
    onLogoClick() {
      this.$router.push("/");
    },

    onChangeSelect(index, questionIndex) {
      const question =
        this.sectionWiseQuestions[questionIndex].questions[index];
      const nextQuestion =
        this.sectionWiseQuestions[questionIndex].questions[index + 1];
      if (question.answer.includes("Others (Please specify)")) {
        if (nextQuestion.name !== "others") {
          const allQuestions =
            this.sectionWiseQuestions[questionIndex].questions;
          const data = {
            question: "Please specify",
            answer: "",
            name: "others",
            type: "text",
            isActive: false,
            options: [],
            placeholder: "Please specify",
            formHeader: "Others",
          };
          allQuestions.splice(index + 1, 0, data);
        }
      } else {
        const question =
          this.sectionWiseQuestions[questionIndex].questions[index + 1];
        if (question.name === "others") {
          this.sectionWiseQuestions[questionIndex].questions.splice(
            index + 1,
            1
          );
        }
      }
    },
    filterQuestion(question) {
      if (question.name === 'workUrl') {
        return true;
      }
      return question.answer !== "";
    },
  },
  components: { Loader, StatusModel, TermsAndCondition },
  computed: {
    isDesktop: function () {
      return screen.width <= 760 ? false : true;
    },
  },
};
</script>
<style scoped>
.register-container {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/auth/reg_background.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden !important;
}

/* **** Very first page of the register section starts here ***** */
.initial-selector {
  width: 100%;
  height: 100%;
  padding: 30px;
}

.initial-choose-section {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.choose-headers {
  width: 35%;
}

.choose-headers>h3 {
  margin: 0px;
  font-size: 25px;
  color: #f2f2f2;
  font-weight: 400;
  text-align: left;
}

.choose-headers>h3>span {
  font-weight: 600 !important;
}

.choose-headers>p {
  font-size: 16px;
  color: #f2f2f2;
  margin: 0px;
  font-weight: 400;
  text-align: left;
}

.choose-options {
  width: 35%;
  height: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option-container {
  flex: 0.3;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.option-card {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  cursor: pointer;
}

.option-card-image {
  width: 65%;
  height: 50%;
}

.option-card-heading>h3 {
  margin: 10px 0px 0px 0px;
  font-size: 20px;
  color: white;
  font-weight: 500;
  text-align: center;
}

.option-card-heading>p {
  font-size: 14px;
  color: white;
  margin: 0px;
  font-weight: 400;
  text-align: center;
}

.initial-footer {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.initial-footer>p {
  font-size: 12px;
  color: white;
  margin: 0px;
  font-weight: 400;
}

.initial-footer>h3 {
  margin: 0px;
  font-size: 23px;
  color: #f2f2f2;
  font-weight: 500;
  text-align: left;
}

.initial-footer-button {
  width: 30%;
  height: 30px;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 56.2%,
      #ff3f7f 98.17%);
  color: white;
  font-family: "Bebas Neue", cursive !important;
  border-radius: 10px;
  font-size: 22px;
}

/* **** Very first page of the register section ENDs here ***** */

/* **** Form pages of the register section STARTs here ***** */
.step-process {
  width: 100%;
  height: 100%;
}

.form-column {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.form-column-second {
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  background: rgba(217, 217, 217, 0.1);
  padding: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

.preview-column {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  background: rgba(196, 196, 196, 0.1);
}

.sections-title {
  color: rgba(255, 255, 255, 0.7);
  font-size: 30px;
  font-weight: 600;
  margin: 10px 0px 00px 0px;
  text-decoration: underline;
}

.form-wizard {
  width: 100%;
  min-height: auto;
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0px;
}

.form-group>p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
  margin-top: 0%;
  margin-left: 0px;
  width: 100%;
}

input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.option-form-card {
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-card {
  flex: 0.45;
  height: 250px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
}

.form-card-image {
  width: 60%;
  height: 50%;
  padding: 10px;
}

.form-card>h3 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  margin: 0px 0px 0px 0px;
  color: white;
}

.check-dot {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: -2.5px;
  right: -2.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preview-form-group {
  width: 100%;
}

.preview-form-group>div>p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0px;
  width: 100%;
  text-transform: uppercase;
}

.preview-form-group>div>h3 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 5%;
  margin-top: 0%;
  margin-left: 0px;
  width: 100%;
  font-weight: 500;
}

/* #00ffbf */
.button-footer {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.btn-prev {
  flex: 0.2;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  background: transparent !important;
  font-size: 22px;
  color: white;
  font-family: "Bebas Neue", cursive !important;
}

.btn-next {
  flex: 0.2;
  border-radius: 10px;
  font-size: 25px;
  color: white;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 56.2%,
      #ff3f7f 98.17%);
  font-family: "Bebas Neue", cursive !important;
}

/* **** Form page of the register section ENDs here ***** */

/* **** Form preview page of the register section STARTs here ***** */
.summary-container {
  width: 100vw;
  height: 100vh;
  background-color: var(--empty-color) !important;
  /* background-image: url("../../assets/auth/reg_background.svg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 30px;
  overflow-y: scroll;
}

.summary-header {
  width: 100%;
}

.summary-header>h2 {
  margin: 0px;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 50px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-family: "Orbitron", sans-serif !important;
}

.summary-header>p {
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
}

.summary-content-column>p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0px;
  width: 100%;
  text-transform: uppercase;
}

.summary-content-column>h6 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 5%;
  margin-top: 0%;
  margin-left: 0px;
  width: 100%;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.summary-button-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.summary-btn-prev {
  flex: 0.1;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  background: transparent !important;
  font-size: 22px;
  color: white;
  font-family: "Bebas Neue", cursive !important;
}

.summary-btn-next {
  flex: 0.1;
  border-radius: 10px;
  font-size: 25px;
  color: white;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 56.2%,
      #ff3f7f 98.17%);
  font-family: "Bebas Neue", cursive !important;
}

/* **** Form preview page of the register section ENDs here ***** */
.initial-logo {
  cursor: pointer;
}

.mySelect {
  width: 100%;
  height: 70px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
  -webkit-appearance: menulist
}

.mySelect.v-text-field>.v-input__control>.v-input__slot:before {
  border-style: none !important;
}

.mySelect.v-text-field>.v-input__control>.v-input__slot:after {
  border-style: none !important;
}

.v-select>.v-input__control>.v-input__slot:before {
  border-style: none;
}

.pagination {
  width: 20%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: white;
  font-weight: 600;
}

.stepper {
  width: 100%;
  height: 10%;
  top: -13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
}

.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: linear-gradient(132.65deg,
      #ffbf3f 8.83%,
      #ff7f3f 65.1%,
      #ff3f7f 104.55%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  font-weight: 700px;
}

.circle-not-complete {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #828282;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.25);
  font-size: 18px;
  font-weight: 700px;
}

.divider {
  border-color: #ff7f3f !important;
}

@media screen and (max-width: 680px) {
  .register-container {
    width: 100vw;
    min-height: 100vh;
    background: url("../../assets/auth/reg_background.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden !important;
  }

  /* **** Very first page of the register section starts here ***** */
  .initial-selector {
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  .initial-choose-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .choose-headers {
    width: 100%;
  }

  .choose-headers>h3 {
    margin: 20px 0px 0px 0px;
    font-size: 25px;
    color: #f2f2f2;
    font-weight: 400;
    text-align: left;
  }

  .choose-headers>h3>span {
    font-weight: 600 !important;
  }

  .choose-headers>p {
    font-size: 16px;
    color: #f2f2f2;
    margin: 0px;
    font-weight: 400;
  }

  .choose-options {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .option-container {
    width: 48%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 20px;
  }

  .option-card {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .option-card-image {
    width: 40%;
    height: 100%;
    padding-top: 10px;
  }

  .option-card-heading>h3 {
    margin: 0px;
    font-size: 20px;
    color: white;
    font-weight: 500;
    text-align: center;
  }

  .option-card-heading>p {
    font-size: 14px;
    color: white;
    margin: 0px;
    font-weight: 400;
    text-align: center;
  }

  .initial-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 5px !important;
  }

  .initial-footer>p {
    font-size: 12px;
    color: white;
    margin: 0px;
    font-weight: 400;
  }

  .initial-footer>h3 {
    margin: 0px;
    font-size: 23px;
    color: #f2f2f2;
    font-weight: 500;
    text-align: left;
  }

  .initial-footer-button {
    width: 30%;
    height: 30px;
    background: linear-gradient(97.3deg,
        #ffbf3f -2.55%,
        #ff7f3f 56.2%,
        #ff3f7f 98.17%);
    color: white;
    font-family: "Bebas Neue", cursive !important;
    border-radius: 10px;
    font-size: 22px;
  }

  /* **** Very first page of the register section ENDs here ***** */

  /* **** Form pages of the register section STARTs here ***** */
  .step-process {
    width: 100%;
    height: 100%;
  }

  .form-column {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 30px;
  }

  .preview-column {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 30px;
    background: rgba(196, 196, 196, 0.1);
    overflow-y: scroll;
  }

  .form-wizard {
    width: 100%;
    height: 80%;
  }

  .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0%;
  }

  .form-group>p {
    font-size: 16px;
    color: #fff;
    margin-bottom: 10px;
    margin-top: 0%;
    margin-left: 0px;
    width: 100%;
  }

  input {
    width: 100%;
    height: 50px;
    background: rgba(196, 196, 196, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid var(--border-color) !important;
    outline: none;
    color: #fff;
    font-family: "Archivo", sans-serif;
    padding: 5px;
  }

  .option-form-card {
    width: 65%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .form-card {
    flex: 0.45;
    height: 250px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    position: relative;
    cursor: pointer;
  }

  .form-card-image {
    width: 90%;
    height: 50%;
    padding: 10px;
  }

  .form-card>h3 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    margin: 0px 0px 0px 0px;
    color: white;
  }

  .check-dot {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: -2.5px;
    right: -2.5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .preview-form-group {
    width: 100%;
  }

  .preview-form-group>div>p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    margin: 0px;
    width: 100%;
    text-transform: uppercase;
  }

  .preview-form-group>div>h3 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 5%;
    margin-top: 0%;
    margin-left: 0px;
    width: 100%;
    font-weight: 500;
  }

  /* #00ffbf */
  .button-footer {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  .btn-prev {
    flex: 0.3;
    border: 1px solid var(--border-color) !important;
    border-radius: 10px;
    background: transparent !important;
    font-size: 16px;
    color: white;
    font-family: "Bebas Neue", cursive !important;
  }

  .btn-next {
    flex: 0.3;
    border-radius: 10px;
    font-size: 18px;
    color: white;
    background: linear-gradient(97.3deg,
        #ffbf3f -2.55%,
        #ff7f3f 56.2%,
        #ff3f7f 98.17%);
    font-weight: 600 !important;
    font-family: "Bebas Neue", cursive !important;
  }

  /* **** Form page of the register section ENDs here ***** */

  /* **** Form preview page of the register section STARTs here ***** */
  .summary-container {
    width: 100vw;
    height: 100vh;
    background: var(--empty-color);
    /* background-image: url("../../assets/auth/reg_background.svg"); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px;
    overflow-y: scroll;
  }

  .summary-header {
    width: 100%;
  }

  .summary-header>h2 {
    margin: 0px;
    background: linear-gradient(97.3deg,
        #ffbf3f -2.55%,
        #ff7f3f 26.2%,
        #ff3f7f 98.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 30px;
    letter-spacing: 0px;
    text-transform: uppercase;
    font-family: "Orbitron", sans-serif !important;
  }

  .summary-header>p {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
  }

  .summary-content-column>p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    margin: 0px;
    width: 100%;
    text-transform: uppercase;
  }

  .summary-content-column>h6 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 5%;
    margin-top: 0%;
    margin-left: 0px;
    width: 100%;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .summary-button-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }

  .summary-btn-prev {
    flex: 0.3;
    border: 1px solid var(--border-color) !important;
    border-radius: 10px;
    background: transparent !important;
    font-size: 22px;
    color: white;
    font-family: "Bebas Neue", cursive !important;
  }

  .summary-btn-next {
    flex: 0.35;
    border-radius: 10px;
    font-size: 25px;
    color: white !important;
    background: linear-gradient(97.3deg,
        #ffbf3f -2.55%,
        #ff7f3f 56.2%,
        #ff3f7f 98.17%);
    font-family: "Bebas Neue", cursive !important;
  }

  /* **** Form preview page of the register section ENDs here ***** */
  .initial-logo {
    cursor: pointer;
  }

  .mySelect {
    width: 100%;
    min-height: 20px;
    background: rgba(196, 196, 196, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid var(--border-color) !important;
    outline: none;
    color: #fff;
    font-family: "Archivo", sans-serif;
    padding: 5px;
  }

  .v-row {
    margin: 0px !important;
  }

  .stepper {
    width: 85%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 12px;
  }

  .circle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: linear-gradient(132.65deg,
        #ffbf3f 8.83%,
        #ff7f3f 65.1%,
        #ff3f7f 104.55%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    font-weight: 700px;
  }

  .circle-not-complete {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #828282;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.25);
    font-size: 18px;
    font-weight: 700px;
  }

  .divider {
    border-color: #ff7f3f !important;
  }
}
</style>
