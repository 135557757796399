<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-bottom-transition" class="dialog" max-width="500" persistent>
            <div class="card-body dialog" :style="isLoading || showPopup ? { 'filter': 'blur(10px)' } : { 'filter': 'none' }">
                <div class="card-header">
                    <v-row style="justify-content: space-between;width: 100%">
                        <v-col lg="10">
                            <h3 class="m-0">Express Interest</h3>
                        </v-col>
                        <v-col lg="2" class="mt-4 ">
                            <v-icon class="span" @click="close()" size="20">mdi-close</v-icon>
                        </v-col>
                    </v-row>
                </div>
                <div class="card-content mt-10">
                    <div class="form-group">
                        <p>Cost * </p>
                        <v-text-field height="50" append-icon="mdi-currency-usd" dark outlined class="text-field"
                            v-model="uploadDetails.budget" type="number"></v-text-field>
                    </div>
                    <div class="form-group">
                        <p>Timeline * </p>
                        <date-picker v-model="uploadDetails.deadline" valueType="format" class="mx-input"
                            input-class="mx-input-data" format="DD/MM/YYYY" popup-class="pop-class">
                            <v-icon slot="icon-calendar" color="white">mdi-calendar</v-icon>
                            <v-icon slot="icon-clear" color="white">mdi-close</v-icon>
                        </date-picker>
                    </div>
                    <div class="form-group">
                        <p>Share your ideas / thoughts</p>
                        <textarea rows="3" v-model="uploadDetails.additionalInformation"></textarea>
                    </div>
                    <div class="form-group">
                        <p>Reference Links (if any)</p>
                        <v-text-field height="50" dark outlined class="text-field" v-model="uploadDetails.reference_url"
                            type="text"></v-text-field>
                    </div>
                    <div class="download-card">
                        <p>Upload Sample / Proposal (Optional)</p>
                        <div class="upload-content">
                            <div class="image" v-if="uploadDetails.file === ''">
                                <img src="../../assets/designer-file.svg" />
                            </div>
                            <div class="image" v-else>
                                <img :src="uploadDetails.file" />
                            </div>
                            <p>Upload</p>
                            <input type="file" id="file" accept="image/*, .fbx, .glb" @change="uploadTemplate" />
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <v-btn class="btn" :disabled="uploadDetails.budget === 0 || uploadDetails.deadline.length === 0"
                        @click="showInterest()">Submit Interest</v-btn>
                </div>
            </div>
        </v-dialog>
        <Loader :visible="isLoading" :text="loaderText"></Loader>
        <StatusModel :title="statusTitle" :message="statusMessage" :visible="showPopup" :status="isSuccess">
        </StatusModel>
    </div>
</template>
<script>
import { uploadFileToBaseIPFS } from '../../helpers/ipfs-upload'
import Loader from '../Loader/Loader.vue';
import StatusModel from './StatusModel.vue';
import { requestObj } from '../../services/request-service';
import { mapState } from "vuex";
import DatePicker from 'vue2-datepicker';
import '../Common/Style/datePicker.css';
import { mailServiceObj } from '../../services/mail-service';

export default {
    name: "ExpressInterest",
    props: ["visible", "tokenDetails"],
    data() {
        return {
            isLoading: false,
            isSuccess: false,
            showPopup: false,
            statusTitle: "",
            statusMessage: "",
            loaderText: "",
            uploadDetails: {
                file: "",
                type: "",
                fileName: "",
                budget: 0,
                deadline: "",
                additionalInformation: "",
                reference_url: "",
            }
        };
    },
    methods: {
        async uploadTemplate(event) {
            this.loaderText = "Uploading Work....";
            const files = event.target.files;
            if (files.length === 0 || files === null || files === undefined) {
                return;
            }
            this.isLoading = true;
            const element = files[0];
            this.uploadDetails.fileName = element.name;
            this.uploadDetails.type = element.name.split(".")[1];
            const url = await uploadFileToBaseIPFS(element);
            this.uploadDetails.file = url;
            this.isLoading = false;
        },
        close() {
            this.$emit("close");
        },
        async showInterest() {
            this.isLoading = true;
            const request_id = this.$route.params.request_id;
            const jsonData = {
                user: this.user,
                status: "pending",
                userId: this.user.uid,
                uploadDetails: this.uploadDetails,
                createdAt: new Date().toISOString(),
            };
            this.tokenDetails.interests.push(jsonData);
            await requestObj.showExpressInterest(request_id, this.tokenDetails);
            await mailServiceObj.sendMailToAdminOnExpressInterest(this.user.name, this.tokenDetails.name);
            this.isLoading = false;
            this.isSuccess = true;
            this.statusTitle = "Submitted Interest";
            this.statusMessage = "The team will notify you once it is approved"
            this.showPopup = true;
            setTimeout(() => {
                this.showPopup = false;
                window.location.reload();
            }, 3000);
        },
        downloadTemplate() {
            window.open(this.tokenDetails.templateFile, '_blank');
        }
    },
    components: { Loader, StatusModel, DatePicker },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
    },
}
</script>
<style scoped>
.dialog {
    background-color: var(--empty-color);
    padding: 20px;
    background-image: url('../../assets/popup.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.span {
    width: 20px;
    height: 20px;
    font-size: 15px;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    margin-left: 50px;
}

h3 {
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 56.2%,
            #ff3f7f 78.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 30px;
    font-weight: 500;
    font-family: "Orbitron", sans-serif !important;
}


.download-card {
    width: 100%;
    height: 100px;
    margin-top: 10px;
}

.download {
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    margin: 10px 0px 0px 0px;
}

.download-card>p {
    margin: 0px;
    font-size: 16px;
}

h4 {
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 56.2%,
            #ff3f7f 78.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 20px;
    font-weight: 500;
    font-family: "Orbitron", sans-serif !important;
}

.upload-content {
    width: 100%;
    height: 100px;
    border-radius: 10px;
    border: 1px solid var(--border-color);
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background: rgba(196, 196, 196, 0.1);
}

.card-footer {
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    margin-top: 40px;
}

.btn {
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 56.2%,
            #ff3f7f 78.17%);
    border-radius: 15px;
    font-weight: 400 !important;
    text-transform: none;
    font-family: "Bebas Neue", cursive !important;
    width: 25%;
    margin-top: 10px;
    color: white;
}

.image {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.image>img {
    width: 100%;
    height: 100%;
}

.upload-content>p {
    margin: 0px;
}

.upload-content>input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.form-group>p {
    margin: 10px 0px 0px 0px;
    font-size: 16px;
    color: white;
}

input {
    width: 100%;
    height: 50px;
    background: rgba(196, 196, 196, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid var(--border-color);
    outline: none;
    color: #fff;
    font-family: "Archivo", sans-serif;
    padding: 5px;
}

textarea {
    width: 100%;
    background: rgba(196, 196, 196, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid var(--border-color);
    outline: none;
    color: #fff;
    font-family: "Archivo", sans-serif;
    padding: 5px;
}

.text-field {
    height: 55px;
    width: 100%;
    background: rgba(196, 196, 196, 0.1);
    box-shadow: none !important;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    outline: none;
    color: #fff;
    font-family: "Archivo", sans-serif;
}

.mx-input>>>input {
  width: 100%;
  height: 50px !important;
  outline: none !important;
  border: none !important;
  color: #fff !important;
  font-family: "Archivo", sans-serif;
}

.mx-icon-calendar>>>svg {
  color: var(--border-color) !important;
}

.pop-class {
  bottom: 0px !important;
  top: 0px !important;
}

.mx-datepicker-popup {
  background: var(--primary-color) !important;
}

.today {
  color: var(--primary-color) !important;
}
.mx-input {
  background-color: rgba(196, 196, 196, 0.1) !important;
  padding: 0px;
  border: 1px solid #ff7f3f;
  width: 100%;
  height: 50px !important;
}
.mx-datepicker-main .mx-datepicker-popup .pop-class {
    bottom: 103px !important;
}
</style>