<template>
  <div class="landing-container pt-10">
    <div class="landing-header">
      <h3 class="general-text-title">Hello {{ user.name }}!</h3>
      <p class="description" v-if="user.role === 'designer'">
        Signa X is your platform to create, sell and lease out 3D digital assets
        for metaverse and gaming projects.
      </p>
      <p class="description" v-else style="width: 80% !important">
        Signa X is the digital asset creation and leasing platform for the
        Metaverse and Gaming Worlds. We optimize the entire digital asset
        production process to make it seamless for projects to acquire assets at
        scale.
      </p>
      <h1>Please use the options below to get started</h1>
    </div>
    <div class="landing-base"></div>
    <div class="landing-content">
      <div class="type-card" @click="routeToProfile()">
        <div class="card-image">
          <img src="../assets/landing/profile.svg" alt="type.name" />
        </div>
        <div class="card-name">
          <v-btn class="button btn">Update Profile</v-btn>
        </div>
      </div>
      <div
        class="type-card"
        @click="routeToUpload()"
        v-if="user.role === 'designer'"
      >
        <div class="card-image">
          <img src="../assets/landing/upload.svg" alt="type.name" />
        </div>
        <div class="card-name">
          <v-btn class="button btn">Upload Assets</v-btn>
        </div>
      </div>
      <div class="type-card" @click="routeToViewMarket()" v-else>
        <div class="card-image">
          <img src="../assets/landing/marketplace.svg" alt="type.name" />
        </div>
        <div class="card-name">
          <v-btn class="button btn">Marketplace</v-btn>
        </div>
      </div>
      <div
        class="type-card"
        @click="routeToView()"
        v-if="user.role === 'designer'"
      >
        <div class="card-image">
          <img src="../assets/landing/view.png" alt="type.name" />
        </div>
        <div class="card-name">
          <v-btn class="button btn">View Projects</v-btn>
        </div>
      </div>
      <div class="type-card" @click="routeToView()" v-else>
        <div class="card-image">
          <img src="../assets/landing/view.png" alt="type.name" />
        </div>
        <div class="card-name">
          <v-btn class="button btn">Request Asset</v-btn>
        </div>
      </div>
      <div
        class="type-card"
        @click="routeToResources()"
        v-if="user.role === 'designer'"
      >
        <div class="card-image">
          <img src="../assets/landing/resource.png" alt="type.name" />
        </div>
        <div class="card-name">
          <v-btn class="button btn">Resources</v-btn>
        </div>
      </div>
      <div class="type-card" @click="routueToCreators()" v-else>
        <div class="card-image">
          <img src="../assets/landing/creator.svg" alt="type.name" />
        </div>
        <div class="card-name">
          <v-btn class="button btn">View Creators</v-btn>
        </div>
      </div>
    </div>
    <FAQModal
      :visible="showFaq"
      @close="close"
      v-if="user.role !== 'team' && user.role !== 'meta'"
    ></FAQModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FAQModal from "../components/Modals/FAQModal.vue";

export default {
  name: "LandingPage",
  computed: {
    ...mapState({
      user: (state) => state.user,
      isNewUser: (state) => state.isNewUser,
    }),
  },
  data() {
    return {
      showFaq: false,
    };
  },
  methods: {
    routeToProfile() {
      this.$store.dispatch("setPath", "/profile");
      this.$router.push("/profile");
    },
    routeToUpload() {
      this.$store.dispatch("setPath", "/profile?tab=2");
      this.$router.push("/profile?tab=2");
    },
    routeToView() {
      this.$store.dispatch("setPath", "/request");
      this.$router.push("/request");
    },
    routeToResources() {
      this.$store.dispatch("setPath", "/resource");
      this.$router.push("/resource");
    },
    routeToViewMarket() {
      this.$store.dispatch("setPath", "/market");
      this.$router.push("/market");
    },
    routueToCreators() {
      this.$store.dispatch("setPath", "/creator");
      this.$router.push("/creator");
    },
    close() {
      this.showFaq = false;
      this.$store.dispatch("setIsNewUser", false);
      this.$store.dispatch("setGlobalPopup", false);
    },
    showLoading() {
      setTimeout(() => {
        this.showFaq = true;
        this.$store.dispatch("setGlobalPopup", true);
      }, 2000);
    },
  },
  components: { FAQModal },
  created() {
    this.showLoading();
  },
};
</script>
<style scoped>
.landing-container {
  width: 100%;
  height: 100%;
  position: relative;
  background: url("../assets/hue.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: var(--empty-color); */
}

.landing-header {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-family: "DM Sans", sans-serif;
  padding-top: 20px;
}

.landing-header > h3 {
  /* background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 56.2%,
    #ff3f7f 98.17%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Bebas Neue", cursive; */
  font-size: 50px;
  font-weight: 500;
  margin: 0px;
}

.landing-header > h1 {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 56.2%,
    #ff3f7f 98.17%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif;
  font-size: 25px;
  font-weight: 600;
  margin: 50px 0px 0px 0px;
}

.description {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin: 5px 0px 0px 0px;
  width: 50%;
  font-weight: 400;
  text-align: center;
}

.landing-base {
  width: 100%;
  height: 50%;
  /* background-image: url("../assets/landing/base-background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
}

.landing-content {
  width: 80%;
  height: 40%;
  position: absolute;
  top: 40%;
  margin-left: 10%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.type-card {
  background: rgba(196, 196, 196, 0.1);
  border: 1px solid #ff7f3f;
  border-radius: 10px;
  flex: 0.22;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.card-image {
  width: 40%;
  height: 40%;
}

.card-image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card-name > h3 {
  font-size: 16px;
  font-weight: 500;
  color: white;
  margin-top: 15px;
  text-align: center;
}

.btn {
  background: linear-gradient(177.35deg, #ffbf3f -22.23%, #ff3f7f 97.79%);
  color: white !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  width: 100%;
  margin-top: 10px;
  font-family: "Bebas Neue", cursive !important;
}

@media screen and (max-width: 680px) {
  .landing-container {
    width: 100%;
    height: 100%;
    position: relative;
    background: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll !important;
    padding-bottom: 30px;
    padding-top: 15% !important;
  }

  .landing-header {
    width: 100%;
    height: 50%;
    background: var(--primary-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-family: "DM Sans", sans-serif;
    padding-top: 45px;
  }

  .landing-header > h3 {
    background: linear-gradient(
      97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 56.2%,
      #ff3f7f 98.17%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: "Bebas Neue", cursive;
    font-size: 20px;
    font-weight: 500;
    color: #e0e0e0;
    text-align: center;
  }

  .landing-header > h1 {
    background: linear-gradient(
      97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 56.2%,
      #ff3f7f 98.17%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: "Bebas Neue", cursive;
    font-size: 25px;
    font-weight: 400;
    color: #e0e0e0;
    margin: 20px 0px 0px 0px;
    text-align: center;
  }

  .description {
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    margin: 5px 0px 0px 0px;
    width: 80%;
    font-weight: 400;
    text-align: center;
  }

  .landing-base {
    width: 100%;
    height: 50%;
    background-image: url("../assets/landing/base-background.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .landing-content {
    width: 100%;
    position: absolute;
    top: 50%;
    margin-left: 0%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    margin-bottom: 20px;
  }

  .type-card {
    background: rgba(196, 196, 196, 0.1);
    border: 1px solid #00ffbf;
    border-radius: 10px;
    flex: none;
    width: 120px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .card-image {
    width: 40%;
    height: 40%;
  }

  .card-image > img {
    width: 100%;
    height: 100%;
  }
  .card-name {
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-name > h3 {
    font-size: 16px;
    font-weight: 500;
    color: white;
    margin-top: 15px;
    text-align: center;
  }

  .btn {
    color: white !important;
    font-size: 20px;
    font-weight: 600;
    width: 75% !important;
    font-size: 12px !important;
  }
}
</style>
