<template>
  <div class="resource-container"
    :style="isLoading || showPDF || showPDF ? { filter: 'blur(5px)' } : { filter: 'none' }">
    <div class="header">
      <div class="header-title">
        <!-- <div class="glow text">Resources</div> -->
        <div class="main text">Resources</div>
      </div>
    </div>
    <div style="
        width: 75%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        " @click="showResource(-1)" v-if="user.role === 'team'">
      <v-icon color="#ff7f3f" size="40">mdi-plus-circle</v-icon>
    </div>
    <div class="resource-body mt-10">
      <v-row style="width: 90%">
        <v-col lg="4" v-for="(resource, index) in resources" :key="index">
          <div class="mt-2 resource-block-outer" v-show="resource.type === 'pdf'" @click="goToUrl(resource)">
            <div class="resource-block">
              <img :src="resource.materialImage" />
              <div class="details">
                <h3>{{ resource.materialTitle }}</h3>
                <div class="user-details mt-4" v-if="resource.enrolledUsers.length !== 0">
                  <v-row>
                    <v-col lg="1" v-for="(user, index) in resource.enrolledUsers" :key="index" class="user-avatar">
                      <div class="dot">
                        <img :src="user" alt="index" />
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <v-btn class="button" height="25" v-if="!resource.isEnrollable" @click="goToUrl(resource)">
                  Read Now!</v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- <div class="header-title mt-10">
      <div class="main small-text">Videos</div>
    </div>
    <v-row class="mt-2" v-if="videos.length > 0">
      <v-col lg="6">
        <div class="video-container">
          <iframe :src="videos[selectedVideo].materialLink" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </v-col>
      <v-col lg="4">
        <div class="other-videos">
          <div class="video-card" v-for="(video, index) in videos" :key="index" @click="changeVideo(index)">
            <div class="video-image">
              <img :src="video.materialImage" />
            </div>
            <div class="video-title">
              <p>{{ video.materialTitle }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row> --> -->
    <Loader :visible="isLoading" text="Loading Content..."></Loader>
    <PDFViewerModal :visible="showPDF" :data="selectedItem" @close="close"></PDFViewerModal>
    <ResourceAdd :visible="showEdit" :resource="selectedResource" :isEdit="selectedResource === null ? false : true" @close="close">
    </ResourceAdd>
  </div>
</template>
<script>
import { resourceObj } from '../services/resource-service';
import Loader from '../components/Loader/Loader.vue';
import PDFViewerModal from '../components/InAppProduct/PDFViewerModal.vue';
import { mapState } from 'vuex';
import ResourceAdd from '../components/Modals/ResourceAdd.vue';

export default {
  name: "Resources",
  data() {
    return {
      selectedVideo: 0,
      videos: [],
      resources: [],
      isLoading: false,
      selectedItem: {},
      showPDF: false,
      selectedItem: {},
      showPDF: false,
      showEdit: false,
      selectedResource: null
    };
  },
  methods: {
    async getAllResources() {
      this.isLoading = true;
      const data = await resourceObj.getAllResources();
      const allVideos = [];
      const links = [];
      const pdfs = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element.type === 'video') {
          allVideos.push(element);
        } else if (element.type === 'link') {
          links.push(element);
        } else if (element.type === 'pdf') {
          pdfs.push(element);
        }
      }

      this.resources = pdfs;
      this.videos = allVideos;
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
      // const data = [
      //   {
      //     materialTitle: "Augmented Reality",
      //     materialLink: "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/resources%2Fpdf%2FCopy%20of%20augmented%20reality.pdf?alt=media&token=07429d89-f4f2-4b30-8530-733c880b2089",
      //     materialImage:
      //       "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/resources%2Fpdf%2Fimages%2FAugmented%20Reality.svg?alt=media&token=e5c7ff54-2037-42c0-a633-011faf1f32c1",
      //     materialDescription: "",
      //     isEnrollable: false,
      //     enrolledUsers: [],
      //     role: "meta",
      //     type: "pdf",
      //   },
      //   {
      //     materialTitle: "Digital Twins",
      //     materialLink: "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/resources%2Fpdf%2FCopy%20of%20Digital%20Twins.pdf?alt=media&token=8e3b5337-725a-4b64-abbe-ce41d0db241b",
      //     materialImage:
      //       "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/resources%2Fpdf%2Fimages%2FDigital%20Twins.svg?alt=media&token=c4c1bf61-7ae9-4d19-8e4a-4750188c526b",
      //     materialDescription: "",
      //     isEnrollable: false,
      //     enrolledUsers: [],
      //     role: "meta",
      //     type: "pdf",
      //   },
      //   {
      //     materialTitle: "Extended Reality",
      //     materialLink: "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/resources%2Fpdf%2FCopy%20of%20Extended%20Reality.pdf?alt=media&token=84fab7ea-ee60-494e-bcf4-236ac260194d",
      //     materialImage:
      //       "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/resources%2Fpdf%2Fimages%2FExtended%20Reality%20(XR).svg?alt=media&token=20279f54-9c35-413b-b8af-24d45957bb30",
      //     materialDescription: "",
      //     isEnrollable: false,
      //     enrolledUsers: [],
      //     role: "meta",
      //     type: "pdf",
      //   },
      //   {
      //     materialTitle: "Metaverse Avatars",
      //     materialLink: "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/resources%2Fpdf%2FCopy%20of%20Metaverse%20Avatars.pdf?alt=media&token=18e3fa32-96b2-4fd5-a498-980b0f76f5a7",
      //     materialImage:
      //       "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/resources%2Fpdf%2Fimages%2FAvatars.svg?alt=media&token=a153df99-a2d9-43c3-9fd3-53bcae8582f3",
      //     materialDescription: "",
      //     isEnrollable: false,
      //     enrolledUsers: [],
      //     role: "meta",
      //     type: "pdf",
      //   },
      //   {
      //     materialTitle: "Virtual World",
      //     materialLink: "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/resources%2Fpdf%2FCopy%20of%20Virtual%20World.pdf?alt=media&token=a08071b8-ccb4-48ad-81dc-49c678726ac4",
      //     materialImage:
      //       "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/resources%2Fpdf%2Fimages%2FVirtual%20World.svg?alt=media&token=4f13e9fb-f57b-4698-8686-c708e65a01d8",
      //     materialDescription: "",
      //     isEnrollable: false,
      //     enrolledUsers: [],
      //     role: "meta",
      //     type: "pdf",
      //   },
      //   {
      //     materialTitle: "What is Metaverse?",
      //     materialLink: "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/resources%2Fpdf%2FCopy%20of%20what%20is%20metaverse.pdf?alt=media&token=b5aa5448-29ba-4f5f-94f2-288f96d72ae8",
      //     materialImage:
      //       "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/resources%2Fpdf%2Fimages%2Fwhat%20is%20metaverse-%204.svg?alt=media&token=b08dadc5-01c8-49ce-b39d-2fa81192e46c",
      //     materialDescription: "",
      //     isEnrollable: false,
      //     enrolledUsers: [],
      //     role: "meta",
      //     type: "pdf",
      //   },
      // ];
      // await resourceObj.bulkCreate(data);
    },
    goToUrl(link) {
      this.selectedItem = link;
      this.openPDf();
    },
    changeVideo(index) {
      this.selectedVideo = index;
    },
    openPDf() {
      this.showPDF = true;
    },
    close() {
      this.showPDF = false;
      this.showEdit = false;
    },
    showResource(index) {
      if (index !== -1) {
        this.selectedResource = this.resources[index];
      }
      this.showEdit = true;
    },
  },
  created() {
    this.getAllResources();
  },
  components: { Loader, PDFViewerModal, ResourceAdd },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
};
</script>
<style scoped>
.resource-container {
  width: 100vw;
  min-height: 100vh;
  /* background-image: url("../assets/resources/resource_bg.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  margin-bottom: 20px;
  background-image: url("../assets/hue.svg");
}

.header {
  width: 100%;
}

.header-title {
  position: relative;
}

.text {
  font-size: 50px;
  font-weight: 600;
  font-family: "Orbitron", sans-serif;
  width: 25%;
}

.small-text {
  font-size: 20px;
  font-weight: 700;
  font-family: "Orbitron", sans-serif;
  text-transform: capitalize !important;
  width: 15%;
}

.glow {
  position: absolute;
  background: linear-gradient(132.65deg, #FFBF3F 8.83%, #FF7F3F 65.1%, #FF3F7F 104.55%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  filter: blur(20px);
}

.main {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 56.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.resource-body {
  width: 80%;
}

.resource-block-outer {
  width: 95%;
  height: 300px;
  border-radius: 10px;
  cursor: pointer;
  padding: 8px;
  background: rgba(224, 224, 224, 0.08);
  backdrop-filter: blur(6.27643px);
}

.resource-block {
  position: relative;
  width: 100%;
  height: 100%;
}

.resource-block>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.details {
  width: 100%;
  height: 70px;
  position: absolute;
  bottom: 0px;
  /* border-radius: 10px; */
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(20px);
  padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.details>h3 {
  font-size: 18px;
  font-weight: 700;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  font-family: "Archivo", sans-serif;
  margin-bottom: 5px;
}

.button {
  padding: 2px;
  height: 20px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Bebas Neue", cursive;
  color: white;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 56.2%,
      #ff3f7f 98.17%);
  text-transform: capitalize !important;
  line-height: 27px;
  box-shadow: none;
}

.user-details {
  width: 40%;
  height: 30px;
  position: relative;
}

.dot {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-top: -10px;
  margin-right: -10px;
  border: 3px solid white;
  background: red;
}

.dot>img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.user-avatar {
  margin-right: -5px !important;
}

.video-container {
  width: 100%;
  height: 300px;
  border: 2px solid var(--border-color);
  border-radius: 10px;
}

.other-videos {
  width: 100%;
  height: 300px;
  overflow-y: scroll;
}

.video-container>iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.video-card {
  width: 80%;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background: rgba(196, 196, 196, 0.16);
  overflow-y: scroll !important;
  cursor: pointer;
}

.video-image {
  flex: 0.5;
  height: 100%;
}

.video-image>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.video-title {
  flex: 0.5;
  height: 100%;
}

.video-title>p {
  font-size: 14px;
  font-weight: 600;
  color: white;
  margin: 0px 0px 0px 10px;
}
</style>
