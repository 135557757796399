import { userObj } from './user-service';
import { assetObj } from './asset-service';
import { requestObj } from './request-service';

class OverviewServices {
    async getContentLength() {
        try {
            const users = await userObj.findAll();
            const assets = await assetObj.getAllLazyMintingAssets();
            const requests = await requestObj.findAll();
            let verified = 0;
            let unVerified = 0; 
            for (let index = 0; index < assets.length; index++) {
                const element = assets[index];
                if(element.verified){
                    verified+=1;
                }else{
                    unVerified+=1 
                }
            }
            return {
                totalUser: users.length-1,
                totalAssets: assets.length,
                totalRequests: requests.length,
                totalVerified: verified,
                totalUnVerified: unVerified,
            }
        } catch (error) {
            throw error;
        }
    }
}
export const overviewObj = new OverviewServices();