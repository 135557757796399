import router from "../router";
import { onAuthStateChanged, firebaseAuth } from "../helpers/firebase-handler";
import store from "../store";
import { userObj } from "../services/user-service";
import {
  initializeNavigationForDesigner,
  initializeNavigationForOwner,
  initializeNavigationForTeam,
} from "./navigation_controller";
import { authObj } from "../services/auth_service";

export const handleAuth = async () => {
  return new Promise((resolve) => {
    onAuthStateChanged(firebaseAuth, async (user) => {
      if (user) {
        const userDetails = await userObj.getUser(user.uid);
        if (!userDetails.exists() || userDetails.data() === undefined || !userDetails.data().verified) {
          await authObj.logout();
          store.commit("setUser", null);
          return;
        }
        store.dispatch("setUser", userDetails.data());
        const data = userDetails.data();
        if (data.role === "designer") {
          initializeNavigationForDesigner();
        } else if (data.role === "meta") {
          initializeNavigationForOwner();
        } else {
          initializeNavigationForTeam();
        }
        // if (router.currentRoute.path !== "/home") {
        //   router.push("/home");
        // }
        if (router.currentRoute.path !== "/home" && router.currentRoute.path !== '/') {
          if(router.currentRoute.name !== 'ViewAssetPublic' || router.currentRoute.name !== 'ViewProfilePublic'){
            router.push(`${router.currentRoute.path}`);
          }else{
            if(router.currentRoute.name === 'ViewAssetPublic' ){
              router.push(`${router.currentRoute.path}?type=${router.currentRoute.query.type}`);
            }else{
              router.push(`${router.currentRoute.path}`);
            }
          }
        }else{
          router.push("/home");
        }
        // } else {
        //   await authObj.logout();
        //   store.commit("setUser", null);
        //   store.commit("setNavigation", []);
        //   if (router.currentRoute.path !== "/")
        //     router.push("/");
        // }
      }else{
        if(router.currentRoute.name === 'ViewAssetPublic' ){
          router.push(`${router.currentRoute.path}?type=${router.currentRoute.query.type}`);
        }else if(router.currentRoute.name === 'ViewProfilePublic' ){
          router.push(`${router.currentRoute.path}`);
        }
      }
      resolve(true);
    });
  });
};
