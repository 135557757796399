import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notification-container",style:(_vm.isLoading ? { filter: 'blur(10px)' } : { filter: 'none' })},[_c('Header',{attrs:{"required":true,"title":"Notification","subtitle":"Find all your notifications here."}}),(_vm.notifications.length === 0 && !_vm.isLoading)?_c('div',{staticClass:"no-notification"},[_c('div',{staticClass:"place-holder"},[_c(VImg,{staticClass:"img",attrs:{"src":require("../assets/no-notification.png")}})],1),_c('p',{staticClass:"sub-title"},[_vm._v("No Notifications available yet!")])]):(!_vm.isLoading)?_c('div',{staticClass:"notification-body"},_vm._l((_vm.notifications),function(notification,index){return _c(VRow,{key:index},[_c(VCol,{attrs:{"lg":"6"}},[_c('div',{staticClass:"notification-card",style:(notification.type.includes('request')
              ? { 'border-left': '10px solid #00bcd4' }
              : notification.type.includes('express')
              ? { 'border-left': '10px solid #ff9800' }
              : {
                  'border-left': '10px solid #4caf50',
                })},[_c(VRow,[_c(VCol,{attrs:{"lg":"1"}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(notification.type.includes("request") ? "mdi-account-voice" : "mdi-account-alert-outline")+" ")])],1),_c(VCol,{staticClass:"notification-details",attrs:{"lg":"8"},on:{"click":function($event){return _vm.goToNotification(notification)}}},[_c('p',{staticClass:"notification-title"},[_vm._v(_vm._s(notification.title))]),_c('p',{staticClass:"notification-sub-title"},[_vm._v(_vm._s(notification.message))]),_c('p',{staticClass:"notification-time"},[_vm._v(" "+_vm._s(notification.createdAt.toDate().toLocaleString())+" ")])]),_c(VCol,{attrs:{"lg":"3"}},[(!notification.readStatus)?_c('div',{staticClass:"new-notification"}):_vm._e()])],1)],1)])],1)}),1):_vm._e(),_c('Loader',{attrs:{"visible":_vm.isLoading,"text":_vm.loaderText}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }