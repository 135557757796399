<template>
  <div>
    <v-dialog transition="dialog-bottom-transition" persistent v-model="visible" class="dialog" max-width="500">
      <div class="card-body dialog">
        <div class="card-header">
          <v-row>
            <v-col lg="10">
              <h3>Payment Methods</h3>
            </v-col>
            <v-col lg="2" class="mt-1">
              <div class="span" @click="close()">X</div>
            </v-col>
          </v-row>
        </div>
        <div class="tab-header">
          <div v-for="(tab, index) in tabs" :key="index">
            <p :class="
              selectedIndex === index ? 'selected-tab' : 'unselected-tab'
            " @click="changeModelIndex(index)">
              {{ tab }}
            </p>
          </div>
        </div>
        <div class="tab-card" v-if="selectedIndex === 0">
          <div>
            <h4 class="pay-title">Payable Amount</h4>
            <div class="asset-nature" v-if="request.details.activityType === 'Leasing'">
              <v-icon color="#ff7f3f" size="20">mdi-currency-usd</v-icon>
              <p>{{ parseFloat(request.price) * parseFloat(request.details.rentDays) }}</p>
            </div>
            <div class="asset-nature" v-else>
              <v-icon color="#ff7f3f" size="20">mdi-currency-usd</v-icon>
              <p>{{ parseFloat(request.price) }}</p>
            </div>
          </div>
          <v-btn class="submit">Pay Now</v-btn>
        </div>
        <div class="tab-card" v-else>
          <v-card class="tab-card" elevation="0">
            <div>
              <h4 class="pay-title">Payable Amount</h4>
              <div class="asset-nature" v-if="request.details.activityType === 'Leasing'">
                <v-icon color="#ff7f3f" size="20">mdi-currency-usd</v-icon>
                <p>{{ parseFloat(request.price) * parseFloat(request.details.rentDays) }}</p>
              </div>
              <div class="asset-nature" v-else>
                <v-icon color="#ff7f3f" size="20">mdi-currency-usd</v-icon>
                <p>{{ parseFloat(request.price) }}</p>
              </div>
            </div>
            <p style="margin: 0px; font-size: 14px" class="mt-5">
              Select Crypto
            </p>
            <v-select dark :items="crypto" label="Select Crypto" solo class="select-type"
              background-color="transparent"></v-select>
            <p style="margin: 0px; font-size: 14px" class="mt-5">
              Copy Address
            </p>
            <v-text-field class="text-field" v-model="address" append-outer-icon="mdi-content-copy" solo color="#00FFBF"
              height="50" background-color="transparent" readonly @click:append-outer="copyAddress" dark>
            </v-text-field>
            <p style="margin: 0px; font-size: 14px" class="mt-5">
              Transaction ID
            </p>
            <v-text-field class="text-field" v-model="transactionId" solo color="#00FFBF" height="50"
              background-color="transparent" dark hint="copy transaction ID, from your wallet" persistent-hint>
            </v-text-field>
            <v-btn class="mt-10 submit" @click="submitPayment()">Submit</v-btn>
          </v-card>
        </div>
      </div>
    </v-dialog>
    <Loader :visible="loading" :text="loaderText"></Loader>
    <status-model :visible="showSuccessPopup" :status="isSuccess" :title="
      isSuccess ? 'Congratulations! Payment Successful' : 'Payment Failed'
    " :message="
  isSuccess
    ? 'Your payment has been successfully made. We will get back to you soon.'
    : 'Your payment has failed. Please try again later.'
"></status-model>
  </div>
</template>
<script>
import { uploadFileToBaseIPFS } from "../../helpers/ipfs-upload";
import Loader from "../Loader/Loader.vue";
import { mapState } from "vuex";
import { requestObj } from "../../services/request-service";
import { assetObj } from "../../services/asset-service";
import StatusModel from "./StatusModel.vue";

export default {
  name: "PaymentModal",
  props: ["visible", "request"],
  data() {
    return {
      message: "",
      loading: false,
      loaderText: "Uploading your model",
      modelFile: [],
      selectedIndex: 0,
      tabs: ["Fiat Currency", "Crypto Currency"],
      crypto: ["Ethereum", "Bitcoin"],
      tab: null,
      address: "0x8626f6940E2eb28930eFb4CeF49B2d1F2C9C1199",
      transactionId: "",
      showSuccessPopup: false,
      isSuccess: false,
    };
  },
  created() {
    // this.getUserAddress();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    changeModelIndex(index) {
      this.selectedIndex = index;
    },
    async getUserAddress() {
      this.address = await assetObj.getWalletAddress();
    },
    copyAddress() {
      navigator.clipboard.writeText(this.address);
    },
    async submitPayment() {
      this.loading = true;
      this.loaderText = "Submitting your payment";
      // let request = this.request;
      // let payment = {
      //   requestId: request.request_id,
      //   paymentType: this.selectedIndex === 0 ? "fiat" : "Crypto",
      //   paymentAmount: request.assetBudget,
      //   paymentAddress: this.address,
      //   paymentTransactionId: this.transactionId,
      //   paymentStatus: "pending",
      //   paymentDate: new Date().toISOString(),
      // };
      // let response = await requestObj.submitPayment(payment);
      if (true) {
        this.loading = false;
        this.isSuccess = true;
        this.showSuccessPopup = true;
        setTimeout(() => {
          this.showSuccessPopup = false;
        }, 2000);
        this.$emit("close");
      } else {
        this.loading = false;
        this.isSuccess = false;
        this.showSuccessPopup = true;
        setTimeout(() => {
          this.showSuccessPopup = false;
        }, 2000);
      }
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  components: { Loader, StatusModel },
};
</script>
<style scoped>
.dialog {
  background: var(--empty-color);
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  background-image: url("../../assets/popup.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

h3 {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
}

.selected-tab {
  font-size: 20px;
  font-weight: 400;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 10px 5px;
  cursor: pointer;
}

.unselected-tab {
  font-size: 20px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.2);
  margin: 10px;
  cursor: pointer;
}

.span {
  width: 20px;
  height: 20px;
  font-size: 15px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.file-body {
  width: 100%;
  height: 200px;
  border: 1px dashed white;
  border-radius: 10px;
  margin: 20px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.placeholder {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.placeholder>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.placeholder>p,
.message-title {
  color: white;
  font-size: 15px;
  margin: 10px 0px;
}

.input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--designer-color);
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.submit-button {
  background: linear-gradient(101.24deg, #ff7f3f 43.86%, #ffbf3f 86.82%);
  border-radius: 15px;
  font-weight: 600 !important;
  text-transform: none;
  font-family: "Archivo", sans-serif !important;
  width: 40%;
  color: white;
  margin-left: 30%;
}

.model-view {
  width: 100%;
  height: 100px;
  border: 1px dashed white;
  border-radius: 10px;
}

.tab-card {
  background: transparent !important;
}

.pay-title {
  margin: 0px;
  color: white;
  font-weight: 400px !important;
}

.pay-value {
  margin: 0px;
  color: white;
  font-size: 20px;
}

.select-type {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color);
  outline: none;
  color: #fff !important;
  font-family: "Archivo", sans-serif;
  padding: 5px;
  font-size: 12px !important;
}

.text-field {
  width: 100%;
  height: 55px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  outline: none;
  color: #fff !important;
  font-family: "Archivo", sans-serif;
}

.hidden-sm-and-down .v-icon {
  color: white !important;
}

.submit {
  margin-left: 70%;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  border-radius: 10px;
  font-weight: 400;
  margin-top: 25px;
  margin-right: 25px;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  letter-spacing: 0.8px !important;
  color: white;
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  width: 30%;
  margin-top: 10px;
  font-size: 18px;
}

.tab-header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.asset-nature {
  padding: 5px;
  background: rgba(196, 196, 196, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  width: 20%;
}

.asset-nature>p {
  font-size: 12px;
  color: white;
  margin: 0px;
  font-weight: 400;
  margin-left: 5px;
}
</style>

<!-- joke exit whale priority ticket judge mango pipe high gain can bird -->