<template>
  <div class="overview-container pt-5">
    <Header :required="true" title="Overview" subtitle="Platform Statistics"></Header>
    <div class="overview-body mt-10">
      <v-row style="width: 80%; padding-left: 20px;">
        <v-col lg="3">
          <div class="stats" @click="navigate('/users')">
            <v-icon color="#ff7f3f" size="50">mdi-account-group</v-icon>
            <p>Registered Users</p>
            <h1 class="general-text-title ">{{ stats.totalUser }}</h1>
          </div>
        </v-col>
        <v-col lg="3">
          <div class="stats" @click="navigate('/asset')">
            <v-icon color="#ff7f3f" size="50">mdi-store</v-icon>
            <p>Assets Created</p>
            <h1 class="general-text-title ">{{ stats.totalAssets }}</h1>
          </div>
        </v-col>
        <!-- <v-col lg="3">
          <div class="stats" @click="navigate('/asset')">
            <v-icon color="#ff7f3f" size="50">mdi-store</v-icon>
            <p>Assets Verified</p>
            <h1 class="general-text-title ">{{ stats.totalVerified }}</h1>
          </div>
        </v-col>
        <v-col lg="3">
          <div class="stats" @click="navigate('/asset')">
            <v-icon color="#ff7f3f" size="50">mdi-store</v-icon>
            <p>Assets UnVerified</p>
            <h1 class="general-text-title ">{{ stats.totalUnVerified }}</h1>
          </div>
        </v-col> -->
        <v-col lg="3">
          <div class="stats" @click="navigate('/request')">
            <v-icon color="#ff7f3f" size="50">mdi-account-voice</v-icon>
            <p>Projects Created</p>
            <h1 class="general-text-title ">{{ stats.totalRequests }}</h1>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Header from "../components/Header.vue";
import { overviewObj } from "../services/overview-service";
export default {
  name: "Overview",
  components: {
    Header,
  },
  data() {
    return {
      stats: {},
    }
  },
  methods: {
    async getStats() {
      try {
        const data = await overviewObj.getContentLength();
        this.stats = data;
      } catch (error) {
        console.log(error);
      }
    },
    navigate(route) {
      this.$store.dispatch("setPath", route);
      this.$router.push(route);
    }
  },
  created() {
    this.getStats();
  },
};
</script>
<style scoped>
.overview-container {
  width: 100vw;
  min-height: 100vh;
  /* background: var(--empty-color); */
  background-image: url("../assets/hue.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.overview-body {
  width: 100%;
  height: 100%;
}

.stats {
  background: rgba(196, 196, 196, 0.1);
  border-radius: 10px;
  flex: 0.22;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
  padding: 20px;
  border-left: 5px solid var(--border-color);
}

.stats>p {
  color: white;
  margin: 0px;
  font-size: 20px;
  font-weight: 400;
}

.stats>h1 {
  margin: 0px;
  font-size: 40px !important;
}
</style>
