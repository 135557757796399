import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,{staticClass:"model-card",attrs:{"color":'#15181E'}},[_c(VRow,{staticStyle:{"justify-content":"space-between","width":"100%"}},[_c(VCol,{attrs:{"lg":"10"}},[_c('div',{class:'model-title-designer'},[_vm._v("Confirmation")])]),_c(VCol,{attrs:{"lg":"1"}},[_c(VIcon,{staticClass:"mt-2",attrs:{"color":"white","size":"20"},on:{"click":function($event){return _vm.closeTerms()}}},[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"sub-title mt-10 mb-10"},[_vm._v(" By submitting this form, you agree to the "),_c('span',{staticStyle:{"color":"#ff7f3f","text-decoration":"none","cursor":"pointer"},on:{"click":function($event){_vm.showTermsUse = true}}},[_vm._v("terms of use ")]),_vm._v(" and "),_c('span',{staticStyle:{"color":"#ff7f3f","text-decoration":"none","cursor":"pointer"},on:{"click":function($event){_vm.showPrivacyPolicy = true}}},[_vm._v("privacy policy.")])]),_c(VBtn,{staticClass:"btn button",on:{"click":function($event){return _vm.onSubmitClick()}}},[_vm._v("Submit")])],1)],1),_c('loader',{attrs:{"visible":_vm.isLoading,"text":"Submitting Your Profile......"}}),_c('status-model',{attrs:{"visible":_vm.showSuccessPopup,"status":_vm.isSuccess,"title":_vm.popupTitle,"message":_vm.popupMessage}}),_c('privacy-policy',{attrs:{"visible":_vm.showPrivacyPolicy},on:{"close":_vm.close}}),_c('terms-use',{attrs:{"visible":_vm.showTermsUse},on:{"close":_vm.close}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }