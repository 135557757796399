<template>
    <div>
        <v-dialog transition="dialog-bottom-transition" persistent v-model="visible" max-width="80%"
            style="padding: 10px">
            <div class="card-body dialog">
                <div class="card-header mb-10">
                    <v-row style="width: 100%; justify-content: space-between">
                        <v-col lg="11">
                            <h3>{{ data.materialTitle }}</h3>
                        </v-col>
                        <v-col lg="1" class="mt-0">
                            <v-icon class="span" @click="close()" size="30">mdi-close</v-icon>
                        </v-col>
                    </v-row>
                </div>
                <div class="pdf-view-container" id="pdf-view-container">
                    <iframe height="100%" width=100% :src="`${data.materialLink}`" style="border:none !important"></iframe>
                </div>
            </div>
        </v-dialog>
        <Loader :visible="loading" :text="loaderText"></Loader>
    </div>
</template>
<script>
import Loader from '../Loader/Loader.vue';


export default {
    name: "PDFViewModal",
    components: { Loader },
    data() {
        return {
            serviceUrl:
                "https://ej2services.syncfusion.com/production/web-services/api/pdfviewer",
            title: "",
            link: "https://firebasestorage.googleapis.com/v0/b/kdem-b32a7.appspot.com/o/documents02%20UC%2011th%20Feb%202021.pdf?alt=media&token=1d7f18ed-f7b3-45d8-bb75-612f081a718d",
            loading: false,
            loaderText: "Loading...",
        }
    },
    methods: {
        close() {
            this.$emit('close');
        }
    },
    props: ["visible", "data"],
}
</script>
<style scoped>
.pdf-view-container {
    width: 100%;
    height: 70vh;
}


.dialog {
    background: var(--empty-color);
    padding: 20px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    height: 80%;
}

h3 {
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 26.2%,
            #ff3f7f 98.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: "Orbitron", sans-serif;
    margin: 0px;
    font-size: 24px !important;
    font-weight: 800;
}

.span {
    width: 20px;
    height: 20px;
    font-size: 15px;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    margin-top: 5px;
    margin-left: 60px;
}

#layout-container {
    opacity: 1 !important;
    width: 1449px !important;
    height: 4355px !important;
    top: 45px !important;
    transform: scale(0.336094) translate(0px, 0px) !important;
}
</style>