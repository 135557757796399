<template>
    <div>
        <v-dialog v-model="visible" fullscreen transition="dialog-bottom-transition" style="padding: 10px"
            class="dialog">
            <div class="card-div dialog">
                <div class="card-header">
                    <v-row class="pl-5" justify="space-between">
                        <v-col lg="11">
                            <h3>User Communication</h3>
                        </v-col>
                        <v-col lg="1" class="mt-4">
                            <v-icon class="span" @click="close()" size="25">mdi-close</v-icon>
                        </v-col>
                    </v-row>
                </div>
                <div class="card-content">
                    <v-row>
                        <v-col lg="3" style="padding: 0px;">
                            <div class="all-user">
                                <v-text-field v-model="search" prepend-icon="mdi-magnify" outlined class="text-field"
                                    dark solo dense background-color="transparent" placeholder="search...."
                                    @input="searchText">
                                    <v-icon slot="prepend" color="#ff7f3f" style="padding-left: 10px">mdi-magnify
                                    </v-icon>
                                </v-text-field>
                                <div class="user-data" v-for="(contact, index) in allContacts" :key="index"
                                    @click="getMessageByUserId(contact.uid, contact)">
                                    <v-row>
                                        <v-col lg="2">
                                            <div class="user-data-profile">
                                                <img :src="contact.profile" />
                                            </div>
                                        </v-col>
                                        <v-col lg="8" class="mt-0 ml-2">
                                            <h2 class="name">{{ contact.name }}</h2>
                                            <p class="role">{{ contact.sentTime }}</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-col>
                        <v-col lg="9" style="padding: 0px;">
                            <div class="chat-screen" v-if="selectedUserId.length !== 0">
                                <div class="message-screen" v-if="selectedMessage.length !== 0">
                                    <div v-for="(message, index) in selectedMessage" :key="index">
                                        <div class="chat-message" v-if="message.user.uid !== user.uid">
                                            <div style="width: 100%; height: 50px">
                                                <v-row>
                                                    <v-col lg="1">
                                                        <v-avatar class="p-10" size="30px">
                                                            <img :src="message.user.profile"
                                                                style="object-fit: contain; width: 100%; height: 100%" />
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col class="ml-2">
                                                        <div class="name">
                                                            {{ message.user.name }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <v-divider></v-divider>
                                            {{ message.message }}
                                        </div>
                                        <div class="chat-message-user" v-else>
                                            {{ message.message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="message-screen" v-else>

                                </div>
                                <div class="message-typer">
                                    <div>
                                        <v-row align="center">
                                            <v-col lg="9">
                                                <input placeholder="Type here......" class="input"
                                                    v-model="chatMessage" />
                                            </v-col>
                                            <v-col lg="3">
                                                <v-btn class="btn" @click="sendMessage()">Send</v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-divider class="divider"></v-divider>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-dialog>
    </div>
</template>
<script>
import { contactObj } from '../../services/contact-service';
import { mapState } from "vuex";
import { userObj } from '../../services/user-service';
import { mailServiceObj } from '../../services/mail-service';
import { notificationObj } from '../../services/notification-service';
import { ADMIN_USER_ID, NOTIFICATION_TYPE_CONTACT_CHAT } from '../../helpers/constants';
import moment from 'moment';

export default {
    name: "ChatModule",
    props: ["visible"],
    created() {
        this.getMessages();
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            hideWarning: (state) => state.hideWarning,
        }),
    },
    data() {
        return {
            allMessages: [],
            allContacts: [],
            selectedMessage: [],
            chatMessage: "",
            selectedUserId: "",
            search: "",
            backupUsers: [],
            selectedUser: "",
        };
    },
    methods: {
        close() {
            this.$emit("close");
        },
        async getMessages() {
            try {
                const contacts = [];
                const mostUsedContact = [];
                const userId = [];
                const data = await userObj.findAll();
                const contactMessage = await contactObj.getAllMessages();
                for (let index = 0; index < contactMessage.length; index++) {
                    const element = contactMessage[index];
                    userId.push(element);
                }
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    if (this.user.uid !== element.id) {
                        const value = userId.filter((user) => user.userId === element.id);
                        if (value.length !== 0) {
                            // element.sentTime = `latest ${moment(value[0].sentTime.toDate()).format('DD/MM/YYYY')}`;
                            element.sentTime = `${value[0].messages[value[0].messages.length - 1].message}`;
                            element.time = value[0].sentTime;
                            mostUsedContact.push(element)
                        } else {
                            element.sentTime = `-`;
                            contacts.push(element)
                        }
                    }
                }
                mostUsedContact.sort((a,b)=>b.time - a.time);
                this.allContacts = mostUsedContact.concat(contacts);
                this.backupUsers = contacts;
            }
            catch (error) {
                console.log(error);
            }
        },
        async getMessageByUserId(userId, userData) {
            this.selectedUserId = userId;
            this.selectedUser = userData;
            const messages = await contactObj.getMessagesFromUserId(userId);
            const msg = [];
            if (messages.length >= 1) {
                for (
                    let chatIndex = 0;
                    chatIndex < messages.length;
                    chatIndex++
                ) {
                    const chatElement = messages[chatIndex].messages;
                    for (let i = 0; i < chatElement.length; i++) {
                        const element = chatElement[i];
                        msg.push(element);
                    }
                }
            }
            this.selectedMessage = msg;
        },
        async sendMessage() {
            this.selectedMessage.push({
                message: this.chatMessage,
                type: "owner",
                user: this.user,
            });
            const jsonData = {
                messages: this.selectedMessage,
                userId: this.selectedUserId,
                sentTime: new Date(),
            };
            await contactObj.adminMessage(jsonData);
            this.chatMessage = "";
            await mailServiceObj.sendMailToUserForContact(this.selectedUser);
            const notificationData = {
                title: `Team Signa X just messaged you`,
                message: `Tap here to know more...`,
                meta: {
                    request_id: '',
                    chat: true,
                },
            };
            notificationObj.sendNotification(
                notificationData,
                ADMIN_USER_ID,
                this.selectedUserId,
                NOTIFICATION_TYPE_CONTACT_CHAT
            );
        },
        searchText(text) {
            const allUser = this.backupUsers;
            if (text.length >= 3) {
                this.allContacts = allUser.filter((user) => {
                    return user.name.toLowerCase().includes(text.toLowerCase());
                });
            } else {
                this.allContacts = this.backupUsers;
            }
        },
    },
}
</script>
<style scoped>
.dialog {
    background-color: var(--primary-color) !important;
    margin: 0px;
    padding: 0px;
    overflow: hidden !important;
}

h3 {
    background: linear-gradient(101.24deg, #ff7f3f 43.86%, #ffbf3f 86.82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 30px;
    font-weight: 500;
    font-family: "Orbitron", sans-serif !important;
}

.card-div {
    width: 100vw;
    height: 100vh;
    background-image: url("../../assets/auth/reg_background.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden !important;
}

.card-header {
    width: 100%;
    background: var(--primary-color);
}

.span {
    width: 20px;
    height: 20px;
    font-size: 15px;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.card-content {
    width: 100%;
}

.all-user {
    width: 100%;
    height: 100vh;
    background: var(--primary-color);
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px;
}

.chat-screen {
    width: 100%;
    height: 100vh;
}

.message-screen {
    width: 100%;
    height: 75vh;
    padding: 20px;
    overflow-y: auto;
}

.message-typer {
    width: 100%;
    height: 25vh;
    background: var(--primary-color);
    padding: 20px;
}

.user-data {
    width: 90%;
    min-height: 50px;
    padding: 10px;
    border-bottom: 1px solid grey;
    cursor: pointer;
}

.user-data-profile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid #e8e8e8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-data-profile>img {
    width: 80%;
    height: 80%;
    object-fit: cover;
}

.name {
    font-size: 15px;
    font-weight: 400;
    color: var(--border-color);
    margin: 0px 0px 0px 0px;
    font-family: 'Archivo', sans-serif;
}

.role {
    font-size: 12px;
    font-weight: 400;
    color: #e8e8e8;
    margin: 0px 0px 0px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
}

.input {
    color: white;
    outline: none;
    border: none;
    width: 100%;
    height: 40px;
}

.divider {
    border-color: rgba(255, 255, 255, 0.418) !important;
    margin-top: 10px;
    width: 90% !important;
}

.btn {
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 26.2%,
            #ff3f7f 98.17%);
    border-radius: 10px;
    font-weight: 400 !important;
    text-transform: none;
    font-family: "Bebas Neue", cursive !important;
    width: 100px !important;
    margin-top: 10px;
    text-align: center;
    padding: 10px;
    color: white;
}

.chat-message {
    margin-left: 5%;
    width: auto;
    max-width: 50%;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #00ffbf;
    color: white;
    font-family: "Archivo", sans-serif !important;
    font-size: 14px;
    line-height: 25px;
    display: inline-block;
    background: rgba(196, 196, 196, 0.25);
}

.chat-message-user {
    margin-left: auto;
    width: auto;
    max-width: 40%;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #fbb142;
    color: white;
    font-family: "Archivo", sans-serif !important;
    font-size: 14px;
    line-height: 25px;
    margin-right: 10%;
    background: rgba(196, 196, 196, 0.25);
    /* display: inline-block; */
}
</style>