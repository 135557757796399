import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{staticClass:"dialog",attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"card-body dialog"},[_c(VRow,{staticStyle:{"justify-content":"space-between","width":"100%"}},[_c(VCol,{attrs:{"lg":"11"}},[_c('h3',{class:'model-title-designer'},[_vm._v("Things you can do on the platform")])]),_c(VCol,{attrs:{"lg":"1"}},[_c(VIcon,{staticClass:"mt-4 ml-10",attrs:{"color":"white","size":"25"},on:{"click":function($event){return _vm.close()}}},[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"content d-flex mt-10"},[_c(VExpansionPanels,{staticStyle:{"width":"100%"},attrs:{"dark":""},model:{value:(_vm.selectedQuestion),callback:function ($$v) {_vm.selectedQuestion=$$v},expression:"selectedQuestion"}},_vm._l((_vm.questions),function(content,index){return _c(VExpansionPanel,{key:index,staticClass:"expansion",staticStyle:{"margin-bottom":"20px"}},[_c(VExpansionPanelHeader,[_c('div',{staticClass:"title-header"},[(_vm.selectedQuestion === index)?_c('img',{staticStyle:{"margin-right":"10px"},attrs:{"src":`${content['selected']}`,"width":"20px"}}):_c('img',{staticStyle:{"margin-right":"10px"},attrs:{"src":`${content['not-selected']}`,"width":"20px"}}),_c('p',{class:_vm.selectedQuestion === index ? 'content-header-selected' : 'content-header'},[_vm._v(" "+_vm._s(content.question))])])]),_c(VExpansionPanelContent,[_c('p',{staticClass:"content-response"},[_vm._v(_vm._s(content.answer))])])],1)}),1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }