<template>
  <div
    :class="'header'"
    :style="required ? { display: 'block' } : { display: 'none' }"
  >
    <h1 class="general-text-title">{{ title }}</h1>
    <p>{{ subtitle }}</p>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Header",
  props: ["title", "subtitle", "required"],
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
};
</script>
<style scoped>
.header {
  padding-left: 20px;
  width: 100%;
  height: 100px;
  padding-top: 0.0%;
  text-align: left;
}
.header > h1 {
  margin: 0px;
  width: 65%;
  font-size: 45px !important;
  line-height: 50px;
}
.header > p {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 20px;
  font-weight: 400;
}

.header-designer {
  padding-left: 20px;
  width: 100%;
  height: 100px;
  padding-top: 0.5%;
  text-align: left;
}
.header-designer > h1 {
  background: linear-gradient(141.3deg, #eb5129 32.76%, #ffbf3f 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 50px !important;
}
.header-designer > p {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
}
</style>
