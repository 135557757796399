<template>
  <v-dialog v-model="visible" persistent max-width="500" class="dialog">
    <v-card :color="'#15181E'" class="model-card text-center dialog">
      <div :class="'model-title-others'">
        {{ title }}
      </div>
      <div class="sub-title">
        {{ message }}
      </div>
      <div :class="status ? 'status-success' : 'status-fail'">
        <img
          :src="
            status
              ? require('../../assets/gif/check.gif')
              : require('../../assets/gif/error.gif')
          "
          class="image"
        />
        <!-- <v-icon size="50" color="white">{{status ? 'mdi-check': 'mdi-close'}}</v-icon> -->
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "StatusMOdel",
  props: ["status", "title", "message", "visible"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) return this.$emit("close");
      },
    },
    ...mapState({
      user: (state) => state.user,
    }),
  },
};
</script>
<style scoped>
.dialog{
  border-radius: 10px;
  background-image: url("../../assets/popup.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.model-card {
  padding: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.model-title-designer {
  background: linear-gradient(141.3deg, #eb5129 32.76%, #ffbf3f 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 50px !important;
}
.model-title-others {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif;
  margin: 0px;
  font-size: 30px !important;
  font-weight: 800;
}
.sub-title {
  color: white;
  margin: 0px;
  font-size: 18px;
  font-weight: 400;
}
.status-fail {
  margin-top: 20px;
  width: 200px;
  height: 200px;
  border-radius: 50% !important;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status-success {
  margin-top: 10px;
  width: 320px;
  height: 200px;
  border-radius: 50% !important;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
