import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    navigation: [],
    path: "/home",
    link: "",
    selectedToken: "",
    hideWarning: false,
    notificationLength: 0,
    isConnected: false,
    isNewUser: true,
    isGlobalPopup: false,
    rowsPerPage: 10,
    allUsers: [],
    allAssets: [],
    selectedMarketItem: null,
    tempUser: {}
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setNavigation(state, payload) {
      state.navigation = payload;
    },
    setPath(state, payload) {
      state.path = payload;
    },
    setLink(state, payload) {
      state.link = payload;
    },
    setToken(state, payload) {
      state.selectedToken = payload;
    },
    setHideWarning(state, payload) {
      state.hideWarning = payload;
    },
    setNotificationLength(state, payload) {
      state.notificationLength = payload;
    },
    setConnection(state, payload) {
      state.isConnected = payload;
    },
    setIsNewUser(state, payload) {
      state.isNewUser = payload;
    },
    setGlobalPopup(state, payload) {
      state.isGlobalPopup = payload;
    },
    setRowsPerPage(state, payload) {
      state.rowsPerPage = payload;
    },
    setAllUsers(state, payload) {
      state.allUsers = payload;
    },
    setAllAssets(state, payload) {
      state.allAssets = payload;
    },
    setSelectedMarketItem(state, payload) {
      state.selectedMarketItem = payload;
    },
    setTemp(state, payload) {
      state.tempUser = payload;
    }
  },
  actions: {
    setUser({ commit }, payload) {
      commit("setUser", payload);
    },
    setNavigation({ commit }, payload) {
      commit("setNavigation", payload);
    },
    setPath({ commit }, payload) {
      commit("setPath", payload);
    },
    setLink({ commit }, payload) {
      commit("setLink", payload);
    },
    setToken({ commit }, payload) {
      commit("setToken", payload);
    },
    setHideWarning({ commit }, payload) {
      commit("setHideWarning", payload);
    },
    setNotificationLength({ commit }, payload) {
      commit('setNotificationLength', payload);
    },
    setConnection({ commit }, payload) {
      commit("setConnection", payload);
    },
    setIsNewUser({ commit }, payload) {
      commit("setIsNewUser", payload);
    },
    setGlobalPopup({ commit }, payload) {
      commit("setGlobalPopup", payload);
    },
    setRowsPerPage({ commit }, payload) {
      commit("setRowsPerPage", payload);
    },
    setAllUsers({ commit }, payload) {
      commit("setAllUsers", payload);
    },
    setAllAssets({ commit }, payload) {
      commit("setAllAssets", payload);
    },
    setSelectedMarketItem({ commit }, payload) {
      commit('setSelectedMarketItem', payload);
    },
    setTemp({ commit }, payload) {
      commit('setTemp', payload);
    }
  },
  modules: {
  }
})
