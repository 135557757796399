<template>
    <div>
        <v-dialog v-model="visible" persistent max-width="650" class="dialog">
            <div class="card-body dialog">
                <v-row style="justify-content: space-between; width: 100%;">
                    <v-col lg="8">
                        <h3 class="general-text-title model-title">Resource</h3>
                    </v-col>
                    <v-col lg="1">
                        <v-icon color="white" size="25" class="mt-4 ml-10" @click="close()">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <div class="card-body mt-5" v-if="isEdit">
                    <div class="form-group mt-5">
                        <p>Title</p>
                        <input type="text" v-model="resource.materialTitle" />
                    </div>
                    <div class="form-group mt-5">
                        <p>Description</p>
                        <textarea type="text" v-model="resource.materialDescription" rows="4"></textarea>
                    </div>
                    <div class="form-group mt-5">
                        <p>Image</p>
                        <input type="file" @change="onImageChanged" accept="image/*" />
                    </div>
                    <div class="form-group mt-5">
                        <p>Material</p>
                        <input type="file" @change="onLinkChanged" accept="application/pdf" />
                    </div>
                    <div class="form-group mt-5">
                        <p>Applicable For?</p>
                        <select v-model="resource.role" class="mySelect">
                            <option value="designer" style="color: black;">3D Creators</option>
                            <option value="meta" style="color: black;">Metaverse Project Owners
                            </option>
                            <option value="general" style="color: black;">General</option>
                        </select>
                    </div>
                </div>
                <div class="card-body mt-5" v-else>
                    <div class="form-group mt-5">
                        <p>Title</p>
                        <input type="text" v-model="resourceData.materialTitle" />
                    </div>
                    <div class="form-group mt-5">
                        <p>Description</p>
                        <textarea type="text" v-model="resourceData.materialDescription" rows="4"></textarea>
                    </div>
                    <div class="form-group mt-5">
                        <p>Image</p>
                        <input type="file" @change="onImageChanged" accept="image/*" />
                    </div>
                    <div class="form-group mt-5">
                        <p>Material</p>
                        <input type="file" @change="onLinkChanged" accept="application/pdf" />
                    </div>
                    <div class="form-group mt-5">
                        <p>Applicable For?</p>
                        <select v-model="resourceData.role" class="mySelect">
                            <option value="designer" style="color: black;">3D Creators</option>
                            <option value="meta" style="color: black;">Metaverse Project Owners
                            </option>
                            <option value="general" style="color: black;">General</option>
                        </select>
                    </div>
                </div>
                <v-btn class="button btn mt-5" @click="submitData()">Submit</v-btn>
            </div>
        </v-dialog>
        <Loader :visible="isLoading" :text="loaderText"></Loader>
    </div>
</template>
<script>
import { uploadFile } from '../../helpers/file-upload';
import { resourceObj } from '../../services/resource-service';
import Loader from '../Loader/Loader.vue';

export default {
    name: "ResourceAdd",
    props: ['visible', 'resource', 'isEdit'],
    data() {
        return {
            resourceData: {
                enrolledUsers: [],
                isEnrollable: false,
                materialDescription: "",
                materialImage: "",
                materialLink: "",
                materialTitle: "",
                order: -1,
                role: "",
                type: "pdf",
            },
            isLoading: false,
            loaderText: "",
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async onImageChanged(event) {
            const file = event.target.files[0];
            if (!file) {
                return;
            }
            this.fileName = file.name;
            this.isLoading = true;
            const url = await uploadFile(file, 'resources/');
            if (this.isEdit) {
                this.resource.materialImage = url;
            } else {
                this.resourceData.materialImage = url;
            }
            this.isLoading = false;
        },
        async onLinkChanged(event) {
            const file = event.target.files[0];
            if (!file) {
                return;
            }
            this.fileName = file.name;
            this.isLoading = true;
            const url = await uploadFile(file, 'resources/');
            if (this.isEdit) {
                this.resource.materialLink = url;
            } else {
                this.resourceData.materialLink = url;
            }
            this.isLoading = false;
        },
        async submitData() {
            try {
                this.loaderText = "Creating Resources.."
                this.isLoading = true;
                await resourceObj.createResource(this.resourceData);
                this.isLoading = false;
                this.close();
            } catch (error) {
                this.isLoading = false;
                console.log(error);
            }
        }
    },
    components: {
        Loader
    }
}
</script>
<style scoped>
.dialog {
    background: var(--empty-color);
    padding: 20px;
    border-radius: 10px;
    background-image: url("../../assets/popup.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.span {
    width: 20px;
    height: 20px;
    font-size: 15px;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.model-title {
    font-size: 40px !important;
    text-align: left;
}

.form-group>p {
    margin-bottom: 0px;
    margin-left: 0px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    font-weight: 400;
}

.form-group>input {
    width: 100%;
    height: 50px;
    background: rgba(196, 196, 196, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid #ff7f3f;
    outline: none;
    color: #fff;
    font-family: "Archivo", sans-serif;
    padding: 5px;
}

.form-group>textarea {
    width: 100%;
    background: rgba(196, 196, 196, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid #ff7f3f;
    outline: none;
    color: #fff;
    font-family: "Archivo", sans-serif;
    padding: 5px;
}

.mySelect {
    width: 100%;
    height: 50px;
    background: rgba(196, 196, 196, 0.1) !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid var(--border-color) !important;
    outline: none;
    color: #fff;
    font-family: "Archivo", sans-serif;
    padding: 5px;
    -webkit-appearance: menulist
}

.btn {
    border-radius: 10px;
    font-size: 18px;
    color: white;
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 56.2%,
            #ff3f7f 98.17%);
    font-weight: 600 !important;
    font-family: "Bebas Neue", cursive !important;
}
</style>