import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resource-container",style:(_vm.isLoading || _vm.showPDF || _vm.showPDF ? { filter: 'blur(5px)' } : { filter: 'none' })},[_vm._m(0),(_vm.user.role === 'team')?_c('div',{staticStyle:{"width":"75%","display":"flex","justify-content":"flex-end","align-items":"center","cursor":"pointer"},on:{"click":function($event){return _vm.showResource(-1)}}},[_c(VIcon,{attrs:{"color":"#ff7f3f","size":"40"}},[_vm._v("mdi-plus-circle")])],1):_vm._e(),_c('div',{staticClass:"resource-body mt-10"},[_c(VRow,{staticStyle:{"width":"90%"}},_vm._l((_vm.resources),function(resource,index){return _c(VCol,{key:index,attrs:{"lg":"4"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(resource.type === 'pdf'),expression:"resource.type === 'pdf'"}],staticClass:"mt-2 resource-block-outer",on:{"click":function($event){return _vm.goToUrl(resource)}}},[_c('div',{staticClass:"resource-block"},[_c('img',{attrs:{"src":resource.materialImage}}),_c('div',{staticClass:"details"},[_c('h3',[_vm._v(_vm._s(resource.materialTitle))]),(resource.enrolledUsers.length !== 0)?_c('div',{staticClass:"user-details mt-4"},[_c(VRow,_vm._l((resource.enrolledUsers),function(user,index){return _c(VCol,{key:index,staticClass:"user-avatar",attrs:{"lg":"1"}},[_c('div',{staticClass:"dot"},[_c('img',{attrs:{"src":user,"alt":"index"}})])])}),1)],1):_vm._e(),(!resource.isEnrollable)?_c(VBtn,{staticClass:"button",attrs:{"height":"25"},on:{"click":function($event){return _vm.goToUrl(resource)}}},[_vm._v(" Read Now!")]):_vm._e()],1)])])])}),1)],1),_vm._v(" --> "),_c('Loader',{attrs:{"visible":_vm.isLoading,"text":"Loading Content..."}}),_c('PDFViewerModal',{attrs:{"visible":_vm.showPDF,"data":_vm.selectedItem},on:{"close":_vm.close}}),_c('ResourceAdd',{attrs:{"visible":_vm.showEdit,"resource":_vm.selectedResource,"isEdit":_vm.selectedResource === null ? false : true},on:{"close":_vm.close}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"header-title"},[_c('div',{staticClass:"main text"},[_vm._v("Resources")])])])
}]

export { render, staticRenderFns }