<template>
  <div
    class="auth-container"
    :style="
      loading || showSuccessPopup
        ? { filter: 'blur(10px)' }
        : { filter: 'none' }
    "
  >
    <div :class="isDesktop ? 'form ml-10' : 'form'">
      <v-row justify="space-between">
        <v-col :lg="isDesktop ? 6 : 12" class="column-one">
          <v-col cols="12" class="text-center mt-10 image-column">
            <v-img src="../../assets/logo.svg" class="img-logo" width="300" />
          </v-col>
          <v-form class="form-main">
            <div class="form-group mt-10">
              <p>Email Address</p>
              <v-text-field
                class="text-field"
                solo
                color="#ff7f3f"
                height="50"
                :type="'text'"
                background-color="transparent"
                v-model="form.email"
                dark
              ></v-text-field>
            </div>
            <div class="form-group mt-10">
              <p>Password</p>
              <v-text-field
                class="text-field"
                solo
                color="#ff7f3f"
                height="50"
                :type="show1 ? 'text' : 'password'"
                background-color="transparent"
                v-model="form.password"
                dark
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1"
              ></v-text-field>
            </div>
            <v-btn text class="forgot-password" @click="open()"
              >Forgot Password?</v-btn
            >
            <v-btn class="button mt-5" @click="authenticate()">Login</v-btn>
          </v-form>
          <div class="base">
            <p>OR</p>
          </div>
          <div class="text-center base">
            <v-btn
              class="google_sign_button"
              @click="authenticateUsingGoogle()"
            >
              <img src="../../assets/google.svg" width="30" />
              Sign in with Google
            </v-btn>
          </div>
          <p class="mt-5 footer-text">
            First time user?
            <span @click="navigateToRegister()">Sign Up</span>
          </p>
        </v-col>
        <v-col lg="6" v-if="isDesktop">
          <div class="image">
            <img src="../../assets/auth/login.svg" />
          </div>
        </v-col>
      </v-row>
    </div>
    <CreateUserProfile
      :visible="createProfilePopup"
      :userId="uid"
      :user="user"
      @close="close"
    ></CreateUserProfile>
    <Loader :visible="loading" :text="loaderText"></Loader>
    <StatusModel
      :visible="showSuccessPopup"
      :status="success"
      :message="popupMessage"
      :title="popupTitle"
    ></StatusModel>
    <ForgetPassword
      :visible="showForgetPassword"
      @close="close()"
    ></ForgetPassword>
  </div>
</template>

<script>
import { authObj } from "../../services/auth_service";
import { handleAuth } from "../../helpers/auth-handler";
import CreateUserProfile from "../../components/Modals/CreateUserProfile.vue";
import Loader from "../../components/Loader/Loader.vue";
import StatusModel from "../../components/Modals/StatusModel.vue";
import ForgetPassword from "../../components/Modals/ForgotPassword.vue";
import { mailServiceObj } from "../../services/mail-service";
// import {trackSetUp} from '../../helpers/tracker.js';

export default {
  name: "Authentication",
  data() {
    return {
      loading: false,
      loaderText: "Authenticating user....",
      popupTitle: "",
      popupMessage: "",
      success: false,
      showSuccessPopup: false,
      createProfilePopup: false,
      uid: "",
      user: {
        name: "",
        email: "",
        photoUrl: "",
      },
      form: {
        email: "",
        password: "",
      },
      showForgetPassword: false,
      show1: false,
    };
  },
  methods: {
    async navigateToRegister() {
      // await mailServiceObj.registrationDetailsToUser('aakarsh@thestartupreneur.co','Aakarsh');
      this.$router.push("/register");
    },
    async authenticate() {
      // trackSetUp();
      this.loading = true;
      const user = await authObj.login(this.form.email, this.form.password);
      if (user.status) {
        this.$router.push("/home");
      } else {
        if (!user.status && user.user !== null) {
          this.loading = false;
          this.success = false;
          this.popupTitle = "Account Verification Pending";
          this.popupMessage =
            "Your application is under review. Please wait for approval.";
          this.showSuccessPopup = true;
          setTimeout(() => {
            this.showSuccessPopup = false;
          }, 5000);
        } else {
          this.loading = false;
          this.success = false;
          this.popupTitle = "Username / Password does not exist";
          this.popupMessage = "Invalid credentials";
          this.showSuccessPopup = true;
          setTimeout(() => {
            this.showSuccessPopup = false;
          }, 5000);
        }
      }
    },
    async authenticateUsingGoogle() {
      const isPresent = await authObj.loginWithGoogle();
      if (isPresent.status) {
        // await handleAuth();
        this.$router.push("/home");
      } else {
        if (!isPresent.status && isPresent.user === null) {
          this.success = false;
          this.popupTitle = "Account Verification Pending";
          this.popupMessage =
            "Your application is under review. Please wait for approval.";
          this.showSuccessPopup = true;
          await authObj.logout();
          setTimeout(() => {
            this.showSuccessPopup = false;
          }, 5000);
          return;
        }
        this.uid = isPresent.uid;
        this.user = isPresent.user;
        this.createProfilePopup = true;
      }
    },
    close() {
      this.createProfilePopup = false;
      this.showForgetPassword = false;
    },
    open() {
      this.showForgetPassword = true;
    },
  },
  components: { CreateUserProfile, Loader, StatusModel, ForgetPassword },
  computed: {
    isDesktop: function () {
      return screen.width <= 760 ? false : true;
    },
  },
};
</script>
<style scoped>
.auth-container {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow-x: hidden;
  background: black;
}
.img-logo {
  width: 20%;
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.form-group > p {
  font-size: 13px;
  color: #e8e8e8;
  margin: 0px 0px 10px 0px;
  text-transform: uppercase;
  font-weight: 200 !important;
}

.footer-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: rgba(196, 196, 196, 0.5);
  margin: 0px 0px 10px 0px;
  padding: 0px 15%;
}
.footer-text > span {
  margin-left: 5px;
  color: #ff7f3f;
  cursor: pointer;
  text-decoration: underline;
}
.button {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 56.2%,
    #ff3f7f 98.17%
  );
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  color: white;
  font-size: 25px;
  font-family: "Bebas Neue", cursive !important;
  text-transform: capitalize !important;
}

.divider {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.5);
  margin: 20px 0px 0px 0px;
}
.google_sign_button {
  font-family: "DM Sans", sans-serif;
  text-transform: none;
  font-weight: 600 !important;
  width: 100%;
  letter-spacing: 0;
}
.google_sign_button:hover {
  background: white !important;
}
input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}
.base {
  width: 100%;
  padding: 0px 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.base > p {
  font-size: 20px;
  font-weight: 500;
  color: rgba(196, 196, 196, 0.5);
  margin: 10px 0px 10px 0px;
}
.image {
  width: 100%;
  height: 100vh;
}
.image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form-main {
  padding: 0px 15%;
}
.forgot-password {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  color: rgba(196, 196, 196, 0.5);
  font-family: "DM Sans", sans-serif;
  margin-left: auto;
  float: right;
  letter-spacing: 0;
}
.forgot-password:hover {
  background: transparent !important;
}
.text-field {
  width: 100%;
  height: 55px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid #ff7f3f !important;
  outline: none;
  color: #fff !important;
  font-family: "Archivo", sans-serif;
}
</style>
