<template>
  <div>
    <v-dialog
      v-model="visible"
      persistent
      max-width="400"
      transition="dialog-bottom-transition"
    >
      <v-card :color="'#15181E'" class="model-card">
        <div class="card-header">
          <v-row>
            <v-col lg="10">
              <h3>Forget Password</h3>
            </v-col>
            <v-col lg="2" class="mt-1">
              <div class="span" @click="close()">
                <v-icon color="white" style="cursor: pointer">mdi-close</v-icon>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="card-body mt-10">
          <p>Email Address</p>
          <input v-model="email" />
        </div>
        <div class="card-action">
          <v-btn class="button btn mt-5" @click="sendEmail()">Send Mail</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <StatusModel
      :visible="showSuccessPopup"
      :status="success"
      :message="popupMessage"
      :title="popupTitle"
    ></StatusModel>
  </div>
</template>
<script>
import { authObj } from "../../services/auth_service";
import StatusModel from "./StatusModel.vue";

export default {
  name: "ForgotPassword",
  props: ["visible"],
  data() {
    return {
      email: "",
      showSuccessPopup: false,
      success: false,
      popupTitle: "",
      popupMessage: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async sendEmail() {
      try {
        await authObj.sendEmailVerification(this.email);
        this.popupTitle = "Reset link sent!";
        this.popupMessage = `password reset link sent to ${this.email}`;
        this.success = true;
        this.showSuccessPopup = true;
        setTimeout(() => {
          this.showSuccessPopup = false;
          this.close();
        }, 3000);
      } catch (error) {
        if (error.code === "auth/user-not-found") {
          this.popupTitle = "User Not Found!";
          this.popupMessage = "given email address does not exits";
          this.success = false;
          this.showSuccessPopup = true;
          setTimeout(() => {
            this.showSuccessPopup = false;
          }, 3000);
        }
      }
    },
  },
  components: {
    StatusModel,
  },
};
</script>
<style scoped>
.model-card {
  padding: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.model-title-designer {
  margin: 0px;
  font-size: 25px !important;
  color: #00ffbf;
  font-weight: 600;
}
.card-header {
  width: 100%;
}
h3 {
  background: linear-gradient(101.24deg, #ff7f3f 43.86%, #ffbf3f 86.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 20px;
  font-weight: 500;
  font-family: "Orbitron", sans-serif !important;
}
input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}
.card-body > p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}
.card-body {
  width: 100%;
}
.btn {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 56.2%,
    #ff3f7f 98.17%
  );
  font-family: "Bebas Neue", cursive !important;
  border-radius: 10px;
  font-weight: 400 !important;
  text-transform: none;
  color: white;
  font-size: 20px;
}
</style>
