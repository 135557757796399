<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      v-model="visible"
      class="dialog"
      max-width="800"
    >
      <div class="card-body dialog">
        <div class="card-header">
          <v-row>
            <v-col lg="10">
              <Header
                :required="true"
                title="Project Hub"
                :subtitle="'Excepteur do mollit amet dolor voluptate duis cillum aute reprehenderit.'"
              ></Header>
            </v-col>
            <v-col lg="2" class="mt-1">
              <div class="span" @click="close()">X</div>
            </v-col>
          </v-row>
        </div>
        <div class="card-content">
          <v-row class="mt-5" v-if="user.role === 'designer' ">
            <v-col
              v-for="(tab, index) in tabs"
              :key="index"
              md="4"
              lg="3"
              @click="changeTab(index, tab)"
            >
              <div
                :class="selectedTab == index ? 'tab-div-selected' : 'tab-div'"
              >
                {{ tab }}
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-5" v-else>
            <v-col
              v-for="(tab, index) in adminTab"
              :key="index"
              md="4"
              lg="3"
              @click="changeTab(index, tab)"
            >
              <div
                :class="selectedTab == index ? 'tab-div-selected' : 'tab-div'"
              >
                {{ tab }}
              </div>
            </v-col>
          </v-row>
          <div class="mt-5" v-if="selectedTab === 0">
            <v-row>
              <v-col
                lg="3"
                md="3"
                v-for="(image, index) in tokenDetails.uploadedImages"
                :key="index"
              >
                <img
                  :src="image"
                  class="img-thumbnail"
                  style="width: 100px; height: 100px; margin: 5px"
                />
              </v-col>
            </v-row>
          </div>
          <!-- <div v-if="selectedTab === 1"></div> -->
          <div v-if="selectedTab === 1">
            <div class="preview-body">
              <v-row>
                <v-col lg="3">
                  <div class="request-img">
                    <img
                      :src="tokenDetails.uploadedImages[0]"
                      alt=""
                      class="request_image"
                    />
                  </div>
                </v-col>
                <v-col lg="7">
                  <div class="request-title">
                    {{ tokenDetails.name}} {{tokenDetails.request_id}}
                  </div>
                  <div class="request-description">
                    {{ tokenDetails.description }}
                  </div>
                </v-col>
              </v-row>
              <div class="request-asset-nature">
                <p>Asset Type:</p>
                <v-row>
                  <v-col lg="2">
                    <div class="asset-nature">
                      <v-icon color="#00FFBF" size="20">mdi-application</v-icon>
                      <p>{{ tokenDetails.selectedAssetType }}</p>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div class="request-asset-nature">
                <p>Asset Nature:</p>
                <v-row>
                  <v-col
                    md="3"
                    lg="3"
                    v-for="nature in tokenDetails.selectedAssetNature"
                    :key="nature"
                  >
                    <div class="asset-nature">
                      <v-icon color="#00FFBF" size="20">mdi-nature</v-icon>
                      <p>{{ nature }}</p>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div class="request-asset-nature">
                <p>Properties</p>
                <v-row>
                  <v-col
                    md="3"
                    lg="2"
                    v-for="(nature, index) in tokenDetails.property"
                    :key="index"
                  >
                    <PropertyCard
                      :title="nature.title"
                      :value="nature.value"
                    ></PropertyCard>
                  </v-col>
                </v-row>
              </div>
              <div class="request-asset-nature">
                <p>Other Props</p>
                <v-row>
                  <v-col md="3" lg="2">
                    <div class="asset-nature">
                      <v-icon color="#00FFBF" size="20">mdi-clock</v-icon>
                      <p>{{ tokenDetails.assetTime }}</p>
                    </div>
                  </v-col>
                  <v-col md="3" lg="2">
                    <div class="asset-nature">
                      <v-icon color="#00FFBF" size="20"
                        >mdi-currency-usd</v-icon
                      >
                      <p>{{ tokenDetails.assetBudget }}</p>
                    </div>
                  </v-col>
                  <v-col md="3" lg="2">
                    <div class="asset-nature">
                      <v-icon color="#00FFBF" size="20"
                        >mdi-account-cash</v-icon
                      >
                      <p>{{ tokenDetails.paymentType }}</p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
          <div v-if="selectedTab === 2 && user.role === 'meta'">
            <v-row>
              <v-col lg="6" v-for="(idea, index) in ideas" :key="index">
                <v-card class="card-box mt-10 ml-5">
                  <v-row>
                    <v-col lg="3">
                      <v-avatar color="white">
                        <img :src="idea.user.profile" />
                      </v-avatar>
                    </v-col>
                    <v-col lg="7">
                      <label>Name:</label>
                      <div class="name">
                        {{ idea.user.name }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-btn class="view-btn" @click="viewIdea(index, idea, tokenDetails.request_id)"
                    >View Idea</v-btn
                  >
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-dialog>
    <ViewSubmittedModel
      @close="closeView"
      :visible="viewModel"
      :modelFile="selectedData.modelFile"
      :message="selectedData.message"
      :request_id="id"
      :user_id="selectedData.userId"
      :selected_data="selectedData"
    ></ViewSubmittedModel>
  </div>
</template>
<script>
import Header from "../Header.vue";
import { requestObj } from "../../services/request-service";
import PreviewToken from "../Tokens/PreviewToken.vue";
import PropertyCard from "../Common/PropertyCard.vue";
import ViewSubmittedModel from "./ViewSubmittedModel.vue";

export default {
  name: "ProjectHub",
  props: ["visible", "tokenDetails", "user", "requests", "ideas"],
  data() {
    return {
      selectedTab: 0,
      viewModel: false,
      selectedData: {},
      tabs: ["References", "Project Details"],
      adminTab: ["References", "Project Details", "Submitted Assets"],
      id: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    changeTab(index) {
      this.selectedTab = index;
    },
    viewIdea(index, idea, request_id) {
      this.selectedData = idea;
      this.viewModel = true;
      this.id = request_id;
    },
    closeView() {
      this.viewModel = false;
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) return this.$emit("close");
      },
    },
  },
  components: {
    Header,
    PreviewToken,
    PropertyCard,
    ViewSubmittedModel,
  },
};
</script>
<style scoped>
.dialog {
  background: var(--empty-color);
  padding: 20px;
}
.span {
  width: 20px;
  height: 20px;
  font-size: 15px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.tab-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  margin-left: 20px;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}
.tab-div-selected {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 5px solid var(--secondary-color);
  padding: 10px;
  margin-left: 20px;
  border-radius: 5px;
  color: var(--secondary-color);
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
}
.card-content {
  width: 100%;
}
.preview-body {
  width: 100%;
  padding-top: 20px;
}
.request-img {
  width: 100%;
  height: 230px;
  border-radius: 15px;
}
.request_image {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: contain;
}
.request-title {
  margin: 0px;
  font-size: 20px;
  font-weight: 600;
  color: white;
}
.request-description {
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(196, 196, 196, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.request-asset-nature {
  margin-top: 20px;
}
.request-asset-nature > p {
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  color: white;
}
.asset-nature {
  padding: 5px;

  background: rgba(217, 217, 217, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--secondary-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}
.asset-nature > p {
  font-size: 12px;
  color: white;
  margin: 0px;
  font-weight: 400;
  margin-left: 5px;
}
.card-box {
  background: rgba(217, 217, 217, 0.1) !important;
  border-radius: 15px !important;
  border: 1px solid var(--secondary-color);
  padding: 10px;
}
.name {
  font-size: 18px;
  font-weight: 600;
  color: white;
}
.view-btn {
  margin-top: 15px;
  background: linear-gradient(101.24deg, #ff7f3f 43.86%, #ffbf3f 86.82%);
  border-radius: 20px;
  font-weight: 600 !important;
  text-transform: none;
  font-family: "Archivo", sans-serif !important;
  width: 50%;
  margin-top: 10px;
  color: white;
  margin-left: 50%;
  font-size: 12px;
}
label {
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
  color: rgba(255, 255, 255, 0.7) !important;
}
</style>
