<template>
  <v-dialog v-model="visible" persistent max-width="500" transition="dialog-bottom-transition"  class="dialog" overlay-opacity="0.5" overlay-color="#e8e8e8">
    <v-card :color="'#15181E'" class="model-card text-center dialog">
      <v-row justify="space-between" style="width: 100%">
        <v-col lg="12">
          <div :class="'general-text-title model-title'">
           Are you sure you want to logout?
          </div>
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <v-col lg="4">
            <v-btn class="mt-10 button button-data" @click="onClickEvent()">Yes</v-btn>
        </v-col>
        <v-col lg="4">
            <v-btn class="mt-10 button button-data-secondary" @click="close()">No</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "LogoutWarning",
  props: ["visible"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) return this.$emit("close");
      },
    },
    ...mapState({
      hideWarning: (state) => state.hideWarning,
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    onClickEvent(){
        this.$emit('close');
        this.$router.push('/logout');
    }
  },
};
</script>
<style scoped>
.model-card {
  padding: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sub-title {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
}
.button-data {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  font-family: "Bebas Neue", cursive;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  letter-spacing: 0.8px !important;
  color: white;
  font-size: 20px;
}
.model-title{
    font-size: 20px !important;
    text-align: center;
}
.button-data-secondary{
  font-family: "Bebas Neue", cursive;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  letter-spacing: 0.8px !important;
  color: white;
  font-size: 20px;
  border: 1px solid #ff7f3f;
  background: transparent !important;
}
.dialog {
  background: var(--empty-color);
  padding: 20px;
  border-radius: 10px;
  background-image: url("../../assets/popup.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
