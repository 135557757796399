import axios from "axios";

const projectId = '2DG6YzNTlu5QfDvrwSjgYqJrqOE';
const projectSecret = '0267ce7a1e34e8b7d82bbe231045c27c';
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');




export const uploadFileToBaseIPFS = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const res = await axios({
      url: 'https://ipfs.infura.io:5001/api/v0/add',
      method: 'POST',
      data: formData,
      headers: {
        'Authorization': `${auth}`,
      },
    });
    if (res.status === 200) {
      const data = res.data;
      const hash = data.Hash;
      return `https://signa-x.infura-ipfs.io/ipfs/${hash}`
    }
  } catch (error) {
    console.log(error);
  }
};

export const uploadAssetToIPFS = async (formInput) => {
  const {
    name,
    description,
    animation_url,
    price,
    selectedAssetType,
    image,
    type,
    attributes,
    user,
    placeholder,
    assetCategories,
    availableFileFormats,
  } = formInput;
  // if (
  //   !name ||
  //   !description ||
  //   !price ||
  //   !selectedAssetType ||
  //   !image || !user || !placeholder || !assetCategories || !availableFileFormats
  // )
  //   return;
  const data = JSON.stringify({
    name,
    description,
    animation_url,
    price,
    selectedAssetType,
    image,
    type,
    attributes,
    user,
    placeholder,
    assetCategories,
    availableFileFormats,
  });
  const formData = new FormData();
  formData.append("data", data);
  const res = await axios({
    url: 'https://ipfs.infura.io:5001/api/v0/add',
    method: 'POST',
    data: formData,
    headers: {
      'Authorization': `${auth}`,
    },
  });
  if (res.status === 200) {
    const data = res.data;
    const hash = data.Hash;
    return `https://signa-x.infura-ipfs.io/ipfs/${hash}`
  }
};

export const getData = async (hash) => {

}