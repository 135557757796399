<template>
  <div class="create-new-request pt-5">
    <Header
      v-if="!preview"
      :required="true"
      :title="'Asset Request Form'"
      :subtitle="'Fill up the form to Request an Asset'"
      style="margin-left: 2%"
    ></Header>
    <!-- <div class="create-header" v-if="!preview">
      <h3>Asset Request Form:</h3>
      <p>Fill in the details below</p>
    </div> -->
    <v-stepper
      class="ml-0 mr-16 stepper"
      width="70%"
      elevation="0"
      alt-labels
      v-model="stepIndex"
      v-if="!preview"
    >
      <v-stepper-header class="stepper-header">
        <v-stepper-step step="1" color="#ff7f3f" :complete="stepIndex > 1">
          <span :class="stepIndex >= 1 ? 'text-color-selected' : 'text-color'"
            >Step 1</span
          >
        </v-stepper-step>
        <v-divider class="divider"></v-divider>
        <v-stepper-step step="2" color="#ff7f3f" :complete="stepIndex > 2">
          <span :class="stepIndex >= 2 ? 'text-color-selected' : 'text-color'"
            >Step 2</span
          >
        </v-stepper-step>
        <v-divider class="divider"></v-divider>
        <v-stepper-step step="3" color="#ff7f3f" :complete="stepIndex > 3">
          <span :class="stepIndex >= 3 ? 'text-color-selected' : 'text-color'"
            >Step 3</span
          >
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <div class="form">
            <v-row>
              <v-col :lg="tokenDetails.selectedAssetType === 'Others' ? 4 : 6">
                <div class="form-group">
                  <p>Asset Type</p>
                  <v-select
                    dark
                    v-model="tokenDetails.selectedAssetNature"
                    :items="nature"
                    :rules="[(v) => !!v || 'Asset Type is required']"
                    class="select-type"
                    color="white"
                    background-color="transparent"
                    solo
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0" class="chip">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ tokenDetails.selectedAssetNature.length - 1 }}
                        others)
                      </span>
                    </template>
                  </v-select>
                </div>
              </v-col>
              <v-col :lg="tokenDetails.selectedAssetType === 'Others' ? 4 : 6">
                <div class="form-group">
                  <p>Asset Environment</p>
                  <v-select
                    dark
                    v-model="tokenDetails.selectedAssetType"
                    :items="assetTypes"
                    :rules="[(v) => !!v || 'Asset Type is required']"
                    class="select-type"
                    color="white"
                    background-color="transparent"
                    solo
                  ></v-select>
                </div>
              </v-col>
              <v-col :lg="4" v-if="tokenDetails.selectedAssetType === 'Others'">
                <div class="form-group">
                  <p>Please Specify</p>
                  <input type="text" v-model="tokenDetails.others" />
                </div>
              </v-col>
            </v-row>
            <div class="form-group">
              <p>Asset Title</p>
              <input type="text" v-model="tokenDetails.name" />
            </div>
            <div class="form-group">
              <p>Description</p>
              <textarea
                type="text"
                v-model="tokenDetails.description"
              ></textarea>
            </div>
            <div class="button-footer">
              <v-btn
                class="btn"
                @click="changeIndex(2)"
                :disabled="
                  tokenDetails.selectedAssetNature.length === 0 ||
                  (tokenDetails.selectedAssetType === 'others' &&
                    tokenDetails.others.length === 0) ||
                  tokenDetails.selectedAssetType.length === 0 ||
                  tokenDetails.description.length === 0 ||
                  tokenDetails.name.length === 0
                "
                >Next</v-btn
              >
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="form">
            <div class="form-group">
              <p>Reference Images (atleast 1 image)</p>
              <v-row>
                <v-col lg="4">
                  <div class="file-input">
                    <v-img
                      src="../../assets/designer-file.svg"
                      width="50"
                    ></v-img>
                    <p>upload references</p>
                    <input
                      type="file"
                      multiple
                      truncate-length="15"
                      id="reference"
                      ref="reference"
                      class="file"
                      accept="image/*"
                      @change="onFileChange"
                    />
                  </div>
                </v-col>
                <v-col lg="8">
                  <div class="scroll-content" id="scroll-cont">
                    <div
                      class="image-card"
                      v-for="(image, index) in tokenDetails.uploadedImages"
                      :key="index"
                    >
                      <img :src="image" class="image-card-image" />
                      <div
                        class="delete-icon-image"
                        @click="deleteImage(index)"
                      >
                        <v-icon color="red" size="15">mdi-delete</v-icon>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="form-group">
              <p>Reference Links (if any)</p>
              <input type="text" v-model="tokenDetails.reference_url" />
            </div>
            <!-- <div class="form-group">
              <p>Properties</p>
              <v-row>
                <v-col lg="4">
                  <div class="property-input" @click="propertiesDialog = true">
                    <v-icon class="mt-2" size="35" color="#ff7f3f">mdi-plus-circle</v-icon>
                  </div>
                </v-col>
                <v-col lg="8">
                  <div class="property-input">
                    <v-row>
                      <v-col lg="4" v-for="(prop, index) in tokenDetails.property" :key="index">
                        <div class="asset-nature" v-if="index < 3">
                          <p class="prop-title">{{ prop.title }}</p>
                          <p class="prop-value">{{ prop.value }}</p>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </div> -->
            <!-- <div class="form-group">
              <p>Submission Template</p>
              <v-row>
                <v-col lg="4">
                  <div class="file-input">
                    <v-img src="../../assets/designer-file.svg" width="50"></v-img>
                    <p>upload submission template</p>
                    <input type="file" truncate-length="15" class="file" accept="image/*"
                      @change="onTemplateFileChange" />
                  </div>
                </v-col>
                <v-col lg="8">
                  <div class="scroll-content" id="scroll-cont">
                    <div class="image-card">
                      <img :src="tokenDetails.templateFile" class="image-card-image" />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div> -->
            <div class="button-footer-mult">
              <v-btn class="btn-prev" @click="changeIndex(1)">Previous</v-btn>
              <v-btn
                class="btn"
                @click="changeIndex(3)"
                :disabled="tokenDetails.uploadedImages.length === 0"
                >Next
              </v-btn>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div class="form">
            <div class="form-group">
              <p>File Formats</p>
              <v-select
                dark
                v-model="tokenDetails.selectedFileType"
                :items="fileNeeded"
                :rules="[(v) => !!v || 'Asset Type is required']"
                class="select-type"
                color="white"
                background-color="transparent"
                solo
                multiple
                chips
              >
              </v-select>
            </div>
            <div class="form-group">
              <p>Time Available for the project</p>
              <date-picker
                v-model="tokenDetails.assetTime"
                valueType="format"
                class="mx-input"
                input-class="mx-input-data"
                format="DD/MM/YYYY"
                popup-class="pop-class"
              >
                <v-icon slot="icon-calendar" color="white">mdi-calendar</v-icon>
                <v-icon slot="icon-clear" color="white">mdi-close</v-icon>
              </date-picker>
            </div>
            <div class="form-group">
              <p>Budget Proposed</p>
              <v-text-field
                class="text-field"
                prepend-icon="mdi-currency-usd"
                solo
                color="#ff7f3f"
                height="50"
                background-color="transparent"
                v-model="tokenDetails.assetBudget"
                dark
              ></v-text-field>
            </div>
            <div class="form-group">
              <p>Payment Type</p>
              <v-radio-group v-model="tokenDetails.paymentType" row dark>
                <v-radio label="Fiat" value="Fiat" color="#ff7f3f"></v-radio>
                <v-radio
                  label="Crypto"
                  value="Crypto"
                  color="#ff7f3f"
                ></v-radio>
              </v-radio-group>
            </div>
            <div class="button-footer-mult">
              <v-btn class="btn-prev" @click="changeIndex(2)">Previous</v-btn>
              <v-btn
                class="btn"
                @click="setPreview()"
                :disabled="tokenDetails.selectedFileType.length === 0"
                >Preview
              </v-btn>
            </div>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <PreviewToken
      title="Asset Summary"
      :tokenDetails="tokenDetails"
      :buttons="[
        {
          text: 'Previous',
          class: 'btn-prev',
          action: () => {
            changeIndex(3);
          },
        },
        {
          text: 'Submit',
          class: 'btn',
          action: async () => {
            createCustomRequest();
          },
        },
      ]"
      v-else
    />
    <AddProperties
      :visible="propertiesDialog"
      @close="close"
      :property_list="tokenDetails.property"
    ></AddProperties>
    <Loader :text="loaderText" :visible="isLoading"></Loader>
    <StatusModel
      :status="isSuccess"
      :title="
        isSuccess ? 'Request Created Successfully' : 'Request Creation Failed!'
      "
      :message="
        isSuccess
          ? 'Your request has been successfully created. We will get back to you shortly'
          : 'Your request creation failed. Please try again later'
      "
      :visible="showSuccessPopup"
      @close="close"
    ></StatusModel>
  </div>
</template>
<script>
import PreviewToken from "../Tokens/PreviewToken.vue";
import AddProperties from "../Modals/AddProperties.vue";
import { uploadFile } from "../../helpers/file-upload";
import { mapState } from "vuex";
import { requestObj } from "../../services/request-service";
import Header from "../Header.vue";
import Loader from "../Loader/Loader.vue";
import StatusModel from "../Modals/StatusModel.vue";
import DatePicker from "vue2-datepicker";
import "../Common/Style/datePicker.css";
import { userObj } from '../../services/user-service';
import { mailServiceObj } from '../../services/mail-service';

export default {
  name: "CreateNewRequest",
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      isLoading: false,
      loaderText: "Creating Asset Request...",
      isSuccess: false,
      showSuccessPopup: false,
      preview: false,
      propertiesDialog: false,
      stepIndex: 1,
      fileNeeded: [
        "OBJ",
        "GLTF",
        "GLB",
        "FBX",
        "PNG",
        "SVG",
        "JPEG",
        "MP3",
        "MP4",
        "AVI",
      ],
      nature: [
        "Metaverse Development",
        "Game Development",
        "AR/VR (Immersive experiences)",
        "Characters (Avatars, etc)",
        "Product Mockups and Prototypes",
        "Digital Twins",
        "3D Logo NFT's",
        "Virtual Showrooms",
        "Virtual Events",
        "3D Environments",
        "In-Game Power-ups",
        "Utilities (Cars, Buildings, Game weapons etc.)",
        "All of the above",
      ],
      assetTypes: [
        "Metaverse",
        "Gaming",
        "Metaverse and Gaming",
        "AR/VR",
        "Others",
      ],
      tokenDetails: {
        selectedAssetType: "",
        selectedAssetNature: [],
        selectedFileType: [],
        name: "",
        description: "",
        uploadedImages: [],
        property: [],
        assetTime: "",
        assetBudget: 0,
        paymentType: null,
        animation_url: "",
        background_color: "",
        request_id: +Date.now(),
        reference_url: "",
        templateFile: "",
        others: "",
      },
    };
  },
  methods: {
    changeIndex(index) {
      this.preview = false;
      this.stepIndex = index;
    },

    onReferenceClick() {
      this.$refs.reference.click();
    },

    onTemplateClick() {
      this.$refs.temp.click();
    },

    setPreview() {
      this.preview = true;
    },

    close() {
      this.propertiesDialog = false;
      this.showSuccessPopup = false;
    },

    async onTemplateFileChange(event) {
      const files = event.target.files;
      this.isLoading = true;
      this.loaderText = "Uploading Files...";
      const element = files[0];
      const url = await uploadFile(element, "requirements/templates/");
      this.tokenDetails.templateFile = url;
      this.isLoading = false;
    },

    async onFileChange(event) {
      const files = event.target.files;
      const urls = [];
      this.isLoading = true;
      this.loaderText = "Uploading Files...";
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        const url = await uploadFile(element, "requirements/");
        this.tokenDetails.uploadedImages.push(url);
      }
      this.isLoading = false;
    },

    async createCustomRequest() {
      this.isLoading = true;
      this.tokenDetails.userId = this.user.uid;
      this.tokenDetails.user = this.user;
      if (this.tokenDetails.selectedAssetType === "Others") {
        this.tokenDetails.selectedAssetType = this.tokenDetails.others;
      }
      const response = await requestObj.create(this.tokenDetails);
      this.isLoading = false;
      this.isSuccess = true;
      this.showSuccessPopup = true;
      setTimeout(() => {
        this.showSuccessPopup = false;
        this.$router.push("/request");
      }, 5000);
    },
    deleteImage(index) {
      this.tokenDetails.uploadedImages.splice(index, 1);
    },
  },
  components: {
    PreviewToken,
    AddProperties,
    Header,
    Loader,
    StatusModel,
    DatePicker,
  },
};
</script>
<style scoped>
.create-new-request {
  width: 100%;
  min-height: 100%;
  background-image: url("../../assets/hue.svg");
  /* background-image: url("../../assets/request/create_request.svg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* padding: 30px; */
}

.create-header {
  padding-top: 2%;
  padding-left: 50px;
}

.create-header > h3 {
  background: linear-gradient(141.3deg, #00bfff 32.76%, #ff7f3f 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 50px !important;
  font-weight: 600;
}

.create-header > p {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
}

.theme--light.v-stepper {
  background: transparent !important;
}

.divider {
  border-color: grey !important;
}

.v-stepper__header {
  box-shadow: none !important;
}

.text-color {
  color: transparent !important;
}

.text-color-selected {
  color: #ff7f3f;
}

.form-group {
  padding-left: 20px;
  margin-bottom: 20px;
}

.form-group > p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}

.form-group > input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.form-group > textarea {
  width: 100%;
  height: 100px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.select-type {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff !important;
  font-family: "DM Sans", sans-serif;
  padding: 5px;
  font-size: 12px !important;
}

.button-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.button-footer-mult {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}

.btn {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  width: 20%;
  margin-top: 10px;
  font-size: 22px !important;
  color: #fff !important;
}

.btn-prev {
  background: transparent !important;
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  width: 20%;
  margin-top: 10px;
  border: 1px solid #ff7f3f;
  color: white !important;
  font-size: 22px !important;
}

.file-input {
  width: 100%;
  height: 100px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
  flex-direction: column;
  display: grid;
  place-items: center;
  cursor: pointer;
  position: relative;
}

.file-input-preview {
  width: 100%;
  height: 100px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
  flex-direction: row;
  place-items: center;
  cursor: pointer;
}

.file-input > p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
}

.property-input {
  width: 100%;
  height: 62px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.text-field {
  width: 100%;
  height: 55px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid #ff7f3f !important;
  outline: none;
  color: #fff !important;
  font-family: "Archivo", sans-serif;
}

.v-select__selection--comma {
  color: white !important;
}

.file {
  position: absolute;
  opacity: 0;
  left: 0;
  cursor: pointer !important;
}

.chip {
  font-size: 10px !important;
}

.image-card {
  height: 85px;
  background: rgba(196, 196, 196, 0.1);
  border: 1px solid #ff7f3f;
  border-radius: 10px;
  padding: 5px;
  margin-right: 10px;
  width: 150px;
  margin-left: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.delete-icon-image {
  position: absolute;
  top: -10px;
  right: -10px;
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.image-card-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.asset-nature {
  padding: 5px;
  background: rgba(217, 217, 217, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 50px;
}

.prop-title {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin: 0px;
}

.prop-value {
  font-size: 12px;
  font-weight: 600;
  color: var(--border-color);
  margin: 0px;
}

.stepper-header {
  margin-left: -4% !important;
  padding-right: 35%;
}

.v-stepper__content {
  padding: 15px !important;
}

.scroll-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 auto;
  border: 1px solid #ff7f3f;
}

.mx-input {
  background-color: rgba(196, 196, 196, 0.1) !important;
  padding: 0px;
  border: 1px solid #ff7f3f;
  width: 100%;
  height: 50px !important;
}

.mx-input >>> input {
  width: 100%;
  height: 50px !important;
  outline: none !important;
  border: none !important;
  color: #fff !important;
  font-family: "Archivo", sans-serif;
}

.mx-icon-calendar >>> svg {
  color: var(--border-color) !important;
}

.pop-class {
  bottom: 0px !important;
  top: 0px !important;
}

.mx-datepicker-popup {
  background: var(--primary-color) !important;
}

.today {
  color: var(--primary-color) !important;
}
</style>
