import {
    addDoc,
    getDocs,
    doc,
    getDoc,
    where,
    query,
    setDoc,
    deleteDoc,
    faqRef
} from "../helpers/firebase-handler";


class FAQServices {

    async createFAQ(data) {
        try {
            const addedData = await addDoc(faqRef, data);
            return addedData;
        } catch (error) {
            throw error;
        }
    }

    async getAllFAQ() {
        try {
            const queryData = await getDocs(faqRef);
            const allFAQ = [];
            for (let index = 0; index < queryData.docs.length; index++) {
                const element = queryData.docs[index];
                const elementData = element.data();
                elementData.id = element.id;
                allFAQ.push(elementData);
            }
            return allFAQ;
        } catch (error) {
            throw error;
        }
    }

    async getAllFAQDesigner(role) {
        try {
            const q = query(faqRef, where("for", "!=", 'metaverse-project-owners'));
            const queryData = await getDocs(q);
            const allFAQ = [];
            for (let index = 0; index < queryData.docs.length; index++) {
                const element = queryData.docs[index];
                const elementData = element.data();
                elementData.id = element.id;
                allFAQ.push(elementData);
            }
            return allFAQ;
        } catch (error) {
            throw error;
        }
    }
    
    async getAllFAQMeta(role) {
        try {
            const q = query(faqRef, where("for", "!=", '3d-creators'));
            const queryData = await getDocs(q);
            const allFAQ = [];
            for (let index = 0; index < queryData.docs.length; index++) {
                const element = queryData.docs[index];
                const elementData = element.data();
                elementData.id = element.id;
                allFAQ.push(elementData);
            }
            return allFAQ;
        } catch (error) {
            throw error;
        }
    }

    async update(data, id) {
        try {
            const q = doc(faqRef, id);
            await setDoc(q, data, { merge: true });
        } catch (error) {
            throw error;
        }
    }

    async delete(id) {
        const docRef = doc(faqRef, id)
        const data = deleteDoc(docRef);
    }
}
export const faqObj = new FAQServices();