<template>
  <div class="chat-module-container">
    <div class="chat-header">
      <v-row>
        <v-col lg="8">
          <h1 class="mt-7" style="margin-left: 3%">{{ tokenDetails.name }}</h1>
        </v-col>
        <!-- <v-col lg="4">
          <v-btn class="submit-btn" v-if="user.role === 'designer'" @click="showSubmit()">Submit Asset</v-btn>
        </v-col> -->
      </v-row>
    </div>
    <div class="chat-body">
      <div class="chat-container">
        <div v-for="(message, index) in messages" :key="index">
          <div class="chat-message" v-if="message.user.uid !== user.uid">
            <div style="width: 100%; height: 50px">
              <v-row>
                <v-col lg="1">
                  <v-avatar class="p-10" size="30px" color="white">
                    <img
                      :src="message.user.profile"
                      style="object-fit: cover; width: 100%; height: 100%"
                    />
                  </v-avatar>
                </v-col>
                <v-col lg="8">
                  <div class="name ml-2">
                    {{ message.user.name }}
                  </div>
                </v-col>
                <v-col lg="2">
                  <div
                    style="
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                    "
                    class="mt-0"
                    v-if="message.file !== undefined"
                  >
                    <v-menu left bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="white"
                          >mdi-chevron-down</v-icon
                        >
                      </template>
                      <v-list style="margin-top: 25%; border-radius: 10px" >
                        <v-list-item
                          @click="goTo(message.file)"
                          v-if="message.file !== undefined"
                        >
                          <v-list-item-title>Download</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-divider></v-divider>
            <div v-if="message.file === undefined">
              {{ message.message }}
            </div>
            <div v-else>
              <div
                v-if="image.includes(message.extensionType)"
                class="image-container-user"
              >
                <img
                  :src="message.file"
                  style="
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    cursor: pointer;
                  "
                  @click="goTo(message.file)"
                />
              </div>
              <div
                class="file-container"
                v-else
                style="cursor: pointer"
                @click="goTo(message.file)"
              >
                <v-row
                  style="
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <v-col lg="10">
                    <p
                      style="
                        margin: 0px;
                        color: white;
                        font-size: 18px;
                        max-lines: 1;
                      "
                    >
                      {{ message.fileName }}
                    </p>
                  </v-col>
                  <v-col lg="2">
                    <v-icon size="30" color="white"
                      >mdi-download-circle-outline</v-icon
                    >
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
          <div class="chat-message-user" v-else>
            <div
              style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
                height: 10px;
              "
            >
              <v-menu left bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="white"
                    >mdi-chevron-down</v-icon
                  >
                </template>
                <v-list style="margin-top: 25%; border-radius: 10px">
                  <v-list-item @click="deleteChat(index)">
                    <v-list-item-title>Delete Chat</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="goTo(message.file)"
                    v-if="message.file !== undefined"
                  >
                    <v-list-item-title>Download</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div v-if="message.file === undefined">
              {{ message.message }}
            </div>
            <div v-else>
              <div
                v-if="image.includes(message.extensionType)"
                class="image-container"
              >
                <img
                  :src="message.file"
                  style="
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    cursor: pointer;
                  "
                  @click="goTo(message.file)"
                />
              </div>
              <div
                class="file-container"
                v-else
                style="cursor: pointer"
                @click="goTo(message.file)"
              >
                <v-row
                  style="
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <v-col lg="10">
                    <p style="margin: 0px; color: white; font-size: 20px">
                      {{ message.fileName }}
                    </p>
                  </v-col>
                  <v-col lg="2">
                    <v-icon size="30" color="white"
                      >mdi-download-circle-outline</v-icon
                    >
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-footer">
      <div>
        <v-row align="center">
          <v-col lg="8">
            <input
              placeholder="Type here......"
              class="input"
              v-model="chatMessage"
            />
          </v-col>
          <v-col lg="3">
            <v-btn class="btn" @click="sendMessage()" style="margin-left: 20%"
              >Send</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <v-divider class="divider"></v-divider>
      <div class="tools" style="width: 20%; margin-top: 10px">
        <v-row>
          <v-col lg="2">
            <div class="attachment">
              <v-icon color="white" size="20" style="cursor: pointer"
                >mdi-paperclip</v-icon
              >
              <input
                type="file"
                class="file-type"
                accept=".png,.jpeg,.jpg,.svg,.pdf,.docx"
                @change="uploadFileChange"
              />
            </div>
          </v-col>
          <v-col lg="2">
            <div class="attachment">
              <v-icon color="white" size="20" style="cursor: pointer"
                >mdi-image</v-icon
              >
              <input
                type="file"
                class="file-type"
                accept="image/*"
                @change="uploadFileChange"
              />
            </div>
          </v-col>
          <v-col lg="2">
            <div class="attachment">
              <v-icon color="white" size="20" style="cursor: pointer"
                >mdi-file-document-outline</v-icon
              >
              <input
                type="file"
                class="file-type"
                accept="application/pdf"
                @change="uploadFileChange"
              />
            </div>
          </v-col>
          <!-- <v-col lg="2">
            <v-btn
              class="submit-btn"
              v-if="user.role === 'designer'"
              @click="showSubmit()"
              >Submit Asset</v-btn
            >
          </v-col> -->
        </v-row>
      </div>
    </div>
    <SubmitRequest
      @close="close"
      :requestId="request_id"
      :visible="showSubmitDialog"
    ></SubmitRequest>
    <Loader :text="loaderText" :visible="isLoading"></Loader>
  </div>
</template>
<script>
import { requestObj } from "../../services/request-service";
import { mapState } from "vuex";
import SubmitRequest from "../Modals/SubmitRequest.vue";
import { uploadFile } from "../../helpers/file-upload";
import Loader from "../Loader/Loader.vue";

export default {
  name: "ChatModule",
  data() {
    return {
      showSubmitDialog: false,
      request_id: "",
      tokenDetails: {},
      messages: [],
      chatMessage: "",
      loaderText: "",
      isLoading: false,
      image: ["png", "jpg", "svg", "jpeg"],
      file: ["pdf", "docx"],
    };
  },
  created() {
    this.getRequestById();
    setInterval(() => {
      this.getMessages();
    }, 2000);
    this.getMessages();
  },
  methods: {
    async getRequestById() {
      const path = this.$router.currentRoute.path;
      this.$store.dispatch("setPath", path);
      this.request_id = this.$route.params.request_id;
      const data = await requestObj.findById(this.request_id);
      this.tokenDetails = data;
    },
    async sendMessage() {
      if (this.user.role === "team") {
        this.user.name = "Team Signa X";
      }
      this.messages.push({
        message: this.chatMessage,
        type: "owner",
        user: this.user,
      });
      const jsonData = {
        messages: this.messages,
        request_id: this.request_id,
        userId: this.user.uid,
      };
      await requestObj.sendMessage(jsonData, this.request_id);
      this.chatMessage = "";
    },
    async showSubmit() {
      this.showSubmitDialog = true;
    },
    close() {
      this.showSubmitDialog = false;
    },
    async getMessages() {
      const chats = [];
      if (this.user.role === "designer") {
        const data = await requestObj.getMessagesByRequestId(this.request_id);
        if (data.length >= 1) {
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            for (
              let chatIndex = 0;
              chatIndex < element.messages.length;
              chatIndex++
            ) {
              const chatElement = element.messages[chatIndex];
              chats.push(chatElement);
            }
          }
        }
        this.messages = chats;
      } else if (this.user.role === "meta" || this.user.role === "team") {
        const data = await requestObj.getMessagesByRequestId(this.request_id);
        if (data.length >= 1) {
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            for (
              let chatIndex = 0;
              chatIndex < element.messages.length;
              chatIndex++
            ) {
              const chatElement = element.messages[chatIndex];
              chats.push(chatElement);
            }
          }
        }
        this.messages = chats;
      }
    },
    async uploadFileChange(event) {
      const files = event.target.files;
      if (!files) {
        return;
      }
      this.loaderText = "Uploading Files...";
      this.isLoading = true;
      const element = files[0];
      const url = await uploadFile(element, `chat/${this.tokenDetails.name}/`);
      const fileName = element.name;
      const extensionType = fileName.split(".")[1];
      if (this.user.role === "team") {
        this.user.name = "Team Signa X";
      }
      this.messages.push({
        message: this.chatMessage,
        type: "owner",
        user: this.user,
        file: url,
        extensionType: extensionType,
        fileName: fileName.split(".")[0],
      });
      const jsonData = {
        messages: this.messages,
        request_id: this.request_id,
        userId: this.user.uid,
      };
      await requestObj.sendMessage(jsonData, this.request_id);
      this.isLoading = false;
    },
    goTo(url) {
      window.open(url, "_blank");
    },
    async deleteChat(index) {
      this.messages.splice(index, 1);
      const jsonData = {
        messages: this.messages,
        request_id: this.request_id,
        userId: this.user.uid,
      };
      await requestObj.sendMessage(jsonData, this.request_id);
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  components: { SubmitRequest, Loader },
};
</script>
<style scoped>
.chat-module-container {
  width: 100vw;
  height: 100vh;
  background: var(--empty-color);
  /* background-image: url("../../assets/comm/background.svg"); */
  background-size: cover;
  background-repeat: no-repeat;
}

.chat-header {
  height: 13vh;
  background: var(--nav-bar-color);
  display: flex;
  justify-content: flex-start;
}

h1 {
  font-size: 20px;
  margin-top: 15px;
  margin-left: 15px;
  color: white;
}

.chat-body {
  height: 72vh;
  width: 100%;
  padding: 10px;
  overflow-y: scroll;
}

.chat-footer {
  height: 16vh;
  background: var(--empty-color);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px;
}

.input {
  color: white;
  outline: none;
  border: none;
  width: 100%;
  height: 40px;
}

.divider {
  border-color: rgba(255, 255, 255, 0.1) !important;
  margin-top: 5px;
  width: 90% !important;
}

.btn {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  border-radius: 10px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  width: 100px !important;
  margin-bottom: 5px;
  text-align: center;
  padding: 10px;
  color: white;
  font-size: 20px !important;
}

.chat-message {
  margin-left: 1%;
  width: auto;
  max-width: 50%;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #00ffbf;
  color: white;
  font-family: "Archivo", sans-serif !important;
  font-size: 14px;
  line-height: 25px;
  display: inline-block;
  background: rgba(196, 196, 196, 0.25);
}

.chat-message-user {
  margin-left: auto;
  width: auto;
  max-width: 30%;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #fbb142;
  color: white;
  font-family: "Archivo", sans-serif !important;
  font-size: 14px;
  line-height: 25px;
  margin-right: 20%;
  background: rgba(196, 196, 196, 0.25);
  /* display: inline-block; */
}

.submit-btn {
  background: linear-gradient(101.24deg, #ff7f3f 43.86%, #ffbf3f 86.82%);
  border-radius: 5px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  color: white;
  letter-spacing: 0.8px !important;
  height: 25px !important;
}

.profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: red;
}

.profile > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.name {
  font-size: 14px;
  font-weight: 600;
  color: var(--border-color);
}
.attachment {
  position: relative;
}
.file-type {
  position: absolute;
  width: 20px;
  left: 0;
  opacity: 0;
}
.image-container {
  width: 200px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-left: 30%;
}
.image-container-user {
  width: 200px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
