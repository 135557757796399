<template>
  <div class="mint-token mb-20">
    <Header class="mt-7" :required="true" title="Mint Assets" subtitle="Fill in the details below" v-if="!preview"></Header>
    <v-stepper class="ml-0 mr-0 stepper mt-5" width="70%" elevation="0" alt-labels v-model="stepIndex" v-if="!preview">
      <v-stepper-header class="stepper-header">
        <v-stepper-step step="1" color="#FF7F3F" :complete="stepIndex > 1">
          <span :class="stepIndex >= 1 ? 'text-color-selected' : 'text-color'">Step 1</span>
        </v-stepper-step>
        <v-divider class="divider"></v-divider>
        <v-stepper-step step="2" color="#FF7F3F" :complete="stepIndex > 2">
          <span :class="stepIndex >= 2 ? 'text-color-selected' : 'text-color'">Step 2</span>
        </v-stepper-step>
        <v-divider class="divider"></v-divider>
        <v-stepper-step step="3" color="#FF7F3F" :complete="stepIndex > 3">
          <span :class="stepIndex >= 3 ? 'text-color-selected' : 'text-color'">Step 3</span>
        </v-stepper-step>
        <v-divider class="divider"></v-divider>
        <v-stepper-step step="4" color="#FF7F3F" :complete="stepIndex > 4">
          <span :class="stepIndex >= 4 ? 'text-color-selected' : 'text-color'">Step 4</span>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <div class="form">
            <v-row>
              <v-col lg="6">
                <v-row>
                  <v-col lg="12">
                    <div :class="
                      user.role === 'designer'
                        ? 'form-group-designer'
                        : 'form-group'
                    ">
                      <p>Asset Name</p>
                      <input type="text" v-model="tokenDetails.name" />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="12">
                    <div :class="
                      user.role === 'designer'
                        ? 'form-group-designer'
                        : 'form-group'
                    ">
                      <p>Asset Description</p>
                      <textarea type="text" v-model="tokenDetails.description"></textarea>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col lg="6">
                <v-row>
                  <v-col lg="12">
                    <div :class="
                      user.role === 'designer'
                        ? 'form-group-designer'
                        : 'form-group'
                    ">
                      <p>Asset Image</p>
                      <div class="file-input">
                        <v-row>
                          <v-col lg="8">
                            <div class="place-holder">
                              <v-row>
                                <v-col lg="5">
                                  <div class="place-holder-upload">
                                    <img src="../../assets/designer-file.svg" width="50" />
                                    <h3>Upload Asset</h3>
                                    <p>(png, jpeg, gltf)</p>
                                    <input accept="image/*" ref="placeholder" type="file" style="
                                        position: absolute;
                                        right: 14%;
                                        opacity: 0;
                                        cursor: pointer !important;
                                      " @change="onPlaceHolderFileChange" />
                                  </div>
                                </v-col>
                                <v-col lg="7">
                                  <div class="choose-model" v-if="tokenDetails.placeholder === ''">
                                    <h3>Upload Placeholder</h3>
                                    <p>(png, jpeg, svg)</p>
                                  </div>
                                  <div class="choose-model" v-else>
                                    <img :src="tokenDetails.placeholder" style="
                                        width: 100%;
                                        height: 100%;
                                        object-fit: contain;
                                      " />
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                          <v-col lg="4">
                            <div class="choose-model" v-if="tokenDetails.image === ''">
                              <h3>Add 3D Model</h3>
                              <p>(glb,  gltf)</p>
                              <v-btn class="button choose-upload" @click="onModelClick()">Upload</v-btn>
                              <input accept=".gltf, .glb" ref="model" type="file"
                                style="position: absolute; right: 0; opacity: 0" @change="onModelFileChange" />
                            </div>
                            <div class="choose-model" v-else @click="onModelClick()">
                              <model-viewer :src="tokenDetails.image" ar-modes="webxr scene-viewer quick-look"
                                seamless-poster shadow-intensity="1" camera-controls ar auto-rotate loading="eager"
                                style="height: 100%; width: 100%"></model-viewer>
                              <input accept=".gltf, .glb" ref="model" type="file"
                                style="position: absolute; right: 0; opacity: 0" @change="onModelFileChange" />
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div class="button-footer">
              <v-btn :class="
                user.role === 'designer'
                  ? 'button btn-designer'
                  : 'button btn'
              " @click="changeIndex(2)" :disabled="
  tokenDetails.placeholder.length === 0 ||
  tokenDetails.image.length === 0 ||
  tokenDetails.name.length === 0 ||
  tokenDetails.description.length === 0
">Next</v-btn>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="form">
            <v-row>
              <v-col lg="6">
                <div :class="
                  user.role === 'designer'
                    ? 'form-group-designer'
                    : 'form-group'
                ">
                  <p>Asset Price in ETH</p>
                  <v-text-field :class="
                    user.role === 'designer'
                      ? 'text-field-designer'
                      : 'text-field'
                  " dark solo color="#ff7f3f" height="50" background-color="transparent" v-model="tokenDetails.price"
                    type="number">
                    <v-icon slot="prepend" color="#ff7f3f">mdi-ethereum</v-icon>
                  </v-text-field>
                </div>
                <div :class="
                  user.role === 'designer'
                    ? 'form-group-designer'
                    : 'form-group'
                ">
                  <p>Asset Type</p>
                  <v-select dark v-model="tokenDetails.selectedAssetType" :items="assetTags" :class="
                    user.role === 'designer'
                      ? 'select-type-designer'
                      : 'select-type'
                  " color="white" background-color="transparent" solo></v-select>
                </div>
              </v-col>
              <v-col lg="6">
                <div class="file-input">
                  <model-viewer :src="tokenDetails.image" ar-modes="webxr scene-viewer quick-look" seamless-poster
                    shadow-intensity="1" camera-controls ar auto-rotate loading="eager"
                    style="height: 100%; width: 100%"></model-viewer>
                </div>
              </v-col>
            </v-row>
            <div class="button-footer-mult">
              <v-btn :class="
                user.role === 'designer'
                  ? 'button btn-prev-designer'
                  : 'button btn-prev'
              " @click="changeIndex(1)">Previous</v-btn>
              <v-btn :class="
                user.role === 'designer'
                  ? 'button btn-designer'
                  : 'button btn'
              " @click="changeIndex(3)" :disabled="
  tokenDetails.selectedAssetType.length === 0 ||
  tokenDetails.price.length === 0
">Next</v-btn>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div class="form">
            <v-row>
              <v-col lg="6">
                <div :class="
                  user.role === 'designer'
                    ? 'form-group-designer'
                    : 'form-group'
                ">
                  <p>Asset Properties</p>
                  <div :class="
                    user.role === 'designer'
                      ? 'property-input-designer'
                      : 'property-input'
                  " @click="propertiesDialog = true">
                    <v-icon color="#ff7f3f" size="35" style="margin-top: 3px">mdi-plus-circle</v-icon>
                  </div>
                </div>
                <v-row style="padding-left: 20px">
                  <v-col lg="3" v-for="(prop, index) in tokenDetails.attributes" :key="index">
                    <div :class="
                      user.role === 'designer'
                        ? 'asset-nature-designer'
                        : 'asset-nature'
                    ">
                      <p class="prop-title">{{ prop.title }}</p>
                      <p class="prop-value">{{ prop.value }}</p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col lg="6">
                <div class="file-input">
                  <model-viewer :src="tokenDetails.image" ar-modes="webxr scene-viewer quick-look" seamless-poster
                    shadow-intensity="1" camera-controls ar auto-rotate loading="eager"
                    style="height: 100%; width: 100%"></model-viewer>
                </div>
              </v-col>
            </v-row>
            <div class="button-footer-mult">
              <v-btn :class="
                user.role === 'designer'
                  ? 'button btn-prev-designer'
                  : 'button btn-prev'
              " @click="changeIndex(2)">Previous</v-btn>
              <v-btn :class="
                user.role === 'designer'
                  ? 'button btn-designer'
                  : 'button btn'
              " @click="changeIndex(4)" :disabled="tokenDetails.attributes.length === 0">Next</v-btn>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="4">
          <div class="form">
            <v-row>
              <v-col lg="6">
                <div :class="
                  user.role === 'designer'
                    ? 'form-group-designer'
                    : 'form-group'
                ">
                  <p>Asset Category</p>
                  <v-select dark v-model="tokenDetails.assetCategories" :items="assetCategories" :class="
                    user.role === 'designer'
                      ? 'select-type-designer'
                      : 'select-type'
                  " color="white" background-color="transparent" solo multiple>
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 2" class="chip">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index > 2" class="grey--text text-caption">
                        (+{{ tokenDetails.assetCategories.length - 2 }}
                        others)
                      </span>
                    </template>
                  </v-select>
                </div>
                <div :class="
                  user.role === 'designer'
                    ? 'form-group-designer'
                    : 'form-group'
                ">
                  <p>Asset Available File</p>
                  <v-select dark v-model="tokenDetails.availableFileFormats" :items="assetFileFormat" :class="
                    user.role === 'designer'
                      ? 'select-type-designer'
                      : 'select-type'
                  " color="white" background-color="transparent" solo multiple>
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 4" class="chip">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index > 4" class="grey--text text-caption">
                        (+{{ tokenDetails.availableFileFormats.length - 4 }}
                        others)
                      </span>
                    </template>
                  </v-select>
                </div>
              </v-col>
              <v-col lg="6">
                <div class="file-input">
                  <model-viewer :src="tokenDetails.image" ar-modes="webxr scene-viewer quick-look" seamless-poster
                    shadow-intensity="1" camera-controls ar auto-rotate loading="eager"
                    style="height: 100%; width: 100%"></model-viewer>
                </div>
              </v-col>
            </v-row>
            <div class="button-footer-mult">
              <v-btn :class="
                user.role === 'designer'
                  ? 'button btn-prev-designer'
                  : 'button btn-prev'
              " @click="changeIndex(2)">Previous</v-btn>
              <v-btn :class="
                user.role === 'designer'
                  ? 'button btn-designer'
                  : 'button btn'
              " @click="setPreview()" :disabled="tokenDetails.assetCategories.length === 0">Preview</v-btn>
            </div>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <AddProperties :visible="propertiesDialog" @close="close" :property_list="tokenDetails.attributes"></AddProperties>
    <TokenView title="Asset Minting Summary" :tokenDetails="tokenDetails" :buttons="[
      {
        text: 'Previous',
        class: `${user.role === 'designer' ? 'btn-prev-designer' : 'btn-prev'
          }`,
        action: () => {
          changeIndex(3);
        },
      },
      {
        text: 'Mint Asset',
        class: `${user.role === 'designer' ? 'btn-designer' : 'btn'}`,
        action: () => {
          onMintingClicked();
        },
      },
    ]" v-if="preview && !isSuccess" :isLazy="false"></TokenView>
    <Loader :text="loaderText" :visible="loading"></Loader>
    <TokenView title="Asset Minted Successfully!" :tokenDetails="tokenDetails" :buttons="[]" v-if="isSuccess"
      :isLazy="false"></TokenView>
    <StatusModel :status="isSuccess" :title="statusTitle"
      :message="statusMessage" :visible="successPopup" @close="close"></StatusModel>
  </div>
</template>
<script>
import AddProperties from "../Modals/AddProperties.vue";
import TokenView from "./TokenView.vue";
import Header from "../Header.vue";
import { mapState } from "vuex";
import {
  createUserAssets,
  fetchUserAssets,
} from "../../helpers/asset-contract";
import {
  uploadFileToBaseIPFS,
  uploadAssetToIPFS,
} from "../../helpers/ipfs-upload";
import Loader from "../Loader/Loader.vue";
import { walletObj } from "../../services/wallet-service";
import { assetObj } from "../../services/asset-service";
import StatusModel from "../Modals/StatusModel.vue";
import { CATEGORIES, AVAILABLE_FILE_TYPES } from "../../helpers/constants";

export default {
  name: "MintToken",
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      loading: false,
      loaderText: "Uploading Placeholder...",
      isSuccess: false,
      successPopup: false,
      preview: false,
      propertiesDialog: false,
      stepIndex: 1,
      assetTags: ["Metaverse", "Gaming", "Metaverse and Gaming"],
      assetTypes: [
        "Avatar/Character",
        "Character",
        "Environment",
        "Objects",
        "GamingZone",
      ],
      assetCategories: CATEGORIES,
      assetFileFormat: AVAILABLE_FILE_TYPES,
      tokenDetails: {
        selectedAssetType: [],
        name: "",
        description: "",
        image: "",
        attributes: [],
        assetTime: "",
        placeholder: "",
        animation_url: "",
        external_url: "",
        background_color: "",
        model_id: +Date.now(),
        youtube_url: "",
        price: 0,
        type: "",
        assetCategories: [],
        availableFileFormats: [],
      },
      fileName: "",
      statusTitle: "",
      statusMessage: "",
    };
  },
  methods: {
    async connectWallet() {
      await walletObj.metamaskConfig();
    },
    changeIndex(index) {
      this.preview = false;
      this.stepIndex = index;
    },
    onReferenceClick() {
      this.$refs.reference.click();
    },
    setPreview() {
      this.preview = true;
    },
    close() {
      this.propertiesDialog = false;
      this.successPopup = false;
    },
    onPlaceHolderClick() {
      this.$refs.placeholder.click();
    },
    onModelClick() {
      this.$refs.model.click();
    },
    async onPlaceHolderFileChange(event) {
      try {
        const file = event.target.files[0];
        this.fileName = file.name;
        this.loading = true;
        const url = await uploadFileToBaseIPFS(file);
        this.tokenDetails.placeholder = url;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async onModelFileChange(event) {
      const file = event.target.files[0];
      this.loading = true;
      this.loaderText = "Uploading Model....";
      const url = await uploadFileToBaseIPFS(file);
      this.tokenDetails.image = url;
      this.tokenDetails.animation_url = url;
      this.fileName = file.name;
      this.tokenDetails.type = this.fileName.split(".")[1];
      this.loading = false;
    },

    async onMintingClicked() {
      try {
        this.loading = true;
        this.loaderText = "Uploading Asset....";
        this.tokenDetails.user = this.user;
        this.tokenDetails.userId = this.user.uid;
        this.tokenDetails.verified = false;
        this.tokenDetails.selectedAssetType = [
          this.tokenDetails.selectedAssetType,
        ];
        const url = await uploadAssetToIPFS(this.tokenDetails);
        const transactionData = await createUserAssets(this.tokenDetails, url);
        if (transactionData) {
          const mintedAsset = await assetObj.getUserMintedAsset(false);
          if (mintedAsset.length > 0) {
            this.loading = false;
            this.tokenDetails.tokenId =
              mintedAsset[mintedAsset.length - 1].tokenId;
            this.statusTitle = "Asset Minted Successful";
            this.statusMessage =
              "You asset minting successful, the team will verify the asset";
            this.isSuccess = true;
            this.successPopup = true;
            setTimeout(() => {
              this.successPopup = false;
            }, 2000);
            this.$router.push("/wallet");
          }
        } else {
          this.loading = false;
          this.statusTitle = "Asset Minting Failed";
          this.statusMessage = "Asset minting failed";
          this.isSuccess = false;
          this.successPopup = true;
          setTimeout(() => {
            this.successPopup = false;
          }, 2000);
        }
      } catch (error) {
        console.log(error)
        this.loading = false;
        this.statusTitle = "Asset Minting Failed";
        this.statusMessage = error.message;
        this.isSuccess = false;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 2000);
      }
    },
  },
  components: { AddProperties, TokenView, Header, Loader, StatusModel },
  created() {
    if (this.user.role === "team") {
      this.connectWallet();
    }
  },
};
</script>
<style scoped>
.mint-token {
  width: 100vw;
  min-height: 100vh;
    background-image: url("../../assets/hue.svg");
  /* background-image: url("../../assets/mint/background.svg"); */
  background-size: cover;
  background-repeat: no-repeat;
}

.mint-header {
  padding-left: 20px;
  width: 100%;
  height: 100px;
  padding-top: 2%;
  text-align: left;
}

.mint-header>h1 {
  background: linear-gradient(141.3deg, #eb5129 32.76%, #ffbf3f 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 50px !important;
}

.mint-header>p {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
}

.theme--light.v-stepper {
  background: transparent !important;
}

.divider {
  border-color: grey !important;
}

.v-stepper__header {
  box-shadow: none !important;
}

.form-group {
  padding-left: 20px;
  margin-bottom: 20px;
}

.form-group>p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}

.form-group>input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color);
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.form-group>textarea {
  width: 100%;
  height: 100px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color);
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.form-group-designer {
  padding-left: 20px;
  margin-bottom: 20px;
}

.form-group-designer>p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}

.form-group-designer>input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color);
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.form-group-designer>textarea {
  width: 100%;
  height: 100px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color);
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.text-color {
  color: transparent !important;
}

.text-color-selected {
  color: white !important;
}

.select-type {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color);
  outline: none;
  color: #fff !important;
  font-family: "DM Sans", sans-serif;
  padding: 5px;
  font-size: 12px !important;
}

.select-type-designer {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color);
  outline: none;
  color: #fff !important;
  font-family: "DM Sans", sans-serif;
  padding: 5px;
  font-size: 12px !important;
}

.file-input {
  width: 100%;
  height: 200px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color);
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.place-holder {
  width: 100%;
  height: 190px;
  border-radius: 5px;
  border: 1px dashed white;
}

.choose-model {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: rgba(196, 196, 196, 0.33);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.choose-model>h3 {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
}

.choose-model>p {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
}

.choose-upload {
  background: linear-gradient(101.24deg,
      #ff7f3f 43.86%,
      #ffbf3f 86.82%) !important;
  border-radius: 15px;
  height: 30px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin-top: 10px !important;
  color: white !important;
  letter-spacing: 1;
}

.place-holder-upload {
  width: 100%;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.place-holder-upload>input {
  cursor: pointer !important;
}

.place-holder-upload>h3 {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
}

.place-holder-upload>p {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
}

.button-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.button-footer-mult {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  margin-top: 30px;
}

.btn {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  width: 20%;
  margin-top: 10px;
  font-size: 22px !important;
  color: #fff !important;
}

.btn-prev {
  background: transparent !important;
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  width: 20%;
  margin-top: 10px;
  border: 1px solid #ff7f3f;
  color: white !important;
  font-size: 22px !important;
}

.btn-designer {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  width: 10%;
  margin-top: 10px;
  font-size: 22px !important;
  color: #fff !important;
  letter-spacing: 0.8px !important;
}

.btn-prev-designer {
  background: transparent !important;
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  width: 10%;
  margin-top: 10px;
  font-size: 22px !important;
  color: #fff !important;
  letter-spacing: 0.8px !important;
  border: 1px solid var(--border-color);
}

.text-field {
  width: 100%;
  height: 55px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  outline: none;
  color: #fff !important;
  font-family: "Archivo", sans-serif;
}

.text-field-designer {
  width: 100%;
  height: 55px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid #eb5129 !important;
  outline: none;
  color: #fff !important;
  font-family: "Archivo", sans-serif;
}

.property-input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color);
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.property-input-designer {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #eb5129;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.asset-nature {
  padding: 5px;
  background: rgba(217, 217, 217, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.asset-nature-designer {
  padding: 5px;
  background: rgba(217, 217, 217, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--designer-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.prop-title {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin: 0px;
}

.prop-value {
  font-size: 12px;
  font-weight: 400;
  color: var(--border-color);
  margin: 0px;
}

.stepper-header {
  padding-right: 43% !important;
  margin-left: -4% !important;
}

.v-stepper__content {
  padding: 15px !important;
}
</style>
