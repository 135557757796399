import { use } from 'chai'
import { SPECIALIZATIONS, TOOLS_USED } from '../helpers/constants'
import moment from 'moment'

export const userFilterMixins = {
  data() {
    return {
      country: [],
      tools: TOOLS_USED,
      SPECIALIZATIONS: SPECIALIZATIONS,
      experiences: ['0-2', '2-5', '5-10', '>10'],
      ratings: [1, 2, 3, 4, 5],
      filterSections: {
        countrySel: [],
        toolsSel: [],
        specializationSel: [],
        experienceSel: '',
        ratings: [],
      },
    }
  },
  methods: {
    applyFilter(allUsers, backupUsers) {
      let users = []
      if (this.filterSections.countrySel.length !== 0) {
        if (users.length > 0) {
          let data = users.filter((user) => {
            if (this.filterSections.countrySel.includes(user.country)) {
              return true
            }
          })
          users = data
        } else {
          users = allUsers.filter((user) => {
            if (this.filterSections.countrySel.includes(user.country)) {
              return true
            }
          })
        }
      }
      if (this.filterSections.toolsSel.length !== 0) {
        if (users.length > 0) {
          const data = users.filter((user) => {
            for (let index = 0; index < user.toolsUsed.length; index++) {
              const element = user.toolsUsed[index]
              if (this.filterSections.toolsSel.includes(element)) {
                return true
              }
            }
          })
          users = data
        } else {
          users = allUsers.filter((user) => {
            for (let index = 0; index < user.toolsUsed.length; index++) {
              const element = user.toolsUsed[index]
              if (this.filterSections.toolsSel.includes(element)) {
                return true
              }
            }
          })
        }
      }
      if (this.filterSections.specializationSel.length !== 0) {
        if (users.length > 0) {
          const data = users.filter((user) => {
            for (let index = 0; index < user.specialization.length; index++) {
              const element = user.specialization[index]
              console.log(element)
              if (this.filterSections.specializationSel.includes(element)) {
                return true
              }
            }
          })
          users = data
        } else {
          users = allUsers.filter((user) => {
            for (let index = 0; index < user.specialization.length; index++) {
              const element = user.specialization[index]
              if (this.filterSections.specializationSel.includes(element)) {
                return true
              }
            }
          })
        }
      }
      if (this.filterSections.experienceSel.length !== 0) {
        if (users.length > 0) {
          const data = users.filter((user) => {
            let year = parseInt(user.yearsOfExperience)
            if (this.filterSections.experienceSel.includes('-')) {
              let min = parseInt(
                this.filterSections.experienceSel.split('-')[0],
              )
              let max = parseInt(
                this.filterSections.experienceSel.split('-')[1],
              )
              if (year >= min && year < max) {
                return true
              }
            } else {
              if (year >= 10) {
                return true
              }
            }
          })
          users = data
        } else {
          users = allUsers.filter((user) => {
            let year = parseInt(user.yearsOfExperience)
            if (this.filterSections.experienceSel.includes('-')) {
              let min = parseInt(
                this.filterSections.experienceSel.split('-')[0],
              )
              let max = parseInt(
                this.filterSections.experienceSel.split('-')[1],
              )
              if (year >= min && year < max) {
                return true
              }
            } else {
              if (year >= 10) {
                return true
              }
            }
          })
        }
      }
      if (this.filterSections.ratings.length !== 0) {
        if (users.length > 0) {
          const data = users.filter((user) => {
            if (
              user.rating !== undefined &&
              this.filterSections.ratings.includes(user.rating.skill)
            ) {
              return true
            }
          })
          users = data
        } else {
          users = allUsers.filter((user) => {
            if (
              user.rating !== undefined &&
              this.filterSections.ratings.includes(user.rating.skill)
            ) {
              return true
            }
          })
        }
      }
      if (
        this.filterSections.countrySel.length !== 0 ||
        this.filterSections.toolsSel.length !== 0 ||
        this.filterSections.specializationSel.length !== 0 ||
        this.filterSections.experienceSel.length !== 0 ||
        this.filterSections.ratings.length !== 0
      ) {
        return users
      } else {
        return backupUsers
      }
    },
    clearFilter() {
      this.filterSections = {
        countrySel: [],
        toolsSel: [],
        specializationSel: [],
        experienceSel: '',
        ratings: [],
      }
    },
    sortName(user, sortWay) {
      if (sortWay) {
        user.sort((a, b) => {
          const nameOne = a.name.toLowerCase().trim()
          const nameTwo = b.name.toLowerCase().trim()
          if (nameOne > nameTwo) {
            return 1
          } else {
            return -1
          }
        })
        return user
      } else {
        user.sort((a, b) => {
          const nameOne = a.name.toLowerCase()
          const nameTwo = b.name.toLowerCase()
          if (nameTwo > nameOne) {
            return 1
          } else {
            return -1
          }
        })
        return user
      }
    },
    sortRating(user, sortWay) {
      if (sortWay) {
        user.sort((a, b) => {
          if (a.rating !== undefined && b.rating !== undefined) {
            return parseInt(a.rating.skill) - parseInt(b.rating.skill)
          }
        })
        return user
      } else {
        user.sort((a, b) => {
          if (a.rating !== undefined && b.rating != undefined) {
            return parseInt(b.rating.skill) - parseInt(a.rating.skill)
          }
        })
        return user
      }
    },
    sortCountry(user, sortWay) {
      if (sortWay) {
        user.sort((a, b) => {
          const nameOne = a.country.toLowerCase().trim()
          const nameTwo = b.country.toLowerCase().trim()
          if (nameOne > nameTwo) {
            return 1
          } else {
            return -1
          }
        })
        return user
      } else {
        user.sort((a, b) => {
          const nameOne = a.country.toLowerCase()
          const nameTwo = b.country.toLowerCase()
          if (nameOne < nameTwo) {
            return 1
          }
          return -1
        })
        return user
      }
    },
    sortAsset(user, sortWay) {
      if (sortWay) {
        user.sort((a, b) => {
          const nameOne = a.assetCreated
          const nameTwo = b.assetCreated
          if (nameOne > nameTwo) {
            return 1
          } else {
            return -1
          }
        })
        return user
      } else {
        user.sort((a, b) => {
          const nameOne = a.assetCreated
          const nameTwo = b.assetCreated
          if (nameOne < nameTwo) {
            return 1
          } else {
            return -1
          }
        })
        return user
      }
    },
    sortDate(user, sortWay) {
      if (sortWay) {
        user.sort(function (a, b) {
          return new Date(a.createdAt.toDate().toLocaleString().split(',')[0]) >
            new Date(b.createdAt.toDate().toLocaleString().split(',')[0])
            ? 1
            : -1
        })
        return user
      } else {
        user.sort(function (a, b) {
          return new Date(b.createdAt.toDate().toLocaleString().split(',')[0]) >
            new Date(a.createdAt.toDate().toLocaleString().split(',')[0])
            ? 1
            : -1
        })
        return user
      }
    },
  },
}
