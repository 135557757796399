<template>
  <div class="token-card">
    <div class="card-image">
      <img src="../../assets/request/request_image.svg" class="card-image" />
    </div>
    <div class="card-title">
      {{ token.name }}
    </div>
    <div class="card-description">
      {{ token.description }}
    </div>
    <div class="card-price"><v-icon color="#ff7f3f">mdi-ethereum</v-icon>{{ token.price }}</div>
    <v-btn class="button" @click="navigateView(token.tokenId)">View Now</v-btn>
  </div>
</template>
<script>
import { assetObj } from '../../services/asset-service';
export default {
  name: "TokenCardOutline",
  props: ["token"],
  methods: {
    navigateView(tokenId) {
      this.$router.push(`/market/${tokenId}`);
    },
  },
};
</script>
<style scoped>
.token-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ff7f3f;
  border-radius: 10px;
  padding: 10px;
}
.card-image {
  width: 100%;
  height: 230px;
  border-radius: 15px;
}
.card-image {
  width: 100%;
  height: 230px;
  border-radius: 15px;
  object-fit: cover;
}
.card-title {
  font-size: 20px;
  font-weight: 600;
  color: white;
  margin: 0px;
  text-align: left;
}
.card-description {
  font-size: 12px;
  font-weight: 400;
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card-price {
  font-size: 18px;
  font-weight: 600;
  color: #ffbf3f;
  margin: 0px;
}
.button {
  background: linear-gradient(101.24deg, #ff7f3f 43.86%, #ffbf3f 86.82%);
  border-radius: 6.36505px;
  font-size: 12px;
  color: white;
  margin-top: 10px;
}
</style>
