<template>
  <div>
    <header
      app
      class="layout-app-container align-center v-sheet theme--light elevation-0 v-toolbar v-app-bar mt-5"
      style=""
      data-booted="true"
    >
      <div class="actions">
        <div class="notification mr-4" v-if="user.role === 'team'">
          <v-icon :color="'white'" @click="notify()" size="15"
            >mdi-message</v-icon
          >
        </div>
        <div
          class="notification mr-4"
          @click="changeRoute('/contact', 0)"
          v-else
        >
          <v-icon :color="'white'" size="15">mdi-message</v-icon>
        </div>
        <div class="notification mr-2">
          <v-badge
            color="#ff7f3f"
            v-if="notificationLength > 0"
            :content="notificationLength"
          >
            <v-icon
              color="white"
              style="cursor: pointer"
              size="20"
              @click="changeRoute('/notification', 0)"
              >mdi-bell
            </v-icon>
          </v-badge>
          <v-icon
            color="white"
            style="cursor: pointer"
            size="20"
            @click="changeRoute('/notification', 0)"
            v-else
          >
            mdi-bell</v-icon
          >
        </div>
        <v-menu left bottom>
          <template v-slot:activator="{ on, attrs }">
            <div class="user-data-profile ml-2" v-bind="attrs" v-on="on">
              <div style="width: 40px; height: 30px; border-radius: 50%">
                <img
                  :src="user.profile"
                  :alt="user.name[0]"
                  style="
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                  "
                />
              </div>
              <div
                class="profile-info ml-2"
                style="width: 100%; cursor: pointer"
              >
                <p class="name">
                  {{ user.name.substring(0, user.name.length) }}..
                </p>
                <p class="userId">{{ user.uid.substring(0, 11) }}..</p>
              </div>
            </div>
          </template>
          <v-list style="margin-top: 25%; border-radius: 10px">
            <v-list-item @click="changeRoute('/profile', 0)">
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeRoute('/logout', 0)">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </header>
    <v-navigation-drawer
      floating
      app
      :permanent="true"
      v-model="sidebarMenu"
      :mini-variant.sync="mini"
      :class="!toggleMini && !isMobileScreen() ? 'pt-0 pl-0 pr-10 navb' : 'pt-0 pl-0 pr-0 navb'"
      :color="'#12184B'"
    >
      <v-list-item>
        <v-list-item-content class="mt-5 pl-2">
          <v-img
            src="../assets/logo.svg"
            @click="logoClick()"
            style="cursor: pointer"
            v-show="!toggleMini && !isMobileScreen()"
          ></v-img>
        </v-list-item-content>
      </v-list-item>
      <v-list dense class="mt-10">
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          link
          :class="!toggleMini && !isMobileScreen() ? pathName === item.path ? 'list-selected' : 'list' : 'list'"
          :style=" 
            pathName === item.path
              ? !toggleMini && !isMobileScreen() ? { border: '1px solid' + item.selected } : {}
              : { border: 'none' }
          "
          @click="changeRoute(item.path, index)"
        >
          <v-list-item-icon :class="!toggleMini && !isMobileScreen() ? 'pl-6': 'pl-0' ">
            <v-tooltip right content-class="white tooltip-right">
              <template v-slot:activator="{ on, attrs }">
                <div
                  :style="{
                    padding: '5px',
                    'border-radius': '10px',
                    display: 'flex',
                    'flex-direction': 'column',
                    'justify-content': 'center',
                    'align-items': 'center',
                    background:
                      pathName === item.path
                        ? 'linear-gradient( 97.3deg, #ffbf3f -2.55%, #ff7f3f 56.2%, #ff3f7f 98.17%)'
                        : 'transparent',
                    width: '30px',
                    height: '30px',
                  }"
                >
                  <v-icon :color="'#FFFFFF'" v-bind="attrs" v-on="on">{{
                    item.icon
                  }}</v-icon>
                </div>
              </template>
              <span style="color: black">{{ item.name }} </span>
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-content v-show="!toggleMini && !isMobileScreen()">
            <v-tooltip right content-class="white">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title
                  :class="
                    selectedIndex === index
                      ? 'list_title-selected'
                      : 'list_title'
                  "
                  :style="
                    selectedIndex === index
                      ? { color: item.selected }
                      : { color: item.color }
                  "
                  v-bind="attrs"
                  v-on="on"
                  >{{ item.name }}</v-list-item-title
                >
              </template>
              <span style="color: black">{{ item.name }} </span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-btn
          v-if="path.includes('communication')"
          class="btn"
          @click="onHubClicked()"
          >Project Hub</v-btn
        > -->
      </v-list>
      <ProjectHub
        :user="user"
        :tokenDetails="tokenDetails"
        :requests="requests"
        :visible="dialog"
        :ideas="getIdeas"
        @close="close"
      ></ProjectHub>
      <!-- <template v-slot:append>
        <div class="branding">
          <v-btn class="button" @click="logout()">Logout</v-btn>
        </div>
      </template> -->
    </v-navigation-drawer>
    <ChatModal :visible="showChat" @close="close"></ChatModal>
    <LogoutWarning :visible="showLogoutWarning" @close="close"></LogoutWarning>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  initializeNavigationForDesigner,
  initializeNavigationForOwner,
  initializeNavigationForTeam,
} from "../helpers/navigation_controller";
import ProjectHub from "./Modals/ProjectHub.vue";
import { requestObj } from "../services/request-service";
import { notificationObj } from "../services/notification-service";
import ChatModal from "./Modals/ChatModal.vue";
import LogoutWarning from "./Modals/LogoutWarning.vue";

export default {
  data() {
    return {
      selectedIndex: 0,
      dialog: false,
      nav: [],
      right: null,
      tokenDetails: {},
      requests: [],
      getIdeas: [],
      notifications: [],
      showChat: false,
      showLogoutWarning: false,
      toggleMini: false,
      sidebarMenu: true,
    };
  },
  created() {
    // setInterval(() => {
    this.getAllNotification();
    this.isMobile();
    // }, 2000);
  },
  methods: {
    async getAllNotification() {
      const response = await notificationObj.getUnReadNotificationByToAddress(
        this.user.uid
      );
      this.notifications = response;
      this.$store.dispatch("setNotificationLength", this.notifications.length);
    },
    changeRoute(route, index) {
      this.selectedIndex = index;
      this.pathName = route;
      this.$store.dispatch("setPath", route);
      if (route === "/logout") {
        this.$store.dispatch("setGlobalPopup", true);
        this.showLogoutWarning = true;
        return;
      }
      if (this.user.role === "designer") {
        initializeNavigationForDesigner();
      } else if (this.user.role === "meta") {
        initializeNavigationForOwner();
      } else {
        initializeNavigationForTeam();
      }
      this.$router.push(route);
    },
    close() {
      this.$store.dispatch("setGlobalPopup", false);
      this.dialog = false;
      this.showLogoutWarning = false;
      this.showChat = false;
    },
    async onHubClicked() {
      const request_id = this.path.split("/")[3];
      const data = await requestObj.findById(request_id);
      const ideas = await requestObj.getSubmittedIdeas(request_id);
      this.getIdeas = ideas;
      this.tokenDetails = data;
      if (this.user.role === "designer") {
        const allRequest = await requestObj.findByStatus("accepted");
        this.requests = allRequest;
      } else if (this.user.role === "team") {
        const allRequest = await requestObj.findAll();
        this.requests = allRequest;
      } else {
        const allRequest = await requestObj.findByUserId(this.user.uid);
        this.requests = allRequest;
      }
      this.dialog = true;
    },
    async notify() {
      this.showChat = true;
    },
    async profileLink() {
      this.$router.push("/profile");
    },
    async logout() {
      this.$router.push("/logout");
    },
    logoClick() {
      this.$router.push("/home");
    },
    getPathNameFromStore() {
      this.pathName = this.$store.state.path;
    },
    showLogout() {
      this.showLogoutWarning = true;
    },
    isMobile() {
      return screen.width <= 600
        ? (this.sidebarMenu = false)
        : (this.sidebarMenu = true);
    },
    isMobileScreen() {
      return screen.width <= 600 ? true : false;
    },
  },
  computed: {
    ...mapState({
      items: (state) => state.navigation,
      user: (state) => state.user,
      pathName: (state) => state.path,
      notificationLength: (state) => state.notificationLength,
      isNewUser: (state) => state.isNewUser,
    }),
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
  },
  components: { ProjectHub, ChatModal, LogoutWarning },
};
</script>
<style scoped>
.list {
  margin-top: 10px;
  height: 50px;
  display: flex;
  align-items: center !important;
}

.list-selected {
  margin-top: 10px;
  height: 50px;
  background: rgba(217, 217, 217, 0.24);
  border-radius: 15px !important;
  display: flex;
  align-items: center !important;
  width: 100%;
  padding-left: 10px;
}

.list_title {
  font-size: 18px !important;
  font-family: "Bebas Neue", cursive !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  letter-spacing: 0.4px !important;
}

.list_title-selected {
  font-size: 18px !important;
  font-family: "Bebas Neue", cursive !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  letter-spacing: 0.4px !important;
}

.btn {
  background: #00ffbf !important;
  border-radius: 10px;
  font-weight: 900 !important;
  text-transform: none;
  font-family: "Archivo", sans-serif !important;
  width: 100%;
  margin-top: 50%;
  text-align: center;
  padding: 10px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 50px;
  padding: 10px 10px 10px 10px;
  border-bottom-left-radius: 20px;
}

.notification {
  width: 40px;
  height: 40px;
  background: var(--primary-color) !important;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  border: 1px solid var(--border-color);
}

.profile-cont {
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.19);
  border-radius: 10px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button {
  background: transparent !important;
  border-radius: 15px;
  font-weight: 500 !important;
  text-transform: none;
  color: var(--secondary-color);
  font-weight: 500 !important;
  font-size: 25px;
  font-family: "Bebas Neue", cursive;
  letter-spacing: 0;
  width: 100%;
  margin-top: 10px;
  border: 1px solid #00ffbf;
  margin-bottom: 20px;
  margin-left: 10px;
}

.notification-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -3px;
}

.user-profile-option {
  width: 100%;
  margin-top: 10px;
  height: 50px;
  background: var(--primary-color) !important;
  border-radius: 10px !important;
  display: flex;
  align-items: center !important;
  width: 100%;
  margin-bottom: 20px;
  /* border: 1px solid var(--border-color); */
}

.name {
  margin: 0px;
  font-size: 10px;
  font-weight: 500;
}

.userId {
  margin: 0px;
  font-size: 10px;
  font-weight: 400;
}

.line {
  width: 0.5px;
  height: 70%;
  background: rgba(255, 255, 255, 1);
  margin: 0px 10px;
}

.layout-app-container {
  position: absolute;
  top: 0;
  z-index: 5;
  margin-top: 0px;
  transform: translateY(0px);
  left: 0px;
  right: 0px;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
}

.user-data-profile {
  width: auto;
  height: 40px;
  background: var(--primary-color) !important;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  border: 1px solid var(--border-color);
}

.navb::-webkit-scrollbar {
  display: none !important;
}
</style>
