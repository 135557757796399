import {
  ADMIN_USER_ID,
  NOTIFICATION_TYPE_EXPRESS_INTEREST,
  NOTIFICATION_TYPE_EXPRESS_INTEREST_ACCEPTED,
  NOTIFICATION_TYPE_REQUEST_ASSET,
  NOTIFICATION_TYPE_REQUEST_ASSET_ACCEPTED,
} from "../helpers/constants";

import {
  requestAssetForm,
  addDoc,
  query,
  getDocs,
  getDoc,
  where,
  doc,
  setDoc,
  requirementSubmissionRef,
  communicationRef,
  paymentRef,
  deleteDoc
} from "../helpers/firebase-handler";
import { notificationObj } from "./notification-service";

class RequestService {
  async create(requestData) {
    const newJson = {
      ...requestData,
      status: "pending",
      createdAt: new Date(),
      projectTakenBy: [],
      interests: [],
    };
    const addedDocs = await addDoc(requestAssetForm, newJson);
    const notificationData = {
      title: "New Custom Asset Request Added",
      message: `User needed a custom asset titled: ${requestData.title}`,
      meta: {
        request_id: addedDocs.id,
      },
    };
    notificationObj.sendNotification(
      notificationData,
      requestData.user.uid,
      ADMIN_USER_ID,
      NOTIFICATION_TYPE_REQUEST_ASSET
    );
    return addedDocs;
  }

  async findByUserId(userId) {
    const q = query(requestAssetForm, where("userId", "==", userId));
    const querySnapshot = await getDocs(q);
    const request = [];
    for (let index = 0; index < querySnapshot.docs.length; index++) {
      const element = querySnapshot.docs[index];
      const elementData = element.data();
      elementData.request_id = element.id;
      request.push(elementData);
    }
    return request;
  }

  async findById(requestId) {
    const q = doc(requestAssetForm, requestId);
    const documentSnapshot = await getDoc(q);
    if (documentSnapshot.exists()) {
      const data = documentSnapshot.data();

      data.request_id = documentSnapshot.id;
      return data;
    }
  }

  async findByStatus(status) {
    const q = query(requestAssetForm, where("status", "==", status));
    const querySnapshot = await getDocs(q);
    const request = [];
    for (let index = 0; index < querySnapshot.docs.length; index++) {
      const element = querySnapshot.docs[index];
      const elementData = element.data();
      elementData.request_id = element.id;
      request.push(elementData);
    }
    return request;
  }

  async findAll() {
    const querySnapshot = await getDocs(requestAssetForm);
    const request = [];
    for (let index = 0; index < querySnapshot.docs.length; index++) {
      const element = querySnapshot.docs[index];
      const elementData = element.data();
      elementData.request_id = element.id;
      request.push(elementData);
    }
    return request;
  }

  async acceptRequest(requestId) {
    const q = doc(requestAssetForm, requestId);
    const documentSnapshot = await getDoc(q);
    if (documentSnapshot.exists()) {
      const data = documentSnapshot.data();
      data.status = "accepted";
      await setDoc(q, data, { merge: true });
      const notificationData = {
        title: "Request Asset Status Update",
        message: `Your Asset Request has been accepted`,
        meta: {
          request_id: requestId,
        },
      };
      notificationObj.sendNotification(
        notificationData,
        ADMIN_USER_ID,
        data.user.uid,
        NOTIFICATION_TYPE_REQUEST_ASSET_ACCEPTED
      );
    }
  }

  async submitIdea(data) {
    const newJson = {
      ...data,
      createdAt: new Date(),
    };
    await addDoc(requirementSubmissionRef, newJson);
  }

  async getSubmittedIdeas(requestId) {
    const q = query(
      requirementSubmissionRef,
      where("requestId", "==", requestId)
    );
    const querySnapshot = await getDocs(q);
    const ideas = [];
    for (let index = 0; index < querySnapshot.docs.length; index++) {
      const element = querySnapshot.docs[index];
      const elementData = element.data();
      elementData.idea_id = element.id;
      ideas.push(elementData);
    }
    return ideas;
  }

  async sendMessage(data, request_id) {
    const newJson = {
      ...data,
      createdAt: new Date(),
    };
    const docId = `${request_id}_RC`;
    const query = doc(communicationRef, docId);
    await setDoc(query, newJson);
  }

  async getMessagesByUserId(request_id, user_id) {
    const docId = `${request_id}_${user_id}`;
    const query = doc(communicationRef, docId);
    const documentSnapshot = await getDoc(query);
    if (documentSnapshot.exists()) {
      const data = documentSnapshot.data();
      return [data];
    }
  }

  async getMessagesByRequestId(request_id) {
    const q = query(communicationRef, where("request_id", "==", request_id));
    const querySnapshot = await getDocs(q);
    const messages = [];
    for (let index = 0; index < querySnapshot.docs.length; index++) {
      const element = querySnapshot.docs[index];
      const elementData = element.data();
      elementData.message_id = element.id;
      messages.push(elementData);
    }
    return messages;
  }

  async showExpressInterest(requestId, request) {
    const query = doc(requestAssetForm, requestId);
    await setDoc(query, request);
    const notificationData = {
      title: "User Expressed Interest",
      message: `There is a new user expressed interest in your request`,
      meta: {
        request_id: requestId,
      },
    };
    notificationObj.sendNotification(
      notificationData,
      request.interests[request.interests.length - 1].user.uid,
      ADMIN_USER_ID,
      NOTIFICATION_TYPE_EXPRESS_INTEREST
    );
  }

  async updateProjectTakenBy(requestId, request) {
    const query = doc(requestAssetForm, requestId);
    await setDoc(query, request, {merge: true});
    const notificationData = {
      title: "Interest has been accepted",
      message: `Your interest has been accepted`,
      meta: {
        request_id: requestId,
      },
    };
    notificationObj.sendNotification(
      notificationData,
      ADMIN_USER_ID,
      request.projectTakenBy[request.projectTakenBy.length - 1],
      NOTIFICATION_TYPE_EXPRESS_INTEREST_ACCEPTED
    );
  }

  async getSubmittedIdeasById(ideaId) {
    const q = doc(requirementSubmissionRef, ideaId);
    const documentSnapshot = await getDoc(q);
    if (documentSnapshot.exists()) {
      const data = documentSnapshot.data();
      data.request_id = documentSnapshot.id;
      return data;
    }
  }

  async submitPayment(data) {
    const newJson = {
      ...data,
      createdAt: new Date(),
    };
    const res = await addDoc(paymentRef, newJson);
    return res;
  }

  async findByStatusAndUserId(status, userId) {
    try {
      const q = query(requestAssetForm, where("status", "==", status), where("userId", "==", userId));
      const querySnapshot = await getDocs(q);
      const request = [];
      for (let index = 0; index < querySnapshot.docs.length; index++) {
        const element = querySnapshot.docs[index];
        const elementData = element.data();
        elementData.request_id = element.id;
        request.push(elementData);
      }
      return request;
    } catch (error) {
      console.log(error);
    }
  }

  async deleteAssetRequest(request_id) {
    try {
      const docRef = doc(requestAssetForm, request_id)
      deleteDoc(docRef);
    } catch (error) {
      throw error;
    }
  }

  async updateRequest(requestId, request) {
    try {
      const query = doc(requestAssetForm, requestId);
      await setDoc(query, request, {merge: true});
    } catch (error) {
      throw error;
    }
  }
}
export const requestObj = new RequestService();
