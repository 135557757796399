import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{staticClass:"dialog",attrs:{"persistent":"","max-width":"500","transition":"dialog-bottom-transition","overlay-opacity":"0.5","overlay-color":"#e8e8e8"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,{staticClass:"model-card text-center dialog",attrs:{"color":'#15181E'}},[_c(VRow,{staticStyle:{"width":"100%"},attrs:{"justify":"space-between"}},[_c(VCol,{attrs:{"lg":"12"}},[_c('div',{class:'general-text-title model-title'},[_vm._v(" Are you sure you want to logout? ")])])],1),_c(VRow,{attrs:{"justify":"space-between"}},[_c(VCol,{attrs:{"lg":"4"}},[_c(VBtn,{staticClass:"mt-10 button button-data",on:{"click":function($event){return _vm.onClickEvent()}}},[_vm._v("Yes")])],1),_c(VCol,{attrs:{"lg":"4"}},[_c(VBtn,{staticClass:"mt-10 button button-data-secondary",on:{"click":function($event){return _vm.close()}}},[_vm._v("No")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }