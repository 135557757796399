<template>
  <div
    class="notification-container"
    :style="isLoading ? { filter: 'blur(10px)' } : { filter: 'none' }"
  >
    <Header
      :required="true"
      title="Notification"
      subtitle="Find all your notifications here."
    ></Header>
    <div
      class="no-notification"
      v-if="notifications.length === 0 && !isLoading"
    >
      <div class="place-holder">
        <v-img src="../assets/no-notification.png" class="img"></v-img>
      </div>
      <p class="sub-title">No Notifications available yet!</p>
    </div>
    <div class="notification-body" v-else-if="!isLoading">
      <v-row v-for="(notification, index) in notifications" :key="index">
        <v-col lg="6">
          <div
            class="notification-card"
            :style="
              notification.type.includes('request')
                ? { 'border-left': '10px solid #00bcd4' }
                : notification.type.includes('express')
                ? { 'border-left': '10px solid #ff9800' }
                : {
                    'border-left': '10px solid #4caf50',
                  }
            "
          >
            <v-row>
              <v-col lg="1">
                <v-icon color="white">
                  {{
                    notification.type.includes("request")
                      ? "mdi-account-voice"
                      : "mdi-account-alert-outline"
                  }}
                </v-icon>
              </v-col>
              <v-col
                lg="8"
                class="notification-details"
                @click="goToNotification(notification)"
              >
                <p class="notification-title">{{ notification.title }}</p>
                <p class="notification-sub-title">{{ notification.message }}</p>
                <p class="notification-time">
                  {{ notification.createdAt.toDate().toLocaleString() }}
                </p>
              </v-col>
              <v-col lg="3">
                <div
                  class="new-notification"
                  v-if="!notification.readStatus"
                ></div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <Loader :visible="isLoading" :text="loaderText"></Loader>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Header from "../components/Header.vue";
import { notificationObj } from "../services/notification-service";
import Loader from "../components/Loader/Loader.vue";

export default {
  name: "Notification",
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      notifications: [],
      isLoading: false,
      loaderText: "Fetching Notifications..",
    };
  },
  components: { Header, Loader },
  created() {
    this.getAllNotifications();
  },
  methods: {
    async getAllNotifications() {
      this.isLoading = true;
      this.notifications = await notificationObj.getNotificationByToAddress(
        this.user.uid
      );
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
      setTimeout(async () => {
        for (let index = 0; index < this.notifications.length; index++) {
          const element = this.notifications[index];
          await notificationObj.updateReadStatus(element.notification_id);
        }
        this.$store.dispatch("setNotificationLength", 0);
      }, 2000);
    },
    goToNotification(notification) {
      if (
        notification.type === "express_interest" ||
        notification.type === "request_asset" ||
        notification.type === "request_asset_accepted" ||
        notification.type === "express_interest_accepted"
      ) {
        if (
          notification.meta !== undefined &&
          notification.meta.request_id !== undefined
        ) {
          this.$router.push(`request/${notification.meta.request_id}`);
        }
      } else if (
        notification.type === "express_interest_rejected" ||
        notification.type === "express_interest_feedback" ||
        notification.type === "contact_chat"
      ) {
        if (this.user.role !== "team") {
          this.$router.push(`/contact`);
        }
      } else if (notification.type === "price_update") {
        this.$router.push(`asset/${notification.meta.asset_id}?type=db`);
      }
    },
  },
};
</script>
<style scoped>
.notification-container {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/hue.svg");
  /* background-image: url("../assets/wallet/background.svg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 2%;
}

.notification-header {
  padding-top: 2%;
  padding-left: 50px;
}

.notification-header > h1 {
  background: linear-gradient(141.3deg, #00bfff 32.76%, #00ffbf 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 50px !important;
  font-weight: 600;
}

.notification-header > p {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
}

.place-holder {
  margin-top: 5%;
  padding-left: 20px;
}

.img {
  width: 10%;
  height: 50%;
}

.sub-title {
  color: white;
  margin: 20px 0px 10px 0px;
  font-size: 20px;
  font-weight: 400;
  padding-left: 1%;
  line-height: 25px !important;
}

.notification-body {
  margin-top: 50px;
  margin-left: 20px;
}

.notification-card {
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  background: rgba(196, 196, 196, 0.15);
}

.notification-title {
  margin: 0px;
  font-size: 16px;
  color: white;
  font-weight: 600;
}

.notification-sub-title {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
}

.notification-time {
  color: white;
  margin: 10px 0px 0px 0px;
  font-size: 10px;
  font-weight: 400;
}

.notification-details {
  cursor: pointer;
}

.new-btn {
  background: linear-gradient(177.35deg, #ffbf3f -22.23%, #ff3f7f 97.79%);
  color: white !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "Bebas Neue", cursive !important;
  padding: 10px !important;
  height: 10px !important;
}

.new-notification {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: linear-gradient(177.35deg, #ffbf3f -22.23%, #ff3f7f 97.79%);
  margin-left: 90%;
}
</style>
