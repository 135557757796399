import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider ,
  sendPasswordResetEmail ,
  deleteUser
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  doc,
  setDoc,
  addDoc,
  collection,
  getDoc,
  getDocs,
  where,
  query,
  updateDoc,
  arrayUnion,
  orderBy,
  deleteDoc
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytesResumable, getMetadata} from 'firebase/storage'

const FIREBASE_CONFIG = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(FIREBASE_CONFIG);
const firebaseDB = getFirestore(firebaseApp);
const firebaseAuth = getAuth(firebaseApp);
const analytics = getAnalytics(firebaseApp);

// firebase docs
const requirementsRef = collection(firebaseDB, "requirements");
const requirementSubmissionRef = collection(
  firebaseDB,
  "requirement_submission"
);
const notificationRef = collection(firebaseDB, "notifications");
const requestAssetForm = collection(firebaseDB, "requestAsset");
const users = collection(firebaseDB, "users");
const lazyMintingRef = collection(firebaseDB, "lazyMinting");
const communicationRef = collection(firebaseDB, "requestCommunications");
const paymentRef = collection(firebaseDB, "requestPayment");
const contactMessageRef = collection(firebaseDB, "contactMessages");
const resourceRef = collection(firebaseDB, "resources");
const faqRef = collection(firebaseDB, "FAQList");
const websiteRef = collection(firebaseDB, "website_request");
// storage
const storage = getStorage();
const requirementStorageRef = ref(storage, "requirements");

export {
  requirementsRef,
  requestAssetForm,
  requirementSubmissionRef,
  notificationRef,
  users,
  lazyMintingRef,
  requirementStorageRef,
  communicationRef,
  analytics,
  firebaseAuth,
  storage,
  getMetadata,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  doc,
  addDoc,
  setDoc,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  onAuthStateChanged,
  getDoc,
  where,
  query,
  getDocs,
  updateDoc,
  arrayUnion,
  signOut,
  GoogleAuthProvider,
  paymentRef,
  sendPasswordResetEmail,
  contactMessageRef,
  orderBy,
  resourceRef,
  deleteDoc,
  deleteUser,
  faqRef,
  websiteRef
};
