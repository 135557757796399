<template>
  <div>
    <v-dialog v-model="visible" persistent max-width="400">
      <v-card :color="'#15181E'" class="model-card">
        <v-row style="justify-content: space-between; width: 100%;">
          <v-col lg="10">
            <div :class="'model-title-designer'">Confirmation</div>
          </v-col>
          <v-col lg="1">
            <v-icon color="white" size="20" class="mt-2" @click="closeTerms()">mdi-close</v-icon>
          </v-col>
        </v-row>
        <div class="sub-title mt-10 mb-10">
          By submitting this form, you agree to the <span style="color: #ff7f3f; text-decoration: none;cursor:pointer"
            @click="showTermsUse = true">terms of use </span> and <span
            style="color: #ff7f3f; text-decoration: none;cursor:pointer" @click="showPrivacyPolicy = true">privacy
            policy.</span>
        </div>
        <v-btn class="btn button" @click="onSubmitClick()">Submit</v-btn>
      </v-card>
    </v-dialog>
    <loader :visible="isLoading" text="Submitting Your Profile......"></loader>
    <status-model :visible="showSuccessPopup" :status="isSuccess" :title="popupTitle" :message="popupMessage">
    </status-model>
    <privacy-policy :visible="showPrivacyPolicy" @close="close"></privacy-policy>
    <terms-use :visible="showTermsUse" @close="close"></terms-use>
  </div>
</template>
<script>
import { mapState } from "vuex";
import StatusModel from "./StatusModel.vue";
import Loader from "../Loader/Loader.vue";
import { userObj } from "../../services/user-service";
import { authObj } from "../../services/auth_service";
import {
  createUserWithEmailAndPassword,
  firebaseAuth,
} from "../../helpers/firebase-handler";
import { mailServiceObj } from "../../services/mail-service";
import PrivacyPolicy from "./PrivacyPolicy.vue";
import TermsUse from "./TermsUse.vue";
import { notificationObj } from "../../services/notification-service";

export default {
  name: "TermsAndCondition",
  props: ["visible", "formData"],
  components: {
    StatusModel,
    Loader,
    PrivacyPolicy,
    TermsUse
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) return this.$emit("close");
      },
    },
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      checked: false,
      isLoading: false,
      loaderText: "Submitting Details.....",
      isSuccess: false,
      showSuccessPopup: false,
      popupTitle: "",
      popupMessage: "",
      showPrivacyPolicy: false,
      showTermsUse: false,
    };
  },
  methods: {
    async onSubmitClick() {
      this.isLoading = true;
      try {
        if (this.formData.uid === "") {
          const createUser = await createUserWithEmailAndPassword(
            firebaseAuth,
            this.formData.email,
            this.formData.password
          );
          this.formData.uid = createUser.user.uid;
          this.formData.profile =
            "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/profile.svg?alt=media&token=cfff3f59-1754-4323-8298-1d72c6a76c40";
          await authObj.logout();
          this.formData.followers = [];
          const res = await userObj.createUser(this.formData);
          if (res) {
            this.isLoading = false;
            this.isSuccess = true;
            this.popupTitle = "Profile Sent for Verification";
            this.popupMessage =
              "The team is verifying your profile. Will get back to you shortly";
            this.showSuccessPopup = true;
            await authObj.logout();
            // await mailServiceObj.sendNotificationEmailToSignax();
            // await mailServiceObj.registrationDetailsToUser(this.formData.email, this.formData.name);
            const notificationData = {
              title: "New User Register",
              message: `New user registered : ${this.formData.name}`,
              meta: {
                request_id: '',
              },
            };
            notificationObj.sendNotification(
              notificationData,
              '2DQqFx9voVV5tPY3fQWbS17L7Dw2',
              '2DQqFx9voVV5tPY3fQWbS17L7Dw2',
              'new_user'
            );
            setTimeout(() => {
              this.showSuccessPopup = false;
              this.$emit("close");
              setTimeout(() => {
                window.open("https://signa-x.io", "_self");
              }, 1000);
            }, 4000);
          } else {
            this.isLoading = false;
            this.isSuccess = false;
            this.popupTitle = "Profile Creation Failed";
            this.popupMessage = "Please try again shortly";
            this.showSuccessPopup = true;
            setTimeout(() => {
              this.showSuccessPopup = false;
            }, 4000);
          }
          return;
        }
        else {
          this.formData.followers = [];
          const res = await userObj.createUser(this.formData);
          if (res) {
            this.isLoading = false;
            this.isSuccess = true;
            this.popupTitle = "Profile Sent for Verification";
            this.popupMessage =
              "The team is verifying your profile. Will get back to you shortly";
            this.showSuccessPopup = true;
            await authObj.logout();
            await mailServiceObj.sendNotificationEmailToSignax();
            await mailServiceObj.registrationDetailsToUser(this.formData.email, this.formData.name);
            const notificationData = {
              title: "New User Register",
              message: `New user registered : ${this.formData.name}`,
              meta: {
                request_id: '',
              },
            };
            notificationObj.sendNotification(
              notificationData,
              '2DQqFx9voVV5tPY3fQWbS17L7Dw2',
              '2DQqFx9voVV5tPY3fQWbS17L7Dw2',
              'new_user'
            );
            setTimeout(() => {
              this.showSuccessPopup = false;
              this.$emit("close");
              setTimeout(() => {
                window.open("https://signa-x.io", "_self");
              }, 1000);
            }, 4000);
            // this.$router.push("/");
          } else {
            this.isLoading = false;
            this.isSuccess = false;
            this.popupTitle = "Profile Creation Failed";
            this.popupMessage = "Please try again shortly";
            this.showSuccessPopup = true;
            setTimeout(() => {
              this.showSuccessPopup = false;
            }, 4000);
          }
        }
      } catch (error) {
        this.isLoading = false;
        this.isSuccess = false;
        this.popupTitle = "Profile Creation Failed";
        this.popupMessage = "Please try again shortly";
        this.showSuccessPopup = true;
        setTimeout(() => {
          this.showSuccessPopup = false;
        }, 4000);
      }
    },
    close() {
      this.showPrivacyPolicy = false;
      this.showTermsUse = false;
    },
    closeTerms() {
      this.$emit('close');
    }
  },
};
</script>
<style scoped>
.model-card {
  padding: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.model-title-designer {
  margin: 0px;
  font-size: 20px !important;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: left;
}

.model-title-others {
  background: linear-gradient(141.3deg, #00bfff 32.76%, #00ffbf 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 30px !important;
  font-weight: 600;
}

.sub-title {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  width: 96%;
  text-align: center;
}

.status-fail {
  margin-top: 20px;
  width: 100px;
  height: 100px;
  background: rgba(255, 0, 0, 0.616);
  border-radius: 50% !important;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-success {
  margin-top: 20px;
  width: 100px;
  height: 100px;
  background: rgba(0, 128, 0, 0.616);
  border-radius: 50% !important;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  color: white !important;
  letter-spacing: 0;
  font-size: 20px;
  font-weight: 500 !important;
  font-family: 'Bebas Neue', cursive;
}
</style>
