<template>
  <div class="logout-container">
    <h1>Signing Out....</h1>
  </div>
</template>
<script>
import { authObj } from "../../services/auth_service";
import { handleAuth } from "../../helpers/auth-handler";
export default {
  name: "Logout",
  created() {
    setTimeout(() => {
      this.logout();
    }, 2000);
  },
  methods: {
    async logout() {
      try {
        await authObj.logout();
        this.$store.commit("setUser", null);
        this.$store.commit("setNavigation", []);
        this.$store.commit("setHideWarning", false);
        this.$store.commit("setPath", '/home');
        this.$router.push("/");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.logout-container {
  width: 90vw;
  height: 100vh;
  background: var(--empty-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logout-container > h1 {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif;
  margin: 0px 0px 0px 0px;
  font-size: 40px !important;
  font-weight: 600;
}
.logout-container > p {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
}
</style>
