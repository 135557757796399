<template>
  <div class="preview-token" style="padding-top: 5%">
    <div class="preview-body">
      <div class="tool-bar mb-5 mt-10">
        <v-row>
          <v-col lg="8">
            <v-btn class="ml-0 btn-secondary" @click="goTo('/request')">
              <v-icon size="20">mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col
            lg="3"
            v-if="tokenDetails.userId === user.uid || user.role === 'team'"
          >
            <v-btn class="mr-5 btn-secondary" @click="showEditPopup = true">
              <v-icon size="20">mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="btn-secondary" @click="deletePopup = true">
              <v-icon size="20">mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-row :class="tokenDetails.userId === user.uid ? 'mt-0' : 'mt-10'">
        <v-col lg="11">
          <v-row>
            <v-col lg="5">
              <div class="general-text-title request-title">
                {{ tokenDetails.name }}
              </div>
              <div class="request-description">
                {{ tokenDetails.description }}
              </div>
              <div class="profile-store">
                <div class="profile-image">
                  <img :src="tokenDetails.user.profile" />
                </div>
                <div class="details ml-2">
                  <div class="name">Creator</div>
                  <div class="username" @click="checkProfile()">
                    {{ tokenDetails.user.uid }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col lg="6">
              <v-row>
                <v-col lg="8">
                  <div class="request-img">
                    <img
                      :src="tokenDetails.uploadedImages[selectedImageIndex]"
                      class="request_image"
                    />
                  </div>
                </v-col>
                <v-col lg="3">
                  <div class="image-list">
                    <div
                      class="image-card mb-2"
                      @click="selectImage(index)"
                      v-for="(image, index) in tokenDetails.uploadedImages"
                      :key="index"
                    >
                      <img :src="image" alt="" class="image-card-image" />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="request-asset-nature">
            <p>Asset Environment</p>
            <v-row>
              <v-col lg="2">
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20">mdi-application</v-icon>
                  <p>{{ tokenDetails.selectedAssetType }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="request-asset-nature">
            <p>Asset Type:</p>
            <v-row>
              <v-col
                md="3"
                lg="2"
                v-for="nature in tokenDetails.selectedAssetNature"
                :key="nature"
              >
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20">mdi-nature</v-icon>
                  <p>{{ nature }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
          <div
            class="request-asset-nature"
            v-if="tokenDetails.property.length !== 0"
          >
            <p>Asset Properties</p>
            <v-row>
              <v-col
                md="3"
                lg="2"
                v-for="(nature, index) in tokenDetails.property"
                :key="index"
              >
                <PropertyCard
                  :title="nature.title"
                  :value="nature.value"
                ></PropertyCard>
              </v-col>
            </v-row>
          </div>
          <div
            class="request-asset-nature"
            v-if="tokenDetails.reference_url !== ''"
            @click="onLinkClick(tokenDetails.reference_url)"
          >
            <p>Reference Link</p>
            <v-row>
              <v-col md="3" lg="2">
                <div class="asset-nature" style="cursor: pointer">
                  <v-icon color="#ff7f3f" size="20">mdi-link</v-icon>
                  <p>{{ tokenDetails.reference_url }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="request-asset-nature">
            <p>File Formats</p>
            <v-row>
              <v-col
                md="3"
                lg="1"
                v-for="(nature, index) in tokenDetails.selectedFileType"
                :key="index"
              >
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20"
                    >mdi-file-document-outline</v-icon
                  >
                  <p>{{ nature }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-row class="mt-0" v-if="user.role !== 'designer'">
            <v-col lg="1">
              <div class="request-asset-nature">
                <p>Asset Price</p>
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20">mdi-ethereum</v-icon>
                  <p>{{ tokenDetails.assetBudget }}</p>
                </div>
              </div>
            </v-col>
            <v-col lg="1">
              <div class="request-asset-nature">
                <p>Payment Type</p>
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20">mdi-currency-usd</v-icon>
                  <p>{{ tokenDetails.paymentType }}</p>
                </div>
              </div>
            </v-col>
            <v-col lg="2">
              <div class="request-asset-nature">
                <p>Timeline</p>
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20">mdi-clock</v-icon>
                  <p>{{ tokenDetails.assetTime }}</p>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="button-footer" v-if="user.role === 'designer'">
        <v-btn
          :class="'btn'"
          v-if="
            !tokenDetails.projectTakenBy.includes(user.uid) &&
            tokenDetails.interests.filter(
              (interest) => interest.userId === user.uid
            ).length === 0
          "
          @click="showInterest()"
          >Express Interest</v-btn
        >
        <v-btn
          :class="'btn-disabled'"
          v-if="
            !tokenDetails.projectTakenBy.includes(user.uid) &&
            tokenDetails.interests.filter(
              (interest) => interest.userId === user.uid
            ).length !== 0
          "
          >Requested</v-btn
        >
        <v-btn
          class="btn"
          v-if="tokenDetails.projectTakenBy.includes(user.uid)"
          @click="navigateToCommunication()"
        >
          Manage Project</v-btn
        >
      </div>
      <div class="button-footer" v-if="user.role === 'meta'">
        <v-btn class="btn-listed" v-if="tokenDetails.status === 'accepted'"
          >Listed
          <v-icon class="ml-2" size="20">mdi-check-decagram</v-icon>
        </v-btn>
        <v-btn
          class="btn"
          v-if="tokenDetails.status !== 'pending'"
          @click="navigateToCommunication()"
          >Manage Project
        </v-btn>
        <v-btn class="btn-disabled" v-else>Verification Pending</v-btn>
      </div>
      <div class="button-footer" v-if="user.role === 'team'">
        <v-btn class="btn-listed" v-if="tokenDetails.status === 'accepted'"
          >Listed
          <v-icon class="ml-2" size="20">mdi-check-decagram</v-icon>
        </v-btn>
        <v-btn
          class="btn"
          v-if="
            tokenDetails.status !== 'pending' &&
            tokenDetails.projectTakenBy.length > 0
          "
          @click="navigateToCommunication()"
          >Manage Project</v-btn
        >
        <v-btn
          class="btn"
          v-else-if="tokenDetails.status === 'pending'"
          @click="acceptRequest()"
          >Accept Request</v-btn
        >
        <v-btn
          class="btn-secondary"
          v-if="tokenDetails.interests.length > 0"
          @click="viewInterests()"
          >View Interests
        </v-btn>
      </div>
    </div>
    <Loader :text="loaderText" :visible="isLoading"></Loader>
    <StatusModel
      :title="isSuccess ? 'Asset Minting Successful' : 'Asset Minting Failed!'"
      message="Pariatur cupidatat dolor elit ullamco."
      :visible="showSuccessPopup"
      :status="isSuccess"
      @close="closeSuccessPopup"
    ></StatusModel>
    <ViewInterests
      :request="tokenDetails"
      :visible="interestViewPopup"
      @close="closeSuccessPopup"
    ></ViewInterests>
    <ExpressInterestMOdal
      :visible="showExpressInterest"
      :tokenDetails="tokenDetails"
      @close="closeSuccessPopup"
    >
    </ExpressInterestMOdal>
    <v-dialog
      v-model="deletePopup"
      persistent
      max-width="500"
      transition="dialog-bottom-transition"
      class="dialog"
    >
      <v-card :color="'#15181E'" class="model-card text-center dialog">
        <v-row justify="space-between" style="width: 100%">
          <v-col lg="12">
            <div :class="'general-text-title model-title'">
              Are you sure you want to delete?
            </div>
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-col lg="4">
            <v-btn class="mt-10 button button-data" @click="onDeletePressed()"
              >Yes</v-btn
            >
          </v-col>
          <v-col lg="4">
            <v-btn
              class="mt-10 button button-data-secondary"
              @click="closeSuccessPopup()"
              >No</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <EditRequestModal
      :visible="showEditPopup"
      :tokenDetails="tokenDetails"
      @close="closeSuccessPopup"
    ></EditRequestModal>
  </div>
</template>
<script>
import { requestObj } from "../../services/request-service";
import { mapState } from "vuex";
import Header from "../Header.vue";
import PropertyCard from "../Common/PropertyCard.vue";
import Loader from "../Loader/Loader.vue";
import StatusModel from "../Modals/StatusModel.vue";
import ViewInterests from "../Modals/ViewInterests.vue";
import ExpressInterestMOdal from "../Modals/ExpressInterestModal.vue";
import EditRequestModal from "../Modals/EditRequestModal.vue";
import { userObj } from "../../services/user-service";
import { mailServiceObj } from "../../services/mail-service";

export default {
  name: "ViewRequest",
  data() {
    return {
      interestViewPopup: false,
      tokenDetails: {},
      selectedImageIndex: 0,
      isLoading: false,
      loaderText: "Sending Interest....",
      isSuccess: false,
      showSuccessPopup: false,
      showExpressInterest: false,
      deletePopup: false,
      showEditPopup: false,
    };
  },
  created() {
    this.getRequestById();
  },
  methods: {
    async getRequestById() {
      const request_id = this.$route.params.request_id;
      const data = await requestObj.findById(request_id);
      this.tokenDetails = data;
    },
    navigateToCommunication() {
      const request_id = this.$route.params.request_id;
      this.$router.push(`/request/communication/${request_id}`);
    },
    async acceptRequest() {
      this.loaderText = "Accepting Request...";
      this.isLoading = true;
      const request_id = this.$route.params.request_id;
      await requestObj.acceptRequest(request_id);
      const allUsers = await userObj.getAllVerifiedUsers("designer");
      console.log(allUsers.length);
      for (let index = 0; index < allUsers.length; index++) {
        const percentage = (index / allUsers.length) * 100;
        this.loaderText = `Sending Email ${parseInt(percentage)}%`;
        const element = allUsers[index];
        await mailServiceObj.sendMailOnProjectCreated(element);
      }
      window.location.reload();
    },
    selectImage(index) {
      this.selectedImageIndex = index;
    },
    async showInterest() {
      this.$store.dispatch("setGlobalPopup", true);
      this.showExpressInterest = true;
      // this.isLoading = true;
      // const request_id = this.$route.params.request_id;
      // const jsonData = {
      //   user: this.user,
      //   status: "pending",
      //   userId: this.user.uid,
      //   createdAt: new Date().toISOString(),
      // };
      // this.tokenDetails.interests.push(jsonData);
      // await requestObj.showExpressInterest(request_id, this.tokenDetails);
      // this.isLoading = false;
      // this.isSuccess = true;
      // this.showSuccessPopup = true;
      // setTimeout(() => {
      //   this.showSuccessPopup = false;
      //   window.location.reload();
      // }, 2000);
    },
    closeSuccessPopup() {
      this.$store.dispatch("setGlobalPopup", false);
      this.showSuccessPopup = false;
      this.interestViewPopup = false;
      this.showExpressInterest = false;
      this.deletePopup = false;
      this.showEditPopup = false;
    },
    viewInterests() {
      this.$store.dispatch("setGlobalPopup", true);
      this.interestViewPopup = true;
    },
    checkProfile() {
      this.$router.push(`/user/${this.tokenDetails.user.uid}`);
    },
    onLinkClick(link) {
      window.open(link, "_blank");
    },
    async onDeletePressed() {
      try {
        const request_id = this.$route.params.request_id;
        this.loaderText = "Deleting Request...";
        this.isLoading = true;
        await requestObj.deleteAssetRequest(request_id);
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push("/request");
        }, 2000);
      } catch (error) {
        this.isLoading = false;
      }
    },
    goTo(route) {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  components: {
    Header,
    PropertyCard,
    Loader,
    StatusModel,
    ViewInterests,
    ExpressInterestMOdal,
    EditRequestModal,
  },
};
</script>
<style scoped>
.preview-token {
  width: 100vw;
  overflow-y: scroll;
  background-image: url("../../assets/hue.svg");
  /* background-image: url('../../assets/request/background.svg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.create-header {
  padding: 20px;
  color: var(--white-color);
}

.preview-body {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  margin-top: 10px;
}

.create-header {
  padding-top: 2%;
  padding-left: 50px;
}

.create-header > h3 {
  background: linear-gradient(141.3deg, #00bfff 32.76%, #ff7f3f 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 50px !important;
  font-weight: 600;
}

.create-header > p {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
}

.request-img {
  width: 100%;
  height: 300px;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(217, 217, 217, 0.1);
  position: relative;
  border: 2px solid var(--border-color);
  margin-bottom: 10px;
}

.request_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.request-title {
  margin: 0px;
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.request-description {
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(196, 196, 196, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  width: 100%;
}

.request-asset-nature {
  margin-top: 20px;
}

.request-asset-nature > p {
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  color: white;
}

.asset-nature {
  padding: 10px;
  background: rgba(217, 217, 217, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}

.asset-nature > p {
  font-size: 12px;
  color: white;
  margin: 0px;
  font-weight: 400;
  margin-left: 5px;
  max-lines: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.button-footer {
  width: 85%;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 10%;
}

.btn {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  border-radius: 10px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  min-width: 15%;
  margin-top: 10px;
  text-align: center;
  padding: 12px 10px 10px 10px !important;
  margin-right: 10px;
  color: white;
  font-size: 20px;
  letter-spacing: 0.8px !important;
}

.btn-listed {
  background: #3aa959 !important;
  border-radius: 10px;
  font-weight: 600 !important;
  text-transform: none;
  font-family: "Archivo", sans-serif !important;
  width: 10%;
  margin-top: 10px;
  text-align: center;
  padding: 10px;
  color: white !important;
  cursor: none;
  margin-right: 10px;
}

.btn-prev {
  background: transparent !important;
  border-radius: 15px;
  font-weight: 600 !important;
  text-transform: none;
  font-family: "Archivo", sans-serif !important;
  width: 30%;
  margin-top: 10px;
  border: 1px solid #ff7f3f;
  color: #fff;
}

.btn-disabled {
  color: white !important;
  background: transparent !important;
  border: 1px solid #eb5129;
  cursor: not-allowed !important;
  margin-top: 10px;
  text-transform: none;
  font-family: "Bebas Neue", cursive;
  border-radius: 15px;
  font-weight: 400 !important;
  min-width: 15%;
  font-size: 20px;
}

.btn-secondary {
  color: white !important;
  background: transparent !important;
  border: 1px solid #eb5129;
  cursor: pointer !important;
  text-transform: none;
  margin-top: 10px;
  font-family: "Bebas Neue", cursive;
  font-size: 20px;
  letter-spacing: 0.8px !important;
  border-radius: 15px;
}

.image-card {
  height: 85px;
  background: rgba(196, 196, 196, 0.1);
  border: 1px solid #ff7f3f;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
}

.image-card-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.profile-store {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  margin-top: 10px;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  padding: 5px;
}

.profile-image > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.name {
  font-size: 10px;
  font-weight: 400;
  color: #e8e8e8;
}

.username {
  font-size: 12px;
  font-weight: 400;
  color: white;
  cursor: pointer;
  text-decoration: underline;
}

.image-list {
  width: 80%;
  height: 300px;
  margin-top: -10px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
}

/* logout modal */
.model-card {
  padding: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-title {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
}

.button-data {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  font-family: "Bebas Neue", cursive;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  letter-spacing: 0.8px !important;
  color: white;
  font-size: 20px;
}

.model-title {
  font-size: 20px !important;
  text-align: center;
}

.button-data-secondary {
  font-family: "Bebas Neue", cursive;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  letter-spacing: 0.8px !important;
  color: white;
  font-size: 20px;
  border: 1px solid #ff7f3f;
  background: transparent !important;
}

.dialog {
  background: var(--empty-color);
  padding: 20px;
  border-radius: 10px;
  background-image: url("../../assets/popup.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
