<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      v-model="visible"
      class="dialog"
      max-width="500"
    >
      <div class="card-body dialog">
        <div class="card-header">
          <v-row>
            <v-col lg="10">
              <h3>Edit Profile</h3>
            </v-col>
            <v-col lg="2" class="mt-1">
              <div class="span" @click="close()">X</div>
            </v-col>
          </v-row>
        </div>
        <div class="card-content">
          <v-row>
            <v-col lg="6">
              <div class="form-group">
                <p>Full Name</p>
                <input v-model="user.name" />
              </div>
            </v-col>
            <v-col lg="6">
              <div class="form-group">
                <p>Email Address</p>
                <input v-model="user.email" />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="6">
              <div class="form-group">
                <p>Phone Number</p>
                <input v-model="user.phone" />
              </div>
            </v-col>
            <v-col lg="6">
              <div class="form-group">
                <p>Area of Specialization</p>
                <input v-model="user.specialization" />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="6">
              <div class="form-group">
                <p>Showcase your work</p>
                <input v-model="user.workUrl" />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="12">
              <div class="form-group">
                <p>About your self</p>
                <textarea v-model="user.about"></textarea>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="card-footer">
          <v-btn class="btn" @click="saveProfile()">Save</v-btn>
        </div>
      </div>
    </v-dialog>
    <Loader :visible="loading" text="Updating Profile...."></Loader>
    <StatusModel
      :visible="showPopup"
      :message="
        isSuccess ? 'Profile Updated Successfully' : 'Error Updating Profile'
      "
      :status="isSuccess"
      @close="closePopup"
      :title="
        isSuccess ? 'Successfully updated profile' : 'Error updating profile'
      "
    >
    </StatusModel>
  </div>
</template>
<script>
import { userObj } from "../../services/user-service";
import Loader from "../Loader/Loader.vue";
import StatusModel from "./StatusModel.vue";
export default {
  name: "EditUserProfile",
  props: ["visible", "user"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) return this.$emit("close");
      },
    },
  },
  data() {
    return {
      loading: false,
      isSuccess: false,
      showPopup: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async saveProfile() {
      this.loading = true;
      await userObj.updateUserData(this.user);
      this.loading = false;
      this.isSuccess = true;
      this.showPopup = true;
      setTimeout(() => {
        this.showPopup = false;
      }, 2000);
    },
    closePopup() {
      this.showPopup = false;
    },
  },
  components: { Loader, StatusModel },
};
</script>
<style scoped>
.dialog {
  background: var(--empty-color);
  padding: 20px;
}
.form-group > p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}
.form-group > input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #00ffbf;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

.form-group > textarea {
  width: 100%;
  height: 100px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #00ffbf;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}
h3 {
  background: linear-gradient(141.3deg, #00bfff 32.76%, #00ffbf 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 30px;
  font-weight: 900;
}
.card-footer {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
}
.btn {
  background: #00ffbf !important;
  border-radius: 15px;
  font-weight: 600 !important;
  text-transform: none;
  font-family: "Archivo", sans-serif !important;
  width: 30%;
  margin-top: 10px;
}
.span {
  width: 20px;
  height: 20px;
  font-size: 15px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
</style>
