<template>
  <div class="user-container pt-5" :style="allUsers.length < 2 ? { 'height': '100vh' } : { 'height': 'auto' }">
    <Header :required="true" title="Users" subtitle="All available users are listed here."></Header>
    <div class="user-body">
      <v-row style="width: 60%; justify-content: end;margin-left: 40%;">
        <v-col lg="4">
          <download-excel :data="allUsers" name="userData.xls" :fields="json_fields" class="mb-5">
            <v-btn color="success">Download Data <v-icon>mdi-download</v-icon>
            </v-btn>
          </download-excel>
        </v-col>
        <v-col lg="2">
          <div
            style="border-radius: 10px;border: 1px solid #ff7f3f !important; display: flex; justify-content: center; align-items: center; flex-direction: column; height: 40px; background: rgba(196, 196, 196, 0.18);"
            @click="showFilter()">
            <v-icon class="mt-0" style="cursor: pointer" color="#ff7f3f">{{ showFilters ?
                'mdi-close' : 'mdi-filter-outline'
            }}
            </v-icon>
          </div>
        </v-col>
      </v-row>
      <div class="search-filter mt-10 mb-10" v-if="showFilters">
        <v-row style="width: 100%;">
          <v-col lg="4">
            <div class="form-group">
              <p>Filter by Country</p>
              <v-select dark v-model="filterSections.countrySel" :items="country" class="select-type" color="white"
                background-color="transparent" solo multiple chips clearable>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 1">
                    <span>{{ item }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ filterSections.countrySel.length - 1 }} others)
                  </span>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col lg="4">
            <div class="form-group">
              <p>Filter by Specializations</p>
              <v-select dark v-model="filterSections.specializationSel" :items="SPECIALIZATIONS" class="select-type"
                color="white" background-color="transparent" solo multiple chips clearable>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 1">
                    <span>{{ item }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ filterSections.specializationSel.length - 1 }} others)
                  </span>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col lg="4">
            <div class="form-group">
              <p>Filter by Tools</p>
              <v-select dark v-model="filterSections.toolsSel" :items="tools" class="select-type" color="white"
                background-color="transparent" solo multiple chips clearable>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 1">
                    <span>{{ item }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ filterSections.toolsSel.length - 1 }} others)
                  </span>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col lg="4">
            <div class="form-group">
              <p>Filter by Experience (in years)</p>
              <v-select dark v-model="filterSections.experienceSel" :items="experiences" class="select-type"
                color="white" background-color="transparent" solo chips clearable>
              </v-select>
            </div>
          </v-col>
          <v-col lg="4">
            <div class="form-group">
              <p>Filter by Rating</p>
              <v-select dark v-model="filterSections.ratings" :items="ratings" class="select-type" color="white"
                background-color="transparent" solo chips clearable multiple>
              </v-select>
            </div>
          </v-col>
        </v-row>
        <v-row style="width: 100%; justify-content: flex-end; padding-right: 0px;">
          <v-col lg="1">
            <v-btn class="btn button" @click="onApplyClick()">Apply</v-btn>
          </v-col>
          <v-col lg="1">
            <v-btn outlined color="error" class="button" style="margin-top: 10px;" @click="clear()">Clear</v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="table-header">
        <v-row align="center" justify="space-between">
          <v-col lg="2">
            <div class="header">
              Name
              <v-icon color="white" class="ml-1 mt-1" style="cursor:pointer" v-if="sort.name"
                @click="applySort('name', sort.name)">
                mdi-sort-alphabetical-ascending</v-icon>
              <v-icon color="white" class="ml-1 mt-1" style="cursor:pointer" v-else @click="applySort('name', !sort.name)">
                mdi-sort-alphabetical-descending</v-icon>
            </div>
          </v-col>
          <v-col lg="1">
            <div class="header">
              Email
            </div>
          </v-col>
          <v-col lg="1">
            <div class="header">
              Phone
            </div>
          </v-col>
          <v-col lg="2">
            <div class="header">
              Country
            </div>
          </v-col>
          <v-col lg="2">
            <div class="header">
              Ratings
              <v-icon color="white" class="ml-1 mt-1" style="cursor:pointer" v-if="sort.rating"
                @click="applySort('rating', sort.rating)">
                mdi-sort-numeric-ascending</v-icon>
              <v-icon color="white" class="ml-1 mt-1" style="cursor:pointer" v-else @click="applySort('rating', !sort.rating)">
                mdi-sort-numeric-descending</v-icon>
            </div>
          </v-col>
          <v-col lg="2">
            <div class="header">
              Portfolio
            </div>
          </v-col>
          <v-col lg="2">
            <div class="header">
              Actions
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="mt-2" v-if="perPageUsers.length > 0">
        <v-row class="table-content" align="center" justify="space-between" v-for="(users, index) in perPageUsers"
          :key="index">
          <v-col lg="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <a style="color: var(--border-color)" @click="routerPush(users.uid)">
                  <div class="name" v-bind="attrs" v-on="on">
                    {{ users.name }}
                  </div>
                </a>
              </template>
              <span> {{ users.name }}</span>
            </v-tooltip>
          </v-col>
          <v-col lg="1" @click="copyToClipBoard(users.email)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="#ff7f3f" dark v-bind="attrs" v-on="on" class="mr-10" style="cursor: pointer;">
                  mdi-email
                </v-icon>
              </template>
              <span>{{ users.email }}</span>
            </v-tooltip>
          </v-col>
          <v-col lg="1">
            <div class="content">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#ff7f3f" dark v-bind="attrs" v-on="on" class="mr-10" style="cursor: pointer;">
                    mdi-phone
                  </v-icon>
                </template>
                <span>{{ users.phone }}</span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col lg="2">
            <div class="name">
              {{ users.country }}
            </div>
          </v-col>
          <v-col lg="2">
            <div class="name">
              <v-row v-if="users.rating !== undefined">
                <v-col lg="1" v-for="n in 5" :key="n">
                  <v-icon color="yellow darken-3">{{ n <= users.rating.skill ? 'mdi-star' : 'mdi-star-outline'
                  }}</v-icon>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col lg="1" v-for="n in 5" :key="n">
                  <v-icon color="white darken-3">mdi-star-outline</v-icon>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <!-- <v-col lg="1">
            <div class="name">
              {{ users.role === 'designer' ? '3D Creator' : users.role === 'meta' ? 'Project Owner' : 'Admin' }}
            </div>
          </v-col> -->
          <v-col lg="2">
            <div class="content">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#ff7f3f" dark v-bind="attrs" v-on="on" class="mr-10" style="cursor: pointer;"
                    @click="viewPortfolio(users.portfolio)">
                    mdi-link
                  </v-icon>
                </template>
                <span>Portfolio</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#ff7f3f" dark v-bind="attrs" v-on="on" style="cursor: pointer;"
                    @click="viewSocial(users.workUrl)">
                    mdi-account-group-outline
                  </v-icon>
                </template>
                <span>Social Link</span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col lg="2">
            <div class="content">
              <v-row>
                <v-col>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="green" dark v-bind="attrs" v-on="on" class="" style="cursor: pointer;"
                        @click="update(index, true)" v-if="users.verified === false">
                        mdi-check
                      </v-icon>
                      <v-icon color="#ff7f3f" dark v-bind="attrs" v-on="on" class="" style="cursor: pointer;"
                        @click="update(index, false)" v-else>
                        mdi-close
                      </v-icon>
                    </template>
                    <span>{{ users.verified ? 'Close Access' : 'Verify Now' }}</span>
                  </v-tooltip>
                </v-col>
                <v-col>
                  <v-icon color="red" @click="deleteInfo(users, index)">mdi-delete</v-icon>
                </v-col>
                <v-col>
                  <v-icon color="white" @click="showSkillRating(users, index)">mdi-pencil</v-icon>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <div class="footer"
          v-if="filterSections.countrySel.length === 0 && filterSections.toolsSel.length === 0 && filterSections.specializationSel.length === 0 && filterSections.experienceSel.length === 0">
          <div class="pagination">
            <h1>Pages </h1>
            <div :class="n === selectedPage ? 'page-selected mr-2' : 'page-not-selected'" v-for="n in totalPage"
              @click="pageChange(n)">
              {{ n }}
            </div>
            <p style="margin: 0px;width:100%">Rows per page</p>
            <select v-model="rowsPerPage" @change="onRowsChange($event)" class="mySelect">
              <option value=5 style="color: black !important">5</option>
              <option value=10 style="color: black !important">10</option>
              <option value=15 style="color: black !important">15</option>
              <option value=20 style="color: black !important">20</option>
              <option value=30 style="color: black !important">30</option>
              <option :value="allUsers.length" style="color: black !important">All</option>
            </select>
          </div>
        </div>
      </div>
      <div class="mt-2" v-else style="width: 100%; display: flex; justify-content: center; align-items: center">
        <v-row>
          <v-col>
            <h1 class="no-data">No Users Found</h1>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-dialog v-model="showWarning" persistent max-width="500" transition="dialog-bottom-transition" class="dialog">
      <v-card :color="'#15181E'" class="model-card text-center dialog">
        <v-row justify="space-between" style="width: 100%">
          <v-col lg="12">
            <div :class="'general-text-title model-title'">
              Are you sure you want to delete?
            </div>
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-col lg="4">
            <v-btn class="mt-10 button button-data" @click="onClickEvent()">Yes</v-btn>
          </v-col>
          <v-col lg="4">
            <v-btn class="mt-10 button button-data-secondary" @click="close()">No</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <SkillRating :user="selectedUser" :visible="showRating" @close="close"></SkillRating>
    <status-snackbar :text="'Email Copied to clipboard'" :visible="showSnack"></status-snackbar>\
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import { mapState } from "vuex";
import { userObj } from "../services/user-service";
import { mailServiceObj } from "../services/mail-service";
import { notificationObj } from "../services/notification-service";
import { ADMIN_USER_ID, NOTIFICATION_ACCEPTING_USER } from '../helpers/constants';
import SkillRating from '../components/Modals/SkillRating.vue'
import { userFilterMixins } from '../mixins/FilterUser';
import StatusSnackbar from "../components/Common/StatusSnackbar.vue";

export default {
  name: "RegisteredUsers",
  components: {
    Header,
    SkillRating,
    StatusSnackbar,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      rowsPerPage: (state) => state.rowsPerPage,
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  data() {
    return {
      allUsers: [],
      backupUsers: [],
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Country", value: "country" },
        { text: "Ratings", value: "role" },
        { text: "Portfolio", value: "portfolio" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      showFilters: false,
      sortType: ['Ascending', 'Descending'],
      showWarning: false,
      selectedUser: null,
      selectedIndex: 0,
      perPageUsers: [],
      selectedPage: 1,
      totalPage: 0,
      showFilters: false,
      selectedTag: [],
      sortType: ['Ascending', 'Descending'],
      selectedCountry: [],
      json_fields: {
        name: "name",
        email: "email",
        phone: "phone",
        role: "role",
        status: "verified",
        about: "about",
        workUrl: "workUrl",
        websiteLink: "websiteLink",
        specialization: "specialization",
        companyName: "company_name",
      },
      showRating: false,
      showSnack: false,
      sort: {
        name: true,
        rating: true,
      },
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      const users = [];
      const countryData = [];
      let page = this.selectedPage;
      const usersList = await userObj.findAll();
      for (let index = 0; index < usersList.length; index++) {
        const element = usersList[index];
        if (this.user.uid !== element.id) {
          users.push(element);
          countryData.push(element.country);
        }
      }
      this.country = [...new Set(countryData)];
      page--;
      const loop_start = this.rowsPerPage * page;
      this.perPageUsers = users.slice(loop_start, loop_start + this.rowsPerPage);
      this.allUsers = users;
      this.totalPage = Math.ceil(users.length / this.rowsPerPage);
      this.backupUsers = users;
      this.$store.dispatch('setGlobalPopup', false);
    },

    deleteItemConfirm() {
      this.allUsers.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.showWarning = false;
      this.$store.dispatch('setGlobalPopup', false);
      this.showFilters = false;
      this.showRating = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async update(index, status) {
      const user = this.allUsers[index];
      user.verified = status;
      await userObj.updateUserData(user);
      if (status) {
        const notificationData = {
          title: `Your registration is accpeted`,
          message: `welcome to signax platform ${user.name}`,
          meta: {},
        };
        await notificationObj.sendNotification(notificationData, ADMIN_USER_ID, user.uid, NOTIFICATION_ACCEPTING_USER,);
        await mailServiceObj.sendMailOnceAccountIsVerified(user.name, user.email, user.password);
      }
      this.initialize();
    },

    viewSocial(socialLink) {
      window.open(socialLink, '_blank');
    },

    viewPortfolio(link) {
      window.open(link, '_blank');
    },

    showFilter() {
      this.showFilters = !this.showFilters;
    },
    deleteInfo(user, index) {
      this.selectedUser = user;
      this.selectedIndex = index;
      this.showWarning = true;
      this.$store.dispatch('setGlobalPopup', true);
    },

    async onClickEvent() {
      try {
        await userObj.deleteDocId(this.selectedUser.uid);
        this.allUsers.splice(this.selectedIndex, 1);
        this.backupUsers.splice(this.selectedIndex, 1);
        this.emit('close');
      } catch (error) {
        console.log(error);
      }
    },

    pageChange(page) {
      this.selectedPage = page;
      page--;
      const loop_start = this.rowsPerPage * page;
      this.perPageUsers = this.allUsers.slice(loop_start, loop_start + this.rowsPerPage);
    },

    onRowsChange(e) {
      const page = e.target.value;
      this.$store.dispatch('setRowsPerPage', parseInt(page));
      const loop_start = 0;
      this.totalPage = Math.ceil(this.allUsers.length / this.rowsPerPage);
      this.perPageUsers = this.allUsers.slice(loop_start, loop_start + this.rowsPerPage);
    },

    routerPush(userId) {
      this.$router.push(`/user/${userId}`)
    },

    showSkillRating(user) {
      this.selectedUser = user;
      this.showRating = true;
    },
    onApplyClick() {
      const filteredUsers = this.applyFilter(this.allUsers, this.backupUsers);
      this.perPageUsers = filteredUsers;
    },
    clear() {
      this.clearFilter();
      this.initialize();
    },
    async copyToClipBoard(email) {
      const url = email;
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = url;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      this.showSnack = true;
      setTimeout(() => {
        this.showSnack = false;
      }, 2000);
    },
    applySort(type) {
      if (type === 'name') {
        this.sort.name = !this.sort.name;
        const sortedUsers = this.sortName(this.perPageUsers, this.sort.name);
        this.perPageUsers = sortedUsers;
      }
      if (type === 'rating') {
         this.sort.rating = !this.sort.rating;
        const sortedUsers = this.sortRating(this.perPageUsers, this.sort.rating);
        this.perPageUsers = sortedUsers;
      }
    }
  },
  mixins: [userFilterMixins]
};
</script>
<style scoped>
.user-container {
  width: 100vw;
    background-image: url("../assets/hue.svg");
  /* background-image: url("../assets/wallet/background.svg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 10%;
}

.user-body {
  margin-top: 40px;
  width: 80%;
  padding-left: 20px;
}

.table-header {
  width: 100%;
  height: 50px;
  border: 1px solid var(--secondary-color);
  color: white;
  border-radius: 15px;
  background: rgba(196, 196, 196, 0.15);
  align-items: center;
  padding: 10px;
}

.table-content {
  width: 100%;
  height: 50px;
  border: 1px solid var(--designer-color);
  color: white;
  border-radius: 15px;
  background: rgba(196, 196, 196, 0.15);
  align-items: center;
  margin: 20px 0px 0px 0px;
}

.header {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.content {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.name {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.model-card {
  padding: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-title {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
}

.button-data {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  font-family: "Bebas Neue", cursive;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  letter-spacing: 0.8px !important;
  color: white;
  font-size: 20px;
}

.model-title {
  font-size: 20px !important;
  text-align: center;
}

.button-data-secondary {
  font-family: "Bebas Neue", cursive;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  letter-spacing: 0.8px !important;
  color: white;
  font-size: 20px;
  border: 1px solid #ff7f3f;
  background: transparent !important;
}

.dialog {
  background: var(--empty-color);
  padding: 20px;
  border-radius: 10px;
  background-image: url("../assets/popup.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.footer {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pagination {
  width: auto;
  height: 50px;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
}

.page-not-selected {
  padding: 10px;
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer
}

.page-selected {
  width: 30px;
  height: 30px;
  padding: 10px;
  background-color: #ff7f3f;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer
}

.pagination>h1 {
  font-size: 20px !important;
  color: white;
  margin-right: 20px;
}

.mySelect {
  width: 50%;
  height: 30px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
  -webkit-appearance: menulist
}

.search-filter {
  width: 100%;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
}

.expansion {
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
}

.select-type {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff !important;
  font-family: "DM Sans", sans-serif;
  padding: 5px;
  font-size: 12px !important;
}

.form-group {
  padding-left: 20px;
  margin-bottom: 20px;
}

.form-group>p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}

.btn {
  background: linear-gradient(177.35deg, #ffbf3f -22.23%, #ff3f7f 97.79%);
  color: white !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  width: 100%;
  margin-top: 10px;
  font-family: "Bebas Neue", cursive !important;
  letter-spacing: 0.8px !important;
}

.no-data {
  font-size: 25px;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
}
</style>
