<template>
  <div class="request">
    <div class="no-request pt-10" v-if="requests.length === 0 && tempArray.length === 0">
      <Header :required="true" :title="
        user.role === 'designer'
          ? 'Projects'
          : user.role === 'meta'
            ? 'My Requests'
            : 'All Projects'
      " subtitle="" style="padding-top: 1.5%"></Header>
      <img src="../assets/request/custom.svg"
        style="height: 100%;width: 100%; object-fit: contain; margin-top: -10%; margin-left: 2%; cursor: pointer;"
        @click="clickCreateNew()" v-if="user.role !== 'designer'" />
      <div class="place-holder" style="margin-top: 5%" v-else>
        <v-img src="../assets/request/empty.png" class="img"></v-img>
        <p>
          There are no available projects yet,<br />you will be notified once
          you receive a project!
        </p>
      </div>
      <p class="sub-title" v-if="user.role !== 'designer'">
        Click below and create your first project!
      </p>
      <!-- <v-btn class="no-request-button" @click="clickCreateNew()" v-if="user.role !== 'designer'">Create Project</v-btn> -->
    </div>
    <div class="request-container pt-5" v-else>
      <v-row dense justify="space-between">
        <v-col lg="8">
          <Header :required="true" :title="
            user.role === 'designer'
              ? 'Projects'
              : user.role === 'meta'
                ? 'My Requests'
                : 'All Projects'
          " subtitle="Find all the available projects including yours"></Header>
        </v-col>
        <!-- <v-col lg="3">
          <v-btn class="button" @click="clickCreateNew()" v-if="user.role !== 'designer'">Create Project</v-btn>
        </v-col> -->
      </v-row>
      <v-row class="mt-15" v-if="user.role !== 'designer'" style="width: 100%; justify-content: space-between;">
        <v-col v-for="(tab, index) in tabs" :key="index" md="3" lg="2" @click="changeTab(index, tab)">
          <div :class="selectedTab == index ? 'tab-div-selected' : 'tab-div'" :style="
            index === selectedTab
              ? { 'border-bottom': '5px solid var(--border-color)' }
              : { 'border-bottom': none }
          ">
            {{ tab }}
          </div>
        </v-col>
        <v-col lg="6">
          <v-btn class="button" @click="clickCreateNew()" v-if="user.role !== 'designer'">Create Project</v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-15" v-if="user.role === 'designer'">
        <v-col v-for="(tab, index) in designerTabs" :key="index" md="3" lg="2" @click="changeTab(index, tab)">
          <div :class="selectedTab == index ? 'tab-div-selected' : 'tab-div'" :style="
            index === 0 && selectedTab === 0
              ? { 'border-bottom': '5px solid var(--border-color)' }
              : index === 1 && selectedTab === 1
                ? { 'border-bottom': '5px solid white' }
                : { 'border-bottom': none }
          ">
            {{ tab }}
          </div>
        </v-col>
      </v-row>
      <div class="request-body" v-if="requests.length > 0">
        <div class="request-data" v-for="(request, index) in requests" :key="index">
          <div class="individual-request mt-10" :style="
            request.projectTakenBy.includes(user.uid)
              ? { 'border-left': '10px solid white' }
              : { 'border-left': '10px solid var(--border-color)' }
          ">
            <v-row>
              <v-col md="3" lg="4" class="pt-1 pl-5 pr-0 pb-1">
                <div class="request-img">
                  <img :src="request.uploadedImages[0]" class="request_image" />
                </div>
              </v-col>
              <v-col md="9" lg="8">
                <div class="request-title">
                  {{ request.name }}
                </div>
                <div class="request-description">
                  {{ request.description }}
                </div>
                <div class="request-asset-nature">
                  <p>Asset Nature:</p>
                  <v-row style="width: 80%">
                    <v-col md="3" lg="4" v-for="(nature, index) in request.selectedAssetNature" :key="index"
                      :style="index < 3 ? { 'display': 'flex' } : { 'display': 'none' }">
                      <div class="asset-nature">
                        <v-icon color="#ff7f3f" size="20">mdi-nature</v-icon>
                        <p>{{ nature }}</p>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div class="misc">
                  <v-row>
                    <v-col lg="9">
                      <v-row>
                        <v-col :lg="
                          request.selectedAssetType ===
                            'Environment + Character'
                            ? 6
                            : 5
                        ">
                          <div class="request-asset-nature">
                            <p>Asset Environment:</p>
                            <div class="asset-nature">
                              <v-icon color="#ff7f3f" size="20">mdi-application</v-icon>
                              <p>{{ request.selectedAssetType }}</p>
                            </div>
                          </div>
                        </v-col>
                        <!-- <v-col lg="4">
                          <div class="request-asset-nature">
                            <p>Asset Deadline:</p>
                            <div class="asset-nature">
                              <v-icon color="#ff7f3f" size="20"
                                >mdi-clock</v-icon
                              >
                              <p>{{ request.assetTime }}</p>
                            </div>
                          </div>
                        </v-col> -->
                      </v-row>
                    </v-col>
                    <v-col lg="3" style="padding-right: 30px">
                      <v-btn v-if="!request.projectTakenBy.includes(user.uid)" class="view-now"
                        @click="navigateToDetailPage(request.request_id)">View Now</v-btn>
                      <v-btn v-if="request.projectTakenBy.includes(user.uid)" class="view-now"
                        @click="navigateToDetailPage(request.request_id)">Manage</v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <div class="request-body" v-else>
        <div class="no-projects mt-10" style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          ">
          <v-img src="../assets/request/empty.png" class="img"></v-img>
          <h3 class="mt-5" style="
              text-align: center;
              font-size: 20px;
              color: white;
              width: 40%;
              line-height: 24px;
            " v-if="user.role === 'designer'">
            No Projects Yet! Check out the
            <span class="span">All Projects</span>
            tab and find some projects to express your interest
          </h3>
          <v-btn class="btn-secondary" @click="changeTab(0, 'All Projects')" v-if="user.role === 'designer'">Explore
            Projects</v-btn>
          <h3 class="mt-5" style="
              text-align: center;
              font-size: 20px;
              color: white;
              width: 40%;
              line-height: 24px;
            " v-if="user.role !== 'designer'">
            No Projects Yet
          </h3>
        </div>
      </div>
    </div>
    <Loader :visible="isLoading" :text="'Fetching Projects....'"></Loader>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { requestObj } from "../services/request-service";
import Header from "../components/Header.vue";
import Loader from "../components/Loader/Loader.vue";

export default {
  name: "Request",
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      selectedTab: 0,
      selectedTabData: "All Requests",
      requests: [],
      tempArray: [],
      tabs: ["All Projects", "Pending", "Accepted"],
      designerTabs: ["All Projects", "My Projects"],
      isLoading: false,
    };
  },
  created() {
    this.getRequestByUserId();
  },
  methods: {
    changeTab(index, status) {
      const array = this.tempArray;
      this.selectedTab = index;
      if (status === "Pending") {
        this.selectedTabData = "Pending";
        this.requests = array.filter((request) => request.status === "pending");
      } else if (status === "Accepted") {
        this.selectedTabData = "Accepted";
        this.requests = array.filter(
          (request) => request.status === "accepted"
        );
      } else if (status === "My Projects") {
        this.selectedTabData = "My Projects";
        this.requests = array.filter((request) =>
          request.projectTakenBy.includes(this.user.uid)
        );
      } else {
        this.selectedTabData = "All Requests";
        this.requests = array;
      }
    },
    clickCreateNew() {
      this.$router.push("/request/new");
    },
    async getRequestByUserId() {
      this.$store.dispatch('setGlobalPopup', true);
      this.isLoading = true;
      if (this.user.role === "designer") {
        const allRequest = await requestObj.findByStatus("accepted");
        this.requests = allRequest;
        this.tempArray = allRequest;
      } else if (this.user.role === "team") {
        const allRequest = await requestObj.findAll();
        this.requests = allRequest;
        this.tempArray = allRequest;
      } else {
        const allRequest = await requestObj.findByUserId(this.user.uid);
        this.requests = allRequest;
        this.tempArray = allRequest;
      }
      setTimeout(() => {
        this.isLoading = false;
        this.$store.dispatch('setGlobalPopup', false);
      }, 2000);
    },
    navigateToDetailPage(requestId) {
      this.$router.push(`/request/${requestId}`);
    },
  },
  components: { Header, Loader },
};
</script>
<style scoped>
.request {
  overflow: hidden;
}

.no-request {
  width: 100%;
  height: 100vh;
    background-image: url("../assets/hue.svg");
  /* background-image: url("../assets/request/request_bg.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.request-header {
  padding-left: 20px;
  width: 100%;
  height: 100px;
  padding-top: 5%;
  text-align: left;
}

.request-header>h1 {
  background: linear-gradient(141.3deg, #00bfff 32.76%, #ff7f3f 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 50px !important;
}

.request-header-designer {
  padding-left: 20px;
  width: 100%;
  height: 100px;
  padding-top: 5%;
  text-align: left;
}

.request-header-designer>h1 {
  background: linear-gradient(141.3deg, #eb5129 32.76%, #ffbf3f 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 50px !important;
}

.request-header>p,
.request-header-designer>p {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
}

.place-holder {
  margin-top: 5%;
  padding-left: 20px;
}

.place-holder>p {
  margin: 20px 0px 0px 0px;
  font-size: 22px;
  line-height: 25px !important;
}

.place-holder>p {
  margin: 20px 0px 0px 0px;
  font-size: 22px;
  line-height: 25px !important;
}

.img {
  width: 15%;
  height: 20%;
}

.sub-title {
  color: rgba(196, 196, 196, 0.5);
  margin: 20px 0px 10px 0px;
  font-size: 16px;
  font-weight: 400;
  padding-left: 20px;
}

.button {
  margin-left: 60%;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  border-radius: 10px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  color: white;
  letter-spacing: 0.8px !important;
  font-size: 18px !important;
}

.no-request-button {
  margin-left: 20px;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  border-radius: 10px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  color: white;
  letter-spacing: 0.8px !important;
  font-size: 18px !important;
}

.request-container {
  width: 100%;
  min-height: 100vh;
  /* background-image: url("../assets/request/background.svg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  padding-bottom: 10%;
  /* background-color: var(--empty-color); */
  background-image: url("../assets/hue.svg");
}

.tab-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  margin-left: 20px;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  font-size: 22px;
}

.tab-div-selected {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  margin-left: 20px;
  border-radius: 5px;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  cursor: pointer;
  font-weight: 600;
  font-size: 22px;
}

.request-body {
  margin-left: 20px;
  margin-top: 20px;
}

.individual-request {
  width: 90%;
  height: 250px;
  background: rgba(196, 196, 196, 0.15);
  border-radius: 15px;
  padding-right: 5px;
  margin-top: 10px;
}

.request-img {
  width: 100%;
  height: 230px;
  border-radius: 15px;
  background: tran;
}

.request_image {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: contain;
}

.request-title {
  margin: 0px;
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.request-description {
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(196, 196, 196, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.request-asset-nature {
  margin-top: 10px;
}

.request-asset-nature>p {
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  color: white;
}

.asset-nature {
  padding: 5px;
  background: rgba(217, 217, 217, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}

.asset-nature>p {
  font-size: 12px;
  color: white;
  margin: 0px;
  font-weight: 400;
  margin-left: 15px;
  text-align: left;
  max-lines: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.view-now {
  width: 100%;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  border-radius: 10px;
  font-weight: 400;
  margin-top: 25px;
  margin-right: 25px;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  letter-spacing: 0.8px !important;
  color: white;
  font-size: 20px;
}

.btn-secondary {
  color: white !important;
  background: transparent !important;
  border: 2px solid #eb5129;
  cursor: pointer !important;
  text-transform: none;
  margin-top: 10px;
  font-family: "Bebas Neue", cursive;
  font-size: 20px;
  letter-spacing: 0.8px !important;
  border-radius: 15px;
  width: 20%;
  padding: 14px 10px 10px 10px !important;
}

.span {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
</style>
