<template>
  <div class="preview-token mb-10">
    <Header :required="true" :title="title" subtitle="" style="margin-left: 2%;"></Header>
    <div class="preview-body pb-10">
      <v-row>
        <v-col lg="7">
          <div class="request-title">
            {{ tokenDetails.name }}
          </div>
          <div class="request-description">
            {{ tokenDetails.description }}
          </div>
          <div class="request-asset-nature">
            <p>Asset Environment:</p>
            <v-row>
              <v-col lg="4">
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20">mdi-application</v-icon>
                  <p>{{ tokenDetails.selectedAssetType }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="request-asset-nature">
            <p>Asset Type:</p>
            <v-row>
              <v-col md="3" lg="4" v-for="nature in tokenDetails.selectedAssetNature" :key="nature">
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20">mdi-nature</v-icon>
                  <p>{{ nature }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="request-asset-nature" v-if="tokenDetails.reference_url !== ''" @click="onLinkClick(tokenDetails.reference_url)">
            <p>Reference Link</p>
            <v-row>
              <v-col md="3" lg="5">
                <div class="asset-nature" style="cursor:pointer;">
                  <v-icon color="#ff7f3f" size="20">mdi-link</v-icon>
                  <p>{{ tokenDetails.reference_url }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
          <!-- <div class="request-asset-nature">
            <p>Properties</p>
            <v-row>
              <v-col md="3" lg="4" v-for="(nature, index) in tokenDetails.property" :key="index">
                <PropertyCard :title="nature.title" :value="nature.value"></PropertyCard>
              </v-col>
            </v-row>
          </div> -->
          <div class="request-asset-nature" v-if="tokenDetails.assetTime !== '' &&  tokenDetails.assetBudget !==0 && tokenDetails.paymentType !== null">
            <p>Other Props</p>
            <v-row>
              <v-col md="3" lg="3" v-if="tokenDetails.assetTime !== ''">
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20">mdi-clock</v-icon>
                  <p>{{ tokenDetails.assetTime }}</p>
                </div>
              </v-col>
              <v-col md="3" lg="2" v-if="user.role !== 'designer' && tokenDetails.assetBudget !==0">
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20">mdi-currency-usd</v-icon>
                  <p>{{ tokenDetails.assetBudget }}</p>
                </div>
              </v-col>
              <v-col md="3" lg="2" v-if="user.role !== 'designer' && tokenDetails.paymentType !== null">
                <div class="asset-nature">
                  <v-icon color="#ff7f3f" size="20">mdi-account-cash</v-icon>
                  <p>{{ tokenDetails.paymentType }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="button-footer-mult">
            <v-btn v-for="(button, index) in buttons" :key="index" :class="button.class" @click="button.action">{{
                button.text
            }}</v-btn>
          </div>
        </v-col>
        <v-col lg="5">
          <div class="request-img">
            <img :src="tokenDetails.uploadedImages[selectedImageIndex]" alt="" class="request_image" />
          </div>
          <v-row justify="center">
            <v-col lg="3" v-for="(image, index) in tokenDetails.uploadedImages" :key="index">
              <div class="image-card mt-2" @click="selectImage(index)">
                <img :src="image" alt="" class="image-card-image" />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import PropertyCard from "../Common/PropertyCard.vue";
import Header from "../Header.vue";
import { mapState } from "vuex";
export default {
  name: "PreviewToken",
  props: ["title", "tokenDetails", "buttons"],
  components: {
    PropertyCard,
    Header,
  },
  data() {
    return {
      selectedImageIndex: 0,
    };
  },
  methods: {
    selectImage(index) {
      this.selectedImageIndex = index;
    },
    onLinkClick(link){
      window.open(link, '_blank');
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
};
</script>
<style scoped>
.preview-token {
  width: 100vw;
  min-height: 100%;
  /* overflow-y: scroll; */
}

.create-header {
  padding: 20px;
  color: var(--white-color);
}

.preview-body {
  width: 75%;
  height: 100%;
  padding-left: 40px;
  margin-top: 30px;
}

.create-header {
  padding-top: 2%;
  padding-left: 50px;
}

.create-header>h3 {
  background: linear-gradient(141.3deg, #00bfff 32.76%, #ff7f3f 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 50px !important;
  font-weight: 600;
}

.create-header>p {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
}

.request-img {
  width: 100%;
  height: 300px;
  border-radius: 5px;
  background: rgba(217, 217, 217, 0.1);
  position: relative;
  border: 2px solid var(--border-color);
  margin-bottom: 10px;
}

.request_image {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: contain;
}

.request-title {
  margin: 0px;
  font-size: 30px;
  font-weight: 600;
  color: white;
}

.request-description {
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(196, 196, 196, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.request-asset-nature {
  margin-top: 20px;
}

.request-asset-nature>p {
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  color: white;
}

.asset-nature {
  padding: 5px;
  background: rgba(217, 217, 217, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}

.asset-nature>p {
  font-size: 12px;
  color: white;
  margin: 0px;
  font-weight: 400;
  margin-left: 5px;
  max-lines: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.button-footer-mult {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  border-radius: 15px;
  text-transform: none;
  font-weight: 500 !important;
  font-size: 22px !important;
  font-family: "Bebas Neue", cursive;
  letter-spacing: 0;
  width: 20%;
  margin-top: 10px;
  color: white !important;
}

.btn-prev {
  background: transparent !important;
  border-radius: 15px;
  text-transform: none;
  font-weight: 500 !important;
  font-size: 22px !important;
  font-family: "Bebas Neue", cursive;
  letter-spacing: 0;
  width: 20%;
  margin-top: 10px;
  border: 1px solid #ff7f3f;
  color: white !important;
}

.image-card {
  height: 85px;
  background: rgba(196, 196, 196, 0.1);
  border: 1px solid #ff7f3f;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
}

.image-card-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
