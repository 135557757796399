import {
  getAddress,
  fetchUserAssets,
  getMarketPlaceItem,
  getAssetByTokenId,
  fetchAllNonVerifiedAssets,
  verifyAsset,
  getAllUserTokens,
  lendNFTToTheMarket,
  buyFromTheMarketplace,
  getBoughtTokens,
  rentNFTFromTheMarket,
  fetchLoggedUserAssets,
  fetchUserNonVerifiedAsset,
} from '../helpers/asset-contract'
import {
  lazyMintingRef,
  addDoc,
  getDocs,
  doc,
  getDoc,
  where,
  query,
  setDoc,
  deleteDoc,
  orderBy,
} from '../helpers/firebase-handler'
import { notificationObj } from './notification-service'
import {
  NOTIFICATION_TYPE_USER_ASSET_REQUEST,
  ADMIN_USER_ID,
  NOTIFICATION_TYPE_USER_ASSET_REQUEST_ACCEPTED,
  NOTIFICATION_OWNER_REQUESTED_ASSET,
} from '../helpers/constants'

class AssetService {
  async getUserRentedAssets(isMarket) {
    const data = await fetchUserAssets(isMarket)
    return data
  }

  async getUserMintedAsset(isMarket) {
    try {
      const data = await fetchAllNonVerifiedAssets(isMarket)
      return data
    } catch (error) {
      throw error
    }
  }

  async getAssetById(id) {
    try {
      const data = await getAssetByTokenId(id)
      return data
    } catch (err) {
      throw err
    }
  }

  async getAllNonVerifiedAssets() {
    const data = await fetchAllNonVerifiedAssets(false)
    return data
  }

  async verifyUserAsset(tokenId) {
    await verifyAsset(tokenId)
    // const notificationData = {
    //   title: `Your asset has been verified`,
    //   message: `Asset request has been accepted`,
    //   meta: {},
    // };
    // await notificationObj.sendNotification(notificationData, ADMIN_USER_ID, tokenDetails.user.uid, NOTIFICATION_TYPE_USER_ASSET_REQUEST_ACCEPTED,);
  }

  async getWalletAddress() {
    const data = await getAddress()
    return data
  }

  async createLazyMintAsset(formData) {
    await addDoc(lazyMintingRef, formData)
    // const notificationData = {
    //   title: `New Asset Added by ${formData.user.name}`,
    //   message: `User added a new asset`,
    //   meta: {},
    // };
    // await notificationObj.sendNotification(notificationData, formData.userId, ADMIN_USER_ID, NOTIFICATION_TYPE_USER_ASSET_REQUEST,);
  }

  async getAllLazyMintingAssets() {
    const q = query(lazyMintingRef)
    const querySnapshot = await getDocs(q)
    const request = []
    for (let index = 0; index < querySnapshot.docs.length; index++) {
      const element = querySnapshot.docs[index]
      const elementData = element.data()
      elementData.tokenId = element.id
      request.push(elementData)
    }
    return request
  }

  async getAllLazyMintingAssetByUser(userId) {
    const q = query(lazyMintingRef, where('userId', '==', userId))
    const querySnapshot = await getDocs(q)
    const request = []
    for (let index = 0; index < querySnapshot.docs.length; index++) {
      const element = querySnapshot.docs[index]
      const elementData = element.data()
      elementData.tokenId = element.id
      request.push(elementData)
    }
    return request
  }

  async getLazyMintById(id) {
    const q = doc(lazyMintingRef, id)
    const documentSnapshot = await getDoc(q)
    if (documentSnapshot.exists()) {
      const data = documentSnapshot.data()
      data.tokenId = documentSnapshot.id
      return data
    }
  }

  async getVerifiedAsset(isMarket) {
    const data = await fetchAllNonVerifiedAssets(isMarket)
    return data
  }

  async getAllTokens() {
    try {
      const data = await getAllUserTokens()
      return data
    } catch (error) {
      throw erro
    }
  }

  async leaseTokenToSigna(tokenId, address) {
    try {
      await lendNFTToTheMarket(tokenId, address)
    } catch (error) {
      throw error
    }
  }

  async getMarketPlaceItems() {
    try {
      const data = await getMarketPlaceItem()
      return data
    } catch (err) {
      throw err
    }
  }

  async buyFromThePlace(tokenId, price) {
    const data = await buyFromTheMarketplace(tokenId, price)
    return data
  }

  async getBoughtAssets() {
    try {
      const data = await getBoughtTokens()
      return data
    } catch (error) {
      throw error
    }
  }

  async getAllLazyMintingAssetByStatus(status) {
    try {
      const q = query(
        lazyMintingRef,
        where('verified', '==', status),
        orderBy('price', 'desc'),
      )
      const querySnapshot = await getDocs(q)
      const request = []
      for (let index = 0; index < querySnapshot.docs.length; index++) {
        const element = querySnapshot.docs[index]
        const elementData = element.data()
        elementData.tokenId = element.id
        request.push(elementData)
      }
      return request
    } catch (error) {
      console.log(error)
    }
  }

  async updateTokenId(tokenId, tokenDetails) {
    const q = doc(lazyMintingRef, tokenId)
    await setDoc(q, { verified: true }, { merge: true })
    const notificationData = {
      title: `Your asset has been verified`,
      message: `Asset request has been accepted`,
      meta: {},
    }
    await notificationObj.sendNotification(
      notificationData,
      ADMIN_USER_ID,
      tokenDetails.user.uid,
      NOTIFICATION_TYPE_USER_ASSET_REQUEST_ACCEPTED,
    )
  }

  async rentNFT(tokenId, price, expiresAt) {
    try {
      const data = await rentNFTFromTheMarket(tokenId, price, expiresAt)
      return data
    } catch (error) {
      throw error
    }
  }

  async mintRequestStatus(request_id) {
    try {
      const query = doc(lazyMintingRef, request_id)
      await setDoc(query, { mintRequest: true }, { merge: true })
    } catch (error) {
      throw error
    }
  }

  async updateLazyMint(tokenId, request) {
    const docData = doc(lazyMintingRef, tokenId)
    await setDoc(docData, request, { merge: true })
    // const notificationData = {
    //   title: `Project Owner Requested Asset`,
    //   message: `project owner requested asset`,
    //   meta: {},
    // };
    // await notificationObj.sendNotification(notificationData, request.user.uid, ADMIN_USER_ID, NOTIFICATION_OWNER_REQUESTED_ASSET,);
  }

  async fetchAllLoggedUserAsset() {
    try {
      const data = await fetchLoggedUserAssets()
      return data
    } catch (error) {
      throw error
    }
  }

  async deleteDocument(id) {
    try {
      const docRef = doc(lazyMintingRef, id)
      const data = deleteDoc(docRef)
    } catch (error) {
      console.log(error)
    }
  }

  async getUserNonVerified() {
    try {
      const data = await fetchUserNonVerifiedAsset()
      return data
    } catch (error) {
      throw error
    }
  }
}
export const assetObj = new AssetService()
