<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      v-model="visible"
      class="dialog"
      max-width="500"
    >
      <div class="card-body dialog">
        <div class="card-header">
          <v-row>
            <v-col lg="10">
              <h3>View Submitted Model</h3>
            </v-col>
            <v-col lg="2" class="mt-1">
              <div class="span" @click="close()">X</div>
            </v-col>
          </v-row>
        </div>
        <div class="file-body">
          <model-viewer
            :src="modelFile[selectedIndex]"
            ar-modes="webxr scene-viewer quick-look"
            seamless-poster
            shadow-intensity="1"
            camera-controls
            ar
            auto-rotate
            loading="eager"
            style="height: 100%; width: 100%"
          ></model-viewer>
        </div>
        <v-row class="mt-5 mb-5">
          <v-col lg="3" v-for="(model, index) in modelFile" :key="index">
            <div class="model-view" @click="changeModelIndex(index)">
              <model-viewer
                :src="model"
                ar-modes="webxr scene-viewer quick-look"
                seamless-poster
                shadow-intensity="1"
                camera-controls
                ar
                auto-rotate
                loading="eager"
                style="height: 100%; width: 100%"
              ></model-viewer>
            </div>
          </v-col>
        </v-row>
        <p class="message-title">{{ message }}</p>
        <v-btn class="submit-button" @click="downloadLink()"
          >Take Delivery Asset</v-btn
        >
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { uploadFileToBaseIPFS } from "../../helpers/ipfs-upload";
import Loader from "../Loader/Loader.vue";
import { mapState } from "vuex";
import { requestObj } from "../../services/request-service";

export default {
  name: "ViewSubmitRequest",
  props: ["visible", "modelFile", "message","request_id","user_id", "selected_data"],
  data() {
    return {
      loading: false,
      loaderText: "Uploading your model",
      selectedIndex: 0,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async onModelChange(event) {
      const file = event.target.files[0];
      this.loading = true;
      this.loaderText = "Uploading Model....";
      const url = await uploadFileToBaseIPFS(file);
      this.modelFile = url;
      this.loading = false;
    },
    async submitIdea() {
      this.loading = true;
      this.loaderText = "Submitting your request...";
      const jsonData = {
        message: this.message,
        modelFile: this.modelFile,
        requestId: this.requestId,
        user: this.user,
        userId: this.user.uid,
      };
      await requestObj.submitIdea(jsonData);
      this.loading = false;
      this.close();
    },
    async downloadLink() {
      this.$store.dispatch("setLink", this.modelFile);
      this.$router.push(`/creator/${this.selected_data.idea_id}`);
       this.$emit("close");
        this.$emit("close");
    },
    changeModelIndex(index) {
      this.selectedIndex = index;
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  components: { Loader },
};
</script>
<style scoped>
.dialog {
  background: var(--empty-color);
  padding: 20px;
}
h3 {
  background: linear-gradient(141.3deg, #eb5129 32.76%, #ffbf3f 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 30px;
  font-weight: 900;
}
.span {
  width: 20px;
  height: 20px;
  font-size: 15px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
}
.file-body {
  width: 100%;
  height: 200px;
  border: 1px dashed white;
  border-radius: 10px;
  margin: 20px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.placeholder {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.placeholder > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.placeholder > p,
.message-title {
  color: white;
  font-size: 15px;
  margin: 10px 0px;
  text-align: center;
}
.text-field {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.input {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--designer-color);
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}
.submit-button {
  background: linear-gradient(101.24deg, #ff7f3f 43.86%, #ffbf3f 86.82%);
  border-radius: 15px;
  font-weight: 600 !important;
  text-transform: none;
  font-family: "Archivo", sans-serif !important;
  width: 40%;
  color: white;
  margin-left: 30%;
}
.model-view {
  width: 100%;
  height: 100px;
  border: 1px dashed white;
  border-radius: 10px;
}
</style>
