<template>
  <div class="snackbar-container">
    <v-snackbar v-model="visible" top max-width="100%" timeout="-1" :width="width" color="#001f5f" :vertical="vertical">
      {{ text }}
      <template v-slot:action="{ attrs }" class="mt-10">
        <v-btn class="btn button mr-2 mt-10 mb-2" color="white" text v-bind="attrs" small> Connect / Create </v-btn>
         <v-btn class="mr-2 btn mt-10 mb-2" color="success" v-bind="attrs" @click="knowMore()" small> Know More </v-btn>
        <v-btn class="btn mt-10 mb-2" color="red" v-bind="attrs" @click="close()" small> Hide </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "Snackbar",
  props: ["visible", "text", "width", "vertical"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) return this.$emit("close");
      },
    },
  },
  methods: {
    close(){
      this.$emit("close");
    },
    knowMore(){
      this.$emit("close");
    }
  }
};
</script>
<style scoped>
/* .snackbar-container {
    
} */
.btn{
  font-size: 14px !important;
  text-transform: none !important;
  margin-top: 50px !important;
}
.button {
  background: linear-gradient(101.24deg, #ff7f3f 43.86%, #ffbf3f 86.82%);
  border-radius: 5px;
  font-size: 12px;
  color: white;
}
</style>
