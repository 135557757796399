<template>
  <div class="marketplace-container">
    <div class="header">
      <div class="header-title">
        <div class="main text">Marketplace</div>
      </div>
      <div class="search-editor">
        <v-row style="width: 100%">
          <v-col lg="4">
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              outlined
              class="text-field"
              dark
              solo
              dense
              background-color="transparent"
              placeholder="search...."
              @keyup="onSearchChanged"
            >
              <v-icon slot="prepend" color="#ff7f3f" style="padding-left: 10px"
                >mdi-magnify</v-icon
              >
            </v-text-field>
          </v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-col lg="2" v-bind="attrs" v-on="on">
                <div
                  @click="sortByPricing(sort.priceSort)"
                  style="
                    border-radius: 10px;
                    border: 1px solid #ff7f3f !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    background: rgba(196, 196, 196, 0.18);
                    cursor: pointer;
                    color: white;
                    padding: 5px;
                  "
                >
                  <v-icon
                    color="white"
                    class="mr-2"
                    style="cursor: pointer"
                    v-if="sort.priceSort"
                  >
                    mdi-sort-numeric-descending</v-icon
                  >
                  <v-icon
                    color="white"
                    class="mr-1"
                    style="cursor: pointer"
                    v-else
                  >
                    mdi-sort-numeric-ascending</v-icon
                  >
                  Price
                </div>
              </v-col>
            </template>
            <span>Sort By Price</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-col lg="2" v-bind="attrs" v-on="on">
                <div
                  @click="sortByModal(sort.has3DModel)"
                  style="
                    border-radius: 10px;
                    border: 1px solid #ff7f3f !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    background: rgba(196, 196, 196, 0.18);
                    cursor: pointer;
                    color: white;
                    padding: 5px;
                  "
                >
                  <v-icon
                    color="white"
                    class="mr-2"
                    style="cursor: pointer"
                    v-if="sort.has3DModel"
                  >
                    mdi-sort-numeric-ascending</v-icon
                  >
                  <v-icon
                    color="white"
                    class="mr-1"
                    style="cursor: pointer"
                    v-else
                  >
                    mdi-sort-numeric-descending</v-icon
                  >
                  3D Model
                </div>
              </v-col>
            </template>
            <span>Sort By 3D Model</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-col lg="3" v-bind="attrs" v-on="on">
                <div
                  @click="sortByDate(sort.uploadDate)"
                  style="
                    border-radius: 10px;
                    border: 1px solid #ff7f3f !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    background: rgba(196, 196, 196, 0.18);
                    cursor: pointer;
                    color: white;
                    padding: 5px;
                  "
                >
                  <v-icon
                    color="white"
                    class="mr-2"
                    style="cursor: pointer"
                    v-if="sort.uploadDate"
                  >
                    mdi-sort-numeric-ascending</v-icon
                  >
                  <v-icon
                    color="white"
                    class="mr-1"
                    style="cursor: pointer"
                    v-else
                  >
                    mdi-sort-numeric-descending</v-icon
                  >
                  Upload Date
                </div>
              </v-col>
            </template>
            <span>Sort By Uploaded Date</span>
          </v-tooltip>
        </v-row>
      </div>
    </div>
    <!-- <div class="header-title mt-10">
      <div class="sub-title">Top Digital Assets</div>
    </div>
    <div class="tag-data" style="
      width: 75%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    ">
      <div class="tag-container">
        <div :class="selectedTag === index ? 'tag-box-selected' : 'tag-box'" v-for="(tag, index) in tags" :key="index">
          <v-icon :color="selectedTag === index ? 'white' : '#ff7f3f'" size="20" style="padding-right: 5px">{{ tag.icon
          }}
          </v-icon>
          <p :class="selectedTag === index ? 'tag-text-selected' : 'tag-text'">
            {{ tag.text }}
          </p>
        </div>
      </div>
      <div class="view-data" style="cursor: pointer;" @click="goTo()">
        <p style="color: #F2F2F2;font-size: 14px;margin: 0px 0px -10px 0px;">View All</p>
        <img src="../assets/arrow.svg" width="50" />
      </div>
    </div> -->
    <div class="asset-body mt-10">
      <v-row>
        <v-col
          lg="4"
          class="token"
          v-for="(token, index) in filterTokens"
          :key="index"
        >
          <token-card-new :data="token"></token-card-new>
          <v-img :src="token">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <img src="../assets/loader.gif" width="50px" />
                <p style="margin: 0px; font-size: 14px; color: #e0e0e0">
                  Asset Loading
                </p>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </div>
    <Loader :text="loaderText" :visible="isLoading"></Loader>
    <StatusModel
      :title="statusTitle"
      :message="statusMessage"
      :status="isSuccess"
      :visible="successPopup"
      @close="close"
    >
    </StatusModel>
  </div>
</template>
<script>
import TokenCardNew from "../components/Common/TokenCardNew.vue";
import TopAssetCard from "../components/Common/TopAssetCard.vue";
import { assetObj } from "../services/asset-service";
import Loader from "../components/Loader/Loader.vue";
import StatusModel from "../components/Modals/StatusModel.vue";
import { mapState } from "vuex";

export default {
  name: "MarketPlace",
  components: {
    TokenCardNew,
    TopAssetCard,
    Loader,
    StatusModel,
  },
  data() {
    return {
      selectedTag: 0,
      tokens: [],
      topTokens: [],
      isLoading: true,
      loaderText: "Fetching Marketplace Data",
      isSuccess: false,
      successPopup: false,
      statusTitle: "",
      statusMessage: "",
      search: "",
      filterTokens: [],
      sort: {
        priceSort: true,
        uploadDate: true,
        has3DModel: true,
      },
    };
  },
  created() {
    this.fetchTokens();
  },
  methods: {
    async fetchTokens() {
      try {
        this.$store.dispatch("setGlobalPopup", true);
        this.isLoading = true;
        const data = await assetObj.getAllLazyMintingAssetByStatus(true);
        data.sort(function (a, b) {
          var nameA = parseFloat(a.price);
          var nameB = parseFloat(b.price);
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          return 0;
        });
        this.tokens = data;
        this.filterTokens = data;
        this.isLoading = false;
        this.$store.dispatch("setGlobalPopup", false);
      } catch (error) {
        this.isLoading = false;
        this.statusTitle = "Error Fetching Data";
        this.statusMessage = error.message;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
          this.$store.dispatch("setGlobalPopup", false);
        }, 3000);
      }
    },
    close() {
      this.successPopup = false;
      this.$store.dispatch("setGlobalPopup", false);
    },
    goTo() {
      this.$router.push("/allMarket");
    },
    onSearchChanged() {
      if (this.search) {
        this.filterTokens = this.tokens.filter((data) => {
          if (data.name) {
            return data.name.toLowerCase().includes(this.search.toLowerCase());
          }
        });
      }
    },
    sortByPricing(p) {
      this.sort.priceSort = !this.sort.priceSort;
      this.filterTokens.sort(function (a, b) {
        var nameA = parseFloat(a.price);
        var nameB = parseFloat(b.price);
        if (p) {
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          return 0;
        } else {
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }
      });
    },
    sortByModal(p) {
      this.sort.has3DModel = !this.sort.has3DModel;
      if(p){
        this.filterTokens = this.tokens.filter((data)=>{
          if(data.image){
            return true;
          }
        })
      }else{
        this.filterTokens = this.tokens;
      }
    },
    sortByDate(p) {
      this.sort.uploadDate = !this.sort.uploadDate;
      this.filterTokens.sort(function (a, b) {
        var nameA = parseFloat(a.model_id);
        var nameB = parseFloat(b.model_id);
        if (p) {
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          return 0;
        } else {
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }
      });
    },    
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      allAssets: (state) => state.allAssets,
    }),
  },
};
</script>
<style scoped>
.marketplace-container {
  width: 100vw;
  min-height: 100vh;
  /* background-image: url("../assets/market/market_bg.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  /* padding-bottom: 20px; */
  background-image: url("../assets/hue.svg");
}

.header {
  width: 100%;
}

.header-title {
  height: 60px;
  position: relative;
}

.main {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px !important;
  width: 40%;
}

.sub-title {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  width: 40%;
}

.header-search-box {
  margin: 20px 0px 0px 0px;
}

.input {
  width: 20%;
  border-radius: 5px;
  padding: 0px 10px;
  font-size: 16px;
  font-family: "Archivo", sans-serif;
  outline: none;
}

.tag-container {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tag-box {
  border: 1px solid var(--border-color);
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 10px 5px 10px;
  min-width: 10%;
  margin-left: 20px;
}

.tag-box-selected {
  border: 1px solid var(--border-color);
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 10px 5px 10px;
  min-width: 10%;
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  margin-left: 20px;
}

.tag-text {
  margin: 0px 0px 0px 0px;
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 600;
  font-size: 16px;
  font-family: "Archivo", sans-serif;
  cursor: pointer;
}

.tag-text-selected {
  margin: 0px 0px 0px 0px;
  color: white;
  font-weight: 600;
  font-size: 16px;
  font-family: "Archivo", sans-serif;
  cursor: pointer;
}

.asset-body {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.search-editor {
  width: 65%;
  height: 50px;
  border-color: var(--border-color) !important;
  margin: 20px 0px 20px 0px;
  padding: 5px;
}

.text-field {
  width: 100%;
  height: 40px !important;
  box-shadow: none !important;
  border-radius: 10px;
  border: 1px solid #ff7f3f !important;
  outline: none;
  color: #fff !important;
  font-family: "Archivo", sans-serif;
  background: rgba(196, 196, 196, 0.18);
  backdrop-filter: blur(50px);
}

.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.theme--light.v-text-field--outline.v-text-field--box {
  border: 1px solid #ff7f3f !important;
}
</style>
