<template>
  <div class="wallet-container">
    <div class="wallet-header">
      <v-row>
        <v-col lg="5">
          <Header
            :required="true"
            title="Wallets"
            :subtitle="
              isConnected
                ? 'You can see all your minted assets and can connect wallet'
                : ''
            "
          >
          </Header>
        </v-col>
        <!-- <v-col lg="6" v-if="isConnected">
          <div class="holder">
            <v-row>
              <v-col lg="2" v-for="(wallet, index) in wallets" :key="index">
                <div class="wallet-connect" @click="wallet.action">
                  <div class="wallet-image">
                    <img :src="wallet.image" alt="" />
                  </div>
                  <div class="wallet-name">
                    {{ wallet.title }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col> -->
      </v-row>
    </div>
    <div class="no-wallet-message">
      <p class="pt-0 pb-5" v-if="!isConnected">
        You have not connected your wallet. Choose the wallet to connect:
      </p>
      <div class="holder mt-5" style="width: 55% !important">
        <v-row>
          <v-col lg="2" v-for="(wallet, index) in wallets" :key="index">
            <div
              class="wallet-connect"
              @click="wallet.action"
              style="height: 100px"
              v-if="index != wallets.length - 1"
            >
              <div class="wallet-image">
                <img
                  :src="wallet.image"
                  alt=""
                  style="height: 70px; object-fit: contain"
                />
              </div>
              <div class="wallet-name" style="height: 30px">
                {{ wallet.title }}
              </div>
            </div>
            <div
              class="wallet-connect-final"
              @click="wallet.action"
              style="height: 100px"
              v-else
            >
              <div class="wallet-image-final">
                <img
                  :src="wallet.image"
                  alt=""
                  style="height: 70px; object-fit: contain"
                />
              </div>
              <div class="wallet-name" style="height: 30px">
                {{ wallet.title }}
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="wallet-scanning" v-if="hasWallet && scanning && isConnected">
      <img src="../assets/scanning.gif" class="scan" />
      <p>Scanning your wallet...</p>
    </div>
    <div class="market-body">
      <v-row>
        <v-col lg="4" v-for="(token, index) in tokens" :key="index">
          <token-card-new :data="token"></token-card-new>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import { walletObj } from "../services/wallet-service";
import { assetObj } from "../services/asset-service";
import TokenCardNew from "../components/Common/TokenCardNew.vue";
import { mapState } from "vuex";

export default {
  name: "Wallet",
  data() {
    return {
      hasWallet: window.ethereum ? true : false,
      scanning: true,
      tokens: [],
      wallets: [
        {
          title: "Metamask",
          image:
            "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/wallets%2Fmetamask.svg?alt=media&token=9410de28-ac56-4ed8-934a-c4eb142b0b37",
          action: async () => {
            const data = await walletObj.metamaskConfig();
            this.address = data.address;
            this.$store.dispatch("setConnection", data.isConnected);
            this.getAllTokens();
          },
        },
        {
          title: "Portis",
          image:
            "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/wallets%2Fportis.svg?alt=media&token=a8cebef8-efde-40e3-8356-42bc56a83626",
          action: async () => {
            const data = await walletObj.portisConfig();
            this.address = data.address;
            this.$store.dispatch("setConnection", data.isConnected);
            this.getAllTokens();
          },
        },
        {
          title: "Torus",
          image:
            "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/wallets%2Ftorus.svg?alt=media&token=40a3c476-5584-40e7-8e76-28134db6cef1",
          action: async () => {
            const data = await walletObj.torusConfig();
            this.address = data.address;
            this.$store.dispatch("setConnection", data.isConnected);
            this.getAllTokens();
          },
        },
        {
          title: "WalletConnect",
          image:
            "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/wallets%2Fwallet.svg?alt=media&token=3fb05652-b0c1-407c-acd5-3d90d2b5c890",
          action: async () => {
            const data = await walletObj.walletConnectConfig();
            this.address = data.address;
            this.$store.dispatch("setConnection", data.isConnected);
            this.getAllTokens();
          },
        },
        {
          title: "Caduceus",
          image:
            "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/wallets%2Fcaduceus-logo.svg?alt=media&token=561878d3-7986-4e52-87f5-f65e41d2003c",
          action: async () => {
            const data = await walletObj.caduceusConfig();
            this.address = data.address;
            this.$store.dispatch("setConnection", data.isConnected);
            this.getAllTokens();
          },
        },
      ],
      address: "",
    };
  },
  methods: {
    mintOfflineRedirect() {
      this.$router.push("/mint");
    },
    clickCreateNew() {
      window.open("https://metamask.io/download/", "_blank");
    },
    async getAllTokens() {
      try {
        this.scanning = true;
        const data = await assetObj.getAllTokens();
        const boughtTokens = await assetObj.fetchAllLoggedUserAsset();
        const token = data.concat(boughtTokens);
        this.tokens = token;
        setTimeout(() => {
          this.scanning = false;
        }, 2000);
      } catch (error) {
        console.log(error);
        setTimeout(() => {
          this.scanning = false;
        }, 2000);
      }
    },
  },
  components: { Header, TokenCardNew },
  created() {
    if (this.isConnected) {
      this.getAllTokens();
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      isConnected: (state) => state.isConnected,
    }),
  },
};
</script>
<style scoped>
.wallet-container {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/hue.svg");
  /* background-image: url("../assets/wallet/background.svg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow-y: scroll;
  overflow-x: hidden;
}

.no-wallet-message {
  margin-top: 0%;
  padding-left: 45px;
}

.img {
  width: 20%;
  height: 50%;
}

.scan {
  width: 100%;
  object-fit: contain;
}

.no-wallet-message > p {
  font-size: 16px;
  font-weight: 400;
  color: white;
  text-align: left;
  margin: 20px 0px 0px 0px;
}

.primary_button {
  background: linear-gradient(101.24deg, #ff7f3f 43.86%, #ffbf3f 86.82%);
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  width: 10%;
  margin-top: 10px;
  color: white !important;
  font-size: 18px;
}

.secondary_button {
  border: 2px solid #ff7f3f;
  border-radius: 15px;
  font-weight: 400 !important;
  text-transform: none;
  font-family: "Bebas Neue", cursive !important;
  width: 10%;
  margin-top: 10px;
  background: transparent !important;
  color: white !important;
  margin-left: 30px;
}

.wallet-header {
  padding-left: 20px;
  width: 100%;
  height: 100px;
  padding-top: 2%;
  text-align: left;
}

.wallet-title {
  background: linear-gradient(141.3deg, #eb5129 32.76%, #ffbf3f 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0px;
  font-size: 50px !important;
}

.wallet-description {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
}

.holder {
  width: 100%;
  height: 100px;
}

.wallet-connect {
  width: 100%;
  height: 80px;
  border: 1px solid #ff7f3f;
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: rgba(196, 196, 196, 0.2);
  cursor: pointer;
}
.wallet-connect-final {
  width: 100%;
  height: 80px;
  border: 1px solid #ff7f3f;
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: white;
  cursor: pointer;
}
.wallet-image > img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.wallet-image-final > img {
  width: 80px;
  height: 60px;
  object-fit: contain;
}
.wallet-name {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 200 !important;
  color: white;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(101.24deg, #ff7f3f 43.86%, #ffbf3f 86.82%);
  font-family: "Bebas Neue", cursive !important;
}

.market-body {
  width: 70%;
  margin-top: 70px;
  margin-left: 20px;
}

.wallet-scanning {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0%;
  padding-left: 25%;
  padding-right: 25%;
}

.wallet-scanning > p {
  margin: -60px 0px 0px 0px;
  font-size: 30px;
  color: #ff7f3f;
  font-family: "Orbitron", sans-serif;
}
</style>
