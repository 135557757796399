import {
  addDoc,
  query,
  getDocs,
  getDoc,
  where,
  doc,
  setDoc,
  contactMessageRef,
  orderBy
} from "../helpers/firebase-handler";
import { userObj } from './user-service';

class ContactServices {
  async sendMessage(data) {
    try {
      const newJson = {
        ...data,
        createdAt: new Date(),
      };
      const query = doc(contactMessageRef, data.userId);
      await setDoc(query, newJson);
    } catch (error) {
      throw error;
    }
  }

  async adminMessage(data) {
    try {
      const newJson = {
        ...data,
        createdAt: new Date(),
      };
      const query = doc(contactMessageRef, data.userId);
      await setDoc(query, newJson);
    } catch (error) {
      throw error;
    }
  }

  async getMessagesFromUserId(userId) {
    try {
      const q = doc(contactMessageRef, userId);
      const messages = await getDoc(q);
      if (messages.exists()) {
        const data = messages.data();
        data.userId = messages.id;
        return [data];
      }
      return [];
    } catch (error) {
      throw error;
    }
  }

  async getAllMessages() {
    try {
      const q = query(contactMessageRef, orderBy('sentTime','desc'))
      const querySnapshot = await getDocs(q);
      const messages = [];
      for (let index = 0; index < querySnapshot.docs.length; index++) {
        const element = querySnapshot.docs[index];
        const elementData = element.data();
        const userData = await userObj.getUser(element.id);
        if(userData.exists()){
          elementData.user = userData.data();
          messages.push(elementData);
        }
      }
      return messages;
    } catch (error) {
      throw error;
    }
  }
}
export const contactObj = new ContactServices();
