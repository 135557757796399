<template>
  <v-app>
    <v-main>
      <v-container class="py-0 fill-height" fluid style="overflow: hidden">
        <v-row class="fill-height">
          <v-col style="padding: 0px">
            <video-bg :sources="['https://asset-signax.b-cdn.net/platform/Pexels%20Videos%202611250.mp4']"
              img="demo/assets/bg.jpg">
              <layout v-if="user !== null && typeof user === 'object' && !$route.fullPath.includes('public')"></layout>
              <transition name="fade" mode="out-in">
                <router-view :key="$route.fullPath"></router-view>
              </transition>
            </video-bg>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <snackbar
      :text="'No wallet detected. Please connect to a wallet or Create a new wallet. You can still perform some of the actions using offline mode. Click on Know More to learn more.'"
      :visible="false" width="90vw" :vertical="false" @close="close"></snackbar>
  </v-app>
</template>

<script>
import Layout from "./components/Layout.vue";
import { mapState } from "vuex";
import { handleAuth } from "./helpers/auth-handler";
import Snackbar from "./components/Snackbar.vue";
import VideoBg from './components/Common/VideoBackground.vue'

export default {
  name: "App",
  data: () => ({
    showWalletNotifier: false,
  }),
  components: { Layout, Snackbar, VideoBg  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      isGlobalPopup: (state) => state.isGlobalPopup,
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await handleAuth();
      if (
        !window.ethereum &&
        this.$router.currentRoute.path !== "/" &&
        this.$router.currentRoute.path !== "/register"
      ) {
        this.showWalletNotifier = true;
      }
    },
    close() {
      this.showWalletNotifier = false;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap");

:root {
  --primary-color: #001f5f;
  --secondary-color: #00ffbf;
  --empty-color: #060e575b;
  --nav-bar-color: #001f5f;
  --designer-color: #eb5129;
  --vs-border-style: none;
  --border-color: #ff7f3f;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  /* background: var(--primary-color) !important; */
}

#app {
  font-family: "Archivo", sans-serif;
  /* background: var(--primary-color) !important; */
  scrollbar-width: none !important;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateX(2em);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  display: none !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e8e8e860;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e8e8e8;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e8e8e8;
}

.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
  border-color: transparent !important;
  outline: none !important;
}

.theme--light.v-label {
  color: white;
  border-color: transparent !important;
  outline: none !important;
}

.theme--light.v-input:not(.v-input--is-disabled) input,
.theme--light.v-input:not(.v-input--is-disabled) textarea {
  color: white;
  border-color: transparent !important;
  outline: none !important;
}

.button {
  font-family: "Bebas Neue", cursive;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  letter-spacing: 0.8px !important;
}

.v-select .dropdown-toggle {
  border: none;
}

.button:disabled {
  background-color: #00ffbf98 !important;
  cursor: not-allowed;
}

.general-text-title {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px !important;
}

.secondary-heading {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif !important;
}

.v-text-field--outlined fieldset {
  border: none !important;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
  box-shadow: none !important;
}

.top-title {
  font-size: 25px !important;
  font-weight: 800 !important;
}
</style>
