import { ethers } from "ethers";
import axios from "axios";
import Web3Modal from "web3modal";
import SystemContact from "../../artifacts/contracts/ModifiedContract.sol/ModifiedContract.json";
import { SYSTEM_CONTRACT_ADDRESS } from "../config";
import { calculateTimestampFromHours } from "./constants";

const providerOptions = {};

const SIGN_IN_MESSAGE =
  "Signa X is the digital asset creation and tokenization platform for the metaverse and enterprise worlds. We optimize the entire digital asset production process to make it seamless for projects to acquire assets at scale.";

export const fetchUserAssets = async (isMarket) => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(
      `https://eth-goerli.alchemyapi.io/v2/EgGVx6bojPRZNe9_5TNlynd2Hp_xo9t6`
    );
    // const provider = new ethers.providers.JsonRpcProvider(
    //   `https://eth-ropsten.alchemyapi.io/v2/NG9LT2SmUX64f6vg_Bj1i4GCZddKwso6`
    // );
    // const provider = new ethers.providers.JsonRpcProvider();
    const contract = new ethers.Contract(
      SYSTEM_CONTRACT_ADDRESS,
      SystemContact.abi,
      provider
    );
    const data = await contract.getAllRentedToken();
    if (data.length === 0 || data[0][0] == 0) return [];
    const items = await Promise.all(
      data.map(async (i) => {
        const tokenUri = await contract.tokenURI(i.tokenId);
        const meta = await axios.get(tokenUri);
        let price = ethers.utils.formatUnits(i.price.toString(), "ether");
        const sellerId = `${i.seller.toString().substring(0, 5)}...${i.seller
          .toString()
          .substring(
            i.seller.toString().length - 5,
            i.seller.toString().length
          )}`;
        const hashedAddress = `${i.assetAddress.substring(
          0,
          5
        )}...${i.assetAddress.substring(
          i.assetAddress.length - 5,
          i.assetAddress.length
        )}`;
        let days = 0;
        const value = meta.data.duration;
        const type = meta.data.durationUnits;
        if (type === "hours") {
          days = value / 24;
        } else if (type === "weeks") {
          days = value * 7;
        } else if (type === "months") {
          days = value * 30;
        } else if (type === "years") {
          days = value * 365;
        } else {
          days = value;
        }
        let item = {
          price,
          tokenId: i.tokenId.toNumber(),
          seller: sellerId,
          owner: i.owner,
          image:
            meta.data.type === "png" ||
              meta.data.type === "jpeg" ||
              meta.data.type === "jpg"
              ? meta.data.image
              : isMarket
                ? meta.data.image
                : meta.data.animation_url,
          name: meta.data.name,
          description: meta.data.description,
          isRented: i.isRented,
          isLent: i.isLent,
          type: meta.data.type,
          placeholder: meta.data.placeholder,
          selectedAssetType: meta.data.selectedAssetType,
          attributes: meta.data.attributes,
          originalSeller: i.seller,
          sold: i.sold,
          isVerified: i.isVerified,
          assetAddress: i.assetAddress,
          shortAddress: hashedAddress,
          user: meta.data.user,
          category: meta.data.assetCategories,
          fileFormats: meta.data.availableFileFormats,
        };
        return item;
      })
    );
    return items;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const createUserAssets = async (formInput, url) => {
  try {
    const web3Modal = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions: providerOptions,
    });
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    await signer.signMessage(SIGN_IN_MESSAGE);

    // create the NFT
    const price = ethers.utils.parseUnits(`${formInput.price}`, "ether");
    let contract = new ethers.Contract(
      SYSTEM_CONTRACT_ADDRESS,
      SystemContact.abi,
      signer
    );
    let listingPrice = await contract.getlisting_price();
    listingPrice = listingPrice.toString();
    let transaction = await contract.createToken(url, price);
    const transactionData = await transaction.wait();
    return transactionData;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const fetchLoggedUserAssets = async (isMarket) => {
  try {
    const web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions: providerOptions,
    });
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      SYSTEM_CONTRACT_ADDRESS,
      SystemContact.abi,
      signer
    );
    const data = await contract.fetchItemListedByUser();
    if (data.length === 0 || data[0][0] == 0) return [];
    const items = await Promise.all(
      data.map(async (i) => {
        const tokenUri = await contract.tokenURI(i.tokenId);
        const meta = await axios.get(tokenUri);
        let price = ethers.utils.formatUnits(i.price.toString(), "ether");
        const sellerId = `${i.seller.toString().substring(0, 5)}...${i.seller
          .toString()
          .substring(
            i.seller.toString().length - 5,
            i.seller.toString().length
          )}`;
        const hashedAddress = `${i.assetAddress.substring(
          0,
          5
        )}...${i.assetAddress.substring(
          i.assetAddress.length - 5,
          i.assetAddress.length
        )}`;
        let item = {
          price,
          tokenId: i.tokenId.toNumber(),
          seller: sellerId,
          owner: i.owner,
          image:
            meta.data.type === "png" ||
              meta.data.type === "jpeg" ||
              meta.data.type === "jpg"
              ? meta.data.image
              : isMarket
                ? meta.data.image
                : meta.data.animation_url,
          name: meta.data.name,
          description: meta.data.description,
          isRented: i.isRented,
          isLent: i.isLent,
          type: meta.data.type,
          placeholder: meta.data.placeholder,
          selectedAssetType: meta.data.selectedAssetType,
          attributes: meta.data.attributes,
          originalSeller: i.seller,
          sold: i.sold,
          isVerified: i.isVerified,
          assetAddress: i.assetAddress,
          shortAddress: hashedAddress,
          user: meta.data.user,
          category: meta.data.assetCategories,
          fileFormats: meta.data.availableFileFormats,
        };
        return item;
      })
    );
    return items;
  } catch (error) {
    throw error;
    return [];
  }
};

export const lendNFTToTheMarket = async (tokenId, assetAddress) => {
  try {
    const web3Modal = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions: providerOptions,
    });
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    await signer.signMessage(SIGN_IN_MESSAGE);
    let contract = new ethers.Contract(
      SYSTEM_CONTRACT_ADDRESS,
      SystemContact.abi,
      signer
    );
    let transaction = await contract.leaseTokenToSigna(tokenId, assetAddress);
    const transactionData = await transaction.wait();
    return transactionData;
  } catch (error) {
    throw error;
  }
};

export const rentNFTFromTheMarket = async (tokenId, price, expiresTime) => {
  try {
    const web3Modal = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions: providerOptions,
    });
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const contract = new ethers.Contract(
      SYSTEM_CONTRACT_ADDRESS,
      SystemContact.abi,
      signer
    );
    const cumulativePrice = ethers.utils.parseUnits(`${price}`, "ether");
    const expiresAt = calculateTimestampFromHours(expiresTime);
    let transaction = await contract.rentToken(address, tokenId, expiresAt, {
      value: cumulativePrice,
    });
    const transactionData = await transaction.wait();
    return transactionData;
  } catch (error) {
    throw error;
  }
};

export const getAllUserRentedAssets = async (isMarket) => {
  try {
    const web3Modal = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions: providerOptions,
    });
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      SYSTEM_CONTRACT_ADDRESS,
      SystemContact.abi,
      signer
    );
    const data = await contract.getAllRentedTokenByUserAddress();
    if (data.length === 0 || data[0][0] == 0) return [];
    const items = await Promise.all(
      data.map(async (i) => {
        const tokenUri = await contract.tokenURI(i.tokenId);
        const meta = await axios.get(tokenUri);
        let price = ethers.utils.formatUnits(i.price.toString(), "ether");
        const sellerId = `${i.seller.toString().substring(0, 5)}...${i.seller
          .toString()
          .substring(
            i.seller.toString().length - 5,
            i.seller.toString().length
          )}`;
        const hashedAddress = `${i.assetAddress.substring(
          0,
          5
        )}...${i.assetAddress.substring(
          i.assetAddress.length - 5,
          i.assetAddress.length
        )}`;
        let days = 0;
        const value = meta.data.duration;
        const type = meta.data.durationUnits;
        if (type === "hours") {
          days = value / 24;
        } else if (type === "weeks") {
          days = value * 7;
        } else if (type === "months") {
          days = value * 30;
        } else if (type === "years") {
          days = value * 365;
        } else {
          days = value;
        }
        let item = {
          price,
          tokenId: i.tokenId.toNumber(),
          seller: sellerId,
          owner: i.owner,
          image:
            meta.data.type === "png" ||
              meta.data.type === "jpeg" ||
              meta.data.type === "jpg"
              ? meta.data.image
              : isMarket
                ? meta.data.image
                : meta.data.animation_url,
          name: meta.data.name,
          description: meta.data.description,
          isRented: i.isRented,
          isLent: i.isLent,
          type: meta.data.type,
          placeholder: meta.data.placeholder,
          selectedAssetType: meta.data.selectedAssetType,
          attributes: meta.data.attributes,
          originalSeller: i.seller,
          sold: i.sold,
          isVerified: i.isVerified,
          assetAddress: i.assetAddress,
          shortAddress: hashedAddress,
          user: meta.data.user,
          category: meta.data.assetCategories,
          fileFormats: meta.data.availableFileFormats,
        };
        return item;
      })
    );
    return items;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getAddress = async () => {
  const web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    providerOptions: providerOptions,
  });
  const connection = await web3Modal.connect();
  const provider = new ethers.providers.Web3Provider(connection);
  const signer = provider.getSigner();
  const address = await signer.getAddress();
  return address;
};

export const getAssetByTokenId = async (tokenId) => {
  try {
    const web3Modal = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions: providerOptions,
    });
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      SYSTEM_CONTRACT_ADDRESS,
      SystemContact.abi,
      signer
    );
    const i = await contract.getTokenDetails(tokenId);
    const tokenUri = await contract.tokenURI(i.tokenId);
    const meta = await axios.get(tokenUri);
    let price = ethers.utils.formatUnits(i.price.toString(), "ether");
    const sellerId = `${i.seller.toString().substring(0, 5)}...${i.seller
      .toString()
      .substring(i.seller.toString().length - 5, i.seller.toString().length)}`;
    const hashedAddress = `${i.assetAddress.substring(
      0,
      5
    )}...${i.assetAddress.substring(
      i.assetAddress.length - 5,
      i.assetAddress.length
    )}`;
    let days = 0;
    const value = meta.data.duration;
    const type = meta.data.durationUnits;
    if (type === "hours") {
      days = value / 24;
    } else if (type === "weeks") {
      days = value * 7;
    } else if (type === "months") {
      days = value * 30;
    } else if (type === "years") {
      days = value * 365;
    } else {
      days = value;
    }
    let item = {
      price,
      tokenId: i.tokenId.toNumber(),
      seller: sellerId,
      owner: i.owner,
      image:
        meta.data.type === "png" ||
          meta.data.type === "jpeg" ||
          meta.data.type === "jpg"
          ? meta.data.image
          : meta.data.animation_url,
      name: meta.data.name,
      description: meta.data.description,
      isRented: i.isRented,
      isLent: i.isLent,
      type: meta.data.type,
      placeholder: meta.data.placeholder,
      selectedAssetType: meta.data.selectedAssetType,
      attributes: meta.data.attributes,
      originalSeller: i.seller,
      sold: i.sold,
      isVerified: i.isVerified,
      assetAddress: i.assetAddress,
      shortAddress: hashedAddress,
      user: meta.data.user,
      category: meta.data.assetCategories,
      fileFormats: meta.data.availableFileFormats,
    };
    return item;
  } catch (err) {
    throw err;
  }
};

export const getAllMarketPlaceAssets = async (isMarket) => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(
      `https://eth-ropsten.alchemyapi.io/v2/NG9LT2SmUX64f6vg_Bj1i4GCZddKwso6`
    );
    // const provider = new ethers.providers.JsonRpcProvider();
    const contract = new ethers.Contract(
      SYSTEM_CONTRACT_ADDRESS,
      SystemContact.abi,
      provider
    );
    const data = await contract.fetchMarketItems();
    console.log(data);
    if (data.length === 0 || data[0][0] == "0") return [];
    const items = await Promise.all(
      data.map(async (i) => {
        const tokenUri = await contract.tokenURI(i.tokenId);
        const meta = await axios.get(tokenUri);
        let price = ethers.utils.formatUnits(i.price.toString(), "ether");
        const sellerId = `${i.seller.toString().substring(0, 5)}...${i.seller
          .toString()
          .substring(
            i.seller.toString().length - 5,
            i.seller.toString().length
          )}`;
        const hashedAddress = `${i.assetAddress.substring(
          0,
          5
        )}...${i.assetAddress.substring(
          i.assetAddress.length - 5,
          i.assetAddress.length
        )}`;
        let days = 0;
        const value = meta.data.duration;
        const type = meta.data.durationUnits;
        if (type === "hours") {
          days = value / 24;
        } else if (type === "weeks") {
          days = value * 7;
        } else if (type === "months") {
          days = value * 30;
        } else if (type === "years") {
          days = value * 365;
        } else {
          days = value;
        }
        let item = {
          price,
          tokenId: i.tokenId.toNumber(),
          seller: sellerId,
          owner: i.owner,
          image:
            meta.data.type === "png" ||
              meta.data.type === "jpeg" ||
              meta.data.type === "jpg"
              ? meta.data.image
              : isMarket
                ? meta.data.image
                : meta.data.animation_url,
          name: meta.data.name,
          description: meta.data.description,
          isRented: i.isRented,
          isLent: i.isLent,
          type: meta.data.type,
          placeholder: meta.data.placeholder,
          selectedAssetType: meta.data.selectedAssetType,
          attributes: meta.data.attributes,
          originalSeller: i.seller,
          sold: i.sold,
          isVerified: i.isVerified,
          assetAddress: i.assetAddress,
          shortAddress: hashedAddress,
          user: meta.data.user,
          category: meta.data.assetCategories,
          fileFormats: meta.data.availableFileFormats,
        };
        return item;
      })
    );
    return items;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const buyFromTheMarketplace = async (tokenId, assetPrice) => {
  try {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    await signer.signMessage(SIGN_IN_MESSAGE);
    let contract = new ethers.Contract(
      SYSTEM_CONTRACT_ADDRESS,
      SystemContact.abi,
      signer
    );
    const updatedPrice = parseFloat(assetPrice.toString()) + 0.025;
    const price = ethers.utils.parseUnits(updatedPrice.toString(), "ether");
    let transaction = await contract.buyToken(tokenId, {
      value: price,
    });
    const transactionData = await transaction.wait();
    return transactionData;
  } catch (error) {
    return undefined;
  }
};

export const fetchAllNonVerifiedAssets = async (isMarket) => {
  try {
    const web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions: providerOptions,
    });
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      SYSTEM_CONTRACT_ADDRESS,
      SystemContact.abi,
      signer
    );
    const data = await contract.fetchNonVerifiedItemsForAdmin();
    if (data.length === 0 || data[0][0] == 0) return [];
    const items = await Promise.all(
      data.map(async (i) => {
        const tokenUri = await contract.tokenURI(i.tokenId);
        const meta = await axios.get(tokenUri);
        let price = ethers.utils.formatUnits(i.price.toString(), "ether");
        const sellerId = `${i.seller.toString().substring(0, 5)}...${i.seller
          .toString()
          .substring(
            i.seller.toString().length - 5,
            i.seller.toString().length
          )}`;
        const hashedAddress = `${i.assetAddress.substring(
          0,
          5
        )}...${i.assetAddress.substring(
          i.assetAddress.length - 5,
          i.assetAddress.length
        )}`;
        let item = {
          price,
          tokenId: i.tokenId.toNumber(),
          seller: sellerId,
          owner: i.owner,
          image: meta.data.image,
          name: meta.data.name,
          description: meta.data.description,
          isRented: i.isRented,
          isLent: i.isLent,
          type: meta.data.type,
          placeholder: meta.data.placeholder,
          selectedAssetType: meta.data.selectedAssetType,
          attributes: meta.data.attributes,
          originalSeller: i.seller,
          sold: i.sold,
          isVerified: i.isVerified,
          assetAddress: i.assetAddress,
          shortAddress: hashedAddress,
          user: meta.data.user,
          category: meta.data.assetCategories,
          fileFormats: meta.data.availableFileFormats,
        };
        return item;
      })
    );
    return items;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const verifyAsset = async (tokenId) => {
  try {
    const web3Modal = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions: providerOptions,
    });
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    await signer.signMessage(SIGN_IN_MESSAGE);
    let contract = new ethers.Contract(
      SYSTEM_CONTRACT_ADDRESS,
      SystemContact.abi,
      signer
    );
    let transaction = await contract.verifyAssetFromUser(tokenId);
    const transactionData = await transaction.wait();
    return transactionData;
  } catch (error) {
    return undefined;
  }
};

export const getAllUserTokens = async () => {
  // getAllUserTokens
  try {
    const web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions: providerOptions,
    });
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      SYSTEM_CONTRACT_ADDRESS,
      SystemContact.abi,
      signer
    );
    const data = await contract.getAllUserTokens();
    if (data.length === 0 || data[0][0] == 0) return [];
    const items = await Promise.all(
      data.map(async (i) => {
        const tokenUri = await contract.tokenURI(i.tokenId);
        const meta = await axios.get(tokenUri);
        let price = ethers.utils.formatUnits(i.price.toString(), "ether");
        const sellerId = `${i.seller.toString().substring(0, 5)}...${i.seller
          .toString()
          .substring(
            i.seller.toString().length - 5,
            i.seller.toString().length
          )}`;
        const hashedAddress = `${i.assetAddress.substring(
          0,
          5
        )}...${i.assetAddress.substring(
          i.assetAddress.length - 5,
          i.assetAddress.length
        )}`;
        let item = {
          price,
          tokenId: i.tokenId.toNumber(),
          seller: sellerId,
          owner: i.owner,
          image: meta.data.image,
          name: meta.data.name,
          description: meta.data.description,
          isRented: i.isRented,
          isLent: i.isLent,
          type: meta.data.type,
          placeholder: meta.data.placeholder,
          selectedAssetType: meta.data.selectedAssetType,
          attributes: meta.data.attributes,
          originalSeller: i.seller,
          sold: i.sold,
          isVerified: i.isVerified,
          assetAddress: i.assetAddress,
          shortAddress: hashedAddress,
          user: meta.data.user,
          category: meta.data.assetCategories,
          fileFormats: meta.data.availableFileFormats,
        };
        return item;
      })
    );
    return items;
  } catch (error) {
    return [];
  }
}

export const getMarketPlaceItem = async () => {
  // getAllUserTokens
  try {
    const web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions: providerOptions,
    });
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      SYSTEM_CONTRACT_ADDRESS,
      SystemContact.abi,
      signer
    );
    const data = await contract.getMarketPlaceItem();
    if (data.length === 0 || data[0][0] == 0) return [];
    const items = await Promise.all(
      data.map(async (i) => {
        const tokenUri = await contract.tokenURI(i.tokenId);
        const meta = await axios.get(tokenUri);
        let price = ethers.utils.formatUnits(i.price.toString(), "ether");
        const sellerId = `${i.seller.toString().substring(0, 5)}...${i.seller
          .toString()
          .substring(
            i.seller.toString().length - 5,
            i.seller.toString().length
          )}`;
        const hashedAddress = `${i.assetAddress.substring(
          0,
          5
        )}...${i.assetAddress.substring(
          i.assetAddress.length - 5,
          i.assetAddress.length
        )}`;
        let item = {
          price,
          tokenId: i.tokenId.toNumber(),
          seller: sellerId,
          owner: i.owner,
          image: meta.data.image,
          name: meta.data.name,
          description: meta.data.description,
          isRented: i.isRented,
          isLent: i.isLent,
          type: meta.data.type,
          placeholder: meta.data.placeholder,
          selectedAssetType: meta.data.selectedAssetType,
          attributes: meta.data.attributes,
          originalSeller: i.seller,
          sold: i.sold,
          isVerified: i.isVerified,
          assetAddress: i.assetAddress,
          shortAddress: hashedAddress,
          user: meta.data.user,
          category: meta.data.assetCategories,
          fileFormats: meta.data.availableFileFormats,
        };
        return item;
      })
    );
    return items;
  } catch (error) {
    throw error;
  }
}

export const getBoughtTokens = async () => {
  try {
    const web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions: providerOptions,
    });
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      SYSTEM_CONTRACT_ADDRESS,
      SystemContact.abi,
      signer
    );
    const data = await contract.fetchBoughtTokens();
    if (data.length === 0 || data[0][0] == 0) return [];
    const items = await Promise.all(
      data.map(async (i) => {
        const tokenUri = await contract.tokenURI(i.tokenId);
        const meta = await axios.get(tokenUri);
        let price = ethers.utils.formatUnits(i.price.toString(), "ether");
        const sellerId = `${i.seller.toString().substring(0, 5)}...${i.seller
          .toString()
          .substring(
            i.seller.toString().length - 5,
            i.seller.toString().length
          )}`;
        const hashedAddress = `${i.assetAddress.substring(
          0,
          5
        )}...${i.assetAddress.substring(
          i.assetAddress.length - 5,
          i.assetAddress.length
        )}`;
        let item = {
          price,
          tokenId: i.tokenId.toNumber(),
          seller: sellerId,
          owner: i.owner,
          image: meta.data.image,
          name: meta.data.name,
          description: meta.data.description,
          isRented: i.isRented,
          isLent: i.isLent,
          type: meta.data.type,
          placeholder: meta.data.placeholder,
          selectedAssetType: meta.data.selectedAssetType,
          attributes: meta.data.attributes,
          originalSeller: i.seller,
          sold: i.sold,
          isVerified: i.isVerified,
          assetAddress: i.assetAddress,
          shortAddress: hashedAddress,
          user: meta.data.user,
          category: meta.data.assetCategories,
          fileFormats: meta.data.availableFileFormats,
        };
        return item;
      })
    );
    return items;
  } catch (error) {
    return [];
  }
}

export const fetchUserNonVerifiedAsset = async (isMarket) => {
  try {
    const web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions: providerOptions,
    });
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      SYSTEM_CONTRACT_ADDRESS,
      SystemContact.abi,
      signer
    );
    const data = await contract.fetchNonVerifiedItems();
    if (data.length === 0 || data[0][0] == 0) return [];
    const items = await Promise.all(
      data.map(async (i) => {
        const tokenUri = await contract.tokenURI(i.tokenId);
        const meta = await axios.get(tokenUri);
        let price = ethers.utils.formatUnits(i.price.toString(), "ether");
        const sellerId = `${i.seller.toString().substring(0, 5)}...${i.seller
          .toString()
          .substring(
            i.seller.toString().length - 5,
            i.seller.toString().length
          )}`;
        const hashedAddress = `${i.assetAddress.substring(
          0,
          5
        )}...${i.assetAddress.substring(
          i.assetAddress.length - 5,
          i.assetAddress.length
        )}`;
        let item = {
          price,
          tokenId: i.tokenId.toNumber(),
          seller: sellerId,
          owner: i.owner,
          image: meta.data.image,
          name: meta.data.name,
          description: meta.data.description,
          isRented: i.isRented,
          isLent: i.isLent,
          type: meta.data.type,
          placeholder: meta.data.placeholder,
          selectedAssetType: meta.data.selectedAssetType,
          attributes: meta.data.attributes,
          originalSeller: i.seller,
          sold: i.sold,
          isVerified: i.isVerified,
          assetAddress: i.assetAddress,
          shortAddress: hashedAddress,
          user: meta.data.user,
          category: meta.data.assetCategories,
          fileFormats: meta.data.availableFileFormats,
        };
        return item;
      })
    );
    return items;
  } catch (error) {
    console.log(error);
    throw error;
  }
};