import { VApp } from 'vuetify/lib/components/VApp';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VMain,[_c(VContainer,{staticClass:"py-0 fill-height",staticStyle:{"overflow":"hidden"},attrs:{"fluid":""}},[_c(VRow,{staticClass:"fill-height"},[_c(VCol,{staticStyle:{"padding":"0px"}},[_c('video-bg',{attrs:{"sources":['https://asset-signax.b-cdn.net/platform/Pexels%20Videos%202611250.mp4'],"img":"demo/assets/bg.jpg"}},[(_vm.user !== null && typeof _vm.user === 'object' && !_vm.$route.fullPath.includes('public'))?_c('layout'):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)],1)],1)],1)],1),_c('snackbar',{attrs:{"text":'No wallet detected. Please connect to a wallet or Create a new wallet. You can still perform some of the actions using offline mode. Click on Know More to learn more.',"visible":false,"width":"90vw","vertical":false},on:{"close":_vm.close}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }