import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"summarize-container"},[_c('Header',{attrs:{"required":true,"title":'Payment Gateway',"subtitle":""}}),_c('div',{staticClass:"summary-body mt-10 mb-10"},[_vm._m(0),_c(VRow,{staticClass:"ml-5 mt-0 mb-0"},[_c(VCol,{attrs:{"lg":"2"}},[_c('div',{staticClass:"model-card"},[_c('img',{staticStyle:{"height":"100%","width":"100%"},attrs:{"src":_vm.tokenData.placeholder}})])])],1),_c('p',{staticClass:"title mt-10 ml-10 mb-0"},[_vm._v("Description")]),_c('p',{staticClass:"description ml-10 mt-2"},[_vm._v(_vm._s(_vm.tokenData.description))]),_c('p',{staticClass:"title mt-10 ml-10 mb-0"},[_vm._v("Payment Details")]),_c(VRow,{staticClass:"ml-7"},[_c(VCol,{attrs:{"md":"3","lg":"1"}},[_c('p',{staticClass:"sub-title mt-0 mb-0"},[_vm._v("Total")]),(_vm.tokenData.details.activityType === 'Leasing')?_c('div',{staticClass:"asset-nature"},[_c(VIcon,{attrs:{"color":"#ff7f3f","size":"20"}},[_vm._v("mdi-currency-usd")]),_c('p',[_vm._v(_vm._s(parseFloat(_vm.tokenData.price) * parseFloat(_vm.tokenData.details.rentDays)))])],1):_c('div',{staticClass:"asset-nature"},[_c(VIcon,{attrs:{"color":"#ff7f3f","size":"20"}},[_vm._v("mdi-currency-usd")]),_c('p',[_vm._v(_vm._s(parseFloat(_vm.tokenData.price)))])],1)])],1),_c(VRow,{staticClass:"ml-5"},[_c(VCol,{attrs:{"lg":"2"}},[_c(VBtn,{staticClass:"btn",on:{"click":function($event){return _vm.openPaymentModal()}}},[_vm._v("Pay Now")])],1)],1)],1),_c('PaymentModal',{attrs:{"visible":_vm.paymentModal,"request":_vm.tokenData},on:{"close":_vm.closePaymentModal}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-header"},[_c('p',{staticClass:"title ml-10 mb-5"},[_vm._v("Uploaded Asset (s)")])])
}]

export { render, staticRenderFns }