import {
  signInWithEmailAndPassword,
  firebaseAuth,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  doc,
  users,
  getDoc,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from "../helpers/firebase-handler";

class AuthServices {
  async login(email, password) {
    try {
      const user = await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      );
      const userId = user.user.uid;
      const query = doc(users, userId);
      const userData = await getDoc(query);
      if (userData.exists()) {
        const verified = userData.data().verified;
        if (verified) {
          const data = {
            status: true,
            uid: user.user.uid,
            user: userData.data(),
          };
          return data;
        } else {
          // await this.logout();
          const data = {
            status: false,
            uid: user.user.uid,
            user: userData.data(),
          };
          return data;
        }
      } else {
        const data = {
          status: false,
          uid: null,
          user: null,
        };
        return data;
      }
    } catch (error) {
      const data = {
        status: false,
        uid: null,
        user: null,
      };
      return data;
    }
  }

  async loginWithGoogle() {
    const provider = new GoogleAuthProvider();
    const user = await signInWithPopup(firebaseAuth, provider);
    const userId = user.user.uid;
    const query = doc(users, userId);
    const userData = await getDoc(query);
    if (userData.exists()) {
      const verified = userData.data().verified;
      if (verified) {
        const data = {
          status: true,
          uid: user.user.uid,
          user: userData.data(),
        };
        return data;
      } else {
        const data = {
          status: false,
          uid: user.user.uid,
          user: null,
        };
        return data;
      }
    } else {
      const data = {
        status: false,
        uid: user.user.uid,
        user: {
          name: user.user.displayName,
          email: user.user.email,
          photoUrl: user.user.photoURL,
        },
      };
      return data;
    }
  }

  async logout() {
    try {
      const response = await signOut(firebaseAuth);
      localStorage.clear();
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  isLoggedIn() {
    return new Promise((resolve) => {
      onAuthStateChanged(firebaseAuth, (user) => {
        if (user) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  async sendEmailVerification(email){
    try{
      await sendPasswordResetEmail(firebaseAuth, email);
    }catch(error){
      console.log(error);
      throw error;
    }
  }
}
export const authObj = new AuthServices();
