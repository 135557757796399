<template>
    <div>
        <v-dialog v-model="visible" persistent max-width="500" class="dialog">
            <div class="card-body dialog">
                <v-row style="justify-content: space-between; width: 100%;">
                     <v-col lg="11" sm="11" md="11" cols="10">
                    </v-col>
                    <v-col lg="1">
                        <v-icon color="white" size="20" class="mt-2" @click="close">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <div class="header mb-10">
                    <h3 class="header title">Terms of Use</h3>
                </div>
                <div class="content">
                    <p class="sub-title">Last revised on 1 August 2022</p>
                    <p class="content-heading">1. Introduction </p>
                    <p class="content-sub-heading">These Terms (as defined below) form an agreement and define the
                        contractual relationship between you and the Company. Please read the Terms carefully. The
                        Company reserves the right to amend the Terms at any time by posting the revised Terms. Unless
                        otherwise stated in the Terms, the revised Terms shall be effective as indicated by the “Last
                        revised” date indicated at the beginning of the Terms. Users are responsible for keeping
                        themselves informed of the current Terms. </p>
                    <p class="content-sub-heading">By accessing the Website and the services made available on this
                        Website (“Services”), you acknowledge that you have read, understood, and agree to be bound by
                        these Terms and the Privacy Policy. Your continued use of the Website and the Services will be
                        regarded as acceptance and acknowledgement of any amendments or modifications made to the Terms
                        from time to time, whether or not reviewed by you. </p>
                    <p class="content-sub-heading">If you do not accept and agree to any of the Terms, you should not
                        use the Website or any of the Services</p>
                    <p class="content-sub-heading">References to “us”, “we”, “our” and “the Company” refer to Signa X.
                        References to “you” and “User(s)” refers to users of the Website and the Services.</p>
                    <p class="content-heading">2. Use of the Website and the Services</p>
                    <p class="content-sub-heading">User representations and warranties. By using the Website and the
                        Services, you represent and warrant that:
                    </p>
                    <div class="content-sub-content">
                        <p>● you are at least 18 years of age and are of sound mind;</p>
                        <p>● in making any transaction on the Website or using the Services, you possess the legal
                            authority to create a binding legal obligation;</p>
                        <p>● you will use the Website in accordance with these Terms and all applicable laws and
                            regulations;</p>
                        <p>● you will only use the Website for lawful purposes;</p>
                        <p>● you will not carry out fraudulent activity using the Website and/or the Services;</p>
                        <p>● you will not impair the Services, the Website and the network and software on which it runs
                            in any way;</p>
                        <p>● you accept the Terms and the Privacy Policy wholly and unconditionally; </p>
                        <p>● if and when applicable, you will satisfy all your obligations, tax, withholding or
                            otherwise as required by your country of residence;</p>
                        <p>● you are not a person located, organised or resident in a country or territory that is
                            subject of sanctions administered or enforced by the United States of America, the European
                            Union or any other governmental authority; </p>
                        <p>● in relation to information supplied by you on the Website or to use the Services: (1) such
                            information is true, accurate, current and complete; (2) it is your responsibility to update
                            your information in a timely way when there are any changes; (3) the Company and its
                            affiliates may rely on such information provided by you as being true, accurate, current and
                            complete; and (4) we have the right to terminate your account if any of the information
                            supplied by you fails to be true, accurate, current and complete; and</p>
                        <p>● you are not an undischarged bankrupt in any country.</p>
                    </div>
                    <p class="content-footer-heading">You further agree not to use the Website, the Services, its
                        respective contents or information for any commercial or non-personal purpose (direct or
                        indirect) or for any purpose that is illegal, unlawful or prohibited by the Terms. </p>
                    <p class="content-footer-heading">You acknowledge that the company might enter into a separate
                        agreement or freelance contract with you for any services towards projects, sale of digital
                        assets/NFT’s/3D Models or any other commercial engagements.</p>
                    <p class="content-footer-heading">Secure Access. You are responsible for implementing reasonable
                        measures to secure access to (i) any device connected with your use of the Website and/or
                        the Services; and (ii) your login or identifying credentials necessary to access the
                        Services. </p>
                    <p class="content-footer-heading">Revocation of Use. We reserve the right at our sole discretion
                        to deny anyone access to the Website and the Services at any time without notice, and for
                        any reason, including but not limited to, violation of these Terms.</p>
                    <p class="content-heading">3. Prohibited Transactions and Activities</p>
                    <p class="content-sub-heading">The content and information on the Website as well as the
                        infrastructure used to provide such content and information, is proprietary to us. You agree not
                        to modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create
                        derivative works from, transfer, sell or re-sell any information, software, products or services
                        obtained from or through the Website (including the Services).
                    </p>
                    <p class="content-sub-heading">If we suspect that you have breached the Terms or that your use of
                        the Website and/or the Services shows signs of fraud, abuse or suspicious activity, we may
                        cancel any transactions associated with you, and we may restrict or terminate your use of the
                        Website and the Services. If you have conducted any fraudulent activity, we reserve the right to
                        take any necessary legal action and you may be liable for losses to us, including litigation
                        costs and damages.
                    </p>
                    <p class="content-sub-heading">The Company reserves the right to prohibit you from using the Website
                        or the Services should you be found doing so by unauthorised means or for purposes other than
                        which the Services or the Website is intended to be used. You will bear all liability for any
                        loss or damage, whether suffered by the Company or any third party, resulting from your breach
                        of any of the provisions of these Terms.
                    </p>
                    <p class="content-sub-heading">In connection with your use of the Website, the Services or in the
                        course of your interaction with us, other Users or third parties, you undertake not to:
                    </p>
                    <div class="content-sub-content">
                        <p>1. breach these Terms; </p>
                        <p>2. breach or attempt to breach any applicable laws and regulations;</p>
                        <p>3. provide false, inaccurate or misleading information; </p>
                        <p>4. use deceptive business practices including but not limited to Ponzi / pyramid schemes,
                            multi-level marketing, guaranteed results, investment or trading courses and services;</p>
                        <p>5. engage in transactions that are potentially fraudulent, suspicious or illegal; </p>
                        <p>6. use services, devices or software used to circumvent the law or remove copyright
                            protections;</p>
                        <p>7. infringe or violate any copyright, trademark, right of publicity or privacy or any other
                            proprietary right under the laws of any jurisdiction, including digital or virtual goods or
                            assets; or</p>
                        <p>8. aid or abet any of the above.</p>
                    </div>
                    <p class="content-sub-heading">Additionally, you represent and warrant not to:
                    </p>
                    <div class="content-sub-content">
                        <p>1. use the Website and/or the Services for any non-authorised commercial purpose;</p>
                        <p>2. access, monitor or copy any content or information on the Website using any robot, spider,
                            scraper or other automated means or any manual process for any purpose without our express
                            prior written permission; </p>
                        <p>3. violate the restrictions in any robot exclusion headers on the Website or bypass or
                            circumvent other measures employed to prevent or limit access to the Website; </p>
                        <p>4. take any action that imposes, or may impose, in our discretion, an unreasonable or
                            disproportionately large load on the Website’s infrastructure; </p>
                        <p>5. deep-link to any portion of the Website for any purpose without our express prior written
                            permission; </p>
                        <p>6. re-sell, use, copy, monitor, display, download or reproduce any content or information,
                            software, products or services available on the Website for any commercial or competitive
                            activity or purpose;</p>
                        <p>7. "frame", "mirror" or otherwise incorporate any part of the Website into any other
                            applications without our prior written authorisation;</p>
                        <p>8. impersonate another person or entity or falsely state or otherwise misrepresent your
                            affiliation with a person or entity, or adopt a false identity if the purpose of doing so is
                            to mislead, deceive, or defraud another;</p>

                        <p>9. manipulate identifiers, including by forging headers, in order to disguise the origin of
                            any posting that you deliver;</p>
                        <p>10. use the Website in any manner which could damage, disable, overburden, impair or
                            otherwise interfere with the use of the Website or other users' browser or equipment, or
                            cause damage, disruption or limit the functioning of any software, hardware or
                            telecommunications equipment;</p>
                        <p>11. attempt to gain unauthorised access to the Website, other accounts, computer systems, or
                            networks connected to the Website, through hacking, password mining or any other means; </p>

                        <p>12. obtain or attempt to obtain any materials or information through any means not
                            intentionally made available through the Website, including harvesting or otherwise
                            collecting information about others such as email addresses; </p>
                        <p>13. do anything else which could cause damage to or would otherwise have a negative impact on
                            the Company and its affiliates, their reputation, directors, advisors, consultants,
                            representatives and/or employees; or</p>
                        <p>14. aid or abet any of the above.</p>
                    </div>

                    <p class="content-heading">4. Privacy Policy</p>
                    <p class="content-sub-heading">Our Privacy Policy, which also governs your use of the Website and is
                        incorporated by reference into these Terms.
                    </p>
                    <p class="content-heading">5. Intellectual Property</p>
                    <p class="content-sub-heading">The Company does not grant you any rights or licenses with respect to
                        the Website; any rights or licenses not expressly granted herein are reserved.
                    </p>
                    <p class="content-sub-heading">The Website (including all its components, processes and design in
                        its entirety), contains copyright material, trade names and other proprietary information,
                        including but not limited to text, software, photos and graphics. The Company owns or is the
                        licensee of all intellectual property rights on the Website. The Company, its affiliates and/or
                        its licensors own copyright and/or database rights in the original content as well as such
                        content as otherwise amended or enhanced from time to time.
                    </p>
                    <p class="content-sub-heading">You may not modify, publish, transmit, participate in the transfer or
                        sale of, create derivative works or in any way exploit any of the content, in whole or in part,
                        on the Website, except as provided in the Terms. You may use information from the Website for
                        your own personal use only. Except as otherwise expressly permitted under copyright law, no
                        copying, redistribution, retransmission, publication or commercial exploitation of material on
                        the Website will be permitted without our express permission and that of the copyright owner’s.
                    </p>
                    <p class="content-sub-heading">Unless stated otherwise, the software required for the Services and
                        the Website and the intellectual property rights (including the trademarks, service marks,
                        logos, designs, copyrights, etc.) in the contents and information and material on the Website
                        are owned by the Company, its affiliates and/or or licensors. We do not assume any liability for
                        copyrighted materials provided by third parties or any intellectual property right infringements
                        by such third parties.
                    </p>

                    <p class="content-heading">6. Links to Third Party Websites and Apps</p>
                    <p class="content-sub-heading">We may provide hypertext links to other apps or websites. Using such
                        a link, you acknowledge leaving the Website and proceeding at your own responsibility.
                    </p>
                    <p class="content-heading">7. Other Terms</p>
                    <p class="content-sub-heading">7.1. GOVERNING LAW. The Terms and the relationship between the
                        Company and you in respect of our Services shall be governed by, and construed in accordance
                        with, the laws of England and Wales.
                    </p>
                    <p class="content-sub-heading">7.2 ENTIRE AGREEMENT. These Terms constitute the entire agreement
                        between you and the Company. We may amend the Terms at any time by posting a variation on the
                        Website. The latest version of the Terms will supersede all previous versions as well as any
                        other agreements or understandings (oral or written) between you and the Company.
                    </p>
                    <p class="content-sub-heading">7.3 SEVERANCE. If any provision of the Terms or part thereof is
                        rendered void, illegal or unenforceable by any legislation to which it is subject, it shall be
                        rendered void, illegal or unenforceable only to that extent and shall in no way affect or
                        prejudice the enforceability of the remainder of such provision or the other provisions of the
                        Terms.
                    </p>
                    <p class="content-sub-heading">7.4 ASSIGNMENT AND THIRD PARTY RIGHTS. You may not assign, convey,
                        transfer or delegate your rights, duties or obligations hereunder and you acknowledge that we
                        may assign, convey, transfer or delegate its rights, duties or obligations hereunder without
                        your consent. Other than you, the Company and the Relevant Persons (in respect of paragraph 8.8
                        and 8.9), no other person has any rights to enforce any provision of these Terms.
                    </p>
                    <p class="content-sub-heading">7.5 WAIVER. Our delay or failure to exercise or enforce any right or
                        provision of these Terms shall not constitute a waiver of such right or provision.
                    </p>
                    <p class="content-sub-heading">7.6 NO REPRESENTATIONS AND WARRANTIES. All content and the Services
                        available on the Website are provided to you on an “as is where is” basis, and we make no
                        representations or warranties of any kind (whether express or implied). We, our shareholders,
                        subsidiaries, affiliates, officers, directors, agents, representatives, advisors and employees,
                        disclaim all representations, conditions and warranties (whether express or implied), including
                        but not limited to implied warranties of title, merchantability, fitness for any purpose,
                        informational content, title or non-infringement of rights of third parties.
                    </p>
                    <p class="content-sub-heading">We do not represent, warrant or guarantee that the Website and/or
                        Services will operate error-free or uninterrupted, that defects will be corrected, or that the
                        Website and/or its servers will be free of viruses and/or other harmful components. The Website
                        and/or Services may be temporarily suspended for maintenance or upgrades, or interrupted or
                        interfered with by multiple factors outside of our control.
                    </p>
                    <p class="content-sub-heading">You further acknowledge that we are not responsible for information
                        disclosed on the Website that is provided by you and/or third-party service providers
                        (“Third-Party Information”). We will not verify whether, and cannot guarantee that, all
                        Third-Party Information is accurate, complete, correct or up to date, nor can we be held
                        responsible for any errors (including manifest and typographical errors) or sudden changes in
                        market conditions which may make any Third-Party Information displayed on the Website outdated,
                        inaccurate, misleading or untrue.
                    </p>
                    <p class="content-sub-heading">7.7 LIMITATION OF LIABILITY. In no event shall the Company, its
                        shareholders, subsidiaries, affiliates, officers, directors, employees, agents, representatives
                        or distributors (together, the “Relevant Persons”) be liable to any person or entity for any
                        loss of revenue, profit, losses, damages or expenses of any kind whatsoever arising in
                        connection with the use of the Services or the Website, regardless of whether (1) such loss,
                        damage or expense is caused by negligence or otherwise, and (2) any Relevant Person has any
                        control or influence over the circumstances giving rise to the claim.
                    </p>
                    <p class="content-sub-heading">For the avoidance of doubt the Relevant Persons shall not be
                        responsible for:
                    </p>
                    <div class="content-sub-content">
                        <p>[a] any failures caused by server errors, misdirected or redirected transmissions, failed
                            internet connections, interruptions in the transmission or receipt of purchases or any
                            mobile phone virus or other technical defect, whether human or technical in nature; </p>
                        <p>[b] any loss, damage or expense resulting from the ceasing or change in the Website and/or
                            the Services; and</p>
                        <p>[c] any inability on your part to possess a compatible device or to download the correct
                            software on the device with which you access the website or use the Services.</p>
                    </div>
                    <p class="content-sub-heading">Nothing in these terms limit or exclude our liability for (a) death
                        or personal injury caused by our negligence; (b) fraud or fraudulent misrepresentation; (c) any
                        other matter in respect of which we are not permitted to exclude or limit our liability under
                        applicable law.
                    </p>
                    <p class="content-sub-heading">Notwithstanding anything in this Agreement to the contrary (but
                        subject always to the preceding paragraph), the liability of the Company in respect of all
                        claims by any User pursuant to or in connection with the Website and/or the Services shall not
                        in any event exceed USD1,000. Further, the Company shall not be liable in any way or in any
                        event in respect of any claim if such claim was not made within 6 months from the date of the
                        cause of action arose.
                    </p>
                    <p class="content-sub-heading">7.8 INDEMNITY. You agree to defend, indemnify and hold harmless the
                        Relevant Persons from and against any and all claims, liabilities, damages or losses (including
                        legal fees and expenses) that the Relevant Persons incurs or suffers as a result of your (a) use
                        of the Website and/or the Services, (b) your breach of the Terms, and (c) your breach of any
                        laws, regulations or third-party rights.
                    </p>
                    <p class="content-heading">8. SUPPORT</p>
                    <p class="content-sub-heading">You may contact us at team@signa-x.io for any technical and customer
                        support in respect of the Website and the Services.
                    </p>
                </div>
            </div>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name: "TermsUse",
    props: ["visible"],
    methods: {
        close() {
            this.$emit('close');
        }
    },
}
</script>
<style scoped>
.dialog {
     background: var(--empty-color);
    padding: 20px;
    border-radius: 10px;
    background-image: url("../../assets/popup.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.title {
    font-size: 40px !important;
    text-align: left;
    font-family: 'Orbitron', sans-serif !important;
}

.header {
    background: linear-gradient(90.45deg, #00FFBF -2.93%, #1657DC 95.78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: 'Orbitron', sans-serif !important;
    font-weight: 800;
}

.content {
    font-family: 'Chakra Petch', sans-serif;
    color: white;
    margin-bottom: 20px;
}

.sub-title {
    font-size: 18px;
    color: white;
    font-weight: bold;
    font-family: 'Chakra Petch', sans-serif;
    margin-left: 0px;
}

.content-desc {
    font-size: 14px;
    color: white;
    font-family: 'Chakra Petch', sans-serif;
    margin-left: 0px;
}

.content-heading {
    font-size: 18px;
    color: white;
    font-weight: bold;
    font-family: 'Chakra Petch', sans-serif;
    margin-left: 0px;
}

.content-sub-heading {
    font-size: 14px;
    color: white;
    font-weight: 400;
    font-family: 'Chakra Petch', sans-serif;
    margin-left: 0px;
}

.content-sub-content {
    margin-left: 0%;
}

.content-sub-heading {
    font-size: 14px;
    color: white;
    font-weight: 400;
    margin-left: 0px;
}
</style>