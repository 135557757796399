<template>
    <div>
        <v-dialog v-model="visible" persistent max-width="800" class="dialog">
            <div class="card-body dialog">
                <v-row style="justify-content: space-between; width: 100%;">
                    <v-col lg="11">
                        <h3 :class="'model-title-designer'">Things you can do on the platform</h3>
                    </v-col>
                    <v-col lg="1">
                        <v-icon color="white" size="25" class="mt-4 ml-10" @click="close()">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <div class="content d-flex mt-10">
                    <v-expansion-panels dark v-model="selectedQuestion" style="width: 100%">
                        <v-expansion-panel v-for="(content, index) in questions" :key="index"
                            style="margin-bottom: 20px;" class="expansion">
                            <v-expansion-panel-header>
                                <div class="title-header">
                                    <img :src="`${content['selected']}`" width="20px" style="margin-right: 10px;"
                                        v-if="selectedQuestion === index" />
                                    <img :src="`${content['not-selected']}`" width="20px" style="margin-right: 10px;"
                                        v-else />
                                    <p
                                        :class="selectedQuestion === index ? 'content-header-selected' : 'content-header'">
                                        {{ content.question }}</p>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="content-response">{{ content.answer }}</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </div>
        </v-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { faqObj } from '../../services/faq-services';
export default {
    name: "FAQModal",
    props: ['visible'],
    methods: {
        close() {
            this.$emit('close');
        },
        async getAllFAQ() {
            try {
                if (this.user.role === 'team') {
                    const data = await faqObj.getAllFAQ();
                    this.questions = data;

                } else if (this.user.role === 'designer') {
                    const data = await faqObj.getAllFAQDesigner('3d-creators');
                    this.questions = data;

                } else {
                    const data = await faqObj.getAllFAQMeta('metaverse-project-owners');
                    this.questions = data;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    data() {
        return {
            questions: [

            ],
            selectedQuestion: -1
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
    },
    created() {
        this.getAllFAQ();
    },
}
</script>
<style scoped>
.dialog {
    background: #090D2E;
    padding: 20px;
    border-radius: 10px;
    background-image: url("../../assets/popup.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

h3 {
    margin: 0px;
    font-size: 30px !important;
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 26.2%,
            #ff3f7f 98.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: 'Orbitron', sans-serif !important;
    font-style: normal;
    font-weight: 800;
    text-align: left;
}

.expansion {
    background: rgba(196, 196, 196, 0.1) !important;
    box-shadow: none !important;
}

.content-header {
    margin: 0px;
    color: white;
    font-family: 'DM Sans', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.content-header-selected {
    margin: 0px;
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 26.2%,
            #ff3f7f 98.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: 'DM Sans', sans-serif !important;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
}

.content-response {
    margin: 0px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    font-family: 'DM Sans', sans-serif !important;
}

.popup {
    margin-left: 30%;
}

.v-dialog {
    margin-left: 30% !important;
}

.title-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
</style>