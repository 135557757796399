import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import Portis from "@portis/web3";
import Torus from "@toruslabs/torus-embed";
import axios from "axios";

class WalletService {
  async metamaskConfig() {
    try {
      window.ethereum.request({ method: 'eth_requestAccounts' });
      const accounts = await window.ethereum.request({ method: 'eth_accounts' });
      const result = {
        address: accounts[0],
        balance: 0,
        isConnected: true,
      };
      return result;
    } catch (error) {
      console.log(error);
      const result = {
        address: undefined,
        balance: 0,
        isConnected: false,
      };
      return result;
    }
  }
  async walletConnectConfig() {
    try {
      const provider = new WalletConnectProvider({
        infuraId: "ID", // Required
      });
      await provider.enable();
      const instance = new ethers.providers.Web3Provider(provider);
      const signer = instance.getSigner();
      const address = await signer.getAddress();
      const balance = await instance.getBalance(address);
      const result = {
        address: address,
        balance: ethers.utils.formatEther(balance),
        isConnected: true,
        isConnected: true,
      };
      return result;
    } catch (error) {
      const result = {
        address: undefined,
        balance: undefined,
        isConnected: false,
      };
      return result;
    }
  }
  async portisConfig() {
    try {
      const portis = new Portis(
        "e5a451b8-dc2d-4885-901d-caef0cde2543",
        "mainnet"
      );
      const web3 = new Web3(portis.provider);
      const address = (await web3.eth.getAccounts())[0];
      const balance = await web3.eth.getBalance(address);
      return {
        address: address,
        balance: ethers.utils.formatEther(balance),
        isConnected: true
      };

    } catch (error) {
      return {
        address: undefined,
        balance: 0,
        isConnected: false,
      };
    }
  }
  async torusConfig() {
    try {
      const torus = new Torus();
      await torus.init();
      const web3 = new Web3(torus.provider);
      const address = (await web3.eth.getAccounts())[0];
      const balance = await web3.eth.getBalance(address);
      return {
        address: address,
        balance: ethers.utils.formatEther(balance),
        isConnected: true,
      };
    } catch (error) {
      return {
        address: undefined,
        balance: 0,
        isConnected: false,
      };
    }
  }
  async getOpenseaData(address, user) {
    try {
      const response = await axios({
        method: "GET",
        url: `https://api.opensea.io/api/v1/collections?asset_owner=${address}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      });
      if (response.status === 200) {
        const data = response.data;
        const assets = [];
        const props = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          const assetData = element['primary_asset_contracts'];
          const traits = element.traits;
          const propData = Object.keys(element.traits);
          for (let propIndex = 0; propIndex < propData.length; propIndex++) {
            const element = propData[propIndex];
            let item = {
              title: element,
              value: traits[element],
            }
            props.push(item);
          }
          for (let assetIndex = 0; assetIndex < assetData.length; assetIndex++) {
            const elementAsset = assetData[assetIndex];
            let item = {
              price: 0,
              tokenId: index,
              seller: '',
              owner: elementAsset.address,
              image: elementAsset.image_url,
              name: elementAsset.name,
              description: elementAsset.description,
              isRented: false,
              isLent: false,
              type: '',
              placeholder: elementAsset.image_url,
              selectedAssetType: ['Metaverse'],
              attributes: props,
              originalSeller: elementAsset.address,
              sold: false,
              isVerified: false,
              assetAddress: '',
              shortAddress: '',
              user: user,
              category: [],
              fileFormats: [],
            };
            assets.push(item);
          }
        }
        return assets;
      }
    } catch (error) {
      return error;
    }
  }
  async caduceusConfig() {
    try {
      window.ethereum.request({ method: 'eth_requestAccounts' });
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: "0x7d200" }], // '0x3830303031'
      });
      const accounts = await window.ethereum.request({ method: 'eth_accounts' });
      const result = {
        address: accounts[0],
        balance: 0,
        isConnected: true,
      };
      console.log(result);
      return result;
    } catch (error) {
      console.log(error);
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: "0x7d200",
              blockExplorerUrls: ['https://galaxy.block.caduceus.foundation'],
              chainName: 'CMP Testnet',
              nativeCurrency: {
                decimals: 18,
                name: 'CMP',
                symbol: 'CMP'
              },
              rpcUrls: ['https://galaxy.block.caduceus.foundation']
            },
          ],
        });
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        const result = {
          address: accounts[0],
          balance: 0,
          isConnected: true,
        };
        console.log('inside catch try');
        console.log(result);
        return result;
      } catch (error) {
        console.log(`wallet_addEthereumChain Error: ${error.message}`);
        console.log(error);
        const result = {
          address: undefined,
          balance: 0,
          isConnected: false,
        };
        return result;
      }
    }
  }
}
export const walletObj = new WalletService();
