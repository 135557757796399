<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      v-model="visible"
    >
      <div
        class="card-body dialog"
        :style="showFeedback ? { filter: 'blur(10px)' } : { filter: 'none' }"
      >
        <div class="card-header">
          <v-row>
            <v-col lg="11">
              <h3>Submitted Interests</h3>
            </v-col>
            <v-col lg="1" class="mt-1">
              <v-icon class="span" @click="close()" size="30">mdi-close</v-icon>
            </v-col>
          </v-row>
        </div>
        <div class="table-header mt-10">
          <v-row align="center" justify="space-between">
            <v-col lg="1">
              <div class="header">Name</div>
            </v-col>
            <v-col lg="2">
              <div class="header">Proposed Budget</div>
            </v-col>
            <v-col lg="2">
              <div class="header">Proposed Deadline</div>
            </v-col>
            <v-col lg="2">
              <div class="header">Actual Budget</div>
            </v-col>
            <v-col lg="2">
              <div class="header">Actual Deadline</div>
            </v-col>
            <v-col lg="3">
              <div class="header">Actions</div>
            </v-col>
          </v-row>
        </div>
        <div class="mt-10">
          <v-row
            class="table-content"
            align="center"
            justify="space-between"
            v-for="(interest, index) in request.interests"
            :key="index"
          >
            <v-col lg="1">
              <div class="content">
                <div
                  style="
                    color: #ff7f3f !important;
                    text-decoration: underline;
                    cursor: pointer;
                  "
                  @click="goToProfile(interest.user.uid)"
                >
                  {{ interest.user.name }}
                </div>
              </div>
            </v-col>
            <v-col lg="2">
              <div class="content">$ {{ interest.uploadDetails.budget }}</div>
            </v-col>
            <v-col lg="2">
              <div class="content">
                {{ interest.uploadDetails.deadline }}
              </div>
            </v-col>
            <v-col lg="2">
              <div class="content">$ {{ request.assetBudget }}</div>
            </v-col>
            <v-col lg="2">
              <div class="content">
                {{ request.assetTime }}
              </div>
            </v-col>
            <v-col lg="3">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="showWarningDetails(index)"
                    v-bind="attrs"
                    v-on="on"
                    style="
                      background: rgba(255, 255, 255, 0.19);
                      border-radius: 10px;
                    "
                    class="mr-2"
                  >
                    <v-icon color="#ff7f3f">mdi-email</v-icon>
                  </v-btn>
                </template>
                <span>Automated Email</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="acceptInterest(interest, index)"
                    v-bind="attrs"
                    v-on="on"
                    style="
                      background: rgba(255, 255, 255, 0.19);
                      border-radius: 10px;
                    "
                    class="mr-2"
                  >
                    <v-icon color="#ff7f3f">mdi-check</v-icon>
                  </v-btn>
                </template>
                <span>Accept Interest</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="
                      openFeedback(
                        'Reject Feedback',
                        interest.user,
                        'reject',
                        index
                      )
                    "
                    v-bind="attrs"
                    v-on="on"
                    style="
                      background: rgba(255, 255, 255, 0.19);
                      border-radius: 10px;
                    "
                    class="mr-2"
                  >
                    <v-icon color="#ff7f3f">mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Reject Interest</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="
                      openFeedback(
                        'Send Feedback',
                        interest.user,
                        'feedback',
                        index
                      )
                    "
                    v-bind="attrs"
                    v-on="on"
                    style="
                      background: rgba(255, 255, 255, 0.19);
                      border-radius: 10px;
                    "
                  >
                    <v-icon color="#ff7f3f">mdi-message-outline</v-icon>
                  </v-btn>
                </template>
                <span>Send Feedback</span>
              </v-tooltip>
              <v-tooltip
                bottom
                v-if="
                  interest.uploadDetails.additionalInformation.length !== 0 ||
                  interest.uploadDetails.reference_url.length !== 0
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="openDetails(index)"
                    v-bind="attrs"
                    v-on="on"
                    style="
                      background: rgba(255, 255, 255, 0.19);
                      border-radius: 10px;
                    "
                    class="ml-2"
                  >
                    <v-icon color="#ff7f3f">mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View More Details</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-dialog>
    <Loader :text="loadingText" :visible="loading"></Loader>
    <v-dialog
      v-model="showFeedback"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <div
        class="card-body dialog"
        :style="loading ? { filter: 'blur(10px)' } : { filter: 'none' }"
      >
        <div class="card-header">
          <v-row>
            <v-col lg="11">
              <h3>{{ title }}</h3>
            </v-col>
            <v-col lg="1" class="mt-1">
              <v-icon class="span" @click="closeFeedback()" size="30"
                >mdi-close</v-icon
              >
            </v-col>
          </v-row>
        </div>
        <div class="form mt-10">
          <div class="form-group">
            <p>Choose the reason</p>
            <v-select
              v-model="feedbackForm.reason"
              :items="reasons"
              class="mySelect"
              height="100%"
              color="white"
              background-color="transparent"
              dark
              solo
              @change="onChangeSelect(index, sectionIndex)"
            >
            </v-select>
          </div>
          <div class="form-group mt-10">
            <p>Other Suggestions / Message</p>
            <textarea
              v-model="feedbackForm.message"
              autocomplete="off"
              :type="'text'"
              :placeholder="'suggestion / messages'"
            ></textarea>
          </div>
          <v-btn
            class="accept-button mt-10"
            @click="buttonClick()"
            :disabled="
              feedbackForm.reason.length === 0 ||
              feedbackForm.message.length === 0
            "
            >Submit</v-btn
          >
        </div>
      </div>
    </v-dialog>
    <v-dialog
      v-model="showDetails"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <div
        class="card-body dialog"
        :style="loading ? { filter: 'blur(10px)' } : { filter: 'none' }"
      >
        <div class="card-header">
          <v-row>
            <v-col lg="11">
              <h3>More Details</h3>
            </v-col>
            <v-col lg="1" class="mt-1">
              <v-icon class="span" @click="closeFeedback()" size="30"
                >mdi-close</v-icon
              >
            </v-col>
          </v-row>
        </div>
        <div class="form-group mt-5" v-if="this.selectedIndex !== -1">
          <p>Ideas/Thoughts</p>
          <h1 style="color: white; font-size: 18px; margin: 0px">
            {{
              request.interests[this.selectedIndex].uploadDetails
                .additionalInformation
            }}
          </h1>
        </div>
        <div class="form-group mt-5" v-if="this.selectedIndex !== -1">
          <p>Reference URL</p>
          <a
            :href="
              request.interests[this.selectedIndex].uploadDetails.reference_url
            "
            target="_blank"
            ><h1 style="color: white; font-size: 18px; margin: 0px">
              {{
                request.interests[this.selectedIndex].uploadDetails
                  .reference_url
              }}
            </h1></a
          >
        </div>
      </div>
    </v-dialog>
    <!-- <v-dialog v-model="viewSubmitted" transition="dialog-bottom-transition" persistent max-width="600">
      <div class="card-body dialog" v-if="request !== null">
        <div class="card-header">
          <v-row>
            <v-col lg="10">
              <h3>Submitted Template</h3>
            </v-col>
            <v-col lg="2" class="mt-1">
              <div class="span" @click="() => { viewSubmitted = false }">X</div>
            </v-col>
          </v-row>
        </div>
        <div class="card-content" v-if="selectedIndex !== -1">
          <p>Uploaded File</p>
          <div class="uploaded-data">
            <div class="uploaded-content">
              <img :src="request.interests[selectedIndex].uploadDetails.file" />
            </div>
          </div>
          <p>Original Template File</p>
          <div class="uploaded-data">
            <div class="uploaded-content">
              <img :src="request.templateFile" />
            </div>
          </div>
        </div>
      </div>
    </v-dialog> -->
    <v-dialog
      v-model="showWarning"
      persistent
      max-width="500"
      transition="dialog-bottom-transition"
      class="dialog"
      overlay-opacity="0.5"
      overlay-color="#e8e8e8"
    >
      <v-card :color="'#15181E'" class="model-card text-center dialog">
        <v-row justify="space-between" style="width: 100%">
          <v-col lg="12">
            <div :class="'general-text-title model-title'">
              Sure you want to send an confirmation email?
            </div>
            <div class="form-group">
              <p>Type of Mail to be sent</p>
              <v-select
                dark
                v-model="mailType"
                :items="mailTypeOptions"
                :rules="[(v) => !!v || 'Asset Type is required']"
                class="select-type"
                color="white"
                background-color="transparent"
                solo
              ></v-select>
            </div>
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-col lg="4">
            <v-btn class="mt-10 button button-data" @click="onClickEvent()"
              >Yes</v-btn
            >
          </v-col>
          <v-col lg="4">
            <v-btn
              class="mt-10 button button-data-secondary"
              @click="showClose()"
              >No</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
     <StatusModel :status="isSuccess" :title="statusTitle"
      :message="statusMessage"
      :visible="statusPopup" @close="close"></StatusModel>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  ADMIN_USER_ID,
  NOTIFICATION_TYPE_EXPRESS_INTEREST_REJECTED,
  NOTIFICATION_TYPE_EXPRESS_INTEREST_FEEDBACK,
} from "../../helpers/constants";
import { contactObj } from "../../services/contact-service";
import { notificationObj } from "../../services/notification-service";
import { requestObj } from "../../services/request-service";
import Loader from "../Loader/Loader.vue";
import { mailServiceObj } from "../../services/mail-service";
import StatusModel from "./StatusModel.vue";

export default {
  name: "ViewInterests",
  props: ["request", "visible"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) return this.$emit("close");
      },
    },
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      loading: false,
      loadingText: "",
      viewSubmitted: false,
      selectedIndex: -1,
      headers: [
        "Name",
        "Proposed Budget",
        "Proposed Deadline",
        "Actual Budget",
        "Actual Deadline",
        "Actions",
      ],
      showFeedback: false,
      title: "",
      selectedUser: null,
      type: "feedback",
      feedbackForm: {
        reason: "",
        message: "",
      },
      reasons: [
        "Not enough uploads",
        "Profile details incomplete",
        "Portfolio not upto the mark",
      ],
      messages: [],
      showDetails: false,
      showWarning: false,
      mailTypeOptions: ["Mail Before Call", "Mail Post Call"],
      mailType: "",
      statusPopup: false,
      statusTitle: "",
      statusMessage: "",
      isSuccess: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async acceptInterest(interest, index) {
      this.loading = true;
      this.loaderText = "Accepting interest...";
      this.request.projectTakenBy.push(interest.userId);
      this.request.interests.splice(index, 1);
      const requestId = this.$route.params.request_id;
      await requestObj.updateProjectTakenBy(requestId, this.request);
      this.loading = false;
      this.$emit("close");
    },
    view(index) {
      this.selectedIndex = index;
      this.viewSubmitted = true;
    },
    goToProfile(userId) {
      this.$router.push(`/user/${userId}`);
    },
    closeFeedback() {
      this.showFeedback = false;
      this.showDetails = false;
    },
    openFeedback(heading, user, statusType, index) {
      this.title = heading;
      this.selectedUser = user;
      this.type = statusType;
      this.selectedIndex = index;
      this.showFeedback = true;
    },
    async buttonClick() {
      try {
        this.loading = true;
        this.loadingText = "Submitting response....";
        const userData = this.request.interests[this.selectedIndex];
        const chats = [];
        const data = await contactObj.getMessagesFromUserId(userData.userId);
        if (data.length >= 1) {
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            for (
              let chatIndex = 0;
              chatIndex < element.messages.length;
              chatIndex++
            ) {
              const chatElement = element.messages[chatIndex];
              chats.push(chatElement);
            }
          }
        }
        this.messages = chats;
        if (this.type === "reject") {
          const msg = `Hello ${userData.user.name}, we are sorry to inform you regarding your interest in project titled ${this.request.name} has been rejected.\nReason: ${this.feedbackForm.reason}\nFeedback: ${this.feedbackForm.message}`;
          this.messages.push({
            message: msg,
            type: "owner",
            user: this.user,
          });
          const jsonData = {
            messages: this.messages,
            userId: userData.userId,
            sentTime: new Date(),
          };
          this.request.interests.splice(this.selectedIndex, 1);
          await requestObj.updateRequest(this.request.request_id, this.request);
          await contactObj.sendMessage(jsonData);
          const notificationData = {
            title: "Interest has been rejected",
            message: `Your interest has been rejected, tap to know more about it`,
            meta: {
              request_id: "",
              chat: true,
            },
          };
          notificationObj.sendNotification(
            notificationData,
            ADMIN_USER_ID,
            userData.userId,
            NOTIFICATION_TYPE_EXPRESS_INTEREST_REJECTED
          );
        } else {
          const msg = `Hello ${userData.user.name}, we found your profile suitable for the project titled ${this.request.name}, but we woudl like you to go through the below feedback to improvise your profile.\nReason: ${this.feedbackForm.reason}\nFeedback: ${this.feedbackForm.message}`;
          this.messages.push({
            message: msg,
            type: "owner",
            user: this.user,
          });
          const jsonData = {
            messages: this.messages,
            userId: userData.userId,
            sentTime: new Date(),
          };
          await contactObj.sendMessage(jsonData);
          const notificationData = {
            title: "Interest status has been updated",
            message: `We would like you to update some of the information. Tap to know more...`,
            meta: {
              request_id: "",
              chat: true,
            },
          };
          notificationObj.sendNotification(
            notificationData,
            ADMIN_USER_ID,
            userData.userId,
            NOTIFICATION_TYPE_EXPRESS_INTEREST_FEEDBACK
          );
        }
        this.loading = false;
        this.closeFeedback();
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async openDetails(index) {
      this.selectedIndex = index;
      this.showDetails = true;
    },
    showClose() {
      this.showWarning = false;
    },
    showWarningDetails(index) {
      this.selectedIndex = index;
      this.showWarning = true;
    },
    async onClickEvent() {
      try {
        this.loadingText = "Sending Mail..";
        this.loading = true;
        const userData = this.request.interests[this.selectedIndex];
        const chats = [];
        const data = await contactObj.getMessagesFromUserId(userData.userId);
        if (data.length >= 1) {
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            for (
              let chatIndex = 0;
              chatIndex < element.messages.length;
              chatIndex++
            ) {
              const chatElement = element.messages[chatIndex];
              chats.push(chatElement);
            }
          }
        }
        this.messages = chats;
        if (this.mailType === "Mail Before Call") {
          this.messages.push({
            message: `Hi ${userData.user.name}!\nHope you are doing well.\nWe notice that you have expressed your interest in - ${this.request.name}. We would like for you to work with us and create some assets for the same.\nWe would request you to upload a few assets onto our platform so we could go through them to get an idea on your work.\nWould be great to get on a quick call with you to take this forward. I have attached our calendly link to your email, please book a slot that works for you.\nLooking forward to talking to you! Calendly Link\nThanks and Best Regards,\nTeam Signa X`,
            type: "owner",
            user: this.user,
          });
          const jsonData = {
            messages: this.messages,
            userId: userData.userId,
            sentTime: new Date(),
          };
          await contactObj.sendMessage(jsonData);
          const notificationData = {
            title: "Message From Team Signa X",
            message: `There is a message for you from Team Signa X`,
            meta: {
              request_id: "",
              chat: true,
            },
          };
          notificationObj.sendNotification(
            notificationData,
            ADMIN_USER_ID,
            userData.userId,
            "prepare_for_call"
          );
          await mailServiceObj.sendMailBeforeCall(
            userData.user.name,
            userData.user.email,
            this.request.name
          );
        } else if (this.mailType === "Mail Post Call") {
          this.messages.push({
            message: `Hi ${userData.user.name}!\nHope you are doing well.\nFirstly, thank you for expressing your interest on the Signa X Platform! Please note that you have proposed your interest for : \nName of Project- ${this.request.name}\nDescription - ${this.request.description}\nBudget- $${userData.uploadDetails.budget}\nTimeline-${userData.uploadDetails.deadline}\nYou can confirm your acceptance for this project by responding to this message or by replying to our email.\nOnce we recieve a confirmation from your end, we will share another email to keep track of the project updates.\nThank you,\nTeam Signa X`,
            type: "owner",
            user: this.user,
          });
          const jsonData = {
            messages: this.messages,
            userId: userData.userId,
            sentTime: new Date(),
          };
          await contactObj.sendMessage(jsonData);
          const notificationData = {
            title: "Message From Team Signa X",
            message: `There is a message for you from Team Signa X`,
            meta: {
              request_id: "",
              chat: true,
            },
          };
          notificationObj.sendNotification(
            notificationData,
            ADMIN_USER_ID,
            userData.userId,
            "prepare_for_call"
          );
          const project = {
            name: this.request.name,
            description: this.request.description,
            assetBudget: userData.uploadDetails.budget,
            assetTime: userData.uploadDetails.deadline,
          }
          await mailServiceObj.sendMailAfterCall(
           userData.user.name,
            userData.user.email,
            project
          );
        }
        this.loading = false;
        this.showClose();
        this.statusTitle = "Mail Sent Successfully";
        this.statusMessage = "Mail has been successfully sent";
        this.isSuccess = true;
        this.statusPopup = true;
        setTimeout(() => {
          this.statusPopup = false;
        }, 3000);
      } catch (error) {
        this.loading = false;
        this.statusTitle = "An Error Occurred";
        this.statusMessage = error.message;
        this.isSuccess = false;
        this.statusPopup = true;
        setTimeout(() => {
          this.statusPopup = false;
        }, 3000);
      }
    },
  },
  components: { Loader,StatusModel },
};
</script>
<style scoped>
.dialog {
  background-color: var(--empty-color);
  padding: 20px;
  background-image: url("../../assets/popup.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
}

h3 {
  background: linear-gradient(141.3deg, #eb5129 32.76%, #ffbf3f 77.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 30px;
  font-weight: 500;
  font-family: "Orbitron", sans-serif !important;
}

.span {
  width: 20px;
  height: 20px;
  font-size: 15px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.interest-list {
  margin-top: 10px;
}

.interest-item {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--border-color);
}

.interest-name {
  font-size: 18px;
  font-weight: 600;
  color: var(--border-color);
}

.interest-description {
  font-size: 12px;
  color: white;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.view-btn {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 56.2%,
    #ff3f7f 78.17%
  );
  font-family: "Bebas Neue" cursive !important;
}

.uploaded-data {
  padding: 10px;
  border: 1px solid var(--border-color);
  width: 100%;
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploaded-content {
  width: 200px;
  height: 200px;
}

.uploaded-content > img {
  width: 100%;
  height: 100%;
}

.card-content > p {
  margin: 30px 0px 0px 0px;
  font-size: 20px;
}

.card-body {
  width: 100%;
  height: auto;
}

.table-header {
  width: 100%;
  height: 50px;
  border: 1px solid var(--secondary-color);
  color: white;
  border-radius: 15px;
  background: rgba(196, 196, 196, 0.15);
  align-items: center;
  padding: 10px;
}

.table-content {
  width: 100%;
  border: 1px solid var(--designer-color);
  color: white;
  border-radius: 15px;
  background: rgba(196, 196, 196, 0.15);
  align-items: center;
  margin: 20px 0px 0px 0px;
}

.accept-button {
  padding: 5px;
  border-radius: 5px;
  font-size: 18px;
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  font-family: "Bebas Neue", cursive;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  letter-spacing: 0.8px !important;
  color: white;
  width: 25%;
}

.form-group {
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.form-group > p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  font-weight: 400;
}

.mySelect {
  width: 100%;
  height: 70px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}

textarea {
  width: 100%;
  height: 100px;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid var(--border-color) !important;
  outline: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  padding: 5px;
}
.model-card {
  padding: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sub-title {
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
}
.button-data {
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  font-family: "Bebas Neue", cursive;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  letter-spacing: 0.8px !important;
  color: white;
  font-size: 20px;
}
.model-title {
  font-size: 20px !important;
  text-align: center;
}
.button-data-secondary {
  font-family: "Bebas Neue", cursive;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  letter-spacing: 0.8px !important;
  color: white;
  font-size: 20px;
  border: 1px solid #ff7f3f;
  background: transparent !important;
}
.dialog {
  background: var(--empty-color);
  padding: 20px;
  border-radius: 10px;
  background-image: url("../../assets/popup.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.select-type {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff !important;
  font-family: "DM Sans", sans-serif;
  padding: 5px;
  font-size: 12px !important;
}
.form-group {
  padding-left: 20px;
  margin-top: 20px;
}

.form-group > p {
  margin-bottom: 10px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}
</style>
