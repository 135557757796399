import store from "../store";

//#eb5129
export const initializeNavigationForDesigner = () => {
  const allowedNavigation = [
    {
      path: "/home",
      name: "Home",
      icon: "mdi-home",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/profile?tab=2",
      name: "Assets",
      icon: "mdi-ethereum",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/request",
      name: "Projects",
      icon: "mdi-account-voice",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    // {
    //   path: "/wallet",
    //   name: "Wallet",
    //   icon: "mdi-wallet",
    //   color: "#C4C4C4",
    //   selected: "#ff7f3f",
    // },
    // {
    //   path: "/mint",
    //   name: "Mint",
    //   icon: "mdi-ethereum",
    //   color: "#C4C4C4",
    //   selected: "#ff7f3f",
    // },
    // {
    //   path: "/software",
    //   name: "Tools",
    //   icon: "mdi-tools",
    //   color: "#C4C4C4",
    //   selected: "#ff7f3f",
    // },
    {
      path: "/resource",
      name: "Resources",
      icon: "mdi-semantic-web",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/faq",
      name: "FAQ's",
      icon: "mdi-frequently-asked-questions",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/contact",
      name: "Contact Us",
      icon: "mdi-forum",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    // {
    //   path: "/logout",
    //   name: "Logout",
    //   icon: "mdi-logout",
    //   color: "#C4C4C4",
    //   selected: "#ff7f3f",
    // },
  ];
  return store.dispatch("setNavigation", allowedNavigation);
};
export const initializeNavigationForOwner = () => {
  const allowedNavigation = [
    {
      path: "/home",
      name: "Home",
      icon: "mdi-home",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/market",
      name: "Marketplace",
      icon: "mdi-store",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/creator",
      name: "3D Creators",
      icon: "mdi-account-group",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/request",
      name: "Requests",
      icon: "mdi-account-voice",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    // {
    //   path: "/wallet",
    //   name: "Wallet",
    //   icon: "mdi-wallet",
    //   color: "#C4C4C4",
    //   selected: "#ff7f3f",
    // },
    // {
    //   path: "/mint",
    //   name: "Mint",
    //   icon: "mdi-ethereum",
    //   color: "#C4C4C4",
    //   selected: "#ff7f3f",
    // },
    {
      path: "/resource",
      name: "Resources",
      icon: "mdi-semantic-web",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/contact",
      name: "Contact Us",
      icon: "mdi-forum",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    // {
    //   path: "/logout",
    //   name: "Logout",
    //   icon: "mdi-logout",
    //   color: "#C4C4C4",
    //   selected: "#ff7f3f",
    // },
  ];
  return store.dispatch("setNavigation", allowedNavigation);
};
export const initializeNavigationForTeam = () => {
  const allowedNavigation = [
    {
      path: "/overview",
      name: "Overview",
      icon: "mdi-view-dashboard",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/home",
      name: "Home",
      icon: "mdi-home",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/users",
      name: "Users",
      icon: "mdi-account",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/analytics",
      name: "Analytics",
      icon: "mdi-google-analytics",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/creator",
      name: "3D Creators",
      icon: "mdi-account-group",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/request",
      name: "Projects",
      icon: "mdi-account-voice",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/asset",
      name: "Assets",
      icon: "mdi-store",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/software",
      name: "Tools",
      icon: "mdi-tools",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/market",
      name: "MarketPlace",
      icon: "mdi-store",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/wallet",
      name: "Wallet",
      icon: "mdi-wallet",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/mint",
      name: "Mint",
      icon: "mdi-ethereum",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/resource",
      name: "Resources",
      icon: "mdi-semantic-web",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/faq",
      name: "FAQ's",
      icon: "mdi-frequently-asked-questions",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/website",
      name: "Website Register",
      icon: "mdi-web",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
  ];
  return store.dispatch("setNavigation", allowedNavigation);
};
export const initializeNavigationForComm = () => {
  const allowedNavigation = [
    {
      path: "/dashboard",
      name: "Profile",
      icon: "mdi-account",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/request",
      name: "Request",
      icon: "mdi-account-voice",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/notification",
      name: "Notification",
      icon: "mdi-bell",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
    {
      path: "/contact",
      name: "Contact Us",
      icon: "mdi-account",
      color: "#C4C4C4",
      selected: "#ff7f3f",
    },
  ];
  return store.dispatch("setNavigation", allowedNavigation);
};