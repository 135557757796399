import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Authentication from "../views/Auth/Authentication.vue";
import DesignerRegister from "../views/Auth/SignUpScreen.vue";
import Request from "../views/Request.vue";
import CreateNewRequest from "../components/Request/CreateNewRequest.vue";
import ViewRequest from "../components/Request/ViewRequest.vue";
import MintToken from "../components/Tokens/MintToken.vue";
import Market from "../views/MarketPlace.vue";
import Wallet from "../views/Wallet.vue";
import ChatModule from "../components/Communication/ChatModule.vue";
import Notification from "../views/Notification.vue";
import Logout from "../views/Auth/Logout.vue";
import ViewMarketItem from "../components/Market/ViewMarketItemNew.vue";
import Users from "../views/Users.vue";
import UserAssets from "../views/UserAssets.vue";
import Overview from "../views/Overview.vue";
import LazyMinting from "../components/LazyMinting.vue";
import SummarizeAssetRequest from "../components/Communication/SummarizeAssetRequest.vue";
import Resources from "../views/Resources.vue";
import OwnersPaymentPage from "../components/Market/OwnersPayment.vue";
import Landing from "../views/Landing.vue";
import { authObj } from "../services/auth_service";
import UploadAssets from "../components/UploadAssets/UploadAssets.vue";
import store from '../store/index.js';
import Analytics from "../views/Analytics.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Authentication",
    component: Authentication,
  },
  {
    path: "/register",
    name: "DesignerRegister",
    component: DesignerRegister,
  },
  {
    path: "/dashboard",
    name: "Home",
    component: Home,
  },
  {
    path: "/request",
    name: "Request",
    component: Request,
  },
  {
    path: "/request/new",
    name: "CreateNewRequest",
    component: CreateNewRequest,
  },
  {
    path: "/request/:request_id",
    name: "ViewRequest",
    component: ViewRequest,
  },
  {
    path: "/request/communication/:request_id",
    name: "ChatModule",
    component: ChatModule,
  },
  {
    path: "/mint",
    name: "MintToken",
    component: MintToken,
  },
  {
    path: "/market",
    name: "Market",
    component: Market,
  },
  {
    path: "/wallet",
    name: "Wallet",
    component: Wallet,
  },
  {
    path: "/notification",
    name: "Notification",
    component: Notification,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/market/:tokenId",
    name: "ViewMarketItem",
    component: ViewMarketItem,
  },
  {
    path: "/users",
    name: "RegisteredUsers",
    component: Users,
  },
  {
    path: "/analytics",
    name: "AdminAnalytics",
    component: Analytics,
  },
  {
    path: "/user-asset",
    name: "UserAssets",
    component: UserAssets,
  },
  {
    path: "/overview",
    name: "Overview",
    component: Overview,
  },
  {
    path: "/creator/mint",
    name: "LazyMinting",
    component: LazyMinting,
  },
  {
    path: "/creator/:ideaId",
    name: "SummarizeAssetRequest",
    component: SummarizeAssetRequest,
  },
  {
    path: "/resource",
    name: "Resources",
    component: Resources,
  },
  {
    path: "/market/:tokenId/payment",
    name: "OwnersPaymentPage",
    component: OwnersPaymentPage,
  },
  {
    path: "/home",
    name: "LandingPage",
    component: function () {
      return import(
        /* webpackChunkName: "LandingPage" */ "../views/Landing.vue"
      );
    },
  },
  {
    path: "/upload",
    name: "UploadAssets",
    component: UploadAssets,
  },
  {
    path: "/profile",
    name: "Profile",
    component: function () {
      return import(/* webpackChunkName: "Profile" */ "../views/Profile.vue");
    },
  },
  {
    path: "/asset",
    name: "LazyAssets",
    component: function () {
      return import(
        /* webpackChunkName: "LazyAssets" */ "../views/LazyAssets.vue"
      );
    },
  },
  {
    path: "/account/usermgmt?mode=resetPassword&oobCode=:oobCode&apiKey=:apiKey&lang=fr",
    name: "PasswordResetView",
    component: function () {
      return import(
        /* webpackChunkName: "PasswordResetView" */ "../views/Auth/PasswordResetView.vue"
      );
    },
  },
  {
    path: "/asset/:tokenId",
    name: "ViewMarketItemUpdate",
    component: function () {
      return import(
        /* webpackChunkName: "ViewMarketItemUpdate" */ "../components/Market/ViewMarketItemUpdate.vue"
      );
    },
  },
  {
    path: "/contact",
    name: "ContactUs",
    component: function () {
      return import(
        /* webpackChunkName: "ContactUs" */ "../components/Communication/ContactUs.vue"
      );
    },
  },
  {
    path: "/creator",
    name: "Creators",
    component: function () {
      return import(
        /* webpackChunkName: "Creators" */ "../views/Creators.vue"
      );
    },
  },
  {
    path: "/user/:userId",
    name: "Profile",
    component: function () {
      return import(/* webpackChunkName: "Profile" */ "../views/Profile.vue");
    },
  },
  {
    path: "/allMarket",
    name: "AllMarketplace",
    component: function () {
      return import(/* webpackChunkName: "AllMarketplace" */ "../views/AllMarketplace.vue");
    },
  },
  {
    path: "/faq",
    name: "FAQPage",
    component: function () {
      return import(/* webpackChunkName: "FAQPage" */ "../views/FAQPage.vue");
    },
  },
  {
    path: "/software",
    name: "Software",
    component: function () {
      return import(/* webpackChunkName: "Software" */ "../views/Softwares.vue");
    },
  },
  {
    path: "/market/:tokenId",
    name: "ViewMarketItemNew",
    component: function () {
      return import(
        /* webpackChunkName: "ViewMarketItemNew" */ "../components/Market/ViewMarketItemNew.vue"
      );
    },
  },
  {
    path: "/asset/public/:tokenId",
    name: "ViewAssetPublic",
    component: function () {
      return import(
        /* webpackChunkName: "ViewMarketItemNew" */ "../publicContent/ViewAssetPublicNew.vue"
      );
    },
  },
  {
    path: "/profile/public/:userId",
    name: "ViewProfilePublic",
    component: function () {
      return import(
        /* webpackChunkName: "ViewProfilePublicNew" */ "../publicContent/ViewProfilePublicNew.vue"
      );
    },
  },
  {
    path: "/website",
    name: "WebsiteRegistration",
    component: function () {
      return import(
        /* webpackChunkName: "WebsiteRegistration" */ "../views/WebsiteRegistration"
      );
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let lastRoute = from.fullPath;
  localStorage.setItem("lastRoute", lastRoute);
  store.dispatch('setGlobalPopup', false);
  window.scrollTo(0,0);
  const d = document.getElementById('video-content');
  if(d){
    d.scrollTo(0,0);
  }
  const publicPages = ["Authentication", "DesignerRegister", "Logout","ViewProfilePublic","ViewAssetPublic"];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = await authObj.isLoggedIn();
  if (!loggedIn && authRequired) {
    next("/");
  } else {
    next();
  }
});

export default router;
