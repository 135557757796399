<template>
  <div class="view-market-container">
    <div class="view-item-container" v-if="tokenDetails !== null">
      <div class="column-1">
        <div class="item-title">
          <div class="glow text">{{ tokenDetails.name }}</div>
          <div class="main text">{{ tokenDetails.name }}</div>
        </div>
        <div class="item-desc">
          {{ tokenDetails.description }}
        </div>
        <div class="item-creator mt-5">
          <div class="profile-pic">
            <img :src="tokenDetails.user.profile" style="width: 100%; height: 100%; object-fit: cover" />
          </div>
          <div class="creator-det">
            <p>Creator</p>
            <h3>{{ tokenDetails.seller }}</h3>
          </div>
        </div>
        <!-- <div class="item-stats mt-5">
          <div class="item-stat">
            <v-icon color="#ff7f3f">mdi-ethereum</v-icon>
            <div class="stat-value">
              <h3>20k+</h3>
              <p>User Active</p>
            </div>
          </div>
          <div class="item-stat">
            <v-icon color="#ff7f3f">mdi-currency-usd</v-icon>
            <div class="stat-value">
              <h3>8k+</h3>
              <p>Artworks</p>
            </div>
          </div>
          <div class="item-stat">
            <v-icon color="#ff7f3f">mdi-ethereum</v-icon>
            <div class="stat-value">
              <h3>5k+</h3>
              <p>Artist</p>
            </div>
          </div>
        </div> -->
        <div class="item-assets mt-5">
          <div class="asset-type">
            <p>Asset Type</p>
            <div class="asset-type-container">
              <v-icon color="#ff7f3f" size="20">mdi-nature</v-icon>
              {{ tokenDetails.selectedAssetType[0] }}
            </div>
          </div>
          <div class="asset-type ml-10">
            <p>File Format</p>
            <v-row class="" style="width: 100%;justify-content: flex-start;">
              <v-col lg="4" class="file-types" v-for="(file, index) in tokenDetails.fileFormats" :key="index">
                <div class="asset-type-container mr-0" style="padding-right: 35px">
                  <v-icon color="#ff7f3f" size="20" class="mr-2">mdi-nature</v-icon>
                  {{ file }}
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="item-assets mt-5">
          <div class="item-properties">
            <p>Properties</p>
            <v-row class="mt-0 ml-1">
              <v-col :lg="prop.value.length > 8 ? 4 : 3" class="property-container mr-2 mt-2" v-for="(prop, index) in tokenDetails.attributes"
                :key="index">
                <v-icon color="#ff7f3f" size="20">mdi-star</v-icon>
                <p class="key ml-1">{{ prop.title }}:</p>
                <p class="value ml-1">{{ prop.value }}</p>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="item-assets mt-10">
          <div class="asset-type">
            <p>Contract</p>
            <div class="asset-type-container">
              <v-icon color="#ff7f3f" size="20">mdi-nature</v-icon>
              0x4E76C10a5D..
            </div>
          </div>
        </div>
      </div>
      <div class="column-2">
        <div class="token-image-container mb-5 ml-5">
          <!-- <model-viewer :src="tokenDetails.image" ar-modes="webxr scene-viewer quick-look" seamless-poster
            shadow-intensity="1" camera-controls ar auto-rotate loading="eager" style="height: 100%; width: 100%">
          </model-viewer> -->
          <img :src="tokenDetails.placeholder"
            style="height: 100%;width: 100%; object-fit: contain;margin-left: 10%;margin-top: 0%" />
        </div>
        <div class="token-meta">
          <div class="section">
            <p class="heading">Price</p>
            <h3 class="section-value">{{ tokenDetails.price }} ETH</h3>
          </div>
          <div class="divider"></div>
          <div class="section">
            <p class="heading">Environment</p>
            <h3 class="section-value" v-if="tokenDetails.selectedAssetType.includes('and')">
              {{ tokenDetails.selectedAssetType.split("and")[0] }}
            </h3>
            <h3 class="section-value" v-else>
              {{ tokenDetails.selectedAssetType[0] }}
            </h3>
          </div>
          <div class="divider"></div>
          <div class="section">
            <div>
              <v-btn class="button" @click="showPopupForRent()">Buy/Lease</v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row-2 mt-10">
      <div class="view-custom-asset" v-if="user.role !== 'designer'">
        <img src="../../assets/request/custom.svg" style="height: 100%;width: 100%; object-fit: contain;cursor:pointer;"
          @click="clickCreateNew()" />
        <!-- <div class="custom-column-1">
        <h3 class="custom-content">
          Want to create custom assets for your project?
        </h3>
        <div style="width: 60%">
          <v-btn class="button" style="font-size: 18px !important">
            Request Now
          </v-btn>
          <p class="key">We will get back to you instantly!</p>
        </div>
      </div>
      <div class="custom-column-2">
        <img :src="customAssetImage" alt="" class="custom-asset-image" />
      </div> -->
      </div>
      <div class="view-top-assets mb-10" v-if="user.role !== 'designer'" style="margin-top: -5%;">
        <div class="header-title mt-10">
          <div class="glow small-text">Top Assets</div>
          <div class="main small-text">Top Assets</div>
        </div>
        <div class="asset-body">
          <v-row>
            <v-col lg="3" class="token">
              <img src="../../assets/marketplace/top-1.svg"
                style="width: 100%;height:100%; object-fit: contain; cursor:pointer" />
            </v-col>
            <v-col lg="3" class="token">
              <img src="../../assets/marketplace/top-2.svg"
                style="width: 100%;height:100%; object-fit: contain; cursor:pointer" />
            </v-col>
            <v-col lg="3" class="token">
              <img src="../../assets/marketplace/top-3.svg"
                style="width: 100%;height:100%; object-fit: contain; cursor:pointer" />
            </v-col>
            <v-col lg="3" class="token">
              <img src="../../assets/marketplace/top-4.svg"
                style="width: 100%;height:100%; object-fit: contain; cursor:pointer" />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <Loader :text="loaderText" :visible="isLoading"></Loader>
    <StatusModel :title="statusTitle" :message="statusMessage" :status="isSuccess" :visible="successPopup"
      @close="close"></StatusModel>
    <BuyOrLeaseModal :data="tokenDetails" :visible="showRentModal" @close="close"></BuyOrLeaseModal>
    <!-- <rent-asset-modal :token-details="tokenDetails" :visible="showRentModal" @close="close"></rent-asset-modal> -->
  </div>
</template>
<script>
import TokenCardUpdate from "../Common/TokenCardUpdate.vue";
import { assetObj } from "../../services/asset-service";
import { mapState } from "vuex";
import Loader from "../Loader/Loader.vue";
import StatusModel from "../Modals/StatusModel.vue";
import { SYSTEM_CONTRACT_ADDRESS } from "../../config";
import BuyOrLeaseModal from "../Modals/BuyOrLeaseModal.vue";
// import RentAssetModal from "../Modals/RentAssetModal.vue";

export default {
  name: "ViewMarketItemNew",
  computed: {
    ...mapState({
      user: (state) => state.user,
      tokenDetails: (state) => state.selectedMarketItem,
    }),
  },
  components: {
    TokenCardUpdate,
    Loader,
    StatusModel,
    // RentAssetModal,
    BuyOrLeaseModal
  },
  data() {
    return {
      tokens: [
        {
          selectedAssetType: ['Metaverse/Gaming'],
          favorites: 90,
          placeholder: 'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/marketplace%2Fimage%20573.svg?alt=media&token=5b465626-2495-429e-81e9-3e315cd28f09',
          name: 'Coming Soon',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt volutpat lacus, donec habitant massa commodo velit, aliquam. Lectus mauris pretium pellentesque vestibulum donec porttitor hac nulla lectus.',
          tokenId: 1,
          user: {
            profile: 'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/profile.svg?alt=media&token=cfff3f59-1754-4323-8298-1d72c6a76c40',
          },
          seller: '0x8626f6....6f694',
          fileFormats: ['glb', 'gltf'],
          attributes: [{ title: 'Hair', value: 'Orange' }],
          price: '50'
        },
        {
          selectedAssetType: ['Metaverse'],
          favorites: 89,
          placeholder: 'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/marketplace%2Fimage%20521.svg?alt=media&token=45a5947f-c32d-47ae-8bc5-8a2d3ea250c5',
          name: 'Coming Soon',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt volutpat lacus, donec habitant massa commodo velit, aliquam. Lectus mauris pretium pellentesque vestibulum donec porttitor hac nulla lectus.',
          tokenId: 2,
          user: {
            profile: 'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/profile.svg?alt=media&token=cfff3f59-1754-4323-8298-1d72c6a76c40',
          },
          seller: '0x8626f6....6f694',
          fileFormats: [ 'glb', 'gltf'],
          attributes: [{ title: 'Hair', value: 'Orange' }],
          price: '50'
        },
        {
          selectedAssetType: ['Metaverse / Gaming'],
          favorites: 83,
          placeholder: 'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/marketplace%2Fimage%20399.svg?alt=media&token=4be300a0-fce2-41a5-9c9f-f113f51f6c5d',
          name: 'Coming Soon',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt volutpat lacus, donec habitant massa commodo velit, aliquam. Lectus mauris pretium pellentesque vestibulum donec porttitor hac nulla lectus.',
          tokenId: 3,
          user: {
            profile: 'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/profile.svg?alt=media&token=cfff3f59-1754-4323-8298-1d72c6a76c40',
          },
          seller: '0x8626f6....6f694',
          fileFormats: [ 'glb', 'gltf'],
          attributes: [{ title: 'Hair', value: 'Orange' }],
          price: '50'
        },
        {
          selectedAssetType: ['Metaverse / Gaming'],
          favorites: 92,
          placeholder: 'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/marketplace%2Fimage%20594.svg?alt=media&token=03246d9e-e915-4bc5-afe9-83488b65573b',
          name: 'Coming Soon',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt volutpat lacus, donec habitant massa commodo velit, aliquam. Lectus mauris pretium pellentesque vestibulum donec porttitor hac nulla lectus.',
          tokenId: 4,
          user: {
            profile: 'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/profile.svg?alt=media&token=cfff3f59-1754-4323-8298-1d72c6a76c40',
          },
          seller: '0x8626f6....6f694',
          fileFormats: ['glb', 'gltf'],
          attributes: [{ title: 'Hair', value: 'Orange' }],
          price: '50'
        }
      ],
      customAssetImage:
        "https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/dummy%2Fcharacter-one.svg?alt=media&token=d65e84be-ea86-47c5-9817-c4c78c15b92d",
      isLoading: false,
      loaderText: "Verifying Asset....",
      isSuccess: false,
      successPopup: false,
      contractAddress: "",
      walletAddress: "",
      statusTitle: "",
      statusMessage: "",
      showRentModal: false,
    };
  },
  created() {
    // this.getAssetDetails();
  },
  methods: {
    async getAssetDetails() {
      try {
        this.loaderText = "Loading Asset Details....";
        this.isLoading = true;
        // const address = await assetObj.getWalletAddress();
        // this.walletAddress = address;
        const tokenId = this.$route.params.tokenId;
        if (tokenId.length > 3) {
          const res = await assetObj.getLazyMintById(tokenId);
          this.tokenDetails = res;
          this.tokenDetails.isVerified = res.verified;
          this.tokenDetails.seller = res.user.uid;
          this.tokenDetails.fileFormats = res.availableFileFormats;
        } else {
          const res = await assetObj.getAssetById(tokenId);
          const data = await assetObj.getMarketPlaceItems();
          this.tokenDetails = res;
          this.tokens = data;
          this.contractAddress = `${SYSTEM_CONTRACT_ADDRESS.substring(
            0,
            5
          )}...${SYSTEM_CONTRACT_ADDRESS.substring(
            SYSTEM_CONTRACT_ADDRESS.length - 5,
            SYSTEM_CONTRACT_ADDRESS.length
          )}`;
        }
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.isSuccess = false;
        this.statusTitle = "Error Fetching Asset Details";
        this.statusMessage = err.message;
        this.successPopup = true;
      }
    },
    async acceptAsset(tokenId) {
      if (tokenId.length > 3) {
        this.isLoading = true;
        await assetObj.updateTokenId(tokenId, this.tokenDetails);
        this.isLoading = false;
        this.statusTitle = "Asset Verified";
        this.isSuccess = true;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 2000);
      } else {
        this.isLoading = true;
        await assetObj.verifyUserAsset(tokenId, this.tokenDetails);
        this.isLoading = false;
        this.statusTitle = "Asset Verified";
        this.isSuccess = true;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 2000);
      }
    },
    close() {
      this.successPopup = false;
      this.showRentPopup = false;
      this.showRentModal = false;
    },
    viewOnEtherscan() {
      window.open(
        `https://goerli.etherscan.io/address/${SYSTEM_CONTRACT_ADDRESS}`,
        "_blank"
      );
    },
    async leaseAssetToSigna() {
      this.loaderText = "Leasing Asset....";
      this.isLoading = true;
      await assetObj.leaseTokenToSigna(
        this.tokenDetails.tokenId,
        this.tokenDetails.assetAddress
      );
      this.isLoading = false;
      this.isSuccess = true;
      this.statusTitle = "Asset Leased Successfully";
      this.statusMessage = "";
      this.successPopup = true;
      setTimeout(() => {
        this.successPopup = false;
      }, 2000);
    },
    async buyAssetFromUser() {
      this.loaderText = "Buying Asset....";
      this.isLoading = true;
      const transactionData = await assetObj.buyFromThePlace(
        this.tokenDetails.tokenId,
        this.tokenDetails.price
      );
      if (transactionData) {
        this.isLoading = false;
        this.isSuccess = true;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 2000);
      } else {
        this.isLoading = false;
        this.isSuccess = false;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 2000);
      }
    },
    async gotToUserProfile(seller_id) {
      this.$router.push(`/user/${seller_id}`);
    },
    showPopupForRent() {
      this.showRentModal = true;
    },
    clickCreateNew() {
      this.$router.push("/request/new");
    },
  },
};
</script>
<style scoped>
.view-market-container {
  width: 100%;
  min-height: 100vh;
  /* background-image: url("../../assets/marketplace/view_background.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 20px;
    background-image: url("../../assets/hue.svg");
}

.row-2 {
  /* background-image: url("../../assets/request/view_request.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
}

.view-item-container {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-image: url("../../assets/marketplace/view_background.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10% 20px 20px 20px;

}

.column-1 {
  flex: 0.5;
  height: 100%;
  padding-left: 5%;
}

.column-2 {
  flex: 0.5;
  height: 90%;
  padding-right: 5%;
  position: relative;
}

.text {
  font-size: 50px;
  font-weight: 700;
  font-family: "Orbitron", sans-serif;
  text-transform: capitalize !important;
}

.glow {
  position: absolute;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  filter: blur(20px);
}

.main {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.item-desc {
  width: 100%;
  color: rgba(255, 255, 255, 0.76);
  font-size: 14px;
  font-weight: 400 !important;
  font-family: 'Archivo', sans-serif;
  text-align: justify;
}

.item-stats {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-stat {
  flex: 0.33;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.stat-value>p {
  margin: 0px;
  font-weight: 500;
}

.stat-value>h3 {
  margin: 0px;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 900;
  font-family: "Archivo", sans-serif;
  font-size: 20px !important;
}

.item-creator {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--empty-color);
  margin-right: 10px;
}

.creator-det {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.creator-det>p {
  margin: 0px 0px 0px 0px;
  color: rgba(255, 255, 255, 0.76);
  font-size: 10px;
  font-weight: 400;
}

.creator-det>h3 {
  margin: 0px 0px 0px 0px;
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.item-assets {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.asset-type>p {
  margin: 0px;
  color: rgba(255, 255, 255, 0.75);
  font-size: 12px;
  font-weight: 400;
}

.asset-type-container {
  padding: 5px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid var(--border-color);
  color: white;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-family: 'Archivo', sans-serif;
}

.file-types {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.item-properties {
  width: 100%;
}

.item-properties>p {
  margin: 0px;
  color: rgba(255, 255, 255, 0.76);
  font-size: 12px;
  font-weight: 400;
}

.item-property {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.property-container {
  padding: 6px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid var(--border-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.key {
  color: rgba(255, 255, 255, 0.76);
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
}

.value {
  color: white;
  font-size: 12px;
  font-weight: 600;
  margin: 0px;
}

.token-image-container {
  width: 350px;
  height: 350px;
  /* border: 1px dashed white; */
  border-radius: 10px;
}

.token-image-container>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.token-meta {
  width: 450px;
  height: 100px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  background: var(--empty-color);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  bottom: -50px;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.heading {
  color: rgba(255, 255, 255, 0.76);
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
}

.section-value {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 20px;
  font-weight: 400;
  margin: 0px;
  font-family: "Archivo", sans-serif;
}

.divider {
  width: 1px;
  height: 60%;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
}

.button {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  font-family: "Bebas Neue", cursive;
  font-weight: 400 !important;
  font-size: 14px;
  color: white !important;
  border: none;
  outline: none;
  text-transform: none !important;
  padding: 10px;
  font-style: normal;
  letter-spacing: 0.8px !important;
}

.view-custom-asset {
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -5%;
  margin-left: 5%;
}

.custom-content {
  /* background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 26.2%,
    #ff3f7f 98.17%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  background-clip: text;
  font-size: 35px;
  font-weight: 500;
  margin: 0px;
  font-family: "Archivo", sans-serif;
  color: white;
}

.custom-column-1 {
  flex: 0.4;
  height: 100%;
  padding: 10px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.custom-column-2 {
  flex: 0.6;
  height: 100%;
}

.custom-asset-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.asset-body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header-title {
  height: 60px;
  position: relative;
}

.small-text {
  font-size: 30px;
  font-weight: 700;
  font-family: "Orbitron", sans-serif;
  text-transform: capitalize !important;
}

.view-top-assets {
  padding-left: 5%;
  padding-right: 5%;

}
</style>
